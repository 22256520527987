import React from 'react';
import { SelectField } from '../input/SelectField';

export type StringSelect = {
    fieldValue?: string;
    name: string;
    control: any;
    options: string[];
    placeholder?: string;
    required: boolean;
    error?: string;
    disabled?: boolean;
};

export function ControlledStringSelectComponent({
    fieldValue,
    name,
    control,
    options,
    placeholder = 'Select One...',
    required,
    error,
    disabled,
}: StringSelect) {
    const formattedOptions = options.map((s) => ({ label: s, value: s }));
    return (
        <SelectField
            placeholder={placeholder}
            name={name}
            control={control}
            value={!!fieldValue ? formattedOptions.find((o) => o.value === fieldValue) : undefined}
            options={formattedOptions}
            isMulti={false}
            required={required}
            error={error}
            onChange={(val) => val.value}
            disabled={disabled}
        />
    );
}
