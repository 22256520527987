import React from 'react';

type Props = {
    className?: string;
};

export const ValorantIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M20,9.7V1.8c0-0.1-0.1-0.2-0.2-0.1l-8.5,10.8c-0.1,0.1,0,0.2,0.1,0.2h6.1c0.2,0,0.3-0.1,0.4-0.2l1.9-2.5
                C20,9.9,20,9.8,20,9.7L20,9.7z M0.1,10l6.4,8.1c0.1,0.1,0.3,0.2,0.4,0.2h6.1c0.1,0,0.2-0.1,0.1-0.2L0.2,1.7C0.2,1.6,0,1.7,0,1.8v7.9
                C0,9.8,0.1,9.9,0.1,10L0.1,10z"
            />
        </svg>
    );
};
