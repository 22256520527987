// Copied from Nest.

import produce from 'immer';
import {
    Content_Media_Link_Type_Enum,
    Espin_Subscription_Status_Enum,
    Player_DetailsQuery,
} from '../generated/spin-graphql';

// could do better w/ types here.
export function removeNullsFromObject(obj: { [key: string]: any }): any {
    return Object.entries(obj)
        .filter(([_, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
}

export function filterDuplicates<T = { id: string }>(arr: T[], propType: keyof T) {
    return arr.filter((v, i, a) => a.findIndex((t) => t[propType] === v[propType]) === i);
}

export function filterDuplicatePrimitives<T>(arr: T[]) {
    return arr.filter(function (item, pos, self) {
        return self.indexOf(item) == pos;
    });
}

export const getRandomStringValue = (num: number = 1, prefix: string = 'rand') => {
    return Array(num)
        .fill(0)
        .map((_) => {
            return `${prefix}-${Date.now()}-${Math.floor(Math.random() * 1000)}`;
        });
};

export const getRandomEmailAddress = (num: number = 1, prefix: string = 'rand') => {
    return Array(num)
        .fill(0)
        .map((_) => {
            return `${prefix}-${Date.now()}-${Math.floor(Math.random() * 1000)}@gmail.com`;
        });
};

export const getRandomIntegerValues = (num: number = 1, min: number = 0, max: number = 100) => {
    return Array(num)
        .fill(0)
        .map((_) => {
            return randomInt(min, max);
        });
};

export const getRandomBooleanValue = () => {
    return Math.random() < 0.5;
};

export const getRandomTextValue = (num: number = 1) => {
    let string = '';
    for (let i = 0; i < num; i++) {
        string += Math.random().toString(36).slice(2) + ' ';
    }
    return string;
};

function randomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function randomFloat(min: number, max: number) {
    return Math.random() * (min - max) + min;
}

export function chooseRandomElement<T = any>(input: T[]): T {
    return input[Math.floor(Math.random() * input.length)];
}

// a square in the continental us
const minLat = 33.174;
const maxLat = 41.22;
const minLong = -108.43;
const maxLong = -86.4;

export function randomCoords() {
    const latitude = randomFloat(minLat, maxLat);
    const longitude = randomFloat(minLong, maxLong);
    return [longitude, latitude];
}

export const isUndefined = (obj: any): obj is undefined => typeof obj === 'undefined';

export const isObject = (fn: any): fn is object => !isNil(fn) && typeof fn === 'object';

export const isPlainObject = (fn: any): fn is object => {
    if (!isObject(fn)) {
        return false;
    }
    const proto = Object.getPrototypeOf(fn);
    if (proto === null) {
        return true;
    }
    const ctor = Object.prototype.hasOwnProperty.call(proto, 'constructor') && proto.constructor;
    return (
        typeof ctor === 'function' &&
        ctor instanceof ctor &&
        Function.prototype.toString.call(ctor) === Function.prototype.toString.call(Object)
    );
};

export const addLeadingSlash = (path?: string): string => (path ? (path.charAt(0) !== '/' ? '/' + path : path) : '');

/**
 * Deprecated. Use the "addLeadingSlash" function instead.
 * @deprecated
 */
export const validatePath = addLeadingSlash;

export const isFunction = (fn: any): boolean => typeof fn === 'function';
export const isString = (fn: any): fn is string => typeof fn === 'string';
export const isConstructor = (fn: any): boolean => fn === 'constructor';
export const isNil = (obj: any): obj is null | undefined => isUndefined(obj) || obj === null;
export const isEmpty = (array: any): boolean => !(array && array.length > 0);
export const isSymbol = (fn: any): fn is symbol => typeof fn === 'symbol';

export const isBrowser = () => typeof window !== 'undefined';
export const isServer = () => !isBrowser();

export function recurseAndRemoveProperty<T>(obj: T, toRemove: string): T {
    return produce(obj, (draft) => {
        for (const prop of Object.keys(draft)) {
            if (prop === toRemove) {
                delete draft[prop];
            } else if (!!draft[prop] && typeof draft[prop] === 'object') {
                draft[prop] = recurseAndRemoveProperty(draft[prop], toRemove);
            }
        }
    });
}

export function capitalizeFirstLetter(str: string) {
    return str[0].toUpperCase() + str.slice(1);
}
export type Removed<T, Drop = 'remove'> = T extends object
    ? {
          [K in Exclude<keyof T, Drop>]: Removed<T[K], Drop>;
      }
    : T;

export function isYoutubeVideo(url: string) {
    return YoutubeVideoRegex.test(url);
}

export function getYouTubeVideoId(url: string) {
    const id = url.match(YoutubeVideoRegex);
    if (id) {
        return id[1];
    }
    return null;
}

export const YoutubeVideoRegex = /^(?:https?:)?(?:\/\/)?(?:youtu\.be\/|(?:www\.|m\.)?youtube\.com\/(?:watch|v|embed)(?:\.php)?(?:\?.*v=|\/))([a-zA-Z0-9\_-]{7,15})(?:[\?&][a-zA-Z0-9\_-]+=[a-zA-Z0-9\_-]+)*$/;

export const YoutubeChannelRegex = /https?:\/\/www\.youtube\.com\/(c|channel|user)\/([a-zA-Z0-9_-]+)/;

export const TwitchVideoRegex = /(^https:\/\/(?:www\.){0,1}twitch.tv\/videos\/\d+$)/;

export const TwitchClipRegex = /(^(https:\/\/(?:www\.){0,1}twitch.tv\/([a-zA-Z0-9_-]+\/clip)\/([a-zA-Z0-9_-]+))|(https:\/\/(?:www\.){0,1}clips.twitch.tv\/([a-zA-Z0-9_-]+))$)/;

export const TwitchChannelRegex = /https:\/\/(?:www\.){0,1}twitch\.tv\/([a-zA-Z0-9_]+)$/;

export const TwitterHandleRegex = /^@[a-zA-Z0-9_]{1,16}$/;
export const TikTokHandleRegex = /^@[a-zA-Z0-9_]{1,28}$/;
export const FacebookProfileRegex = /(?:https:\/\/(?:www\.)?(?:facebook|fb)\.com\/([A-z0-9_\-\.]+)$|^[A-z0-9_\-\.]+$)/;
export const InstagramHandleRegex = /(^@?[a-zA-Z0-9_]{1,28}$|^https:\/\/www\.instagram\.com\/.*?$)/;
export const PhoneNumberRegex = /^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;
export const PhoneNumberRegexForPlayers = /^$|^(\+\d{1,2}\s)?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/;

// this should not be used anywher in app!!!
export const DiscordIdRegex = /^.{3,32}#[0-9]{4}$/;

// todo, make a choice on either vars above, or an object like below.
export const validators = {
    minimumGPA: /^([0-3]\.\d\d|1|2|3|4)/,
    // allow ANY https url
    httpsUrl: /^https:\/\/.*?$/,
};

export function getTwitchVideoFromInput(input: string) {
    if (input.startsWith('https')) {
        const matched = input.match(/https:\/\/www.twitch.tv\/videos\/(\d+)$/);
        if (matched) {
            return matched[1];
        }
    } else {
        // check and see if it's the id alone
        const matched = input.match(/^(\d+)$/);
        if (matched) {
            return matched[1];
        }
    }
    return null;
}

export function getTwitchClipFromInput(input: string) {
    if (input.startsWith('https')) {
        const matched = input.match(TwitchClipRegex);
        if (matched) {
            return matched[1];
        }
    }
    return null;
}

export function getYouTubeChannelFromInput(input: string) {
    const m = input.match(YoutubeChannelRegex);
    if (!!m && m.length > 2) {
        return `${m[1]}/${m[2]}`;
    }
    return null;
}

export function getTwitchChannelFromInput(input: string) {
    const m = input.match(TwitchChannelRegex);
    if (!!m && m.length > 1) {
        return m[1];
    }
    return null;
}

export function getMediaDataForMediaLinkType(input: string, mediaType: Content_Media_Link_Type_Enum) {
    return (() => {
        switch (mediaType) {
            case Content_Media_Link_Type_Enum.TwitchVideo:
                // parse the data
                return getTwitchVideoFromInput(input);
            case Content_Media_Link_Type_Enum.YoutubeVideo:
                return getYouTubeVideoId(input);
            case Content_Media_Link_Type_Enum.TwitchClip:
                return getTwitchClipFromInput(input);
            default:
                // default - not sure if we want this to actually send back null instead??
                return input;
        }
    })();
}
export function getAgeByBirthdate(birthDate: Date) {
    var today = new Date();
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

export function prettyPrint(thing: any) {
    console.log(JSON.stringify(thing, null, 2));
}

export function buildDefaultAutoMessageResponse(program_name: string, subbed: boolean) {
    // college receiving the message is subbed
    if (subbed) {
        return `Hello, and thank you for taking the time to reach out to us! I would love the opportunity to tell you a little bit about ${program_name} and also learn more about you and your goals. We will get back in touch with you ASAP.\n\nThanks again and we look forward to talking about your possible future with ${program_name} and esports!`;
    }
    // college receiving the message is unsubbed
    else {
        return `Hello, and thank you for taking the time to reach out to us! I would love the opportunity to tell you a little bit about ${program_name} and also learn more about you and your goals. We are not currently a subscriber on this platform, but please take a minute to visit our profile and there, you should see our contact info for esports. Please send us a message and we will get back in touch with you ASAP.\n\nThanks again and we look forward to talking about your possible future with ${program_name} and esports!`;
    }
}

export function isWithinRegistrationRange(register_start_date: string, register_end_date: string) {
    return (
        (register_start_date?.localeCompare(new Date().toISOString()) ?? 0) <= 0 &&
        (register_end_date?.localeCompare(new Date().toISOString()) ?? 0) >= 0
    );
}

export async function asyncForEach<T>(array: Array<T>, callback: (item: T, index: number) => Promise<void>) {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index);
    }
}

export function stripHTMLForPreview(HTMLString) {
    let doc = new DOMParser().parseFromString(HTMLString, 'text/html');
    return doc.body.textContent || '';
}

export function matchExactPlayerSlug(slug: string, isReady: boolean, data: Player_DetailsQuery) {
    if (!isReady) {
        return undefined;
    }

    if (data?.espin_player_profile.length === 0) {
        return undefined;
    } else if (data?.espin_player_profile.length === 1) {
        return data?.espin_player_profile[0];
    } else {
        return data?.espin_player_profile.find((player) => player.slug === slug);
    }
}
