import { CollegeCardFragment } from 'generated/spin-graphql';
import React from 'react';
import Link from 'next/link';
import { PictureWithFallback } from '../../../../sections/PictureWithFallback';
import { PlainTextLocationDisplay } from '../../../PlainTextLocationDisplay';

type Props = {
    college: CollegeCardFragment;
};

export const CollegePreviewCard: React.FC<Props> = ({ college }) => {
    return (
        <Link href={`/colleges/${college.slug}`}>
            <a className={`college-preview-card`}>
                <div className="college-icon">
                    <PictureWithFallback
                        icon={college.logo}
                        width={60}
                        height={60}
                        alt={`${college.program_name}} profile picture`}
                    />
                </div>
                <div className="college-info-container">
                    <h2 className="program-name">{college.program_name}</h2>
                    <p className="program-location">
                        <PlainTextLocationDisplay address={college.address} />
                    </p>
                </div>
            </a>
        </Link>
    );
};
