import React from 'react';

interface Props {
    className?: string;
}

export const PlayerIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="30" height="30" viewBox="0 0 30 30">
            <path
                d="M21.5,19.4H8.4c-2.7,0-4.9,2.4-4.9,5.3v1.8c0,2,1.5,3.6,3.3,3.6h16.4c1.8,0,3.3-1.6,3.3-3.6v-1.8
	            C26.4,21.7,24.3,19.4,21.5,19.4z"
            />
            <path
                d="M23.9,7.6h-0.2C23.1,3.3,19.4,0,15,0S6.9,3.3,6.3,7.6H6.1c-0.6,0-1.1,0.5-1.1,1.1v3.5c0,0.6,0.5,1.1,1.1,1.1
                h2.1c0.2,0,0.3,0,0.4-0.1c1.1,2.5,3.5,4.2,6.4,4.2c2.8,0,5.3-1.8,6.4-4.2c0.1,0.1,0.3,0.1,0.5,0.1h2.1c0.6,0,1.1-0.5,1.1-1.1V8.7
                C24.9,8.1,24.5,7.6,23.9,7.6z M15,14.2c-2.1,0-3.8-1.8-3.8-3.8s1.8-3.8,3.8-3.8s3.8,1.8,3.8,3.8S17.1,14.2,15,14.2z M15,3.5
                c-2.8,0-5.3,1.8-6.4,4.2C8.5,7.6,8.4,7.6,8.2,7.6c0.6-3.3,3.4-5.8,6.8-5.8c3.4,0,6.2,2.5,6.8,5.8c-0.2,0-0.3,0-0.5,0.1
                C20.3,5.2,17.8,3.5,15,3.5z"
            />
        </svg>
    );
};
