import Image from 'next/image';
import React from 'react';
import { buildFileUrlOrNull } from '../../client/images.utils';
import { Content_File } from '../../generated/spin-graphql';
import { DefaultPicture } from '../common/DefaultPicture';

type Props = {
    icon: Partial<Content_File> | string;
    width: string | number;
    height: string | number;
    alt: string;
};

export const PictureWithFallback: React.FC<Props> = ({ icon: iconURL, width, height, alt }) => {
    const imageURL = buildFileUrlOrNull(iconURL as Partial<Content_File>);
    const hasImageCustomImage = !(iconURL === null || iconURL === undefined) && imageURL === null;
    const urlToUse = hasImageCustomImage ? (iconURL as string) : imageURL;

    return (
        <>
            {hasImageCustomImage || imageURL ? (
                <Image
                    width={width}
                    height={height}
                    src={urlToUse}
                    alt={alt}
                    placeholder={'blur'}
                    blurDataURL={urlToUse}
                />
            ) : (
                <DefaultPicture />
            )}
        </>
    );
};
