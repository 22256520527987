import React from 'react';
import { Espin_Game_Definition_Enum } from '../../generated/spin-graphql';
import { getGameIconFromEnum } from '../../utils/GameUtils';

type Props = {
    className?: string;
    gameName: string;
    game: Espin_Game_Definition_Enum;
};

export const GameIcon: React.FC<Props> = ({ gameName, game, className }) => {
    return (
        <div title={gameName} className={`icon-container ${game}${className ? ` ${className}` : ''}`}>
            {getGameIconFromEnum(game)}
        </div>
    );
};
