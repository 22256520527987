import React from 'react';

type Props = {
    className?: string;
};

export const WoWIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M13,5.1c0.1,0.2,0.2,0.4,0.1,0.6c0,0.2-0.1,0.4-0.1,0.6c0,0.1,0,0.2-0.1,0.4c0,0.3-0.1,0.5-0.1,0.8c0,0.3-0.1,0.5-0.1,0.8
                c0,0.3-0.1,0.5-0.1,0.8c0,0.3-0.1,0.5-0.1,0.8c-0.1,0.4-0.1,0.8-0.2,1.2c0,0.1,0,0.3-0.1,0.4c-0.1,0.4-0.1,0.8-0.2,1.2
                c0,0.1,0,0.1,0,0.2c0,0,0,0-0.1,0.1c0,0,0,0,0-0.1c0-0.1,0-0.1,0-0.2c-0.1-0.3-0.2-0.6-0.3-1c-0.1-0.2-0.1-0.4-0.2-0.7
                c-0.1-0.2-0.1-0.5-0.2-0.7c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.2-0.9c-0.1-0.3-0.1-0.6-0.2-0.9c-0.1-0.3-0.2-0.6-0.3-1
                c-0.1-0.2-0.1-0.4-0.2-0.7c0,0.1-0.1,0.1-0.1,0.1C10,6.1,10,6.3,9.9,6.5C9.9,6.7,9.8,7,9.7,7.3C9.7,7.5,9.6,7.8,9.6,8
                C9.5,8.3,9.4,8.7,9.3,9C9.3,9.3,9.2,9.5,9.1,9.8C9.1,10,9,10.2,9,10.4c-0.1,0.3-0.2,0.6-0.2,0.9c-0.1,0.2-0.1,0.4-0.2,0.6
                c-0.1,0.2-0.1,0.4-0.2,0.5c0,0,0,0-0.1,0c0,0,0,0-0.1,0c0-0.2-0.1-0.3-0.1-0.5c0-0.2-0.1-0.4-0.1-0.7c-0.1-0.3-0.1-0.6-0.2-0.9
                c0-0.2-0.1-0.4-0.1-0.6c0-0.2-0.1-0.4-0.1-0.6C7.6,9,7.5,8.7,7.5,8.4C7.4,8.1,7.3,7.7,7.2,7.3c0-0.2-0.1-0.4-0.1-0.6
                C7.1,6.5,7,6.3,7,6.1c0-0.4,0-0.7,0-1.1c0-0.2,0-0.2-0.2-0.2c-1,0-1.9,0-2.9,0c0,0-0.1,0-0.1,0C3.9,5,4,5,4,5
                c0.2,0.2,0.4,0.4,0.5,0.6c0.1,0.2,0.2,0.5,0.2,0.7c0.1,0.2,0.1,0.5,0.2,0.7C4.9,7.3,5,7.5,5,7.7c0.1,0.3,0.2,0.7,0.2,1
                c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.2,0.7,0.2,1c0.1,0.3,0.1,0.5,0.2,0.8c0.1,0.3,0.2,0.7,0.2,1c0.1,0.2,0.1,0.5,0.2,0.7
                c0.1,0.4,0.2,0.8,0.3,1.2c0.1,0.4,0.2,0.7,0.2,1.1c0,0.2,0.1,0.3,0,0.5c-0.1,0.3-0.2,0.5-0.4,0.7c0,0-0.1,0.1-0.1,0.2
                c1.1,0,2.3,0,3.4,0c0-0.1-0.1-0.2-0.1-0.2c-0.1-0.3-0.2-0.6-0.1-0.9c0.1-0.3,0.1-0.6,0.2-0.8c0.1-0.2,0.1-0.5,0.2-0.7
                c0-0.2,0.1-0.4,0.1-0.6c0.1-0.3,0.1-0.7,0.2-1c0,0,0-0.1,0-0.1c0,0,0,0,0,0c0.1,0.2,0.1,0.5,0.2,0.7c0.1,0.3,0.1,0.6,0.2,0.8
                c0.1,0.3,0.1,0.6,0.2,0.9c0,0.2,0.1,0.4,0.1,0.6c0.1,0.3,0.1,0.7,0,1c0,0.1-0.1,0.2-0.1,0.4c0,0,0.1,0,0.1,0c1,0,2.1,0,3.1,0
                c0.1,0,0.1,0,0.1-0.1c-0.1-0.2-0.2-0.5-0.3-0.7c-0.1-0.2,0-0.4,0-0.6c0-0.2,0.1-0.5,0.1-0.8c0.1-0.3,0.1-0.7,0.2-1
                c0.1-0.5,0.1-1,0.2-1.5c0-0.1,0-0.2,0.1-0.4c0-0.2,0.1-0.5,0.1-0.7c0.1-0.4,0.1-0.7,0.2-1.1c0-0.3,0.1-0.5,0.1-0.8
                c0-0.3,0.1-0.6,0.1-0.9c0-0.2,0.1-0.5,0.1-0.7c0-0.2,0.1-0.4,0.1-0.5c0.2-0.5,0.5-1,0.8-1.5C16,5.2,16.1,5.1,16.2,5
                c-1.1,0-2.2,0-3.2,0C13,5,13,5,13,5.1z"
            />
            <path
                d="M18.7,5V2.4h-2.2C14.7,0.9,12.5,0,10,0S5.3,0.9,3.5,2.4H1.3V5C0.5,6.5,0,8.2,0,10s0.5,3.5,1.3,5v2.6h2.2
                c1.7,1.5,4,2.4,6.5,2.4s4.7-0.9,6.5-2.4h2.2V15c0.9-1.5,1.3-3.2,1.3-5S19.5,6.5,18.7,5z M10,18.5c-4.7,0-8.5-3.8-8.5-8.5
                S5.3,1.5,10,1.5s8.5,3.8,8.5,8.5S14.7,18.5,10,18.5z"
            />
        </svg>
    );
};
