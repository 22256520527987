import React from 'react';

interface Props {
    className?: string;
}

export const MessageIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M10,0.6C3.8,0.6,0,1.9,0,8.1c0,6.2,3.8,7.5,10,7.5c1.1,0,2.1,0,3.1-0.1l3.4,3.9l1.7-5.8
            c1.2-1.1,1.9-2.9,1.9-5.4C20,1.9,16.2,0.6,10,0.6z M16.6,11.9l-0.4,0.4l-0.7,2.5L14,13.1l-1.2,0.1c-0.8,0.1-1.8,0.1-2.8,0.1
            c-6.7,0-7.8-1.6-7.8-5.3S3.3,2.8,10,2.8s7.8,1.6,7.8,5.3C17.8,9.9,17.4,11.2,16.6,11.9z"
            />
        </svg>
    );
};
