import React, { KeyboardEventHandler, useCallback, useEffect, useState } from 'react';

import CreatableSelect from 'react-select/creatable';
import { ActionMeta, OnChangeValue } from 'react-select';
import { Controller } from 'react-hook-form';

const components = {
    DropdownIndicator: null,
};

interface Option {
    label: string;
    value: string;
}

const createOption = (label: string) => ({
    label,
    value: label,
});

interface State {
    readonly inputValue: string;
    readonly value: readonly Option[];
}

export function ControlledMultiSelectTextInput({
    label,
    placeholder = 'Type something and press enter...',
    name,
    control,
    defaultValue = [],
    required,
    error,
}: {
    label: string;
    placeholder?: string;
    control: any;
    name: string;
    defaultValue?: string[];
    required: boolean;
    error?: string;
}) {
    return (
        <Controller
            name={name}
            control={control}
            defaultValue={defaultValue}
            render={({ field }) => {
                return (
                    <MultiSelectTextInput
                        label={label}
                        fieldValue={field.value ?? undefined}
                        onChange={(e) => field.onChange(e)}
                        placeholder={placeholder}
                        error={error}
                        required={required}
                    />
                );
            }}
        />
    );
}

function MultiSelectTextInput({
    placeholder = 'Type something and press enter...',
    onChange,
    fieldValue,
    required,
    label,
    error,
}: {
    placeholder?: string;
    fieldValue?: string[];
    required: boolean;
    label: string;
    error?: string;
    onChange: (items: string[]) => void;
}) {
    const [inputState, setInputState] = useState<State>({
        inputValue: '',
        value: fieldValue?.map((s) => ({ label: s, value: s })) ?? [],
    });

    useEffect(() => {
        setInputState({
            inputValue: '',
            value: fieldValue?.map((s) => ({ label: s, value: s })) ?? [],
        });
    }, [fieldValue, setInputState]);

    const handleChange = (value: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => {
        // on removes
        onChange(value.map((o) => o.value));
    };
    const handleInputChange = (inputValue: string) => {
        setInputState({
            ...inputState,
            inputValue,
        });
    };
    const handleKeyDown: KeyboardEventHandler<HTMLDivElement> = (event) => {
        const { inputValue, value } = inputState;
        if (!inputValue) return;
        switch (event.key) {
            case 'Enter':
            case 'Tab':
                onChange([...value, createOption(inputValue)].map((o) => o.value));
                event.preventDefault();
        }
    };

    const { inputValue, value } = inputState;

    return (
        <>
            <div className={`tag-select-container${required ? ' has-required' : ''}${error ? ' error' : ''}`}>
                {required && <div className="required">*</div>}
                <p className={`tag-select-label`}>
                    {label}
                    <span>{'Press TAB or ENTER after entering each to confirm.'}</span>
                </p>
                <CreatableSelect
                    classNamePrefix="tag-select"
                    components={components}
                    inputValue={inputValue}
                    isClearable={false}
                    isMulti
                    // need form attribute to be set to anything in order to enable
                    // tab => "Go" instead of tab => "Next" within forms on mobile devices
                    form="form"
                    menuIsOpen={false}
                    onChange={handleChange}
                    onInputChange={handleInputChange}
                    onKeyDown={handleKeyDown}
                    placeholder={placeholder}
                    value={value}
                />
                {error && <p className="error-label">{error}</p>}
            </div>
        </>
    );
}
