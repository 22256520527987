import React from 'react';

type Props = {
    className?: string;
};

export const BrawlhallaIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M11.6,4.6c0.2-0.5,0.2-0.9-0.3-1.2c-0.5-0.2-1.5,0-1.6,0.5C9.6,4.5,9.4,5.2,9.2,6C10.2,5.6,11.2,5.7,11.6,4.6z" />
            <path d="M10.6,9.5C10.7,9,11,8.5,10.3,8.2C9.8,8,8.6,8.5,8.5,8.9c-0.2,0.8-0.4,1.5-0.6,2.5C9,11,10.1,10.7,10.6,9.5z" />
            <path
                d="M16.3,1c-0.6-1-2.3-1-3.2-1c-0.1,0-0.4,0-0.5,0c-0.2,0-0.5,0-0.6,0.1c-1.2,0.1-2.4,0.2-3.5,0.3C7.8,0.5,7.2,1.1,7.1,1.7
                c0,0,0,0.1,0,0.1c0,0,0,0,0,0.1C7,1.9,7,1.9,7,2C6.8,2.8,6.5,3.7,6.3,4.5C6.2,4.9,6.1,5.4,6,5.8C5.5,8,4.9,10.2,4.3,12.4l-1.2,4.7
                l0,0c0,0,0,0,0,0L6,19.8L6.4,20c0.7-0.4,0,0,0.6-0.3c1.4-0.7,2.7-1.4,4-2c2.7-1.1,4.2-3.3,4.3-6.7c0-0.7-0.1-1.3-0.2-2
                c0-0.1,0-0.2,0-0.3c1.2-1.3,1.4-3.3,1.6-5.1c0-0.2,0-0.3,0-0.5C16.9,2.3,16.6,1.6,16.3,1z M8.6,1.3C10,1.1,11.5,1,12.9,0.9
                c1-0.1,1.5,0.4,1.4,1.4c-0.2,1.6-0.2,3.2-1.5,4.1c0.1,0.8,0.3,1.5,0.3,2.1c-0.1,2.2-1,3.7-3.2,4.5c-1.8,0.6-3.5,1.5-5.4,2.4
                c0.8-3.3,1.6-6.4,2.4-9.5c0.3-1.4,0.7-2.8,1-4.1C8,1.7,8.3,1.3,8.6,1.3z"
            />
        </svg>
    );
};
