import Link from 'next/link';
import React from 'react';
import { SPINLogo } from '../svg/SPINLogo';

type Props = {};

export const FooterDescription: React.FC<Props> = ({}) => {
    return (
        <div className="footer-column description">
            <SPINLogo className="spin-logo" />
            <p className="footer-description">
                {`Stay Plugged In is the premier esports recruiting platform for college scholarships, 
                providing opportunities for students to compete in front of a network of hundreds of 
                college esports programs with the potential to earn scholarships. Stay Plugged In is 
                the go-to source for collegiate and high school recruiting opportunities.`}
            </p>
            <p className="footer-slogan">
                Better Person. Better Player. <span> Better Recruit.</span>
            </p>
            <div className="link-container">
                <a
                    className="link"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.websitepolicies.com/policies/view/80epIdLk"
                >
                    Terms and Conditions
                </a>

                <a
                    className="link"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.websitepolicies.com/policies/view/9rHEwmSD"
                >
                    Cookie Policy
                </a>

                <a
                    className="link"
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.websitepolicies.com/policies/view/cZZ1gf2n"
                >
                    Privacy Policy
                </a>
            </div>
            <p className="footer-copyright">© 2024 Stay Plugged In</p>
        </div>
    );
};
