import React from 'react';

interface Props {
    className?: string;
}

export const MyTeamsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M10.3,11.3H2.8c-1.5,0-2.8,1.4-2.8,3.1v1c0,1.1,0.8,2.1,1.9,2.1h9.4c1.1,0,1.9-0.9,1.9-2.1v-1
                C13.1,12.7,11.9,11.3,10.3,11.3z M11.5,14.4v0.4c0,0.5-0.4,0.9-0.9,0.9H2.6c-0.5,0-0.9-0.4-0.9-0.9v-0.4c0-0.8,0.6-1.3,1.3-1.3h7.1
                C10.9,13.1,11.5,13.7,11.5,14.4z"
            />
            <path
                d="M6.6,2.5c-2.2,0-4,1.8-4,4s1.8,4,4,4s4-1.8,4-4S8.8,2.5,6.6,2.5z M6.6,8.7c-1.2,0-2.2-1-2.2-2.2s1-2.2,2.2-2.2
                s2.2,1,2.2,2.2S7.8,8.7,6.6,8.7z"
            />
            <path
                d="M17.7,11.1h-3.8c0.4,0.4,0.6,0.9,0.9,1.4h2.8c0.6,0,1.1,0.5,1.1,1.1v0.4c0,0.4-0.3,0.7-0.7,0.7h-2.8l0,0.8
                c0,0.2,0,0.4-0.1,0.6h3.4c0.8,0,1.5-0.7,1.5-1.7v-0.8C20,12.2,19,11.1,17.7,11.1z"
            />
            <path
                d="M14.8,3.7c-1.7,0-3.2,1.4-3.2,3.2s1.4,3.2,3.2,3.2s3.2-1.4,3.2-3.2S16.5,3.7,14.8,3.7z M14.8,8.6
                c-1,0-1.8-0.8-1.8-1.8s0.8-1.8,1.8-1.8c1,0,1.8,0.8,1.8,1.8S15.7,8.6,14.8,8.6z"
            />
        </svg>
    );
};
