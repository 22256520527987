import React from 'react';

interface Props {
    className?: string;
}

export const MyProfileIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M10,4C7.8,4,6,5.8,6,8c0,2.2,1.8,4,4,4c2.2,0,4-1.8,4-4C14,5.8,12.2,4,10,4z M10,10c-1.1,0-2-0.9-2-2s0.9-2,2-2
                c1.1,0,2,0.9,2,2S11.1,10,10,10z M10,0C4.5,0,0,4.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10C20,4.5,15.5,0,10,0z M15,16.2
                c-1.4,1.1-3.1,1.8-5,1.8c-1.9,0-3.6-0.7-5-1.8V16c0-0.6,0.5-1,1-1h8c0.6,0,1,0.5,1,1V16.2z M16.6,14.5C16.1,13.6,15.1,13,14,13H6
                c-1.1,0-2.1,0.6-2.6,1.5C2.5,13.2,2,11.7,2,10c0-4.4,3.6-8,8-8c4.4,0,8,3.6,8,8C18,11.7,17.5,13.2,16.6,14.5z"
            />
        </svg>
    );
};
