import React from 'react';

interface Props {
    className?: string;
}

export const HubIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M19.8,8.4l-9.1-7.1c-0.4-0.4-1-0.4-1.5,0L0.2,8.4C-0.2,8.7,0,9.4,0.5,9.4h1.8v7.5c0,1.2,1,2.2,2.2,2.2H8h4h3.4
            c1.2,0,2.2-1,2.2-2.2V9.4h1.8C20,9.4,20.2,8.7,19.8,8.4z M15.6,16c0,0.5-0.5,1-1,1H12v-4c0-1.1-0.9-2-2-2h0c-1.1,0-2,0.9-2,2v4H5.4
            c-0.5,0-1-0.5-1-1V8.1c0-0.3,0.1-0.6,0.4-0.8l4.9-3.9c0.2-0.1,0.4-0.1,0.6,0l4.9,3.9c0.2,0.2,0.4,0.5,0.4,0.8V16z"
            />
        </svg>
    );
};
