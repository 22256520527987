import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import { MyEventsIcon } from '../../svg/dashboard/MyEventsIcon';
import { MySubscriptionsIcon } from '../../svg/dashboard/MySubscriptionsIcon';
import { MyProfileIcon } from '../../svg/dashboard/MyProfileIcon';
import { MyGamesIcon } from '../../svg/dashboard/MyGamesIcon';
import { ScoutingReportsIcon } from '../../svg/dashboard/ScoutingReportsIcon';
import { MyVODsIcon } from '../../svg/dashboard/MyVODsIcon';
import { useAppState } from '../../../client/providers/AppProvider';
import { Espin_Role_Enum } from '../../../generated/spin-graphql';
import { CommonDashboardNavigationLinks } from './CommonDashboardNavigationLinks';
import { MyTeamsIcon } from '../../svg/dashboard/MyTeamsIcon';

type Props = {
    messages: number;
};

export const DashboardNavigationLinks: React.FC<Props> = ({ messages }) => {
    const router = useRouter();

    const { user, subscribed } = useAppState();

    return (
        <nav className="links">
            <CommonDashboardNavigationLinks messages={messages} />
            <div className="separator" />
            {user?.role === Espin_Role_Enum.EspinCollegeUser && subscribed && (
                <Link href="/dashboard/scouting-reports">
                    <a
                        title="Scouting Reports"
                        className={`link${router.pathname.startsWith('/dashboard/scouting-reports') ? ' active' : ''}`}
                    >
                        <ScoutingReportsIcon />
                        <span>Scouting Reports</span>
                    </a>
                </Link>
            )}
            <Link href="/dashboard/my-events">
                <a title="My Events" className={`link${router.pathname == '/dashboard/my-events' ? ' active' : ''}`}>
                    <MyEventsIcon />
                    <span>My Events</span>
                </a>
            </Link>
            <Link href="/dashboard/my-games">
                <a title="My Games" className={`link${router.pathname == '/dashboard/my-games' ? ' active' : ''}`}>
                    <MyGamesIcon />
                    <span>My Games</span>
                </a>
            </Link>
            {/* conditionally show the player vod (just cleaner to do inline since there's no else) */}
            {user?.role === Espin_Role_Enum.EspinPlayerUser && (
                <>
                    <Link href="/dashboard/my-teams">
                        <a
                            title="My Teams"
                            className={`link${router.pathname == '/dashboard/my-teams' ? ' active' : ''}`}
                        >
                            <MyTeamsIcon />
                            <span>My Teams</span>
                        </a>
                    </Link>
                    <Link href="/dashboard/my-vods">
                        <a
                            title="My VODs"
                            className={`link${router.pathname == '/dashboard/my-vods' ? ' active' : ''}`}
                        >
                            <MyVODsIcon />
                            <span>My VODs</span>
                        </a>
                    </Link>
                </>
            )}
            <Link href="/dashboard/my-profile">
                <a title="My Profile" className={`link${router.pathname == '/dashboard/my-profile' ? ' active' : ''}`}>
                    <MyProfileIcon />
                    <span>My Profile</span>
                </a>
            </Link>
            <Link href="/dashboard/subscriptions">
                <a
                    title="Subscriptions"
                    className={`link${router.pathname == '/dashboard/subscriptions' ? ' active' : ''}`}
                >
                    <MySubscriptionsIcon />
                    <span>Subscriptions</span>
                </a>
            </Link>
        </nav>
    );
};
