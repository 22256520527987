import React from 'react';

interface Props {
    className?: string;
}

export const ScoutingReportsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M10,7C9.4,7,9,7.4,9,8v8h2V8C11,7.4,10.6,7,10,7z" />
            <path d="M14,10c-0.6,0-1,0.4-1,1v5h2v-5C15,10.4,14.6,10,14,10z" />
            <path d="M6,12c-0.6,0-1,0.4-1,1v3h2v-3C7,12.4,6.6,12,6,12z" />
            <path
                d="M16,0H4C2.3,0,1,1.3,1,3v14c0,1.7,1.3,3,3,3h12c1.7,0,3-1.3,3-3V3C19,1.3,17.7,0,16,0z M17,17c0,0.6-0.4,1-1,1H4
                c-0.6,0-1-0.4-1-1V3c0-0.6,0.4-1,1-1h3v0c0,1.7,1.3,3,3,3h0c1.7,0,3-1.3,3-3v0h3c0.6,0,1,0.4,1,1V17z"
            />
        </svg>
    );
};
