import React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

interface Props {
    label: string;
    placeholder: string;
    className?: string;
    htmlFor: string;
    register: UseFormRegisterReturn;
    required: boolean;
    error?: string;
}

export const NewsletterInputField: React.FC<Props> = ({
    label,
    placeholder,
    className,
    htmlFor,
    register,
    required,
    error,
}) => {
    return (
        <div className={`newsletter-input-field-container${className ? ` ${className}` : ''}${error ? ` error` : ''}`}>
            <label htmlFor={htmlFor}>{label}</label>
            <input
                className={`input-field${required ? ` has-required` : ''}`}
                placeholder={placeholder}
                {...register}
                required={required}
            />
            {required && <span className="required">*</span>}
            {error && <p className="error-label">{error}</p>}
        </div>
    );
};
