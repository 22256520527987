import React, { useEffect, useMemo } from 'react';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import { getClientEnv } from '../../client/clientEnv';
import { ReactChildrenProps } from '../../types/react.types';
import { isBrowser } from '../../shared/shared.utils';
import { useAppState } from '../../client/providers/AppProvider';
import { CustomClientErrorScreen } from '../errors/CustomErrorScreen';

const { NEXT_PUBLIC_BROWSER_LOG_CLIENT_ID = '', NEXT_PUBLIC_VERCEL_ENV = 'local' } = getClientEnv();
function canUseBugsnag() {
    return NEXT_PUBLIC_BROWSER_LOG_CLIENT_ID !== '' && isBrowser();
}

if (canUseBugsnag()) {
    Bugsnag.start({
        apiKey: NEXT_PUBLIC_BROWSER_LOG_CLIENT_ID,
        releaseStage: NEXT_PUBLIC_VERCEL_ENV,
        plugins: [new BugsnagPluginReact()],
        // pull the git commit sha as the version number
        appVersion: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ?? 'localdev',
    });
}

export function useAddBugsnagUser() {
    const { user } = useAppState();
    useEffect(() => {
        if (canUseBugsnag() && !!user) {
            Bugsnag.setUser(user.id, user.email, user.id);
        }
    }, [user]);
}
export function BugSnagErrorBoundary({ children }: ReactChildrenProps) {
    useAddBugsnagUser();

    const ErrorBoundary = useMemo(() => {
        if (canUseBugsnag()) {
            return Bugsnag.getPlugin('react').createErrorBoundary(React);
        }
        // just SOMETHING so TS won't error out here BUT - it needs to be UNCHANGING!
        return function FailSafe({ FallbackComponent }: { FallbackComponent: any }) {
            return <></>;
        };
    }, []);

    if (!canUseBugsnag()) {
        return <>{children}</>;
    }

    return <ErrorBoundary FallbackComponent={CustomClientErrorScreen}>{children}</ErrorBoundary>;
}

export function LogBugsnagError(e: any) {
    if (canUseBugsnag()) {
        Bugsnag.notify(e);
    }
}
