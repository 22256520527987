import React from 'react';

type Props = {
    className?: string;
};

export const IRacingIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M1.4,2.1c0.3,0,0.5,0,0.7,0c5.6,0,11.1,0,16.7,0c1,0,1.2,0.2,1.2,1.2c0,0.4,0,0.9,0,1.3c-0.1-0.1-0.3-0.2-0.4-0.4
                c0.1-0.2,0.2-0.5,0.2-0.7c0-0.1,0-0.2,0-0.3c-0.1,0-0.3,0.1-0.3,0.2c-0.4,0.6-0.7,1.3-1,1.9c-0.1,0.1-0.2,0.3-0.3,0.4
                c-0.7,0.8-1.5,1.5-2.2,2.2c-0.5,0.5-1,1.1-1.6,1.5c-0.3,0.2-0.8,0-1.2,0c0,0-0.1-0.2,0-0.2c0-0.3,0-0.5,0.1-0.8
                c0.3-0.7,0.2-1.3,0.1-2c0-0.4-0.2-0.6-0.6-0.8c-1.1-0.5-2.4-0.4-3.3,0.5C9.2,6.3,9,6.8,8.8,7.2c0,0,0,0.1,0.1,0.2
                C8.9,7,9.2,6.9,9.6,7c0.6,0.1,1.1,0.3,1.7,0.5c0.3,0.1,0.6,0.3,0.9,0.4c-0.4,0.4-2,0.5-3.1,0.1c-0.5,0.3,0,0.8-0.2,1.3
                C8.4,9.1,7.8,9.1,7.4,8.8S6.7,8,6.2,7.7C6.1,7.6,6.1,7.3,5.9,7.1c-0.6-0.5-1.2-1-1.8-1.5c-0.7-0.7-1.5-1.3-2-2.2
                C1.9,3,1.7,2.6,1.4,2.1z"
            />
            <path
                d="M1.1,2.2c0.1,0.4,0.2,0.7,0.2,1C0.7,3.8,0.6,4,0.9,4.5c0,0.1,0.2,0.1,0.3,0.1C1.5,4.9,1.7,5,1.9,5.2C2,5.3,2.1,5.3,2.1,5.4
                c0.2,0.7,0.7,1.2,1.1,1.7c0.3,0.3,0.6,0.7,0.9,1.1c1,1.2,1.9,2.5,3.2,3.4c0.1,0.1,0.2,0.2,0.2,0.3c0.5,1.4,0.8,2.8,0.8,4.3
                c0,0.5-0.1,1-0.2,1.4c0,0.2-0.2,0.3-0.4,0.3c-2.3,0-4.6,0-6.9,0c-0.4,0-0.7-0.3-0.8-0.7c0-0.1,0-0.2,0-0.3c0-4.6,0-9.2,0-13.8
                C0.1,2.4,0.4,2.1,1.1,2.2z"
            />
            <path
                d="M20,5.6c0,3.7,0,7.3,0,11c0,0.2,0,0.4,0,0.6c0,0.4-0.3,0.7-0.7,0.7c-1.8,0-3.6,0-5.4,0c-0.2,0-0.5-0.5-0.4-0.8
                c0.4-1.6,0.7-3.3,1.1-4.9c0-0.1,0.1-0.2,0.1-0.2c0.4-0.4,0.8-0.7,1.2-1.1c0.3-0.3,0.5-0.6,0.7-0.9c0.1-0.1,0.2-0.2,0.2-0.3
                c0.2-0.2,0.3-0.3,0.5-0.5c0.5-0.6,0.9-1.3,1.4-1.9C18.8,7.1,19,6.9,19,6.7C19.1,6.1,19.7,6,20,5.6z"
            />
            <path d="M11.6,9.6c-1.2,0.5-2.5,0.5-2.7-0.1C9.9,10.2,10.7,9.6,11.6,9.6z" />
        </svg>
    );
};
