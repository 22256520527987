import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useImmer } from 'use-immer';
import { StatesAndCodes } from '../../../client/static';
import { Espin_Game_Definition_Enum, PlayerSearchFilterFragment } from '../../../generated/spin-graphql';
import { ControlledStringSelectComponent } from '../../forms/ControlledStringSelectComponent';
import { GamesSelectComponent } from '../../forms/GameSelectComponent';
import { CheckboxWithControl } from '../../input/Checkbox';
import { GenericToggleSwitch, GenericToggleSwitchProps } from '../../input/GenericToggleSwitch';
import { NumberSlider } from '../../input/NumberSlider';
import { SelectField } from '../../input/SelectField';
import { PlayerAcademicInterests } from '../profile/PlayerAcademicInterests';

export type PlayerSearchFilterState = {
    gameFilters: Espin_Game_Definition_Enum[];
    useGPA: boolean;
    useMaxDistance: boolean;
    maxDistance: number;
    minGPA: number;
    useGames: boolean;
    useGraduatingClass: boolean;
    graduatingClass: Array<ClassOptions>;
    useState: boolean;
    state: string;
    useSorting: boolean;
    sorting: PlayerSorting;
    fieldOfStudy: FieldOfStudy;
    advancedFieldOfStudy: string[];
    useFilterByFieldOfStudy: boolean;
    fieldOfStudyType: FieldOfStudyToggleType;
    usePlayersIFollow: boolean;
    usePlayersIDoNotFollow: boolean;
    useInternationalSearch: boolean;
    classOptions?: Array<ClassOptions>;
};

export type FieldOfStudy = {
    label: string;
    value: string;
};

export type ClassOptions = {
    label: string;
    value: string;
};

export enum PlayerSorting {
    GRADUATING_CLASS = 'graduating_class',
    LOCATION = 'location',
}

export enum FieldOfStudyToggleType {
    SIMPLE = 'simple',
    ADVANCED = 'advanced',
}

export function PlayerSearchFilterComponent({
    data,
    defaults,
    onChange,
}: {
    defaults: PlayerSearchFilterState;
    data: PlayerSearchFilterFragment;
    onChange: (s: PlayerSearchFilterState) => void;
}) {
    const { espin_study_field: studyFields } = data;
    const [fieldOfStudyType, setFieldOfStudyType] = useState<FieldOfStudyToggleType>(FieldOfStudyToggleType.SIMPLE);
    const sortOptions = useMemo(
        () => [
            { label: 'Graduating Class (Ascending)', value: PlayerSorting.GRADUATING_CLASS },
            // { label: 'Location (Nearest)', value: PlayerSorting.LOCATION },
        ],
        [],
    );
    const { watch, control, register, setValue } = useForm<{
        useGpa: boolean;
        gpa: string;
        games: { label: string; value: Espin_Game_Definition_Enum }[];
        maxDistance: string;
        useDistance: boolean;
        useGraduatingClass: boolean;
        graduatingClass: Array<ClassOptions>;
        useState: boolean;
        state: string;
        useGames: boolean;
        fieldOfStudyType: FieldOfStudyToggleType;
        fieldOfStudy: FieldOfStudy;
        advancedFieldOfStudy: string[];
        useFilterByFieldOfStudy: boolean;
        usePlayersIFollow: boolean;
        usePlayersIDoNotFollow: boolean;
        useSorting: boolean;
        sorting: { label: string; value: PlayerSorting };
        useInternationalSearch: boolean;
    }>({
        defaultValues: {
            gpa: defaults.minGPA.toString(),
            maxDistance: defaults.maxDistance.toString(),
            useGpa: defaults.useGPA,
            games: [],
            useDistance: defaults.useMaxDistance,
            useSorting: defaults.useSorting,
            useState: defaults.useState,
            fieldOfStudyType: defaults.fieldOfStudyType,
            fieldOfStudy: defaults.fieldOfStudy,
            advancedFieldOfStudy: defaults.advancedFieldOfStudy,
            usePlayersIFollow: defaults.usePlayersIFollow,
            useFilterByFieldOfStudy: defaults.useFilterByFieldOfStudy,
            usePlayersIDoNotFollow: defaults.usePlayersIDoNotFollow,
            useGraduatingClass: defaults.useGraduatingClass,
            state: defaults.state,
            useGames: defaults.useGames,
            useInternationalSearch: defaults.useInternationalSearch,
            sorting: sortOptions[0],
            graduatingClass: defaults.graduatingClass,
        },
    });

    const currentFormDetails = watch();

    const graduatingClassOptions = useMemo(() => {
        const options = [];
        for (let i = 0; i < 4; i++) {
            const ob = {
                label: `Class of ${new Date().getFullYear() + i}`,
                value: new Date().getFullYear() + i,
            };
            options.push(ob);
        }
        options.push({ label: `Other`, value: 'Other' });
        return options;
        //calculate only on mount
    }, []);

    useEffect(() => {
        onChange({
            gameFilters: currentFormDetails.games.map((g) => g.value),
            useGames: currentFormDetails.useGames,
            maxDistance: parseInt(currentFormDetails.maxDistance, 10),
            minGPA: parseFloat(currentFormDetails.gpa),
            useGPA: currentFormDetails.useGpa,
            useMaxDistance: currentFormDetails.useDistance,
            useGraduatingClass: currentFormDetails.useGraduatingClass,
            graduatingClass: currentFormDetails.graduatingClass,
            useState: currentFormDetails.useState,
            state: currentFormDetails.state,
            fieldOfStudy: currentFormDetails.fieldOfStudy,
            fieldOfStudyType: fieldOfStudyType,
            advancedFieldOfStudy: currentFormDetails.advancedFieldOfStudy,
            useFilterByFieldOfStudy: currentFormDetails.useFilterByFieldOfStudy,
            usePlayersIFollow: currentFormDetails.usePlayersIFollow,
            usePlayersIDoNotFollow: currentFormDetails.usePlayersIDoNotFollow,
            useSorting: currentFormDetails.useSorting,
            sorting: currentFormDetails.sorting.value,
            useInternationalSearch: currentFormDetails.useInternationalSearch,
            classOptions: graduatingClassOptions,
        });
    }, [currentFormDetails, onChange, graduatingClassOptions, fieldOfStudyType]);

    const filterFields = useMemo(() => {
        const arr = studyFields.map((field) => {
            return { label: field.name, value: field.name };
        });
        return arr;
    }, [studyFields]);

    const fieldOfStudyToggleProps: GenericToggleSwitchProps<FieldOfStudyToggleType> = {
        currentValue: FieldOfStudyToggleType.SIMPLE,
        options: [
            { label: 'Simple', value: FieldOfStudyToggleType.SIMPLE },
            { label: 'Advanced', value: FieldOfStudyToggleType.ADVANCED },
        ],
        onToggle: (value: FieldOfStudyToggleType) => {
            setFieldOfStudyType(value);
        },
    };

    return (
        <>
            <div className="filter-by-games">
                <CheckboxWithControl label={'Filter By Games'} name="useGames" control={control} />
                <GamesSelectComponent
                    placeholder="Select games"
                    name="games"
                    required={watch('useGames')}
                    control={control}
                    defaultValue={null}
                    disabled={!watch('useGames')}
                />
            </div>
            <div className="filter-by-gpa-distance">
                <div className="filter-by-gpa">
                    <CheckboxWithControl label={'Filter By Minimum GPA'} name="useGpa" control={control} />
                    <NumberSlider
                        disabled={!watch('useGpa')}
                        min={0}
                        max={4}
                        step={0.1}
                        name="gpa"
                        htmlFor="gpa"
                        label="GPA"
                        control={control}
                        required={false}
                        value={watch('gpa')}
                    />
                </div>
                <div className="filter-by-distance">
                    <CheckboxWithControl label={'Filter By Maximum Distance'} name="useDistance" control={control} />
                    <NumberSlider
                        htmlFor="maxDistance"
                        label="Miles"
                        control={control}
                        required={false}
                        value={watch('maxDistance')}
                        min={10}
                        max={5000}
                        disabled={!watch('useDistance')}
                        step={10}
                        name={'maxDistance'}
                    />
                </div>
            </div>
            <div className="filter-by-graduating-class">
                <CheckboxWithControl label={'Filter By Graduating Class'} name="useGraduatingClass" control={control} />
                <SelectField
                    name={'graduatingClass'}
                    required={watch('useGraduatingClass')}
                    control={control}
                    placeholder={'Select graduating classes'}
                    isMulti={true}
                    options={graduatingClassOptions}
                    disabled={!watch('useGraduatingClass')}
                    value={watch('graduatingClass')}
                />
            </div>
            <div className="filter-by-state-sort">
                <div className="filter-by-state">
                    <CheckboxWithControl label={'Filter By State'} name="useState" control={control} />
                    <ControlledStringSelectComponent
                        fieldValue={watch('state')}
                        required={watch('useState')}
                        disabled={!watch('useState')}
                        placeholder={'State'}
                        name="state"
                        control={control}
                        options={StatesAndCodes.map((s) => s.name)}
                    />
                </div>
                <div className="sort-by">
                    <CheckboxWithControl label={'Sort Results By'} name="useSorting" control={control} />
                    <SelectField
                        name={'sorting'}
                        required={watch('useSorting')}
                        control={control}
                        placeholder={'Select an option'}
                        isMulti={false}
                        options={sortOptions}
                        disabled={!watch('useSorting')}
                        value={watch('sorting')}
                    />
                </div>
            </div>
            <CheckboxWithControl
                label={'Search for players outside of the United States'}
                name="useInternationalSearch"
                control={control}
            />
            <div className="filter-by-follows">
                <CheckboxWithControl
                    label={'Filter out players I follow'}
                    name="usePlayersIDoNotFollow"
                    disabled={watch('usePlayersIFollow')}
                    control={control}
                />
                <CheckboxWithControl
                    label={'Filter by players I follow'}
                    name="usePlayersIFollow"
                    disabled={watch('usePlayersIDoNotFollow')}
                    control={control}
                />
            </div>
            <div className="filter-by-field-of-study">
                <div className="field-of-study-heading">
                    <CheckboxWithControl
                        label={'Filter by Field of Study'}
                        name="useFilterByFieldOfStudy"
                        control={control}
                    />
                    <GenericToggleSwitch props={fieldOfStudyToggleProps} />
                </div>
                {fieldOfStudyType === FieldOfStudyToggleType.SIMPLE && (
                    <SelectField
                        name={'fieldOfStudy'}
                        required={watch('useFilterByFieldOfStudy')}
                        control={control}
                        placeholder={'Select an option'}
                        isMulti={false}
                        options={filterFields}
                        disabled={!watch('useFilterByFieldOfStudy')}
                        value={watch('fieldOfStudy')}
                    />
                )}
                {fieldOfStudyType === FieldOfStudyToggleType.ADVANCED && (
                    <PlayerAcademicInterests
                        name={'advancedFieldOfStudy'}
                        control={control}
                        disabled={!watch('useFilterByFieldOfStudy')}
                        outlined={true}
                        studyFieldOptions={data.espin_study_field}
                        studySubjectOptions={data.espin_study_subject}
                        register={register}
                        setValue={setValue}
                        watch={watch}
                        hasLabel={false}
                        isCreatable={false}
                    />
                )}
            </div>
            {/* <div className="game-filters">
                <p className="label">Filter By Game</p>
                {filterElements}
            </div> */}
        </>
    );
}
