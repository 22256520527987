import React from 'react';

interface Props {
    className?: string;
}

export const FollowingIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M9.3,0C6.4,0,4,2.4,4,5.3s2.4,5.3,5.3,5.3s5.3-2.4,5.3-5.3S12.2,0,9.3,0z M9.3,8.4C7.6,8.4,6.2,7,6.2,5.3
                s1.4-3.1,3.1-3.1s3.1,1.4,3.1,3.1S11,8.4,9.3,8.4z"
            />
            <path
                d="M10.5,17.7C10.5,17.7,10.5,17.7,10.5,17.7l-7.9,0c-0.2,0-0.4-0.2-0.4-0.4V16c0-1,0.8-1.8,1.8-1.8h5.2
                c0.1-0.8,0.3-1.6,0.7-2.2H4c-2.2,0-4,1.8-4,4v1.3C0,18.8,1.2,20,2.7,20h10.1L10.5,17.7z"
            />
            <path
                d="M19.2,12.7c-1.2-1.1-2.8-0.8-3.6,0.2c-0.8-1-2.4-1.3-3.6-0.2c-0.5,0.5-0.8,1.1-0.8,1.8v0c0,0.7,0.3,1.3,0.7,1.8l3.7,3.7h0h0
                l3.7-3.7c0.5-0.5,0.7-1.1,0.7-1.8v0C20,13.8,19.7,13.1,19.2,12.7z"
            />
        </svg>
    );
};
