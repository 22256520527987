import React from 'react';

type Props = {
    className?: string;
};

export const SideSwipeIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M3.5,9.2c0-0.1,0-0.1,0.1-0.1c1.5-1.6,3.2-2.9,5.1-4c1.2-0.7,2.3-1.3,3.6-1.8c1.4-0.6,2.8-1,4.3-1.2c1-0.1,2-0.2,3,0
                c0.1,0,0.2,0,0.3,0.1c0,0,0,0,0.1,0.1c-0.2,0.2-0.3,0.4-0.5,0.6c-0.2,0.3-0.4,0.6-0.6,0.8c-0.3,0.4-0.5,0.8-0.8,1.2
                C18,5,17.9,5,17.8,5c-1.1,0-2.2,0-3.3,0.3c-0.9,0.2-1.8,0.5-2.6,0.8C11,6.4,10.2,6.9,9.5,7.5C9.3,7.6,9.2,7.6,9.1,7.7
                C8.7,7.8,8.4,7.9,8,8C8,8,7.9,8,7.9,8c1.4,0,2.8,0,4.2-0.1c0.9,0,1.8-0.1,2.7,0.1c0.4,0.1,0.8,0.1,1.2,0.3c0.9,0.4,1.2,1.2,1,2.1
                c0,0.1-0.1,0.1-0.1,0.2c0,0-0.1,0-0.1,0c-0.3-0.1-0.3-0.1-0.5,0.1c-0.4,0.6-0.9,1.1-1.4,1.6c-1.3,1.1-2.7,2-4.2,2.7
                c-2,0.9-4.1,1.6-6.3,2.1c-1.3,0.3-2.5,0.6-3.8,0.8C0.5,18,0.2,18,0,18c0,0,0-0.1,0-0.1c0.6-0.8,1.3-1.5,1.9-2.3
                c0.1-0.1,0.3-0.2,0.4-0.3c1.9-0.5,3.7-1.2,5.5-1.9C8.9,13,10,12.5,11,11.9c0.5-0.3,1-0.7,1.5-1.1c0.1-0.1,0.2-0.2,0.2-0.3
                c0-0.1,0-0.2-0.1-0.2c-0.1,0-0.2,0-0.3-0.1c-0.4,0-0.9,0-1.3,0c-1.1,0.1-2.2,0.2-3.2,0.2c-0.4,0-0.8,0-1.1-0.1
                c-0.3-0.1-0.6-0.2-0.9-0.4C5.5,9.7,5.5,9.3,5.5,8.8c0-0.1,0-0.3,0-0.4c-0.1,0-0.2,0-0.3,0.1c-0.5,0.2-1,0.3-1.4,0.6
                C3.7,9.1,3.6,9.2,3.5,9.2L3.5,9.2z"
            />
        </svg>
    );
};
