import React from 'react';

type Props = {
    className?: string;
};

export const LeagueOfLegendsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M2.7,0l1.8,3V17l-1.9,3h13.7l2.8-3.6h-10V0H2.7z M10.9,0v1.8c4.1,0.5,7.3,3.9,7.3,8.1c0,1.7-0.5,3.2-1.4,4.5
                h2.1c0.7-1.4,1.1-2.9,1.1-4.5C20,4.8,16,0.5,10.9,0z M2.6,3.3C1,5.1,0,7.4,0,10c0,2.6,1,4.9,2.6,6.7l0.1-0.2v-2.8
                c-0.6-1.1-0.9-2.4-0.9-3.7c0-1.3,0.3-2.6,0.9-3.7V3.5L2.6,3.3z"
            />
        </svg>
    );
};
