import React from 'react';

type Props = {
    className?: string;
};

export const HearthstoneIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M3.7,16C3.8,15.7,3.9,15.3,4,15c0.2-0.5,0.3-0.9,0.5-1.4c0,0,0-0.1,0-0.1c-0.3-0.4-0.5-0.9-0.6-1.3c0-0.1-0.1-0.1-0.1-0.1
                c-1.2-0.7-2.4-1.4-3.6-2.1c0,0-0.1,0-0.1-0.1c0,0,0.1,0,0.1-0.1c1.2-0.7,2.5-1.4,3.7-2.1C3.9,7.7,3.9,7.6,4,7.5
                c0.1-0.4,0.3-0.8,0.5-1.1c0.2-0.2,0.2-0.4,0.1-0.7C4.4,5,4.2,4.2,4,3.5c0,0,0-0.1,0-0.1c0.1,0,0.2,0.1,0.3,0.1
                c0.7,0.3,1.4,0.6,2.1,0.9c0,0,0.1,0,0.1,0c0.4-0.2,0.8-0.3,1.2-0.5c0.1,0,0.1-0.1,0.1-0.1c0.7-1.2,1.4-2.4,2.2-3.6
                c0,0,0-0.1,0.1-0.1c0,0,0,0.1,0.1,0.1c0.7,1.2,1.4,2.3,2.1,3.5c0,0.1,0.1,0.1,0.1,0.1c0.3,0.1,0.5,0.2,0.8,0.4
                c0.1,0.1,0.2,0.2,0.4,0.3c0.1,0,0.1,0,0.2,0c0.8-0.2,1.6-0.5,2.4-0.7c0,0,0.1,0,0.1,0c0,0,0,0.1,0,0.1c-0.2,0.8-0.4,1.5-0.6,2.3
                c0,0.1,0,0.1,0,0.2C15.8,6.6,16,7,16.2,7.5c0,0,0.1,0.1,0.1,0.1c1.2,0.7,2.4,1.4,3.6,2.2c0,0,0.1,0,0.1,0.1c0,0-0.1,0-0.1,0.1
                c-1.1,0.7-2.2,1.3-3.3,2c-0.1,0-0.1,0.1-0.1,0.2c-0.3,0.5-0.5,1-0.8,1.5c0,0.1,0,0.1,0,0.2c0.3,0.8,0.5,1.6,0.7,2.4c0,0,0,0,0,0.1
                c-0.1,0-0.1,0-0.2,0c-0.8-0.2-1.5-0.4-2.3-0.6c0,0-0.1,0-0.1,0c-0.5,0.3-1,0.5-1.6,0.8c-0.1,0-0.1,0.1-0.2,0.1
                c-0.7,1.2-1.4,2.3-2.1,3.5c0,0,0,0,0,0.1c0,0,0-0.1-0.1-0.1c-0.6-1.1-1.2-2.3-1.8-3.4c0-0.1-0.1-0.1-0.2-0.2
                c-0.4-0.2-0.8-0.5-1.2-0.7c0,0-0.1-0.1-0.1-0.1c-0.2-0.2-0.3-0.2-0.6-0.1c-0.7,0.2-1.5,0.4-2.2,0.5C3.7,16,3.7,16,3.7,16z
                M7.1,13.3c0,0,0.1,0.1,0.1,0.1c0.3,0.3,0.7,0.5,1.1,0.6c0.6,0.1,1.2,0.2,1.8,0.1c0.7,0,1.3-0.2,1.9-0.5c1.2-0.6,1.9-1.6,2.1-2.9
                c0.1-0.7,0-1.4-0.1-2.1c-0.1-0.3-0.1-0.6-0.3-0.9c-0.3-0.5-0.7-1-1.2-1.4c0,0-0.1,0-0.1,0C12,6.2,11.6,6.1,11.3,6
                c-0.3-0.1-0.5-0.1-0.7-0.4c0,0-0.1,0-0.1,0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0-0.1,0-0.1,0c-0.1,0-0.3-0.1-0.4-0.1c-0.1,0-0.1,0-0.2,0
                C8.9,6,8.4,6.3,7.9,6.6c-0.1,0-0.1,0.1-0.2,0.1C7.4,7,7,7.3,6.7,7.6C6.6,7.7,6.6,7.7,6.6,7.8c0,0.2,0.1,0.4,0,0.6
                c-0.1,0.4,0,0.8,0.2,1.2c0.3,0.6,0.7,1.1,1.1,1.5c0.1,0.1,0.2,0.2,0.3,0.3c0.3,0.2,0.6,0.3,1,0.3c0.7,0,1.3-0.3,1.9-0.8
                c0,0,0-0.1,0-0.1c0-0.3,0-0.6-0.1-0.9c-0.1-0.2-0.2-0.4-0.3-0.6C10.3,8.9,9.8,8.9,9.4,9c0,0,0,0.1,0,0.1c0,0.1,0,0.2,0,0.3
                c0,0.1,0,0.2,0,0.4c0,0.2-0.1,0.2-0.3,0.2C9.1,9.9,9,9.9,9,9.8C8.6,9.5,8.6,9.1,8.7,8.7c0-0.2,0.1-0.4,0.2-0.6
                c0.6-0.6,1.3-0.8,2.2-0.7c0.1,0,0.2,0,0.3,0c0.1,0,0.1,0,0.1,0.1c0,0.1,0.1,0.2,0.1,0.2c0.5,0.6,0.7,1.4,0.6,2.2
                c-0.1,0.4-0.2,0.7-0.3,1c-0.1,0.3-0.3,0.4-0.5,0.6c-0.4,0.3-0.8,0.5-1.3,0.7c-0.8,0.3-1.5,0.6-2.3,0.9C7.5,13.2,7.3,13.2,7.1,13.3z
                "
            />
        </svg>
    );
};
