import React from 'react';

interface Props {
    className?: string;
}

export const BadgeIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M13.8,13.1l-2-0.3l-0.7-1.6h0.1c1.4,0,2.5-1.1,2.5-2.5V2.5c0-1.4-1.1-2.5-2.5-2.5H8.8C7.4,0,6.3,1.1,6.3,2.5v6.2
                c0,1.4,1.1,2.5,2.5,2.5h0.1l-0.7,1.6l-2,0.3c-1,0.1-1.4,1.3-0.7,2L7,16.6l-0.4,2c-0.2,1,0.9,1.7,1.7,1.2l1.6-1l1.6,1
                c0.9,0.5,1.9-0.2,1.7-1.2l-0.4-2l1.5-1.5C15.2,14.4,14.8,13.2,13.8,13.1z M8.6,8.7V2.5c0-0.1,0.1-0.2,0.2-0.2h2.5
                c0.1,0,0.2,0.1,0.2,0.2v6.2c0,0.1-0.1,0.2-0.2,0.2H8.8C8.7,8.9,8.6,8.8,8.6,8.7z"
            />
        </svg>
    );
};
