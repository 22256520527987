import React, { createContext, useContext, useState } from 'react';
import { Updater, useImmer } from 'use-immer';
import { Espin_Game_Definition_Enum, EventCardFragment } from '../../../generated/spin-graphql';
import { ReactChildrenProps } from '../../../types/react.types';

/****
 *
 * Until this is moved server side, we'll keep event search without history.
 * It creates a lot of weird hacks that need to be done.
 *
 */

type EventSearchHistoryData = {
    eventFilters: EventSearchFilterState;
    eventSearch: string;
    searchResults: EventCardFragment[];
    searchOffset: number;
};

export type EventSearchHistory = EventSearchHistoryData & {
    setEventSearchHistory: Updater<EventSearchHistoryData>;
};

type EventSearchFilterState = {
    gameFilters: Espin_Game_Definition_Enum[];
};

const EventSearchHistoryContext = createContext<EventSearchHistory>({
    eventFilters: {
        gameFilters: [],
    },
    searchResults: [],
    eventSearch: '',
    searchOffset: 0,
    setEventSearchHistory: null,
});

export function EventSearchHistoryProvider({ children }: ReactChildrenProps): JSX.Element {
    const [searchEventHistory, setEventSearchHistory] = useImmer<EventSearchHistoryData>({
        eventFilters: {
            gameFilters: [],
        },
        searchResults: [],
        eventSearch: '',
        searchOffset: 0,
    });

    return (
        <EventSearchHistoryContext.Provider
            value={{
                ...searchEventHistory,
                setEventSearchHistory,
            }}
        >
            {children}
        </EventSearchHistoryContext.Provider>
    );
}

export const useEventSearchHistoryProvider = (): EventSearchHistory =>
    useContext(EventSearchHistoryContext) as EventSearchHistory;
