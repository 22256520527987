import Link from 'next/link';
import React from 'react';
import { useModals } from '../../SpinModalProvider';
import { BaseRouteModal } from '../BaseRouteModal';
import { CustomPromoPopup } from './CustomPromoPopup';

type Props = {
    params: CokeAllstarsPromoPopupParams;
};

export type CokeAllstarsPromoPopupParams = {
    popupTitle: string;
    askText: string | JSX.Element;
    confirmCTAText: string;
    cancelCTAText: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmCTAButtonColour: string;
    cancelCTAButtonColour: string;
};

const CokeAllstarsPromoPopupContent: React.FC<Props> = ({ params }) => {
    return (
        <div className="promo-coke-allstars">
            <p>$20,000 in prizes, custom merch and MORE! ($250/per player minimum guaranteed)</p>
            <p>{`Fill out your nomination form to have the chance of being named a 2023 Stay Plugged In Coca-Cola All-Star! Nominations close Nov. 1st!`}</p>
            <Link href={`https://allstars.staypluggedin.gg`}>
                <a target={'_blank'} className={`link-text`}>{`Click Here to Get Started!`}</a>
            </Link>
        </div>
    );
};

export const CokeAllstarsPromoModal: React.FC<Props> = ({ params }) => {
    console.log(params);
    const { closeActiveModal } = useModals();

    return (
        <CustomPromoPopup
            content={<CokeAllstarsPromoPopupContent params={params} />}
            title={`Coke Allstars`}
            onCancel={closeActiveModal}
            className="coke-allstars"
        />
    );
};
