import React from 'react';
import { NumberSlider } from '../../../../input/NumberSlider';
import { CheckboxWithControl } from '../../../../input/Checkbox';
import { RadioButton } from '../../../../input/RadioButton';
import { StatesAndCodes } from '../../../../../client/static';
import { ControlledMultiSelectComponent } from '../../../../forms/ControlledMultiSelectComponent';
import { Control, UseFormRegister, useFormState, UseFormWatch } from 'react-hook-form';
import { useAppState } from '../../../../../client/providers/AppProvider';
import { FindCollegesFormData } from './FindCollegesPopup';

type Props = {
    register: UseFormRegister<FindCollegesFormData>;
    watch: UseFormWatch<FindCollegesFormData>;
    control: Control<FindCollegesFormData, object>;
};

export enum CollegeLocationEnum {
    MY_STATE = 'my_state',
    OTHER_STATES = 'other_states',
    ANY_STATE = 'any_state',
    INTERNATIONAL = 'international',
    ANY_LOCATION = 'any_location',
}

export const FindCollegesForm: React.FC<Props> = ({ register, watch, control }) => {
    const { user } = useAppState();
    const { errors } = useFormState({ control });

    let locationQuestion = 'Where do you want to go to college?';
    let gameLabel = 'My Primary Game';
    let gpaLabel = 'GPA';

    if (!!user.parent_profile) {
        locationQuestion = 'Where does your child want to go to college?';
        gameLabel = "My Child's Primary Game";
        gpaLabel = "My Child's GPA";
    }

    return (
        <>
            <div className="location-container">
                <h2 className="form-heading">{locationQuestion}</h2>
                <RadioButton
                    label={'In my state.'}
                    name="location_preference"
                    id="location_preference_my_state"
                    value={CollegeLocationEnum.MY_STATE}
                    register={register}
                    checked={watch('location_preference') === CollegeLocationEnum.MY_STATE}
                />
                <RadioButton
                    label={'I am open to states outside of my home state.'}
                    name="location_preference"
                    id="location_preference_other_states"
                    value={CollegeLocationEnum.OTHER_STATES}
                    register={register}
                    checked={watch('location_preference') === CollegeLocationEnum.OTHER_STATES}
                />
                <RadioButton
                    label={'I am open to any location in the US.'}
                    name="location_preference"
                    id="location_preference_any_state"
                    value={CollegeLocationEnum.ANY_STATE}
                    register={register}
                    checked={watch('location_preference') === CollegeLocationEnum.ANY_STATE}
                />
                <RadioButton
                    label={'I am looking for colleges outside of the US.'}
                    name="location_preference"
                    id="location_preference_international"
                    value={CollegeLocationEnum.INTERNATIONAL}
                    register={register}
                    checked={watch('location_preference') === CollegeLocationEnum.INTERNATIONAL}
                />
                <RadioButton
                    label={'I am open to any location.'}
                    name="location_preference"
                    id="location_preference_any_location"
                    value={CollegeLocationEnum.ANY_LOCATION}
                    register={register}
                    checked={watch('location_preference') === CollegeLocationEnum.ANY_LOCATION}
                />
                {watch('location_preference') === CollegeLocationEnum.OTHER_STATES && (
                    <ControlledMultiSelectComponent
                        error={(errors.states as any)?.message}
                        required={true}
                        placeholder={'States'}
                        name="states"
                        control={control}
                        options={StatesAndCodes}
                    />
                )}
            </div>

            <div className="offer-container">
                <h2 className="form-heading">I want the College to offer</h2>

                <CheckboxWithControl label={'Scholarships'} name="scholarships" control={control} />
                <CheckboxWithControl label={gameLabel} name="primay_game" control={control} />
                <NumberSlider
                    label={gpaLabel}
                    max={4}
                    min={0}
                    step={0.1}
                    name={'gpa'}
                    htmlFor={'gpa'}
                    required={true}
                    control={control}
                    value={watch('gpa')}
                />
            </div>
        </>
    );
};
