import React from 'react';

type Props = {
    className?: string;
};

export const RocketLeagueIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M2.7,0.5C1.2,0.5,0,1.7,0,3.2s1.2,2.7,2.7,2.7c1.5,0,2.7-1.2,2.7-2.7S4.2,0.5,2.7,0.5z M10.4,1.5
                c-1.6,0-3,0.1-4.3,0.4c0.2,0.4,0.3,0.9,0.3,1.4c0,0.7-0.2,1.4-0.6,2c0,0,0.1,0,0.1,0c0-0.2,0.1-0.2,0.1-0.2s0,0,0,0
                c0.2,0,0.7,0.3,0.9,0.4c0.2,0.1,0.2,0.1,0.2,0.1s0.5,0,0.7,0c0.2,0,0.4-0.1,0.8-0.2C8.9,5.2,9,5.2,9.1,5.2c0.1,0,0.8,0,1.4,0.1
                c0.6,0.1,1.2,0.3,1.3,0.4l0.2,0l0-0.1l-0.3-0.1c0,0,0-0.2,0-0.2c0,0,0-0.1,0.1-0.1c0,0,0.4,0.1,0.5,0.1c0.1,0,0.5,0.3,0.5,0.3
                c0,0,0,0,0,0c0,0.1-0.1,0.2-0.1,0.2s0.1,0,0.2,0.1c0,0,0.2,0.1,0.3,0.2c0.1,0.1,0.1,0.2,0.1,0.2l0.3,0.3v0.1l0.6,0.6v0l-0.3,0.2
                c0,0,0.4,0,0.7,0.1c0.3,0.1,0.3,0.2,0.3,0.3c-0.1,0-0.2,0.1-0.2,0.1c-0.2,0.1-0.8,0.4-0.8,0.4v0l0.3,0.1c0.3-0.3,0.9-0.4,1.4-0.3
                C16.7,8.5,17.5,9,18,9.6c0.4,0.5,0.7,0.9,0.9,1.2C20.2,7.2,20,4,20,4l0-0.2l-0.2-0.1c-2.2-1.4-5.1-2.1-9.1-2.1H10.4z M5.9,5.2
                c0,0,0,0.1,0,0.3c0.6,0.1,0.7,0.2,0.7,0.2s0.2,0,0.3,0C6.7,5.5,5.9,5.2,5.9,5.2z M8.8,5.3c0,0-1,0.3-1,0.3s0.3,0.1,0.3,0.1
                c0,0,0.6,0.2,0.8,0.3c0.2,0.1,0.3,0,0.3,0c0,0,0.7-0.4,0.7-0.4C9.4,5.4,8.8,5.3,8.8,5.3z M5.6,5.5l0,0.1L5.6,5.5
                C5.5,5.5,5.5,5.5,5.5,5.5C5.3,5.7,5.1,5.9,5,6c0,0.2,0,0.5,0,0.5C4.9,6.6,5,6.7,5,6.7l0,0.4h0.2l0-0.3V7h0V6.7h0V6.6l0.3,0.2l0,0.1
                l0.1,0.1h0.1l0,0v0c-0.2,0-0.2-0.1-0.2-0.1s0-0.3,0.1-0.3c0-0.1,0.2,0,0.2,0l0,0H5.7c-0.1,0-0.3-0.2-0.4-0.2v0l0,0V5.9h0
                c0,0,0,0,0-0.1c0.1,0,0.6-0.1,0.6-0.1l0,0c0.1,0,0.2,0,0.2,0L5.6,5.5z M10.2,5.7c-0.1,0-0.1,0-0.1,0S9.9,5.9,9.7,6
                C9.5,6.1,9.5,6.2,9.4,6.2c0,0,0.1,0.2,0.1,0.2l0.3,0.4c0,0,0.2,0.1,0.2,0.1s0.6,0,1-0.1h1c0,0,0-0.1-0.1-0.3
                c-0.1-0.1-0.2-0.5-0.2-0.5c0-0.1-0.1-0.1-0.6-0.1C10.7,5.8,10.3,5.7,10.2,5.7z M8.2,6.1C8.1,6.1,8.1,6.1,8.2,6.1L8,6.1l0,0.3h0
                l0.1,0c0,0,0,0,0.1,0c0.1,0,0.1-0.1,0.1-0.1C8.3,6.1,8.2,6.1,8.2,6.1C8.2,6.1,8.2,6.1,8.2,6.1z M4.8,6.1C4.7,6.2,4.6,6.3,4.5,6.3
                c0,0.1,0.1,0.1,0.1,0.1l0,0.4h0.3v0h0v0h0l0-0.1l0-0.2l0,0V6.4l0,0L4.8,6.1z M8.6,6.2v0.3c0,0,0.4,0,1,0.1C9.2,6.4,8.6,6.2,8.6,6.2z
                M12.1,6.3L12,6.4l0.2,0.4L12.9,7C12.6,6.4,12.1,6.3,12.1,6.3z M4.5,6.4C3.9,6.7,3.3,6.8,2.7,6.8c-0.6,0-1.1-0.1-1.6-0.4
                C1.2,7.2,1.4,8.1,1.6,9c0.2,0.7,0.7,1.2,1.3,1.4c0.7,0.3,1.6,0.4,2.3,0.6c3.5,0.6,6.9,2.8,8.1,4.7c0,0,0.1,0.2,0.2,0.3
                c0.3,0.6,0.4,1.1,0.4,1.6c1.9-1.5,3.3-3.2,4.2-5c-0.1-0.9-1.1-1.1-1.5-1.1c-0.8,0-1.6-0.3-2.1-0.9v0c-0.3,0.4-0.7,0.4-0.9,0.4
                c-0.2,0-0.6-0.2-0.8-0.4c-0.2-0.2-0.2-0.6-0.2-0.6l-0.8-0.3l-0.9-0.5l-0.2,0c-0.3,0.1-0.7,0-1-0.2c0,0.1-0.2,0.5-0.5,0.7
                c-0.2,0.2-0.7,0.4-1,0.4c-0.3,0-1.1-0.4-1.3-0.9c-0.2-0.5,0-1.1,0-1.1L6.5,8.1L6.4,7.9c0,0-0.5,0.4-0.9,0.2C4.9,8,4.5,7.6,4.4,7.2
                C4.3,6.8,4.4,6.5,4.5,6.4C4.5,6.4,4.5,6.4,4.5,6.4z M7.4,6.5C7.4,6.5,7.4,6.5,7.4,6.5C8,6.6,8.2,6.7,8.3,6.7c0,0,0,0.1,0.1,0.1
                c-0.5-0.1-1,0-1.1,0c0.1,0.1,0.1,0.3,0,0.4C7.1,7.3,6.9,7.4,6.9,7.4H6.8l-0.2,0C6.5,7.3,6.5,7,6.5,7c0,0,0,0.1,0,0.1
                c0,0.2,0.1,0.2,0.2,0.2c-0.2-0.1-0.2-0.4,0-0.6c0.1-0.1,0.2,0,0.2,0S6.8,6.5,6.7,6.6C6.6,6.7,6.6,6.8,6.5,7L6.3,7
                C6.2,6.9,5.9,6.5,5.9,6.5L5.8,6.8c0,0,0.2,0.3,0.3,0.4L6,7.3c0,0,0,0.1,0,0.1c0,0,0,0.1,0,0.1L5.7,7.5h0L5.2,7.1v0.3h0
                c0,0.1,0.1,0.2,0.3,0.3c0.2,0.1,0.3,0.1,0.4,0c0.1,0,0.1,0,0.2,0.1c0.2,0.1,0.2,0,0.2,0l0.2-0.1h0l0,0l0.1-0.1c0,0,0.4,0,0.5,0
                c0.2-0.2,0.6-0.6,1-0.6c0.3,0,0.9,0.1,1.4,0.5c0.4,0.4,0.3,1,0.3,1l0.8,0.3l-0.4-0.3c-0.3-0.7-1-1.4-1-1.4s-0.2,0-0.3,0
                c-0.1,0-0.2-0.1-0.2-0.1s0,0-0.1-0.1L8.6,6.8c0,0-0.1-0.1-0.2-0.1C8.3,6.5,7.9,6.5,7.6,6.5C7.5,6.4,7.5,6.4,7.4,6.5z M5.7,6.9
                L5.7,6.9L5.6,7.3l0.1,0V6.9z M10.8,7c-0.5,0-0.8,0-0.8,0c0.2,0.3,0.5,0.6,0.5,0.6c0.4-0.4,1.2-0.5,2.2-0.5C11.9,7.1,11.3,7,10.8,7z
                M9.4,7.3C9.7,7.4,10,7.8,10,7.8l0.1,0l0.2,0C9.9,7.3,9.4,7.3,9.4,7.3z M13.5,7.4c0,0-0.1,0.1-0.1,0.2c0,0-0.3,0.1-0.5,0.1
                c-0.2,0.1-0.4,0.2-0.5,0.3c0,0,0,0.1,0,0.2c0,0,0,0,0.1,0c0,0,0,0,0,0V8c0,0,0.5-0.4,1.2-0.4l0.1-0.2L13.5,7.4z M12.5,8.1
                C12.5,8.1,12.5,8.2,12.5,8.1L12.5,8.1C12.5,8.1,12.5,8.1,12.5,8.1z M12.5,8.2l-0.3,0.3l0.1,0.1l0.8,0.3c0.2-0.1,0.5-0.2,0.5-0.2
                C13.4,8.5,12.8,8.3,12.5,8.2C12.6,8.2,12.6,8.2,12.5,8.2z M8.9,7.9c-0.4,0-0.7,0.3-0.7,0.3l0.3,0.2l0.1,0L8.9,7.9z M9.1,8L8.8,8.5
                l0,0l0.6,0C9.4,8.1,9.1,8,9.1,8z M8,8.4C7.7,8.9,7.9,9.3,7.9,9.3l0.3-0.5V8.7L8,8.4z M12.1,8.6l-0.6,0.6l0.5,0.1l0.5-0.5L12.1,8.6
                L12.1,8.6z M14.8,8.6c-0.2,0-0.3,0-0.5,0.1c0,0,0.1,0,0.1,0.1C14.7,9,14.8,9,14.8,9.4c0,0.3,0,0.6-0.2,1c1.1,0.7,2.5,0.2,3.1,0.7
                C17.6,9.8,16,8.6,14.8,8.6z M9.4,8.8l-0.6,0l0,0l0.2,0.6C9.3,9.2,9.4,8.8,9.4,8.8z M12.7,8.9l-0.5,0.6l0.4,0.1
                c0.1-0.3,0.4-0.6,0.4-0.6L12.7,8.9z M8.4,9L8,9.5c0.3,0.2,0.7,0.1,0.7,0.1L8.4,9L8.4,9z M14.3,9.3c-0.2,0-0.4,0.3-0.4,0.3l0.1,0.1
                l0,0L14.3,9.3L14.3,9.3z M14.4,9.3l-0.3,0.4v0h0.4C14.6,9.5,14.4,9.3,14.4,9.3z M13.7,9.8c-0.2,0.4-0.2,0.6-0.2,0.6l0.2-0.4V9.9
                C13.8,9.9,13.8,9.9,13.7,9.8z M14.6,10l-0.5,0l0,0l0.1,0.5c0,0,0.1-0.1,0.2-0.3C14.5,10.1,14.6,10,14.6,10z M13.8,10.2l-0.2,0.4l0,0
                c0.2,0.1,0.4,0,0.4,0L13.8,10.2L13.8,10.2z M3.8,12.1c-0.3,0-0.7,0.1-0.9,0.4c1.3,2.6,3.5,5.2,7.2,6.9l0.4,0.2l0.3-0.2
                c0.9-0.4,1.7-0.9,2.4-1.4c0.3-0.4,0.3-1.1,0-1.7c0-0.1-0.2-0.3-0.2-0.4c-1.2-1.8-5.2-3.7-9-3.9C4,12.1,3.9,12.1,3.8,12.1z"
            />
        </svg>
    );
};
