import React from 'react';

interface Props {
    className?: string;
}

export const MyVODsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M16,2H4C1.8,2,0,3.8,0,6v8c0,2.2,1.8,4,4,4h12c2.2,0,4-1.8,4-4V6C20,3.8,18.2,2,16,2z M18,14c0,1.1-0.9,2-2,2H4
                c-1.1,0-2-0.9-2-2V6c0-1.1,0.9-2,2-2h12c1.1,0,2,0.9,2,2V14z"
            />
            <path d="M12.8,8.8L8.5,6.3c-0.9-0.5-2,0.1-2,1.2v5c0,1,1.1,1.7,2,1.2l4.3-2.5C13.7,10.6,13.7,9.4,12.8,8.8z" />
        </svg>
    );
};
