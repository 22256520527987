import { formatDistanceStrict, isEqual, isPast } from 'date-fns';
import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useAppState } from '../../../../client/providers/AppProvider';
import { SpinAppModals, useModals } from '../../../common/SpinModalProvider';
import { Tooltip } from '../../../common/Tooltip';
import { ComposeIcon } from '../../../svg/ComposeIcon';

type Props = {
    nextAvailable?: Date;
    setNextAvailable: Dispatch<SetStateAction<undefined | Date>>;
};

export const ComposeGroupMessage: React.FC<Props> = ({ nextAvailable, setNextAvailable }) => {
    const nextAvailableDate = useMemo(() => {
        return new Date(nextAvailable);
    }, [nextAvailable]);

    const formattedDate = useMemo(() => {
        if (nextAvailable) {
            return formatDistanceStrict(nextAvailableDate, new Date());
        } else {
            return '';
        }
    }, [nextAvailable, nextAvailableDate]);

    const canSendMessage = useMemo(() => {
        if (!!!nextAvailable || isPast(nextAvailableDate) || isEqual(nextAvailableDate, new Date())) {
            return true;
        } else {
            return false;
        }
    }, [nextAvailableDate, nextAvailable]);

    const { openModal } = useModals();
    const { subscribed, subtier } = useAppState();

    const enabled = subscribed && subtier >= 2;

    return (
        <div className="compose-group-message-container">
            <div className={`compose-group-message${enabled ? '' : ' disabled'}`}>
                <div className="compose-info-container">
                    <h2 className="title">Add Group Message</h2>
                    {canSendMessage && <p className="subtitle blue">Limited to once per week</p>}
                    {!canSendMessage && <p className="subtitle red">Available in {formattedDate}</p>}
                </div>
                {enabled && (
                    <button
                        className="button blue-button"
                        disabled={!canSendMessage}
                        onClick={() => openModal(SpinAppModals.COMPOSE_GROUP_MESSAGE, { setNextAvailable })}
                    >
                        <ComposeIcon />
                    </button>
                )}
                {!enabled && (
                    <div className="unsubbed-button">
                        <ComposeIcon />
                    </div>
                )}
            </div>
            {!enabled && (
                <Tooltip
                    message="This feature is only available to Tier 2 subscribers."
                    buttonLabel="Upgrade"
                    buttonLink="/dashboard/subscriptions"
                />
            )}
        </div>
    );
};
