import React, { Dispatch, SetStateAction, useMemo } from 'react';
import { useAppState } from '../../../client/providers/AppProvider';
import {
    Espin_Role_Enum,
    UnreadCollegeMessagesDocument,
    UnreadPlayerMessagesDocument,
    UnreadParentMessagesDocument,
    UnreadCollegeMessagesQuery,
    UnreadCollegeMessagesQueryVariables,
    UnreadPlayerMessagesQuery,
    UnreadPlayerMessagesQueryVariables,
    UnreadParentMessagesQuery,
    UnreadParentMessagesQueryVariables,
} from '../../../generated/spin-graphql';

import { gql } from '@urql/core';
import { MobileDashboardNavigation } from '../navigation/MobileDashboardNavigation';
import { DashboardNavigation } from '../navigation/DashboardNavigation';
import { useAuthenticatedQuery } from '../../../client/hooks';

type Props = {
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>;
};

gql`
    query UnreadCollegeMessages($collegeId: uuid) {
        espin_message(
            where: {
                _and: {
                    college_id: { _eq: $collegeId }
                    viewed_at: { _is_null: true }
                    message_type: { _eq: player_messages_college }
                }
            }
            order_by: { created_at: asc }
        ) {
            id
        }
    }

    query UnreadPlayerMessages($playerUserId: String) {
        espin_message(
            where: {
                _and: {
                    player_user_id: { _eq: $playerUserId }
                    viewed_at: { _is_null: true }
                    message_type: { _eq: college_messages_player }
                }
            }
            order_by: { created_at: asc }
        ) {
            id
        }
    }

    query UnreadParentMessages($playerUserIds: [String!]) {
        espin_message(
            where: {
                _and: {
                    player_user_id: { _in: $playerUserIds }
                    viewed_at: { _is_null: true }
                    message_type: { _eq: college_messages_player }
                }
            }
            order_by: { created_at: asc }
        ) {
            id
        }
    }
`;

export const DashboardNavigationWrapper: React.FC<Props> = ({ open, setOpen }) => {
    const { user } = useAppState();

    const { data: collegeMessages } = useAuthenticatedQuery<
        UnreadCollegeMessagesQuery,
        UnreadCollegeMessagesQueryVariables
    >(
        {
            query: UnreadCollegeMessagesDocument,
            variables: useMemo(() => ({ collegeId: user?.college_profile?.college?.id }), [user]),
            pause: !!!user?.college_profile?.college,
        },
        ['espin_message'],
    );

    const { data: playerMessages } = useAuthenticatedQuery<
        UnreadPlayerMessagesQuery,
        UnreadPlayerMessagesQueryVariables
    >(
        {
            query: UnreadPlayerMessagesDocument,
            variables: useMemo(() => ({ playerUserId: user?.id }), [user]),
            pause: !!!user?.player_profile,
        },
        ['espin_message'],
    );

    const { data: parentMessages } = useAuthenticatedQuery<
        UnreadParentMessagesQuery,
        UnreadParentMessagesQueryVariables
    >(
        {
            query: UnreadParentMessagesDocument,
            variables: useMemo(() => ({ playerUserIds: user?.parent_profile?.child_profiles.map((i) => i.user_id) }), [
                user,
            ]),
            pause: !!!user?.parent_profile?.child_profiles,
        },
        ['espin_message'],
    );

    const messages = useMemo(() => {
        if (user?.role === Espin_Role_Enum.EspinCollegeUser) {
            return collegeMessages?.espin_message ?? [];
        } else if (user?.role === Espin_Role_Enum.EspinPlayerUser) {
            return playerMessages?.espin_message ?? [];
        } else if (user?.role === Espin_Role_Enum.EspinParentUser) {
            return parentMessages?.espin_message ?? [];
        } else {
            return [];
        }
    }, [collegeMessages?.espin_message, playerMessages?.espin_message, parentMessages?.espin_message, user?.role]);

    return (
        <>
            <MobileDashboardNavigation
                open={open}
                onClick={() => setOpen(!open)}
                onClose={() => setOpen(false)}
                messages={messages.length}
            />
            <DashboardNavigation messages={messages.length} />
        </>
    );
};
