import Link from 'next/link';
import { useMemo, useState } from 'react';
import { HamburgerIcon } from '../../svg/HamburgerIcon';
import { SPINIcon } from '../../svg/SPINIcon';
import { AccountDropdown } from '../navigation/AccountDropdown';
import { DashboardNavigationWrapper } from '../navigation/DashboardNavigationWrapper';
import { HeadMeta } from './HeadMeta';
import { FixedProfileIncomplete } from '../../sections/FixedProfileIncomplete';
import { useNeedsOnboardingInfo } from '../../../client/hooks';

import { Espin_Role_Enum } from 'generated/spin-graphql';
import { useAppState } from '../../../client/providers/AppProvider';
import { ComposeGroupMessage } from '../../dashboard/messages/colleges/ComposeGroupMessage';
import { useRouter } from 'next/router';

type Props = {
    pageTitle: string;
    subtitle?: string;
    showProfileIncomplete?: boolean;
};

export const DashboardLayout: React.FC<Props> = ({ pageTitle, subtitle, children, showProfileIncomplete = false }) => {
    const [open, setOpen] = useState<boolean>(false);
    const { needs } = useNeedsOnboardingInfo();
    const { user } = useAppState();
    const router = useRouter();

    const hasCollegeGroupMessage =
        router.pathname === '/dashboard/messages' && user?.role === Espin_Role_Enum.EspinCollegeUser;

    const nextAvailableCalculated = useMemo(() => {
        if (!!user?.college_profile?.last_group_message) {
            let nextAvailableDate = new Date(user?.college_profile?.last_group_message);
            nextAvailableDate.setDate(nextAvailableDate.getDate() + 7);
            return nextAvailableDate;
        } else {
            return undefined;
        }
    }, [user?.college_profile?.last_group_message]);

    const [nextAvailable, setNextAvailable] = useState<undefined | Date>(nextAvailableCalculated);

    const hasCollegeVerificationMessage =
        user?.role === Espin_Role_Enum.EspinCollegeUser && user.college_profile?.college?.verified === false;

    const hasHighSchoolVerificationMessage =
        user?.role === Espin_Role_Enum.EspinHighschoolUser && user.high_school_profile?.high_school?.verified === false;

    return (
        <>
            <HeadMeta seo={{ title: pageTitle }} />
            <div className={`dashboard`}>
                {hasCollegeVerificationMessage && (
                    <div className="college-verification-container">
                        <p className="verification-label">
                            Your college is being verified. This can take up to three business days to complete. You
                            will receive an email notification when your college is verified.
                        </p>
                    </div>
                )}
                {hasHighSchoolVerificationMessage && (
                    <div className="college-verification-container">
                        <p className="verification-label">
                            Your high school is being verified. This can take up to three business days to complete. You
                            will receive an email notification when your high school is verified.
                        </p>
                    </div>
                )}
                <div
                    className={`dashboard-container${
                        hasCollegeVerificationMessage || hasHighSchoolVerificationMessage
                            ? ' has-verification-message'
                            : ''
                    }`}
                >
                    <DashboardNavigationWrapper setOpen={setOpen} open={open} />
                    <div className="mobile-dashboard-header">
                        <button className="mobile-navigation-button" onClick={() => setOpen(!open)}>
                            <HamburgerIcon />
                        </button>
                        <Link href="/">
                            <a className="brand">
                                <SPINIcon className="spin-logo" />
                            </a>
                        </Link>
                        <AccountDropdown />
                    </div>
                    <div className="dashboard-content-container">
                        <div className="dashboard-content-header">
                            <div className="title-container">
                                <h1 className="title">{pageTitle}</h1>
                                {subtitle && <p className="subtitle">{subtitle}</p>}
                            </div>
                            {hasCollegeGroupMessage && (
                                <ComposeGroupMessage
                                    nextAvailable={nextAvailable}
                                    setNextAvailable={setNextAvailable}
                                />
                            )}
                            <AccountDropdown />
                        </div>
                        <div className="dashboard-content">{children}</div>
                    </div>
                </div>
            </div>
            <FixedProfileIncomplete show={needs} />
        </>
    );
};
