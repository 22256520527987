import React, { createContext, useCallback, useContext } from 'react';
import { useImmer } from 'use-immer';
import { CokeAllstarsPromoModal } from './modals/custom-promos/CokeAllstarsPromoPopup';
import { ComingSoonPopup } from './modals/ComingSoonPopup';
import { AddGamePopup } from './modals/dashboard/AddGamePopup';
import { ComposeGroupMessagePopup } from './modals/dashboard/ComposeGroupMessagePopup';
import { EditGamePopup } from './modals/dashboard/EditGamePopup';
import { FindCollegesPopup } from './modals/dashboard/find-colleges/FindCollegesPopup';
import { FindPlayersPopup } from './modals/dashboard/find-players/FindPlayersPopup';
import { GenericConfirmationModal } from './modals/GenericConfirmationModal';
import { ReferralOptionSelectModal } from './modals/ReferralOptionSelectModal';
import { SignupAccountSelectModal } from './modals/SignupAccountSelectModal';
import { HighSchoolCreateTeamPopup } from './modals/dashboard/my-teams/HighSchoolCreateTeamPopup';
import { HighSchoolAddPlayersToTeamPopup } from './modals/dashboard/my-teams/HighSchoolAddPlayersToTeamPopup';
import { HighSchoolEditTeamPopup } from './modals/dashboard/my-teams/HighSchoolEditTeamPopup';
import { PlayerCreateTeamPopup } from './modals/dashboard/my-teams/PlayerCreateTeamPopup';
import { PlayerInviteResponsePopup } from './modals/dashboard/my-teams/PlayerInviteResponsePopup';
import { PlayerEditTeamPopup } from './modals/dashboard/my-teams/PlayerEditTeamPopup';

export enum SpinAppModals {
    SIGNUP_SELECT_ACCT_TYPE = 'signup-select-account-type',
    SIGNUP_SELECT_ACCT_TYPE_FROM_SUB = 'signup-select-account-type-from-sub',
    SUB_SELECT_REFERRAL_OPTION = 'subscription-select-referral-option',
    EDIT_GAME = 'edit-game',
    ADD_GAME = 'add-game',
    CONFIRMATION = 'confirmation',
    COMING_SOON = 'coming-soon',
    COMPOSE_GROUP_MESSAGE = 'compose-group-message',
    HIGH_SCHOOL_CREATE_TEAM = 'high-school-create-team',
    HIGH_SCHOOL_EDIT_TEAM = 'high-school-edit-team',
    HIGH_SCHOOL_ADD_PLAYERS_TO_TEAM = 'high-school-add-players-to-team',
    PLAYER_INVITE_RESPONSE = 'player-invite-response',
    PLAYER_CREATE_TEAM = 'player-create-team',
    PLAYER_EDIT_TEAM = 'player-edit-team',
    FIND_COLLEGES = 'find-colleges',
    FIND_PLAYERS = 'find-players',
    COKE_ALLSTARS = 'coke-allstars',
}

export function useModals() {
    return useContext(ModalContext);
}
type ModalContextType = {
    closeActiveModal: () => void;
    openModal: (modal: SpinAppModals, params?: any) => void;
};

export const ModalContext = createContext<ModalContextType>({
    closeActiveModal: () => {},
    openModal: (m) => {},
});

export function SpinModalProvider({ children }: { children: React.ReactNode }) {
    // watches for the "modal" query param and opens the appropriate modal

    const [currentModal, setCurrentModal] = useImmer<null | { modal: SpinAppModals; params: any }>(null);
    const closeActiveModal = useCallback(() => {
        setCurrentModal(null);
    }, [setCurrentModal]);

    const openModal = useCallback(
        (modal: SpinAppModals, params?: any) => {
            setCurrentModal({ modal, params });
        },
        [setCurrentModal],
    );

    const activeModal: JSX.Element = useCallback(
        (m) => {
            switch (m) {
                case SpinAppModals.SIGNUP_SELECT_ACCT_TYPE:
                    return <SignupAccountSelectModal {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.SUB_SELECT_REFERRAL_OPTION:
                    return <ReferralOptionSelectModal {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.COMING_SOON:
                    return <ComingSoonPopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.EDIT_GAME:
                    return <EditGamePopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.ADD_GAME:
                    return <AddGamePopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.CONFIRMATION:
                    return <GenericConfirmationModal {...(currentModal.params && { params: currentModal.params })} />;
                case SpinAppModals.COMPOSE_GROUP_MESSAGE:
                    return <ComposeGroupMessagePopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.FIND_COLLEGES:
                    return <FindCollegesPopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.FIND_PLAYERS:
                    return <FindPlayersPopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.COKE_ALLSTARS:
                    return <CokeAllstarsPromoModal {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.HIGH_SCHOOL_CREATE_TEAM:
                    return <HighSchoolCreateTeamPopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.HIGH_SCHOOL_EDIT_TEAM:
                    return <HighSchoolEditTeamPopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.HIGH_SCHOOL_ADD_PLAYERS_TO_TEAM:
                    return <HighSchoolAddPlayersToTeamPopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.PLAYER_CREATE_TEAM:
                    return <PlayerCreateTeamPopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.PLAYER_EDIT_TEAM:
                    return <PlayerEditTeamPopup {...(currentModal.params && { ...currentModal.params })} />;
                case SpinAppModals.PLAYER_INVITE_RESPONSE:
                    return <PlayerInviteResponsePopup {...(currentModal.params && { ...currentModal.params })} />;
                default:
                    return <></>;
            }
        },
        [currentModal],
    )(currentModal?.modal);
    return (
        <ModalContext.Provider value={{ openModal, closeActiveModal }}>
            {activeModal}
            {children}
        </ModalContext.Provider>
    );
}
