import React from 'react';

type Props = {
    className?: string;
};

export const HaloIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M0,8.8C0.5,8,1.1,7.5,1.8,7.1c0.6-0.3,1.3-0.6,2-0.8C4,6.3,4.2,6.2,4.3,6C4.5,5.7,4.8,5.7,5,5.6c1.7-0.3,3.5-0.3,5.2-0.2
                c1.9,0.1,3.7,0.6,5.4,1.4c0.7,0.3,1.3,0.7,1.8,1.3c0.3,0.4,0.5,0.8,0.8,1.2c0.1,0.3,0.3,0.4,0.6,0.4c0.4,0,0.7,0,1.1,0
                c0.1,0,0.1,0,0.2,0c0,0.3,0,0.6,0,0.9c-0.1,0-0.1,0-0.2,0c-0.4,0-0.8,0-1.2,0c-0.2,0-0.4,0.1-0.5,0.3c-1.1,1.9-2.9,2.8-4.9,3.3
                c-1.5,0.4-3,0.5-4.5,0.5c-0.4,0-0.8,0-1.2,0c-0.1,0-0.2,0-0.3-0.1c-0.3-0.2-0.5-0.4-0.8-0.4c-0.7-0.1-1.4-0.2-2.1-0.3
                c-0.2,0-0.2-0.1-0.2-0.3c0-0.3,0-0.5,0-0.8c0.3,0.1,0.6,0.1,0.9,0.1c1.4,0.2,2.8,0.4,4.2,0.3c1.7-0.2,3.4-0.5,4.9-1.5
                c0.3-0.2,0.6-0.4,0.8-0.7c0.5-0.6,0.5-1.3,0.1-1.9c-0.3-0.4-0.6-0.7-1-0.9c-0.9-0.6-1.8-0.9-2.8-1C9.3,6.9,7.6,6.9,5.8,7.1
                C4.5,7.3,3.3,7.6,2.2,8.2c-0.4,0.3-0.8,0.6-1.1,1C0.9,9.5,0.9,9.5,0.7,9.3C0.5,9.1,0.2,8.9,0,8.8z"
            />
            <path
                d="M8.3,9.3c0.4,0,0.8,0,1.1,0.2c0.2,0.1,0.3,0.2,0.4,0.3c0.2,0.3,0.2,0.6,0,0.8c-0.2,0.3-0.6,0.4-0.9,0.5
                c-0.6,0.1-1.1,0.1-1.6-0.1c-0.2-0.1-0.4-0.2-0.5-0.3c-0.3-0.3-0.3-0.6,0-0.9c0.3-0.3,0.7-0.4,1.1-0.5C8,9.2,8.2,9.3,8.3,9.3z"
            />
        </svg>
    );
};
