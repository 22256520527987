import React, { useCallback, useMemo, useState } from 'react';
import { useModals } from '../../../SpinModalProvider';
import { BaseRouteModal } from '../../BaseRouteModal';
import { GamesSelectComponent } from '../../../../forms/GameSelectComponent';
import { useFormWithSchema } from '../../../../../client/hooks/use-form-schema';
import { toast } from 'react-toastify';
import { InputField } from '../../../../input/InputField';
import { gql } from '@urql/core';
import * as Yup from 'yup';
import { useAuthenticatedMutation } from '../../../../../client/hooks';
import {
    DeleteHighSchoolTeamInvitesDocument,
    DeleteHighSchoolTeamInvitesMutation,
    DeleteHighSchoolTeamInvitesMutationVariables,
    DeleteHighSchoolTeamMembersDocument,
    DeleteHighSchoolTeamMembersMutation,
    DeleteHighSchoolTeamMembersMutationVariables,
    EditPlayerTeamActionDocument,
    EditPlayerTeamActionMutation,
    EditPlayerTeamActionMutationVariables,
    Espin_Team_Role_Enum,
    HighSchoolTeamCardFragment,
} from '../../../../../generated/spin-graphql';
import { ActionSuccessError } from '../../../../../types/shared.types';
import { PlayerInviteOptions } from './InvitePlayerOptions';
import { SelectField } from '../../../../input/SelectField';
import { HighSchoolEditableTeamPlayerList } from '../../../../dashboard/my-profile/high-school/HighSchoolEditableTeamPlayerList';
import { HighSchoolEditableTeamInviteList } from '../../../../dashboard/my-profile/high-school/HighSchoolEditableTeamInviteList';
import { teamCaptainChangeMessage } from '../../../../../shared/shared.constants';

const EditPlayerTeamForm = Yup.object({
    team_name: Yup.string().required('Required').min(3, 'Must have a team name with at least 3 characters'),
    game: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required('Please select a game'),
    }),
    team_captain: Yup.object()
        .shape({
            label: Yup.string(),
            value: Yup.string(),
        })
        .nullable(),
    players_to_invite: Yup.array(Yup.string()),
    invites_to_remove: Yup.array(Yup.string()).nullable(),
    members_to_remove: Yup.array(Yup.string()).nullable(),
});
type EditPlayerTeamFormData = Yup.InferType<typeof EditPlayerTeamForm>;

gql`
    mutation EditPlayerTeamAction($input: EditPlayerTeamInput!) {
        editPlayerTeam(input: $input) {
            id
            notFound
            status
            comments
        }
    }
`;

type Props = {
    team: HighSchoolTeamCardFragment;
};

const EditTeamForm: React.FC<Props> = ({ team }) => {
    const [captainWarning, setCaptainWarning] = useState<string>();

    const [_, editTeam] = useAuthenticatedMutation<EditPlayerTeamActionMutation, EditPlayerTeamActionMutationVariables>(
        EditPlayerTeamActionDocument,
    );

    const [__, deleteInvites] = useAuthenticatedMutation<
        DeleteHighSchoolTeamInvitesMutation,
        DeleteHighSchoolTeamInvitesMutationVariables
    >(DeleteHighSchoolTeamInvitesDocument);

    const [___, deleteMembers] = useAuthenticatedMutation<
        DeleteHighSchoolTeamMembersMutation,
        DeleteHighSchoolTeamMembersMutationVariables
    >(DeleteHighSchoolTeamMembersDocument);

    const options = useMemo(() => team?.members.map((t) => ({ label: t.player_profile.spin_username, value: t.id })), [
        team?.members,
    ]);

    const teamCaptain = useMemo(() => team?.members.filter((m) => m.team_role === Espin_Team_Role_Enum.Captain), [
        team?.members,
    ]);

    const {
        register,
        handleSubmit,
        control,
        watch,
        setError,
        setValue,
        formState: { isSubmitting, errors },
    } = useFormWithSchema(EditPlayerTeamForm, {
        defaultValues: {
            team_name: team?.display_name,
            team_captain:
                teamCaptain && teamCaptain.length
                    ? { label: teamCaptain[0].player_profile?.spin_username, value: teamCaptain[0].id }
                    : undefined,
            // @ts-ignore
            game: team?.game ? { label: team.game.name, value: team.game.game_definition } : undefined,
        },
    });
    const { closeActiveModal } = useModals();
    const onSubmit = useCallback(
        async (values: EditPlayerTeamFormData) => {
            const { team_name, game, players_to_invite, team_captain, invites_to_remove, members_to_remove } = values;

            if (!!invites_to_remove && invites_to_remove.length > 0) {
                const deleteInviteResult = await deleteInvites({
                    inviteIds: invites_to_remove,
                });

                if (deleteInviteResult.error) {
                    toast.error(`There was an error deleting your invites. Check details and try again`);
                    console.error(deleteInviteResult.error);
                    return;
                }
            }

            if (!!members_to_remove && members_to_remove.length > 0) {
                const deleteMemberResult = await deleteMembers({
                    memberIds: members_to_remove,
                });

                if (deleteMemberResult.error) {
                    toast.error(`There was an error deleting your team members. Check details and try again`);
                    console.error(deleteMemberResult.error);
                    return;
                }
            }

            const result = await editTeam(
                {
                    input: {
                        id: team?.id,
                        team_captain: team_captain?.value,
                        name: team_name,
                        primary_game: game?.value,
                        members: players_to_invite,
                    },
                },
                { additionalTypenames: ['espin_team', 'espin_team_members', 'espin_team_invite'] },
            );

            if (result.error) {
                toast.error(`There was an error editing your team. Check details and try again`);
                console.error(result.error);
                return;
            }
            // check ze result to notify which team members failed to add
            const { status, notFound, id, comments } = result.data?.editPlayerTeam;
            if (status === ActionSuccessError.SUCCESS) {
                toast.success('Team edited!');
                closeActiveModal();
            } else {
                if (notFound && notFound.length > 0) {
                    setError('players_to_invite', {
                        type: 'custom',
                        message: `User(s) not found! Please be sure to only invite players with active accounts. If they don't have an account, have them make one first, then you can invite them to your team.`,
                    });
                } else if (id === 'Duplicate Team Name') {
                    toast.error(`That team name already exists`);
                } else if (comments) {
                    toast.error(comments);
                } else {
                    // parse out other things here
                    toast.error(`There was an error editing your team`);
                }

                return;
            }
        },
        [editTeam, team?.id, deleteInvites, deleteMembers, closeActiveModal, setError],
    );

    const captainChangeWarning = useCallback(
        (val) => {
            if (!!watch('team_captain')) {
                setCaptainWarning(teamCaptainChangeMessage);
            }
            return val;
        },
        [setCaptainWarning, watch],
    );

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmit)(e);
                }}
                className="create-team-content"
            >
                <GamesSelectComponent
                    error={errors.game?.value?.message}
                    control={control}
                    isMulti={false}
                    required={true}
                    placeholder={'Select a game'}
                    name="game"
                />
                <InputField
                    error={errors.team_name?.message}
                    htmlFor={'team_name'}
                    name={'team_name'}
                    label="Team Name"
                    placeholder="Team Name"
                    required={true}
                    register={register}
                />
                <SelectField
                    warning={captainWarning}
                    className="offered-games"
                    name="team_captain"
                    required={false}
                    control={control}
                    placeholder={'Select a team captain'}
                    isMulti={false}
                    options={options}
                    onChange={captainChangeWarning}
                />
                {team && (
                    <div className="roster">
                        <HighSchoolEditableTeamPlayerList
                            setVal={(val: string[]) => setValue('members_to_remove', val)}
                            playerList={team}
                            register={register}
                        />
                        <HighSchoolEditableTeamInviteList
                            setVal={(val: string[]) => setValue('invites_to_remove', val)}
                            inviteList={team}
                            register={register}
                        />
                    </div>
                )}
                <PlayerInviteOptions control={control} required={false} />
                <div className="button-container">
                    <button disabled={isSubmitting} className="button medium-button blue-button">
                        Update Team
                    </button>
                </div>
            </form>
        </>
    );
};

export const PlayerEditTeamPopup: React.FC<Props> = ({ team }) => {
    const { closeActiveModal } = useModals();
    return (
        <BaseRouteModal
            className="edit-team-popup"
            content={<EditTeamForm team={team} />}
            description=""
            title="Edit Team"
            onCancel={() => closeActiveModal()}
        />
    );
};
