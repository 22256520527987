import React from 'react';

interface Props {
    className?: string;
}

export const ComposeIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M4.354 15.771h1.042l6.875-6.896-1.042-1.042-6.875 6.896Zm11.875-8.104-3.791-3.792.958-.958q.562-.584 1.344-.594.781-.011 1.385.594l1.104 1.062q.583.583.552 1.386-.031.802-.593 1.343ZM3.562 17.688q-.458 0-.802-.344-.343-.344-.343-.802v-2.188q0-.229.083-.427.083-.198.25-.365l8.458-8.458 3.813 3.792-8.479 8.458q-.146.167-.354.25-.209.084-.438.084Zm8.209-9.334-.542-.521 1.042 1.042Z" />
        </svg>
    );
};
