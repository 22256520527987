import React, { useMemo } from 'react';
import { SelectField } from '../input/SelectField';

export type Props = {
    control: any;
    name: string;
    options: any[];
    isMulti?: boolean;
    defaultValue?: { label: string; value: string };
    required?: boolean;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
};

export function ControlledMultiSelectComponent({
    control,
    name,
    options,
    defaultValue,
    required = false,
    isMulti = true,
    placeholder = 'Game',
    error,
    disabled,
}: Props) {
    const mappedOptions = useMemo(() => options.map((option) => ({ label: option.name, value: option.name })), [
        options,
    ]);

    return (
        <SelectField
            name={name}
            required={required}
            control={control}
            placeholder={placeholder}
            isMulti={isMulti}
            options={mappedOptions}
            error={error}
            disabled={disabled}
        />
    );
}
