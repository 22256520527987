// Wrapper function to render only on client side

import React from 'react';
import { useEffect, useState } from 'react';

// helper hook to determine if we're in SSR or CSR
export function useIsClientReady() {
    const [hasMounted, setHasMounted] = useState(false);
    useEffect(() => {
        setHasMounted(true);
    }, []);
    return hasMounted;
}

// Pass in fallback for a SSR version if you want.
export function ClientOnly({ children, fallback = null, ...delegated }: { children: any; fallback?: any }) {
    const hasMounted = useIsClientReady();
    if (!hasMounted) {
        return fallback;
    }
    return <React.Fragment {...delegated}>{children}</React.Fragment>;
}
