import React from 'react';

type Props = {
    className?: string;
};

export const DefaultGameIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M20,14.3l-1.1-8c-0.3-2.1-2.1-3.6-4.1-3.6H5.3c-2.1,0-3.8,1.5-4.1,3.6l-1.1,8c-0.2,1.6,1,3,2.6,3l0,0
                c0.7,0,1.4-0.3,1.9-0.8l2.4-2.3h6.2l2.3,2.3c0.5,0.5,1.2,0.8,1.9,0.8l0,0C19,17.3,20.2,15.9,20,14.3z M9,9H6.9V11h-1V9H3.8v-1h2.1
                V5.8h1v2.1H9V9z M13.1,7.9c-0.6,0-1-0.5-1-1s0.5-1,1-1c0.6,0,1,0.5,1,1S13.7,7.9,13.1,7.9z M15.2,11c-0.6,0-1-0.5-1-1s0.5-1,1-1
                s1,0.5,1,1S15.8,11,15.2,11z"
            />
        </svg>
    );
};
