import React from 'react';

interface Props {
    className?: string;
}

export const CollegeIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M9.2,14.4l-5.5-4.2v4.5c0,1.7,2.9,3.9,6.5,3.9c3.6,0,6.5-2.1,6.5-3.9v-4.3l-5.5,4.1C10.6,14.8,9.8,14.8,9.2,14.4z
                M18.9,5.6L11,0.2c-0.4-0.3-1-0.3-1.4,0L1.7,5.6C1,6.1,1,7.1,1.6,7.7v6.5l-1.1,4.4C0.4,19.3,0.9,20,1.6,20h0.7
                c0.7,0,1.3-0.7,1.1-1.4l-1.1-4.4v-6l7.1,5.4c0.4,0.3,1.1,0.3,1.6,0l7.9-5.9C19.7,7.2,19.6,6.1,18.9,5.6z M10.2,7.6
                c-0.6,0-1.1-0.5-1.1-1s0.4-1.1,1.1-1.1s1.1,0.5,1.1,1.1S10.8,7.6,10.2,7.6z"
            />
        </svg>
    );
};
