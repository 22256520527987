import { useClient } from 'urql';
import { getClientEnv } from '../clientEnv';

export function useAuthenticatedClient() {
    const url = getClientEnv().NEXT_PUBLIC_GQL_URL;
    const gqlClient = useClient();
    return {
        getAuthClient: (accessToken: string) => {
            return {
                gqlClient,
                options: {
                    url,
                    fetchOptions: {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                },
            };
        },
    };
}
