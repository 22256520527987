import React from 'react';

type Props = {
    className?: string;
    link: string;
    ariaLabel: string;
    label?: string;
    title?: string;
    icon?: JSX.Element;
};

export const ExternalLink: React.FC<Props> = ({ className = '', link, label, ariaLabel, icon, title }) => {
    return (
        <a
            className={className}
            href={link}
            title={title}
            target={'_blank'}
            rel="noopener noreferrer"
            aria-label={ariaLabel}
        >
            {icon && icon}
            {label && <span>{label}</span>}
        </a>
    );
};
