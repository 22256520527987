import { PlayerCardFragment } from 'generated/spin-graphql';
import React from 'react';
import Link from 'next/link';
import { PictureWithFallback } from '../../../../sections/PictureWithFallback';
import { PlainTextLocationDisplay } from '../../../PlainTextLocationDisplay';
import { GameIcon } from '../../../../icons/GameIcon';

type Props = {
    player: PlayerCardFragment;
};

export const PlayerPreviewCard: React.FC<Props> = ({ player }) => {
    if (player.primary_game.length === 0) {
        return <></>;
    }
    const {
        game: { game_definition, name },
        rank,
    } = player.primary_game[0];

    return (
        <div className={`player-preview-card`}>
            <div className="card-top-container">
                <div className="player-personal-info">
                    <div className="player-profile-image">
                        <PictureWithFallback
                            icon={player.profile_image}
                            width={60}
                            height={60}
                            alt={`${player.first_name} ${player.last_name}'s profile picture`}
                        />
                    </div>
                    <div className="player-info-container">
                        <h2 className="player-name">{player.first_name}</h2>
                        <p className="player-location">
                            <PlainTextLocationDisplay address={player.address} />
                        </p>
                    </div>
                </div>
                <div className="player-rank-display">
                    <GameIcon game={game_definition} gameName={name} />
                    <div className="player-rank">
                        <p className="rank-label">RANK</p>
                        <p className="rank">{rank}</p>
                    </div>
                </div>
            </div>
            <div className="card-bottom-container">
                <div className="info-display">
                    <p className="display-label">GPA</p>
                    <div className="gpa display">{player.gpa}</div>
                </div>
                {player.graduating_class && (
                    <div className="info-display">
                        <p className="display-label">Class</p>
                        <div className="year display">{player.graduating_class}</div>
                    </div>
                )}
                <Link href={`/players/${player.slug}`}>
                    <a className={`button medium-button blue-button`}>View Profile</a>
                </Link>
            </div>
        </div>
    );
};
