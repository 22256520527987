import React from 'react';

type Props = {
    className?: string;
};

export const ApexLegendsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M3,20c-0.1-0.1-0.2-0.2-0.4-0.3c-0.9-0.8-1.7-1.6-2.6-2.4c0,0,0,0-0.1-0.1C3.3,11.5,6.7,5.7,10,0c3.3,5.7,6.7,11.4,10,17.1
                c-0.1,0.1-0.1,0.1-0.2,0.2c-0.9,0.9-1.7,1.7-2.6,2.6c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0-0.1-0.1c-1.9-1.3-3.9-2.6-5.8-4
                c0,0,0,0-0.1-0.1c1.2,0,2.4,0,3.6,0c-1.6-2.7-3.2-5.5-4.8-8.2c-1.6,2.7-3.2,5.5-4.8,8.2c1.2,0,2.4,0,3.6,0c0,0-0.1,0.1-0.1,0.1
                c-1.7,1.2-3.3,2.3-5,3.5C3.5,19.7,3.3,19.8,3,20C3,20,3,20,3,20z"
            />
        </svg>
    );
};
