import React from 'react';

interface Props {
    className?: string;
}

export const MySubscriptionsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M18,2H2C0.9,2,0,2.9,0,4v12c0,1.1,0.9,2,2,2h16c1.1,0,2-0.9,2-2V4C20,2.9,19.1,2,18,2z M17,16H3c-0.6,0-1-0.4-1-1v-4
                c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v4C18,15.6,17.6,16,17,16z M17,6H3C2.4,6,2,5.6,2,5v0c0-0.6,0.4-1,1-1h14c0.6,0,1,0.4,1,1v0
                C18,5.6,17.6,6,17,6z"
            />
        </svg>
    );
};
