import React from 'react';

type Props = {
    className?: string;
};

export const ChessIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M9.2,0c0,0.3,0.1,0.6,0.1,0.9C9.3,0.9,9.4,1,9.4,1c1.6-0.1,2.7,0.5,3.5,1.9c0.3,0.5,1,0.7,1.5,1.1c0.5,0.3,1,0.6,1.3,1.1
                c0.5,0.7,0.3,1.7-0.3,2.2c-0.6,0.6-1.7,0.7-2.2,0.1c-0.9-1.1-2-0.5-3.1-0.6c1.9,1.5,3.4,3.1,4.2,5.6c-0.6,0-1.1,0-1.6,0
                c0,0.3,0,0.6,0,0.9c0.8,0,1.1,0.2,0.8,1.2c-0.2,0.6,0.1,1.4,0.1,2.2c0.5,0.3,0.8,1.3,0.4,2c-0.2,0.4-0.4,0.9-0.6,1.4
                c-2.8,0-5.7,0-8.5,0c-0.3-0.5-0.5-0.9-0.7-1.3c-0.4-0.7-0.1-1.9,0.6-2.1c0-0.7,0.1-1.4,0.1-2c0-0.1,0.1-0.2,0-0.2
                c-0.6-0.9,0-1.1,0.7-1.2c0-0.3,0-0.5,0-0.9c-0.5,0-0.9,0-1.3,0C3.2,7.1,4.6,1.5,9.2,0z"
            />
        </svg>
    );
};
