import React, { useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
interface Props {
    label?: string;
    className?: string;
    register?: UseFormRegister<any>;
    value?: boolean;
    name?: string;
    required?: boolean;
    handleChange?: (checked: boolean) => void;
    error?: string;
}

export const ToggleSwitch: React.FC<Props> = ({ label, className, register, value, name, required = false, error }) => {
    const [checked, setChecked] = useState<boolean>(value);

    const toggle = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
    };

    return (
        <div className={className}>
            {!!label && <span className="description">Is this your primary game?</span>}
            <div className="toggle-switch-container">
                <label className="switch">
                    <input
                        type="checkbox"
                        name={name}
                        {...(!!register && register(name, { required }))}
                        required={required}
                        onChange={(event) => toggle(event)}
                        checked={checked}
                    />
                    <span className="slider round"></span>
                </label>
                <span className="value">{checked ? 'Yes' : 'No'}</span>
                {error && <p className="error-label">{error}</p>}
            </div>
        </div>
    );
};
