import React from 'react';

interface Props {
    className?: string;
}

export const DeleteIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M19,2h-6c0-1.1-0.9-2-2-2H9C7.9,0,7,0.9,7,2H1C0.4,2,0,2.4,0,3s0.4,1,1,1h1v13c0,1.7,1.3,3,3,3h10c1.7,0,3-1.3,3-3V4h1
                c0.6,0,1-0.4,1-1S19.6,2,19,2z M16,16.5c0,0.8-0.7,1.5-1.5,1.5h-9C4.7,18,4,17.3,4,16.5V4h12V16.5z"
            />
            <path d="M8,15c0.6,0,1-0.4,1-1V8c0-0.6-0.4-1-1-1S7,7.4,7,8v6C7,14.6,7.4,15,8,15z" />
            <path d="M12,15c0.6,0,1-0.4,1-1V8c0-0.6-0.4-1-1-1s-1,0.4-1,1v6C11,14.6,11.4,15,12,15z" />
        </svg>
    );
};
