import { XIcon } from 'components/svg/socials/XIcon';
import React from 'react';
import { ExternalLink } from '../common/ExternalLink';
import { DiscordIcon } from '../svg/socials/DiscordIcon';
import { FacebookIcon } from '../svg/socials/FacebookIcon';
import { InstagramIcon } from '../svg/socials/InstagramIcon';
import { TwitchIcon } from '../svg/socials/TwitchIcon';
import { YoutubeIcon } from '../svg/socials/YoutubeIcon';

type Props = {};

export const FooterSocials: React.FC<Props> = ({}) => {
    return (
        <div className="footer-column socials">
            <p className="footer-column-title">Get In Touch</p>
            <p className="info">info@staypluggedin.com</p>
            {/* <p className="info">704.507.6844</p> */}
            <div className="socials-container">
                <ExternalLink
                    className="social-link"
                    ariaLabel="Twitter"
                    link="https://x.com/stay_plugged_in"
                    icon={<XIcon />}
                />
                <ExternalLink
                    className="social-link"
                    ariaLabel="Facebook"
                    link="https://www.facebook.com/StayPluggedInGG/"
                    icon={<FacebookIcon />}
                />
                <ExternalLink
                    className="social-link"
                    ariaLabel="Instagram"
                    link="https://www.instagram.com/stay_pluggedin/"
                    icon={<InstagramIcon />}
                />
                <ExternalLink
                    className="social-link"
                    ariaLabel="YouTube"
                    link="https://www.youtube.com/channel/UCP3Bvpzqm1kJFBX2MuMnm-A"
                    icon={<YoutubeIcon />}
                />
                <ExternalLink
                    className="social-link"
                    ariaLabel="Twitch"
                    link="https://www.twitch.tv/staypluggedin/"
                    icon={<TwitchIcon />}
                />
                <ExternalLink
                    className="social-link"
                    ariaLabel="Discord"
                    link="https://discord.gg/6Wm5FuK9ac"
                    icon={<DiscordIcon />}
                />
            </div>
        </div>
    );
};
