/***
 *
 * Changes here require a restart of the dev server
 *
 */
export type AppEnvironment = {
    NEXT_PUBLIC_GQL_URL: string;
    NEXT_PUBLIC_AUTH0_DOMAIN: string;
    NEXT_PUBLIC_AUTH0_ESPIN_CLIENT_ID: string;
    NEXT_PUBLIC_AUTH0_AUDIENCE: string;
    NEXT_PUBLIC_VERCEL_ENV: string;
    NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY: string;
    NEXT_PUBLIC_IMAGE_HOST: string;
    NEXT_PUBLIC_MAPS_KEY: string;
    NEXT_PUBLIC_PVS_REDIRECT: string;
    NEXT_PUBLIC_GTM_ID: string;
    NEXT_PUBLIC_MIXPANEL_TOKEN: string;
    NEXT_PUBLIC_PAGING_TEST: number;
    NEXT_PUBLIC_BROWSER_LOG_CLIENT_ID: string;
};

export function getClientEnv(): AppEnvironment {
    return {
        NEXT_PUBLIC_GQL_URL: process.env.NEXT_PUBLIC_GQL_URL,
        NEXT_PUBLIC_PAGING_TEST: process.env.NEXT_PUBLIC_PAGING_TEST
            ? parseInt(process.env.NEXT_PUBLIC_PAGING_TEST)
            : undefined,
        NEXT_PUBLIC_BROWSER_LOG_CLIENT_ID: process.env.NEXT_PUBLIC_BROWSER_LOG_CLIENT_ID ?? '',
        NEXT_PUBLIC_AUTH0_DOMAIN: process.env.NEXT_PUBLIC_AUTH0_DOMAIN,
        NEXT_PUBLIC_AUTH0_ESPIN_CLIENT_ID:
            process.env.NEXT_PUBLIC_AUTH0_ESPIN_CLIENT_ID ?? 'He7MFgpin6YROpq4jXQ54QE1cCcjioq1',
        NEXT_PUBLIC_AUTH0_AUDIENCE: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE,
        NEXT_PUBLIC_VERCEL_ENV: process.env.NEXT_PUBLIC_VERCEL_ENV ?? 'local',
        NEXT_PUBLIC_GTM_ID: process.env.NEXT_PUBLIC_GTM_ID ?? 'GTM-52XMFDV',
        NEXT_PUBLIC_MIXPANEL_TOKEN: process.env.NEXT_PUBLIC_MIXPANEL_TOKEN ?? '',
        NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY:
            process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY ??
            'pk_test_51JvslEJQACiKXBP4vyL9M2wim3piTabnhqqmTJHPQqQkMHr3qWxtttch7aVJFuXsQbziRxaMOlpC4dwOdIzyHYfn00k7PjD5cP',
        NEXT_PUBLIC_IMAGE_HOST:
            process.env.NEXT_PUBLIC_IMAGE_HOST ?? 'https://files-ceh.nyc3.cdn.digitaloceanspaces.com',
        NEXT_PUBLIC_MAPS_KEY: process.env.NEXT_PUBLIC_MAPS_KEY ?? 'AIzaSyD435iO5-dN6zbnY_SgpN861zaAgnmRO8Y',
        NEXT_PUBLIC_PVS_REDIRECT:
            process.env.NEXT_PUBLIC_PVS_REDIRECT ?? 'https://app.playvs.com/app/account/connections',
    } as AppEnvironment;
}
