import React from 'react';

type Props = {
    className?: string;
};

export const StreetFighterIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M10.9,2C10.9,2,10.9,2,10.9,2c0.3-0.1,0.5-0.2,0.8-0.3c0.2-0.1,0.4-0.2,0.7-0.2c0.1,0,0.2,0,0.3,0c0,0,0,0,0.1,0
                c0,0,0,0,0,0c-0.1,0.1-0.3,0.1-0.4,0.2c0,0,0,0,0,0c0,0,0,0,0,0c0,0,0,0,0,0c0.3-0.1,0.7-0.1,1-0.2c0.1,0,0.2,0,0.3,0
                c0.2,0,0.4,0,0.6,0c0.1,0,0.1,0,0.2,0c0.1,0,0.2,0,0.3,0c0,0,0,0,0,0c0,0,0,0,0,0c-1.1,0.2-2.2,0.6-3.2,1.1
                c-0.4,0.2-0.8,0.4-1.2,0.6c0,0,0,0-0.1,0C9.5,3.5,9.1,3.7,8.7,4C8.4,4.2,8.2,4.3,8,4.5C7.9,4.7,7.7,4.9,7.6,5.2
                C7.5,5.4,7.4,5.6,7.4,5.8c0,0.2,0.1,0.3,0.1,0.5C7.7,6.6,7.9,6.8,8.1,7c0.1,0.1,0.3,0.2,0.4,0.3C8.8,7.4,9,7.6,9.2,7.7
                c0.4,0.3,0.8,0.8,1,1.2c0.1,0.2,0.2,0.4,0.2,0.6c0,0.2,0,0.4,0,0.6c0,0.4-0.1,0.8-0.2,1.1c-0.1,0.4-0.4,0.8-0.6,1.2
                c-0.4,0.5-0.8,1-1.3,1.5c-0.6,0.5-1.3,0.9-2,1.3c-0.3,0.1-0.5,0.3-0.8,0.4C5.2,15.8,5,15.9,4.9,16c-0.2,0.1-0.4,0.2-0.7,0.3
                c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.2-0.2,0.3-0.3c0.1-0.1,0.1-0.2,0.2-0.3
                c0-0.1,0-0.2-0.1-0.3C4.5,15.1,4.4,15,4.3,15c-0.2,0-0.4,0-0.6,0c-0.3,0-0.6,0.1-0.9,0.1c-0.1,0-0.3,0-0.4,0c-0.1,0-0.1,0-0.2,0
                c0,0,0,0,0,0c0.1,0,0.3-0.1,0.4-0.1c0,0,0.1,0,0.1-0.1c0,0,0,0,0,0c0.8-0.2,1.6-0.6,2.3-1c0,0,0,0,0,0c0,0,0,0,0,0c0,0-0.1,0-0.1,0
                c-0.5,0.1-1.1,0.2-1.6,0.3c-0.3,0.1-0.7,0.1-1,0.1c-0.2,0-0.4,0-0.6,0.1c-0.2,0-0.4,0-0.6,0c-0.2,0-0.5,0-0.7,0c-0.1,0-0.2,0-0.3,0
                c0,0-0.1,0-0.1,0c0,0,0,0,0,0c0,0,0,0,0,0c0.3-0.1,0.7-0.2,1-0.3c0.6-0.3,1.3-0.5,1.9-0.8c1-0.5,1.9-1.1,2.7-1.7
                c0.3-0.2,0.5-0.5,0.7-0.7c0.1-0.2,0.2-0.4,0.2-0.6c0.1-0.4,0-0.7-0.3-0.9C6.1,9.1,6,9.1,5.8,9C5.7,8.9,5.5,8.8,5.3,8.7
                C5.2,8.6,5,8.5,4.8,8.4C4.5,8.2,4.2,7.9,4,7.6C3.9,7.3,3.8,7.1,3.8,6.9c0-0.2-0.1-0.4-0.1-0.6c0-0.1,0-0.3,0.1-0.4
                c0-0.2,0.1-0.4,0.2-0.6C4.1,5.1,4.2,5,4.3,4.8c0.3-0.4,0.6-0.7,1-1C5.7,3.5,6.2,3.2,6.7,3c0.6-0.3,1.3-0.6,2-0.8
                C9.1,2,9.6,1.9,10,1.7c0.3-0.1,0.6-0.2,0.9-0.2c0.5-0.1,1-0.3,1.5-0.4c0,0,0.1,0,0.1,0c0,0,0,0,0,0c-0.2,0.1-0.4,0.2-0.7,0.3
                C11.5,1.6,11.2,1.7,10.9,2C10.9,1.9,10.9,2,10.9,2C10.9,2,10.9,2,10.9,2z"
            />
            <path
                d="M18,9.1C18,9.2,18,9.2,18,9.1c-0.4,0.2-0.8,0.4-1.1,0.7c-0.4,0.3-0.8,0.7-1.2,1.1c-0.1,0.1-0.2,0.3-0.3,0.5
                c-0.1,0.2-0.2,0.5-0.2,0.8c0,0.1,0,0.3,0,0.4c0,0,0,0,0,0c-0.1,0-0.1,0-0.2,0c-0.2,0-0.4,0-0.7,0.1c-0.2,0-0.4,0.1-0.6,0.1
                c-0.3,0-0.6,0.1-0.9,0.2c-0.1,0-0.2,0-0.3,0.1c-0.1,0-0.1,0-0.2,0c0,0,0,0-0.1,0c0,0-0.1,0.1-0.1,0.1c0,0.2,0,0.4-0.1,0.7
                c0,0.3,0,0.6,0,0.9c0,0.2,0,0.5,0,0.7c0,0.4,0.1,0.8,0.1,1.2c0.1,0.5,0.2,1.1,0.3,1.6c0,0.2,0.1,0.4,0.1,0.5c0,0,0,0,0,0
                c0,0,0,0,0,0c0,0,0,0,0,0c-0.3-0.4-0.6-0.8-0.9-1.3c0,0,0,0,0-0.1c0,0,0,0.1,0,0.1c0.1,0.3,0.2,0.5,0.3,0.8c0,0,0,0,0,0
                c0,0,0,0-0.1-0.1c-0.1-0.1-0.1-0.2-0.2-0.3c-0.1-0.2-0.3-0.4-0.4-0.7c-0.1-0.1-0.2-0.3-0.3-0.4c0,0-0.1-0.1-0.1-0.1
                c0,0-0.1,0-0.1-0.1c0,0,0,0,0,0.1c0,0.1,0,0.2,0,0.3c0,0.2,0.1,0.4,0.2,0.7c0.1,0.2,0.2,0.4,0.2,0.6c0,0,0,0,0,0.1c0,0,0,0,0,0
                c-0.1-0.1-0.1-0.2-0.2-0.2c0,0,0,0,0,0c-0.2-0.4-0.5-0.8-0.7-1.2c-0.3-0.6-0.5-1.1-0.6-1.7c-0.1-0.4-0.2-0.7-0.2-1.1c0,0,0,0,0,0
                c-0.6,0.4-1.3,0.7-1.9,1.1c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.3-0.4c0.3-0.3,0.7-0.7,1-1c0.2-0.2,0.3-0.4,0.5-0.6
                c0.1-0.1,0.2-0.2,0.3-0.4c0.1-0.1,0.1-0.2,0.2-0.3c0.4-0.5,0.6-1,0.8-1.6c0.1-0.3,0.2-0.6,0.2-0.9c0-0.4,0-0.7-0.1-1.1
                c0-0.2-0.1-0.3-0.2-0.4c0-0.1-0.1-0.1-0.1-0.2c-0.1-0.2-0.3-0.4-0.4-0.6C10.1,7.8,10,7.8,10,7.7c0,0,0,0,0-0.1c0,0,0,0,0,0
                c0.4-0.2,0.7-0.5,1.1-0.7c0.1,0,0.1-0.1,0.2-0.1c0.4-0.2,0.7-0.4,1.1-0.6c0.7-0.4,1.3-0.7,2.1-0.9c0.1-0.1,0.3-0.1,0.4-0.2
                C15,5,15.2,4.9,15.4,4.9c0.2,0,0.4-0.1,0.5-0.1c0.1,0,0.1,0,0.2-0.1c0,0,0,0-0.1,0.1c0,0,0,0-0.1,0c-0.1,0.1-0.2,0.1-0.2,0.2
                C15.7,5,15.6,5,15.5,5.1c-0.1,0.1-0.2,0.2-0.3,0.4c0,0,0,0,0,0c0,0,0,0,0,0c0.1-0.1,0.2-0.2,0.4-0.3c0.1-0.1,0.3-0.1,0.4-0.1
                c-0.2,0.1-0.3,0.2-0.4,0.4c-0.1,0.2-0.1,0.3,0,0.5c0,0,0,0,0,0c0.1,0,0.3,0.1,0.4,0.1c0.2,0,0.4,0.1,0.6,0.1c0.1,0,0.1,0,0.2,0
                c0.2,0,0.4,0,0.6,0c0,0,0.1,0,0.1,0c0.1,0,0.2,0,0.3,0c0.2,0,0.3,0,0.5,0c0.1,0,0.2,0,0.3,0c0.2,0,0.5,0,0.7,0c0.2,0,0.3,0,0.5,0
                c0,0,0,0,0.1,0c0,0-0.1,0-0.1,0c-0.2,0-0.4,0.1-0.5,0.1c-0.3,0.1-0.6,0.2-1,0.2c-0.9,0.2-1.8,0.4-2.6,0.7c-0.4,0.1-0.7,0.3-1.1,0.4
                c-0.3,0.1-0.6,0.3-0.9,0.4c0,0,0,0-0.1,0.1c-0.2,0.5-0.4,1-0.6,1.5C13,10,13,10.2,12.9,10.5c0,0,0,0,0,0.1c0,0,0,0,0.1,0
                c0.3-0.1,0.6-0.2,0.8-0.3c0.3-0.1,0.7-0.2,1-0.3c0.5-0.1,0.9-0.2,1.4-0.4c0.3-0.1,0.6-0.1,0.9-0.2C17.4,9.2,17.6,9.2,18,9.1
                C17.9,9.1,18,9.1,18,9.1C18,9.1,18,9.1,18,9.1z"
            />
        </svg>
    );
};
