import { BooleanLiteral } from '@babel/types';
import React, { HTMLAttributes, LegacyRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { CheckmarkIcon } from '../svg/CheckmarkIcon';

interface Props {
    label: string | JSX.Element;
    value: boolean;
    name: string;
    handleChange: (v: boolean) => void;
    error?: string;
    required?: boolean;
    disabled?: boolean;
    innerRef?: any;
}

export const Checkbox: React.FC<Props> = ({
    value,
    name,
    handleChange,
    label,
    disabled = false,
    error,
    required = false,
    innerRef,
}) => {
    const [checked, setChecked] = useState<boolean>(value);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        handleChange(event.currentTarget.checked);
        setChecked(event.currentTarget.checked);
    };

    return (
        <div className={`checkbox-container${disabled ? ' disabled' : ''}`}>
            <label htmlFor={name}>
                <div className={`checkbox-display${checked ? ' active' : ''}`}>
                    <CheckmarkIcon />
                </div>
                <div className="checkbox-label">{label}</div>
            </label>
            <ForwardedRefCheckbox
                className="checkbox"
                checked={checked}
                disabled={disabled}
                onChange={(event) => onChange(event)}
                id={name}
                name={name}
                ref={innerRef}
            />

            {error && <p className="error-label">{error}</p>}
            {required && <span className="required">*</span>}
        </div>
    );
};

type InputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

// gets rid of the error for checkboxes
const ForwardedRefCheckbox = React.forwardRef<any, InputProps>(function CheckForward(props, ref) {
    return <input {...props} type="checkbox" ref={ref} />;
});

export const CheckboxWithControl: React.FC<{
    name: string;
    label: string | JSX.Element;
    control: any;
    disabled?: boolean;
}> = ({ control, name, label, disabled = false }) => {
    return (
        <Controller
            name={name}
            control={control}
            render={({ field }) => {
                const { ref, ...rest } = field;
                return (
                    <Checkbox
                        {...rest}
                        innerRef={ref}
                        disabled={disabled}
                        handleChange={field.onChange}
                        label={label}
                    />
                );
            }}
        />
    );
};
