/***
 *
 * Notification subscriptions
 *
 */

import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { ReactChildrenProps } from '../../types/react.types';

import {
    CollegeNotificationsDocument,
    CollegeNotificationsSubscription,
    CollegeNotificationsSubscriptionVariables,
    Espin_Notification_Type_Enum,
    HighSchoolNotificationsDocument,
    HighSchoolNotificationsSubscription,
    HighSchoolNotificationsSubscriptionVariables,
    ParentNotificationsDocument,
    ParentNotificationsSubscription,
    ParentNotificationsSubscriptionVariables,
    PlayerNotificationsDocument,
    PlayerNotificationsSubscription,
    PlayerNotificationsSubscriptionVariables,
    RefreshOnSocialDocument,
    SpinNotificationDataFragment,
} from '../../generated/spin-graphql';
import { Client, defaultExchanges, gql, OperationResult } from '@urql/core';

import { useAppState } from './AppProvider';

import { subscriptionExchange, useClient, UseSubscriptionArgs } from 'urql';

import { SubscriptionClient } from 'subscriptions-transport-ws';
import ws from 'isomorphic-ws';
import { getClientEnv } from '../clientEnv';
import { pipe, subscribe } from 'wonka';
import { useAuthenticatedMutation } from '../hooks';

// todo - expand out from here to include more social items to add in with notifications

gql`
    fragment SpinNotificationData on espin_notification {
        player_user_id
        id
        created_at
        viewed_at
        notification_type
        ...InterestedInCard
        ...ConversationStartedCard
        follows {
            ...FollowerCard
        }
        profile_views {
            ...ProfileViewCard
        }
        messages {
            ...MessageCard
        }
    }

    subscription HighSchoolNotifications($highSchoolId: uuid!) {
        espin_notification(
            limit: 10
            order_by: { created_at: desc }
            where: { high_school_id: { _eq: $highSchoolId } }
        ) {
            ...SpinNotificationData
        }
    }

    subscription CollegeNotifications($collegeId: uuid!) {
        espin_notification(
            limit: 10
            order_by: { created_at: desc }
            where: { notification_type: { _neq: conversation_started }, college_id: { _eq: $collegeId } }
        ) {
            ...SpinNotificationData
        }
    }

    subscription PlayerNotifications($playerUserId: String!) {
        espin_notification(
            limit: 10
            order_by: { created_at: desc }
            where: {
                notification_type: { _nin: [conversation_started, interested_in] }
                player_user_id: { _eq: $playerUserId }
            }
        ) {
            ...SpinNotificationData
        }
    }

    subscription ParentNotifications($playerUserIds: [String!]!) {
        espin_notification(
            limit: 10
            order_by: { created_at: desc }
            where: { player_user_id: { _in: $playerUserIds } }
        ) {
            ...SpinNotificationData
        }
    }

    subscription HighSchoolNotifications($highSchoolId: uuid!) {
        espin_notification(
            limit: 10
            order_by: { created_at: desc }
            where: { high_school_id: { _eq: $highSchoolId } }
        ) {
            ...SpinNotificationData
        }
    }
`;

type SpinNotificationList = {
    isLoading?: boolean;
    espin_notification: SpinNotificationDataFragment[];
};

const NotificationContext = createContext<SpinNotificationList>({
    espin_notification: [],
    isLoading: true,
});

// DO NOT USE THIS SUB ANYWHERE ELSE, UNLESS YOU HAVE A GOOD REASON AND KNOW WHAT YOU'RE DOING
// AND OK IT WITH DAVE
function useAuthenticatedSubscription<Data = any, Variables = object>(args: UseSubscriptionArgs<Variables, Data>) {
    const { isAuthenticated, getAccessTokenSilently } = useAppState();
    const [accessToken, setAccessToken] = useState('');

    const [currentResult, setCurrentResult] = useState<OperationResult<Data, Variables>>({
        data: undefined,
        operation: undefined,
        error: undefined,
        extensions: undefined,
    });

    useEffect(() => {
        if (isAuthenticated) {
            (async () => {
                setAccessToken(await getAccessTokenSilently());
            })();
        }
    }, [isAuthenticated, getAccessTokenSilently]);

    const handleOperationResult = useCallback(
        (res: OperationResult<Data, Variables>) => {
            setCurrentResult(res);
        },
        [setCurrentResult],
    );

    // setup our sub client + our specific gql client.
    useEffect(() => {
        if (accessToken !== '' && !args.pause) {
            // ok - create our stuff
            const subClient = new SubscriptionClient(
                getClientEnv().NEXT_PUBLIC_GQL_URL.replace('http', 'ws'),
                {
                    reconnect: true,
                    connectionParams: {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    },
                },
                ws,
            );
            const client = new Client({
                url: getClientEnv().NEXT_PUBLIC_GQL_URL,
                exchanges: [
                    ...defaultExchanges,
                    subscriptionExchange({
                        forwardSubscription: (operation) => subClient.request(operation),
                    }),
                ],
            });
            // todo - not 100% sure if we even _need_ to call unsub,
            // since this will never actually unsub unless page is reloaded
            const { unsubscribe } = pipe(
                client.subscription<Data>(args.query, args.variables),
                // the any here is cheating... i know. i'm sorry.
                subscribe((res) => handleOperationResult(res as any)),
            );
        }
        return () => {
            // todo - actually clean up unsubs on unmount
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [accessToken, handleOperationResult, args.pause]);

    return currentResult;
}

gql`
    mutation RefreshOnSocial {
        delete_espin_force_notification_reload(where: { id: { _is_null: true } }) {
            affected_rows
        }
    }
`;
export function SocialCacheInvalidator() {
    const notifications = useNotificationProvider();

    const [_, refreshSocials] = useAuthenticatedMutation(RefreshOnSocialDocument);
    // store our notifications here.
    // when we get a new notification, force a refresh of related queries
    useEffect(() => {
        if (!notifications.isLoading && notifications.espin_notification.length > 0) {
            // we only want to do something if a NEW one comes in. :D
            // ok - is this initial load, if so, don't propagate refresh
            const [mostRecent] = notifications.espin_notification;
            // todo- need a way to say is this new since this new session?

            const refreshType = {
                [Espin_Notification_Type_Enum.Follow]: 'espin_follow',
                [Espin_Notification_Type_Enum.Message]: 'espin_message',
                [Espin_Notification_Type_Enum.ProfileView]: 'espin_profile_view',
            }[mostRecent.notification_type];
            if (!!refreshType) {
                // super hacky, but eliminates WAY too many subs
                refreshSocials({}, { additionalTypenames: [refreshType] });
            }
        }
    }, [notifications.espin_notification, notifications.isLoading, refreshSocials]);

    return <></>;
}

export function SpinNotificationProvider({ children }: ReactChildrenProps): JSX.Element {
    const { user, selectedChild } = useAppState();
    // going to setup all our subs here for notifications and then setup a provider for each
    const { data: playerNotificationData } = useAuthenticatedSubscription<
        PlayerNotificationsSubscription,
        PlayerNotificationsSubscriptionVariables
    >({
        query: PlayerNotificationsDocument,
        variables: useMemo(() => ({ playerUserId: user?.id }), [user]),
        pause: !!!user?.player_profile,
    });
    const { data: collegeNotificationData } = useAuthenticatedSubscription<
        CollegeNotificationsSubscription,
        CollegeNotificationsSubscriptionVariables
    >({
        query: CollegeNotificationsDocument,
        variables: useMemo(() => ({ collegeId: user?.college_profile?.college?.id }), [user]),
        pause: !!!user?.college_profile?.college,
    });
    const { data: highSchoolNotificationData } = useAuthenticatedSubscription<
        HighSchoolNotificationsSubscription,
        HighSchoolNotificationsSubscriptionVariables
    >({
        query: HighSchoolNotificationsDocument,
        variables: useMemo(() => ({ highSchoolId: user?.high_school_profile?.high_school?.id }), [user]),
        pause: !!!user?.high_school_profile?.high_school,
    });

    const { data: parentNotificationData } = useAuthenticatedSubscription<
        ParentNotificationsSubscription,
        ParentNotificationsSubscriptionVariables
    >({
        query: ParentNotificationsDocument,
        variables: useMemo(() => ({ playerUserIds: user?.parent_profile?.child_profiles.map((i) => i.user_id) }), [
            user,
        ]),

        pause: !!!user?.parent_profile?.child_profiles,
    });

    // we pull + prep all 3 subs, but only one ever runs since the rest are paused based on the user's role + data
    const notificationValue = useMemo(
        () =>
            playerNotificationData ??
            collegeNotificationData ??
            highSchoolNotificationData ??
            parentNotificationData ?? { espin_notification: [], isLoading: true },
        [collegeNotificationData, parentNotificationData, playerNotificationData, highSchoolNotificationData],
    );
    // also provide a nulled version for anywhere using this context
    return (
        <NotificationContext.Provider value={notificationValue}>
            <SocialCacheInvalidator />
            {children}
        </NotificationContext.Provider>
    );
}
export const useNotificationProvider = (): SpinNotificationList =>
    useContext(NotificationContext) as SpinNotificationList;
