import React, { useCallback, useMemo, useState } from 'react';

import { BaseRouteModal } from './BaseRouteModal';
import { useAppState } from '../../../client/providers/AppProvider';

import { useModals } from '../SpinModalProvider';
import { Espin_Role_Enum } from '../../../generated/spin-graphql';
import { CollegeIcon } from '../../svg/CollegeIcon';
import { PlayerIcon } from '../../svg/PlayerIcon';
import { ClientOnly } from '../../../utils/ClientOnly';
import { HighSchoolIcon } from '../../svg/HighSchoolIcon';

function SelectAccountTypeForm() {
    const { loginWithRedirect } = useAppState();
    const [accountType, setAccountType] = useState<Espin_Role_Enum>(undefined);

    const buttonText = useMemo(() => {
        switch (accountType) {
            case Espin_Role_Enum.EspinPlayerUser: {
                return 'Sign up as a Player';
            }
            case Espin_Role_Enum.EspinHighschoolUser: {
                return 'Sign up as a High School';
            }
            default:
                return 'Sign up as a College';
        }
    }, [accountType]);

    const buttonColour = useMemo(() => {
        switch (accountType) {
            case Espin_Role_Enum.EspinPlayerUser: {
                return ' blue-button';
            }
            case Espin_Role_Enum.EspinHighschoolUser: {
                return ' purple-button';
            }
            case Espin_Role_Enum.EspinCollegeUser: {
                return ' green-button';
            }
            default:
                return '';
        }
    }, [accountType]);

    const onSignUpClicked = useCallback(() => {
        if (accountType === undefined) return;
        console.log({ accountType });
        switch (accountType) {
            case Espin_Role_Enum.EspinCollegeUser: {
                loginWithRedirect({
                    role: 'espin_college_user',
                    action: 'signup',
                    appState: {
                        returnTo: '/dashboard/onboarding',
                    },
                });
                break;
            }
            case Espin_Role_Enum.EspinHighschoolUser: {
                loginWithRedirect({
                    role: 'espin_highschool_user',
                    action: 'signup',
                    appState: {
                        returnTo: '/dashboard/onboarding',
                    },
                });
                break;
            }
            default: {
                loginWithRedirect({
                    role: 'espin_player_user',
                    action: 'signup',
                    appState: {
                        returnTo: `/dashboard/onboarding`,
                    },
                });
            }
        }
    }, [loginWithRedirect, accountType]);

    return (
        <ClientOnly>
            <>
                <div className="account-select-content">
                    <div className="title-container">
                        <h2 className="title-description">Choose your account type</h2>
                        <p className="description">
                            Empowering students, college coaches and high school coaches to connect.
                        </p>
                    </div>
                    <div className="type-container">
                        <div
                            onClick={() => setAccountType(Espin_Role_Enum.EspinPlayerUser)}
                            className={`account-type-container player${
                                accountType === Espin_Role_Enum.EspinPlayerUser ? ' active' : ''
                            }`}
                        >
                            <div className="icon-container">
                                <PlayerIcon />
                            </div>
                            <div className="info-container">
                                <h2 className="type-title">Player</h2>
                                <p className="type-slogan">
                                    I am a player looking to find an esports scholarship and related opportunities.
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => setAccountType(Espin_Role_Enum.EspinCollegeUser)}
                            className={`account-type-container college${
                                accountType === Espin_Role_Enum.EspinCollegeUser ? ' active' : ''
                            }`}
                        >
                            <div className="icon-container">
                                <CollegeIcon />
                            </div>
                            <div className="info-container">
                                <h2 className="type-title">College</h2>
                                <p className="type-slogan">
                                    I am a college coach, director or administrator looking to make finding players
                                    easier.
                                </p>
                            </div>
                        </div>
                        <div
                            onClick={() => setAccountType(Espin_Role_Enum.EspinHighschoolUser)}
                            className={`account-type-container high-school${
                                accountType === Espin_Role_Enum.EspinHighschoolUser ? ' active' : ''
                            }`}
                        >
                            <div className="icon-container">
                                <HighSchoolIcon />
                            </div>
                            <div className="info-container">
                                <h2 className="type-title">High School</h2>
                                <p className="type-slogan">
                                    I am a high school coach, teacher or administrator, looking to help my students find
                                    esports opportunities.
                                </p>
                            </div>
                        </div>
                    </div>

                    <button
                        onClick={onSignUpClicked}
                        disabled={accountType === undefined}
                        className={`button medium-button${buttonColour}`}
                    >
                        {accountType && <>{buttonText}</>}
                        {!accountType && <>Sign Up</>}
                    </button>
                </div>
                <button
                    className="button sign-in"
                    onClick={() =>
                        loginWithRedirect({
                            appState: {
                                returnTo: window.location.href,
                            },
                        })
                    }
                >
                    Already have an account? Sign In
                </button>
            </>
        </ClientOnly>
    );
}

export function SignupAccountSelectModal() {
    const { closeActiveModal } = useModals();
    return (
        <BaseRouteModal
            className="sign-up-popup"
            content={<SelectAccountTypeForm />}
            description=""
            title="Sign Up"
            onCancel={() => closeActiveModal()}
        />
    );
}

export function SignupAccountFromSubModal() {
    const { closeActiveModal } = useModals();
    return (
        <BaseRouteModal
            className="sign-up-popup"
            content={<SelectAccountTypeForm />}
            description="To subscribe, you must first create an account"
            title="Sign Up"
            onCancel={() => closeActiveModal()}
        />
    );
}
