import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import { MyEventsIcon } from '../../svg/dashboard/MyEventsIcon';
import { MySubscriptionsIcon } from '../../svg/dashboard/MySubscriptionsIcon';
import { MyProfileIcon } from '../../svg/dashboard/MyProfileIcon';
import { ScoutingReportsIcon } from '../../svg/dashboard/ScoutingReportsIcon';

import { useAppState } from '../../../client/providers/AppProvider';
import { Espin_Role_Enum } from '../../../generated/spin-graphql';
import { CommonDashboardNavigationLinks } from './CommonDashboardNavigationLinks';
import { PlayerIcon } from '../../svg/PlayerIcon';
import { MyTeamsIcon } from '../../svg/dashboard/MyTeamsIcon';

type Props = {
    messages: number;
};

export const HighSchoolDashboardNavigationLinks: React.FC<Props> = ({ messages }) => {
    const router = useRouter();

    const { user, subscribed } = useAppState();

    return (
        <nav className="links">
            <CommonDashboardNavigationLinks messages={messages} />
            <div className="separator" />
            <Link href="/dashboard/my-events">
                <a
                    title="My Team's Events"
                    className={`link${router.pathname == '/dashboard/my-events' ? ' active' : ''}`}
                >
                    <MyEventsIcon />
                    <span>My Team's Events</span>
                </a>
            </Link>

            <Link href="/dashboard/my-players">
                <a title="My Players" className={`link${router.pathname == '/dashboard/my-players' ? ' active' : ''}`}>
                    <PlayerIcon />
                    <span>My Players</span>
                </a>
            </Link>
            <Link href="/dashboard/my-teams">
                <a title="My Teams" className={`link${router.pathname == '/dashboard/my-teams' ? ' active' : ''}`}>
                    <MyTeamsIcon />
                    <span>My Teams</span>
                </a>
            </Link>

            <Link href="/dashboard/my-profile">
                <a title="My Profile" className={`link${router.pathname == '/dashboard/my-profile' ? ' active' : ''}`}>
                    <MyProfileIcon />
                    <span>My Profile</span>
                </a>
            </Link>
            <Link href="/dashboard/subscriptions">
                <a
                    title="Subscriptions"
                    className={`link${router.pathname == '/dashboard/subscriptions' ? ' active' : ''}`}
                >
                    <MySubscriptionsIcon />
                    <span>Subscriptions</span>
                </a>
            </Link>
        </nav>
    );
};
