import React from 'react';

interface Props {
    className?: string;
}

export const MyEventsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M17.8,2.2h-2.2v0c0-1.2-1-2.2-2.2-2.2H6.7C5.4,0,4.4,1,4.4,2.2v0H2.2C1,2.2,0,3.2,0,4.4v1.1c0,2.8,2.1,5.1,4.9,5.5
            c0.7,1.7,2.2,2.9,4,3.3v3.4H5.6c-0.6,0-1.1,0.5-1.1,1.1v0c0,0.6,0.5,1.1,1.1,1.1h8.9c0.6,0,1.1-0.5,1.1-1.1v0c0-0.6-0.5-1.1-1.1-1.1
            h-3.3v-3.4c1.8-0.4,3.3-1.6,4-3.3c2.7-0.3,4.9-2.7,4.9-5.5V4.4C20,3.2,19,2.2,17.8,2.2z M2.2,5.6V4.4h2.2v4.2C3.2,8.2,2.2,7,2.2,5.6
            z M10,12.2c-1.8,0-3.3-1.5-3.3-3.3V2.2h6.7v6.7C13.3,10.7,11.8,12.2,10,12.2z M17.8,5.6c0,1.4-0.9,2.7-2.2,3.1V4.4h2.2V5.6z"
            />
        </svg>
    );
};
