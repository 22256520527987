import Link from 'next/link';
import React from 'react';
import { useRouter } from 'next/router';
import { HubIcon } from '../../svg/dashboard/HubIcon';
import { FollowersIcon } from '../../svg/dashboard/FollowersIcon';
import { MessageIcon } from '../../svg/MessageIcon';
import { BadgeIcon } from '../../svg/BadgeIcon';
import { ProfileViewsIcon } from '../../svg/dashboard/ProfileViewsIcon';
import { FollowingIcon } from '../../svg/dashboard/FollowingIcon';

type Props = {
    messages: number;
};

export const CommonDashboardNavigationLinks: React.FC<Props> = ({ messages }) => {
    const router = useRouter();

    return (
        <>
            <Link href="/dashboard">
                <a title="Recruiting Hub" className={`link${router.pathname == '/dashboard' ? ' active' : ''}`}>
                    <HubIcon />
                    <span>Recruiting Hub</span>
                </a>
            </Link>
            <Link href="/dashboard/profile-views">
                <a
                    title="Profile Views"
                    className={`link${router.pathname == '/dashboard/profile-views' ? ' active' : ''}`}
                >
                    <ProfileViewsIcon />
                    <span>Profile Views</span>
                </a>
            </Link>
            <Link href="/dashboard/followers">
                <a title="Followers" className={`link${router.pathname == '/dashboard/followers' ? ' active' : ''}`}>
                    <FollowersIcon />
                    <span>Followers</span>
                </a>
            </Link>
            <Link href="/dashboard/following">
                <a title="Following" className={`link${router.pathname == '/dashboard/following' ? ' active' : ''}`}>
                    <FollowingIcon />
                    <span>Following</span>
                </a>
            </Link>
            <Link href="/dashboard/messages">
                <a title="Messages" className={`link${router.pathname == '/dashboard/messages' ? ' active' : ''}`}>
                    <MessageIcon />
                    <span>Messages</span>
                    {messages > 0 && <span className="messages">{Math.min(messages, 99)}</span>}
                </a>
            </Link>
            <Link href="/dashboard/badges">
                <a title="Badges" className={`link${router.pathname == '/dashboard/badges' ? ' active' : ''}`}>
                    <BadgeIcon />
                    <span>Badges</span>
                </a>
            </Link>
        </>
    );
};
