import React, { useEffect, useState } from 'react';
import { Controller, UseFormRegisterReturn, useWatch } from 'react-hook-form';
import { Range } from 'react-range';

interface Props {
    name: string;
    //in case we want to use float values
    step: number | string;
    min: number;
    max: number;
    disabled?: boolean;
    label: string;
    className?: string;
    htmlFor: string;
    control: any;
    required: boolean;
    value: string;
    error?: string;
}

export const NumberSlider: React.FC<Props> = ({
    step,
    min,
    max,
    disabled,
    name,
    label,
    className,
    htmlFor,
    control,
    required,
    value,
    error,
}) => {
    const [stepVal, setStepVal] = useState(0);
    return (
        <div className={`number-slider-container${className ? ` ${className}` : ''}${disabled ? ` disabled` : ''}`}>
            {error && stepVal === 0 && <span className="error-label">{error}</span>}
            {required && <span className="required">*</span>}
            <label htmlFor={htmlFor}>{label}</label>
            <div className="slider-container">
                <Controller
                    control={control}
                    name={name}
                    render={({ field }) => (
                        <Range
                            // prettier-ignore
                            step={typeof step === 'string' ? parseFloat(step)*.10 : step}
                            min={min}
                            disabled={disabled}
                            max={max}
                            values={[field.value]}
                            onChange={(values) => {
                                setStepVal(values[0]);
                                field.onChange(values[0]);
                            }}
                            renderTrack={({ props, children }) => (
                                <div {...props} className="slider-track">
                                    {children}
                                </div>
                            )}
                            renderThumb={({ props }) => <div {...props} className="slider-thumb" />}
                        />
                    )}
                />
            </div>
            <p className="slider-value">{parseFloat(value).toFixed(2)}</p>
        </div>
    );
};
