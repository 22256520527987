import React from 'react';

type Props = {
    className?: string;
};

export const NBA2KIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M15.1,9H20c-0.2-1.6-0.7-3.1-1.5-4.4C16.7,5.4,15.4,7,15.1,9z" />
            <path d="M4.9,9C4.6,7,3.3,5.4,1.5,4.6C0.7,5.9,0.2,7.4,0,9H4.9z" />
            <path d="M13.1,9c0.3-2.6,1.9-4.8,4.1-6c-1.6-1.6-3.8-2.7-6.2-3v9H13.1z" />
            <path d="M6.9,9H9V0C6.6,0.2,4.4,1.3,2.8,3C5,4.2,6.6,6.4,6.9,9z" />
            <path d="M13.1,11H11v9c2.4-0.2,4.6-1.3,6.2-3C15,15.8,13.4,13.6,13.1,11z" />
            <path d="M1.5,15.4c1.7-0.8,3-2.5,3.3-4.4H0C0.2,12.6,0.7,14.1,1.5,15.4z" />
            <path d="M15.1,11c0.3,2,1.6,3.6,3.3,4.4c0.8-1.3,1.4-2.8,1.5-4.4H15.1z" />
            <path d="M6.9,11c-0.3,2.6-1.9,4.8-4.1,6c1.6,1.6,3.8,2.7,6.2,3v-9H6.9z" />
        </svg>
    );
};
