import { gql } from 'urql';
import {
    Espin_Team_Role_Enum,
    HighSchoolTeamMemberFragment,
    HighSchoolTeamPlayerListFragment,
} from '../../../../generated/spin-graphql';
import { DeleteIcon } from '../../../svg/DeleteIcon';
import { BackIcon } from '../../../svg/BackIcon';
import { useCallback, useMemo, useState } from 'react';
import { UseFormRegister } from 'react-hook-form';
import { EditHighSchoolTeamFormData } from '../../../common/modals/dashboard/my-teams/HighSchoolEditTeamPopup';

gql`
    mutation DeleteHighSchoolTeamMembers($memberIds: [uuid!]!) {
        delete_espin_team_members(where: { id: { _in: $memberIds } }) {
            affected_rows
        }
    }

    fragment HighSchoolTeamMember on espin_team_members {
        id
        team_role
        player_profile {
            spin_username
            id
            social {
                discord
            }
        }
    }
`;

enum MemberStatus {
    MEMBER = '',
    REMOVED = '(Removed Member)',
}

type MemberData = {
    member: HighSchoolTeamMemberFragment;
    status: MemberStatus;
};

export function HighSchoolEditableTeamPlayerList({
    playerList,
    register,
    setVal,
}: {
    playerList: HighSchoolTeamPlayerListFragment;
    register: UseFormRegister<EditHighSchoolTeamFormData>;
    setVal: (val: string[]) => void;
}) {
    const setInitialMemberStatuses = useMemo(() => {
        return playerList.members.length > 0
            ? playerList.members.map((member) => {
                  return {
                      member,
                      status: MemberStatus.MEMBER,
                  };
              })
            : [];
    }, [playerList]);

    const [playerMemberStatuses, setPlayerMemberStatuses] = useState<MemberData[]>(setInitialMemberStatuses);

    const sortedMemberList = useMemo(() => {
        return playerList.members.length > 0
            ? playerList.members.sort((a, b) => {
                  if (a.team_role === Espin_Team_Role_Enum.Captain) return -1;
                  if (b.team_role === Espin_Team_Role_Enum.Captain) return 1;
                  return 0;
              })
            : [];
    }, [playerList.members]);

    const handleStatusChange = useCallback(
        (playerId) => {
            const updatedPlayers = playerMemberStatuses.map((player) =>
                player.member.id === playerId
                    ? {
                          ...player,
                          status: player.status === MemberStatus.MEMBER ? MemberStatus.REMOVED : MemberStatus.MEMBER,
                      }
                    : player,
            );
            setPlayerMemberStatuses(updatedPlayers);
            setVal(updatedPlayers.filter((p) => p.status === MemberStatus.REMOVED).map((p) => p.member.id));
        },
        [playerMemberStatuses, setPlayerMemberStatuses, setVal],
    );

    return (
        <>
            {sortedMemberList.length > 0 && (
                <>
                    <p className="title-label">ROSTER</p>
                    <div className="players-list">
                        {sortedMemberList.map((member) => (
                            <div
                                className="player-card"
                                key={`team-member-${member.player_profile?.spin_username ?? ''}`}
                            >
                                <div className="player-info">
                                    {member.player_profile.spin_username}
                                    <i>
                                        {playerMemberStatuses.length > 0 &&
                                            playerMemberStatuses.filter((p) => p.member.id === member.id)[0].status}
                                    </i>
                                </div>
                                <div className="controls-container">
                                    {playerMemberStatuses.length > 0 &&
                                        playerMemberStatuses.filter((p) => p.member.id === member.id)[0].status ===
                                            MemberStatus.MEMBER && (
                                            <button
                                                className="red-circle-button control-button"
                                                onClick={() => handleStatusChange(member.id)}
                                            >
                                                <DeleteIcon />
                                            </button>
                                        )}
                                    {playerMemberStatuses.length > 0 &&
                                        playerMemberStatuses.filter((p) => p.member.id === member.id)[0].status ===
                                            MemberStatus.REMOVED && (
                                            <button
                                                className="red-circle-button control-button"
                                                onClick={() => handleStatusChange(member.id)}
                                            >
                                                <BackIcon />
                                            </button>
                                        )}
                                </div>
                            </div>
                        ))}
                    </div>
                </>
            )}
            <input type="hidden" {...register('members_to_remove')} />
        </>
    );
}
