import React from 'react';

type Props = {
    className?: string;
};

export const ForzaIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M4.3,8.1C5.1,6.7,6,5.7,7.6,5.5c0.2,0,0.3,0,0.5,0c3.9,0,7.7,0,11.6,0c0.1,0,0.2,0,0.3,0c-0.8,1.6-2,2.6-3.7,2.6
                c-3.9,0-7.8,0-11.7,0C4.5,8.1,4.4,8.1,4.3,8.1z"
            />
            <path
                d="M0,14.5c1-1.5,2-3,3-4.4C3,10,3.2,9.9,3.3,9.9c1.1,0.2,2.2,0.4,3.3,0.6c-0.8,1.1-1.5,2.2-2.3,3.3C4.3,13.9,4.2,14,4.1,14
                C2.8,14.2,1.4,14.3,0,14.5z"
            />
            <path
                d="M7.6,10.1C5.3,9.7,3,9.3,0.8,8.9c0,0,0-0.1,0-0.1c3.3,0,6.6,0,10,0c-0.5,0.7-0.9,1.4-1.4,2C9.2,11,9,11.2,8.9,11.5
                c-0.3,0.5-0.6,0.8-1.2,0.8c-0.5,0-1.1,0.1-1.7,0.2C6.5,11.6,7,10.9,7.6,10.1z"
            />
            <path
                d="M9.2,12C10,11,10.6,10,11.3,9c0.1-0.1,0.2-0.2,0.4-0.2c0.7,0,1.3,0,2.1,0c-0.7,1-1.3,1.9-1.9,2.9c-0.1,0.1-0.2,0.1-0.3,0.2
                C10.8,11.9,10.1,11.9,9.2,12z"
            />
            <path
                d="M12.5,11.7c0.6-1,1.2-1.8,1.8-2.7c0.1-0.1,0.2-0.2,0.4-0.2c0.7,0,1.3,0,2.1,0c-0.5,0.8-1,1.5-1.5,2.1
                c-0.2,0.3-0.6,0.4-1,0.5C13.7,11.5,13.1,11.6,12.5,11.7z"
            />
        </svg>
    );
};
