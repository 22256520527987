import React from 'react';

type Props = {
    className?: string;
};

export const MaddenIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M0,14c-0.1,2.7,0.4,4.8,0.7,5.2S3.3,20.1,6,20L0,14z" />
            <path
                d="M11.2,0.3C8.6,0.8,5.9,1.8,3.8,3.8s-3.1,4.8-3.5,7.4l8.5,8.5c2.6-0.5,5.3-1.5,7.4-3.5s3.1-4.8,3.5-7.4L11.2,0.3z
                M13.1,8.4l-4.7,4.7c-0.4,0.4-1.1,0.4-1.6,0c-0.4-0.4-0.4-1.1,0-1.6l4.7-4.7c0.4-0.4,1.1-0.4,1.6,0S13.5,8,13.1,8.4z"
            />
            <path d="M20,6c0.1-2.7-0.4-4.8-0.7-5.2C18.9,0.4,16.7-0.1,14,0L20,6z" />
        </svg>
    );
};
