import Link from 'next/link';
import React, { useState } from 'react';
import { CloseIcon } from '../svg/CloseIcon';

type Props = {
    message: string;
    buttonLabel: string;
    buttonLink: string;
    customButton?: JSX.Element;
};

export const Tooltip: React.FC<Props> = ({ message, buttonLabel, buttonLink, customButton }) => {
    const [show, setShow] = useState<boolean>(false);

    return (
        <div className="tooltip-container">
            <button
                onClick={() => setShow(!show)}
                className={`tooltip-button button blue-button${show ? ' show' : ''}`}
            >
                {customButton && <>{customButton}</>}
                {!customButton && (
                    <>
                        <span>?</span>
                        <CloseIcon />
                    </>
                )}
            </button>
            <div className={`tooltip-modal${show ? ' show' : ''}`} onClick={() => setShow(!show)}>
                <div className="tooltip">
                    <button onClick={() => setShow(!show)} className={`close-button button blue-button`}>
                        <CloseIcon />
                    </button>
                    <p className="tooltip-message">{message}</p>
                    <Link href={buttonLink}>
                        <a className="button blue-button medium-button">{buttonLabel}</a>
                    </Link>
                </div>
            </div>
        </div>
    );
};
