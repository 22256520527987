import React, { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { ComingSoon } from '../ComingSoon';
import { CloseIcon } from '../../svg/CloseIcon';

type Props = {
    onCloseHook?: () => boolean;
    onCancel: () => void;
};

export const ComingSoonPopup: React.FC<Props> = ({ onCancel, onCloseHook = () => true }) => {
    const initialFocusRef = useRef();

    return (
        <Dialog
            open={true}
            initialFocus={initialFocusRef}
            onClose={() => {
                if (onCloseHook()) {
                    onCancel();
                }
            }}
        >
            <div className="modal" onClick={onCancel}>
                <Dialog.Overlay />
                <div className={`popup coming-soon-popup`} ref={initialFocusRef}>
                    <ComingSoon />
                    <button className="close-button" onClick={onCancel}>
                        <CloseIcon />
                    </button>
                </div>
            </div>
        </Dialog>
    );
};
