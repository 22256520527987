import React from 'react';

interface Props {
    className?: string;
}

export const HighSchoolIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M18.9,17.8v-5c0-1.1-0.9-2-2-2h-1V9.9c0-0.7-0.4-1.4-1-1.7L11,5.4c-0.1-0.1-0.3-0.1-0.5-0.2V3h3.9l-1-1.5l1-1.5h-3.9H9.5v3
                v2.2C9.3,5.2,9.1,5.3,9,5.4L5.1,8.2c-0.6,0.4-1,1-1,1.7v0.8h-1c-1.1,0-2,0.9-2,2v5c-0.6,0-1.1,0.5-1.1,1.1S0.5,20,1.1,20h17.8
                c0.6,0,1.1-0.5,1.1-1.1S19.5,17.8,18.9,17.8z M12,17.8v-3c0-1.1-0.9-2-2-2s-2,0.9-2,2v3H6.3v-7.1c0-0.4,0.2-0.7,0.5-0.9L10,7.3
                l3.2,2.4c0.3,0.2,0.5,0.5,0.5,0.9v7.1H12z"
            />
        </svg>
    );
};
