import React from 'react';

interface Props {
    className?: string;
}

export const ProfileViewsIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M10,6.7c-1.8,0-3.3,1.5-3.3,3.3s1.5,3.3,3.3,3.3s3.3-1.5,3.3-3.3S11.8,6.7,10,6.7z M10,11.1
	            c-0.6,0-1.1-0.5-1.1-1.1S9.4,8.9,10,8.9s1.1,0.5,1.1,1.1S10.6,11.1,10,11.1z"
            />
            <path
                d="M10,3.3c-4,0-7.4,3.5-9,5.4c-0.6,0.7-0.6,1.8,0,2.5c1.6,1.9,5.1,5.4,9,5.4c3.9,0,7.4-3.5,9-5.4
                c0.6-0.7,0.6-1.8,0-2.5C17.4,6.8,14,3.3,10,3.3z M10,14.4c-2.4,0-4.9-1.9-6.6-3.8c-0.4-0.4-0.4-1,0-1.4C5,7.6,7.5,5.6,10,5.6
                c2.6,0,5.1,2.1,6.6,3.7c0.4,0.4,0.4,1,0,1.4C14.9,12.4,12.5,14.4,10,14.4z"
            />
        </svg>
    );
};
