import { Control, useFormState } from 'react-hook-form';
import { ControlledMultiSelectTextInput } from '../../../../forms/MultiSelectTextInput';

type InviteProps = {
    control: Control<any>;
    required: boolean;
};
export const PlayerInviteOptions: React.FC<InviteProps> = ({ control, required }) => {
    const { errors } = useFormState({ control });

    return (
        <div className="invite-players-input-container">
            <div className="title-container">
                {required && <div className="required">*</div>}
                <p className="tag-select-label">Invite Players</p>
            </div>
            <div className="title-container">
                <p className="tag-select-label">
                    Please invite players using either an email address or their exact SPIN Username.
                </p>
            </div>
            <ControlledMultiSelectTextInput
                control={control}
                error={(errors.players_to_invite as any)?.message}
                name={'players_to_invite'}
                label={``}
                placeholder="Select players to invite"
                required={required}
            />
        </div>
    );
};
