import React from 'react';

type Props = {
    className?: string;
};

export const MTGIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M15.2,4.2l-0.9,5.1c0,0-1.3,1-1.8-7.2l-0.9,6.7c0,0-0.8,1.7-1.6-8.9C9.2,10.6,8.4,8.9,8.4,8.9L7.5,2.2
                c-0.4,8.2-1.8,7.2-1.8,7.2L4.8,4.2l-0.6,6.2C9.8,12.3,10,20,10,20s0.2-7.7,5.8-9.6L15.2,4.2z"
            />
        </svg>
    );
};
