import Link from 'next/link';
import React from 'react';

type Props = {
    className: string;
    title: string;
    description: string;
    buttonLabel: string;
    buttonLink: string;
};

export const CTACard: React.FC<Props> = ({ className, title, description, buttonLabel, buttonLink }) => {
    return (
        <div className={`cta-card ${className}`}>
            <div className="title-container">
                <h2 className="title">{title}</h2>
                <p className="description">{description}</p>
            </div>
            <Link href={buttonLink}>
                <a className="button medium-button blue-button">{buttonLabel}</a>
            </Link>
        </div>
    );
};
