import Link from 'next/link';
import React, { useEffect, useState } from 'react';
import { useAppState } from '../../../client/providers/AppProvider';
import { HamburgerIcon } from '../../svg/HamburgerIcon';
import { SPINIcon } from '../../svg/SPINIcon';
import { SPINLogo } from '../../svg/SPINLogo';
import { MobileNavigation } from './MobileNavigation';

import { useRouter } from 'next/router';
import { SpinAppModals, useModals } from '../SpinModalProvider';
import { AccountDropdown } from './AccountDropdown';
import { NavDropdown } from './NavDropdown';
import { ClientOnly } from '../../../utils/ClientOnly';

type Props = {
    isTransparentAtTop: boolean;
};

export type dropdownLink = {
    name: string;
    path: string;
};

const collegesLinks: dropdownLink[] = [
    {
        name: 'Search Engine',
        path: '/colleges',
    },
    {
        name: 'Scholarships',
        path: '/scholarships',
    },
    {
        name: 'College Fair',
        path: '/college-fair',
    },
];

const playerLinks: dropdownLink[] = [
    {
        name: 'Search Engine',
        path: '/players',
    },
    {
        name: 'Rankings',
        path: '/leaderboards',
    },
];

export const NavigationBar: React.FC<Props> = ({ isTransparentAtTop }) => {
    const { user, logout, loginWithRedirect, isLoggingIn } = useAppState();

    const { openModal } = useModals();
    const [mobileNavOpen, SetMobileNavOpen] = useState<boolean>(false);
    const [headerScrolled, setHeaderScrolled] = useState(false);
    const router = useRouter();

    const listenScrollEvent = (event) => {
        return setHeaderScrolled(window.scrollY > 90);
    };

    useEffect(() => {
        window.addEventListener('scroll', listenScrollEvent);

        return () => window.removeEventListener('scroll', listenScrollEvent);
    }, []);

    let userControls = <></>;
    if (!isLoggingIn && !!!user) {
        userControls = (
            <div className="user-controls">
                <button
                    className="button medium-button green-button"
                    onClick={() => {
                        SetMobileNavOpen(false);
                        openModal(SpinAppModals.SIGNUP_SELECT_ACCT_TYPE);
                    }}
                >
                    Sign Up
                </button>
                <button
                    className="button medium-button blue-button"
                    onClick={() =>
                        loginWithRedirect({
                            // return to where i was
                            appState: {
                                returnTo: window.location.href,
                            },
                        })
                    }
                >
                    Sign In
                </button>
            </div>
        );
    }

    return (
        <>
            <MobileNavigation
                mobileNavOpen={mobileNavOpen}
                onClick={() => SetMobileNavOpen(!mobileNavOpen)}
                onClose={() => SetMobileNavOpen(false)}
                userControls={userControls}
            />

            <div className={`navigation-bar${headerScrolled || !isTransparentAtTop ? ' active' : ''}`}>
                <div className="container">
                    <button className="mobile-nav-button" onClick={() => SetMobileNavOpen(!mobileNavOpen)}>
                        <HamburgerIcon />
                    </button>
                    <Link href="/">
                        <a className="brand">
                            <SPINLogo className="spin-logo" />
                            <SPINIcon className="spin-logo mobile" />
                        </a>
                    </Link>
                    <nav className="navigation-links">
                        <NavDropdown navItem={'Colleges'} navItemLink={'/colleges'} dropDownLinks={collegesLinks} />
                        <NavDropdown navItem={'Players'} navItemLink={'/players'} dropDownLinks={playerLinks} />
                        <Link href="/high-schools">
                            <a className={`navigation-link${router.pathname === '/high-schools' ? ' active' : ''}`}>
                                High Schools
                            </a>
                        </Link>
                        <Link href="/events">
                            <a className={`navigation-link${router.pathname === '/events' ? ' active' : ''}`}>Events</a>
                        </Link>
                        <Link href="/news">
                            <a className={`navigation-link${router.pathname === '/news' ? ' active' : ''}`}>News</a>
                        </Link>
                        <Link href="/pricing">
                            <a className={`navigation-link${router.pathname === '/pricing' ? ' active' : ''}`}>
                                Pricing
                            </a>
                        </Link>
                        <Link href="/partners">
                            <a className={`navigation-link${router.pathname === '/partners' ? ' active' : ''}`}>
                                Partners
                            </a>
                        </Link>
                    </nav>

                    <ClientOnly>
                        {(isLoggingIn || user) && <AccountDropdown />}
                        {/* if they login from here and are NOT a real user, then they must select on the landding page what to do. */}
                        {userControls}
                    </ClientOnly>
                </div>
            </div>
        </>
    );
};
