import { Espin_Event_Ticket_Type_Enum } from '../generated/spin-graphql';

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

export type PostSummary = {
    title: string;
    excerpt: string;
    sys: {
        firstPublishedAt: string;
    };
    postAuthor: {
        name: string;
        email: string | null;
    };
    slug: string;
    leadingImage: {
        url: string;
    };
};
export type FullPost = PostSummary & { content: { json: any } };

export type PostListing = {
    postCollection: {
        items: Array<PostSummary>;
    };
};

export type PostListBySlug = {
    postCollection: {
        items: Array<{ slug: string }>;
    };
};
export type EventRegistrationData = {
    data: {
        discordIds: string[];
        teamName: string;
        registrationType: Espin_Event_Ticket_Type_Enum;
    };
};

export enum ActionSuccessError {
    SUCCESS = 'success',
    ERROR = 'error',
}
