import React, { createContext, useContext } from 'react';
import { Updater, useImmer } from 'use-immer';
import { ReactChildrenProps } from '../../../types/react.types';
import { HighSchoolSearchFilterState } from '../../../components/highschool/search/HighSchoolSearchFilterComponent';
import { HighSchoolCardFragment } from '../../../generated/spin-graphql';

export type HighSchoolSearchHistoryData = {
    highSchoolFilters: HighSchoolSearchFilterState;
    highSchoolSearch: string;
    previousSearchData: Array<HighSchoolCardFragment>;
    previousSearchCount: number;
    previousSearchOffset: number;
};

export type HighSchoolSearchHistory = HighSchoolSearchHistoryData & {
    setHighSchoolSearchHistory: Updater<HighSchoolSearchHistoryData>;
};

const HighSchoolSearchHistoryContext = createContext<HighSchoolSearchHistory>({
    highSchoolFilters: {
        gameFilters: [],
        useMaxDistance: false,
        maxDistance: 50,
        useInternationalSearch: false,
        stateFilters: [],
    },
    highSchoolSearch: '',
    previousSearchData: [],
    previousSearchCount: 0,
    previousSearchOffset: 0,
    setHighSchoolSearchHistory: null,
});

export function HighSchoolSearchHistoryProvider({ children }: ReactChildrenProps): JSX.Element {
    const [searchHistory, setHighSchoolSearchHistory] = useImmer<HighSchoolSearchHistoryData>({
        highSchoolFilters: {
            gameFilters: [],
            useMaxDistance: false,
            maxDistance: 50,
            useInternationalSearch: false,
            stateFilters: [],
        },
        highSchoolSearch: '',
        previousSearchData: [],
        previousSearchCount: 0,
        previousSearchOffset: 0,
    });

    return (
        <HighSchoolSearchHistoryContext.Provider
            value={{
                setHighSchoolSearchHistory,
                ...searchHistory,
            }}
        >
            {children}
        </HighSchoolSearchHistoryContext.Provider>
    );
}

export const useHighSchoolSearchHistoryProvider = (): HighSchoolSearchHistory =>
    useContext(HighSchoolSearchHistoryContext) as HighSchoolSearchHistory;
