import React from 'react';

type Props = {
    className?: string;
};

export const LegendsOfRuneterraIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M17.8,1.5c-0.8-0.4-1.7-0.8-2.5-1.3C15.1,0.2,15,0.1,14.8,0c-3.2,0-6.4,0-9.6,0c-1,0.5-1.9,1-2.9,1.5
                C2,1.7,1.8,1.9,1.9,2.2c0.2,2.2,0.4,4.3,0.6,6.5c0,0.2,0.1,0.5,0.3,0.6c1.1,1,1.3,2.1,0.5,3.4C3,13.1,2.9,13.5,3,13.9
                c0.2,1,0.3,2,0.4,3c0,0.4,0.2,0.6,0.6,0.8c1.9,0.7,3.8,1.5,5.6,2.2c0.2,0.1,0.6,0.1,0.8,0c1.9-0.7,3.8-1.5,5.6-2.2
                c0.4-0.2,0.6-0.4,0.6-0.8c0.1-1.1,0.3-2.2,0.4-3.3c0-0.2-0.1-0.5-0.2-0.7c-0.9-1.4-0.8-2.5,0.4-3.6c0.1-0.1,0.2-0.3,0.3-0.5
                c0.2-2.2,0.4-4.4,0.6-6.6C18.2,1.9,18,1.7,17.8,1.5z M11.3,16.3c0.8-0.9,0.2-1.7,0-2.6c-0.3-0.9-0.6-1.7-1-2.7
                c-0.4,0.3-0.7,0.6-1,0.9c-0.1,0.1-0.1,0.3-0.1,0.5c0,0.5,0,0.9,0,1.4c0,0.9-0.1,1.7,0.7,2.5c-1.2,0-2.2,0-3.2,0c0,0,0-0.1,0-0.1
                c0.6-0.6,0.6-1.4,0.6-2.2c0-2.6,0-5.2,0-7.8c0-1.1,0.2-2.2-0.7-3.2c0,0,0.1-0.1,0.1-0.1c0.5,0,0.9,0,1.4,0c1.1,0,2.2,0,3.2,0.1
                c1.6,0.1,2.3,1,2.3,2.6c0,1.4-0.5,2.6-1.3,3.7c-0.3,0.3-0.3,0.6-0.1,1c0.5,1.3,0.9,2.6,1.4,3.9c0.3,0.7,0.6,1.5,0.9,2.2
                C13.5,16.3,12.4,16.3,11.3,16.3z"
            />
            <path
                d="M9.2,4.4c0.4,0,0.7,0,1.1,0c0.7,0,1.1,0.4,1.2,1.1c0.2,1.1-0.1,2.1-0.9,2.9c-0.4,0.4-0.8,0.8-1.3,1.3
                C9.2,7.9,9.2,6.2,9.2,4.4z"
            />
        </svg>
    );
};
