import 'reflect-metadata';
import { ArrayNotEmpty, IsEmail, IsEnum, IsOptional, IsString, MinLength, ValidateNested } from 'class-validator';
import { Type } from 'class-transformer';

export enum APIErrorMessages {
    DUPLICATE = 'duplicate',
    NO_CLIENT = 'no_client',
    SERVER_ERROR = 'server_error',
}

export type EspinAPIResponse<T = any> = {
    error?: APIErrorMessages;
    data?: T;
};

export enum MixpanelEvents {
    SUBSCRIPTION_SALE = 'SubscriptionSale',
    SUBSCRIPTION_CANCEL = 'SubscriptionCancel',
    EVENT_REGISTRATION = 'EventRegistration',
    FOLLOW = 'Follow',
    CONVERSATION = 'Conversation',
    MESSAGE = 'Message',
    PROFILE_VIEW = 'ProfileView',
    PLAYER_SEARCH = 'PlayerSearch',
    COLLEGE_SEARCH = 'CollegeSearch',
    HIGH_SCHOOL_SEARCH = 'HighSchoolSearch',
    PAGEVIEW = 'PageView',
    PLAYER_REFERRAL_REDEMPTION = 'PlayerReferralRedemption',
    COLLEGE_REFERRAL_REDEMPTION = 'CollegeReferralRedemption',
    COLLEGE_SENDS_MASS_PLAYER_MESSAGE = 'CollegeSendsMassPlayerMessage',
    PLAYER_7DAY_FREE_TRIAL_ACTIVATION = 'Player7DayFreeTrialActivation',
    INITIAL_SUBSCRIPTION_CHECKOUT = 'InitialSubscriptionCheckout',
    PLAYER_INTERESTED_IN_COLLEGE = 'PlayerInterestedInCollege',
    PLAYER_PREFERRED_STUDY_FIELD = 'PlayerPreferredStudyField',
}

export type PropType<TObj, TProp extends keyof TObj> = TObj[TProp];

// DTO and validation classes

export class NewsLetterSignupFormValuesDTO {
    @IsString()
    @IsOptional()
    first_name?: string;

    @IsString()
    @IsOptional()
    last_name?: string;

    @IsString()
    email: string;

    @IsString()
    @IsOptional()
    phone?: string;
}
