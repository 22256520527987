import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { CloseIcon } from '../svg/CloseIcon';

type Props = {
    show: boolean;
};

export const FixedProfileIncomplete: React.FC<Props> = ({ show }) => {
    const [manuallyClosed, setManuallyClosed] = useState<boolean>(false);
    const router = useRouter();

    if (!show || manuallyClosed) {
        return <></>;
    }
    // already editing, don't show the fixed one
    if (router.pathname === '/dashboard/my-profile/edit' || router.pathname === '/dashboard/onboarding') {
        return <></>;
    }
    return (
        <div className={`fixed-profile-incomplete`}>
            <h2 className="title">Profile Incomplete</h2>
            <p className="subtitle">Complete your profile to gain access to features!</p>

            <Link href="/dashboard/onboarding">
                <a className="button medium-button blue-button">Finish Profile</a>
            </Link>

            <button className="close-button" onClick={() => setManuallyClosed(true)}>
                <CloseIcon />
            </button>
        </div>
    );
};
