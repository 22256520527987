import React from 'react';

type Props = {
    className?: string;
};

export const WildRiftIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <polygon points="11.7,14.4 12.9,17.6 15,17.6 15.9,14.4 	" />
            <path
                d="M9.6,11.5C8.8,9.7,7.9,7.2,8.2,7c0.1-0.1,0.6,0.4,1.4,1.7c0.1,0.2,0.1-0.1,0.1-0.2c0-1,0-7.1,0-7.1s-2.7,0-3,0
                c-0.2,0-0.2,0-0.2,0.1c-0.1,0.1,0,0.1,0,0.2c1,2.9,1.4,4.5,1.2,4.6C7.5,6.4,7,5.7,6.2,4C5,1.4,4.2-0.1,4.1,0C4,0.1,4.8,2.8,7,8.7
                c0.7,2,1,3,0.8,3.1c-0.2,0.1-0.6-0.6-1.2-1.8c-0.7-1.4-1-2-1.1-2c-0.1,0.1,0.2,1,0.6,2.3c0.7,2.1,1.8,5.6,1.6,5.8
                c-0.1,0.1-0.5-0.5-1.2-2.1c-0.2-0.4-0.3-0.5-0.3-0.5s-0.1,0.1-0.1,0.2c0,0.2,0,2.3,0,2.3l-0.8,1.7h3.7c-1-2.8-1.3-4-1.1-4.1
                c0.2-0.1,1,1.5,2,4.1c1,0.5,2.2,1.6,2.9,2.3c0.2,0.2,0.2,0.2,0.2,0C12.2,17.9,11.1,15.2,9.6,11.5z"
            />
        </svg>
    );
};
