import React from 'react';

type Props = {
    className?: string;
};

export const SmashBrosIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M8.8,12c0-4,0-7.9,0-11.9c0.8-0.1,1.7-0.1,2.5,0c3.7,0.6,6.4,2.6,7.9,6c0.8,1.8,1,3.7,0.6,5.6c0,0.2-0.1,0.3-0.3,0.3
                C16,12,12.5,12,9,12C8.9,12,8.9,12,8.8,12z"
            />
            <path d="M19.2,13.9c-1.6,4-6,6.6-10.3,6c0-2,0-4,0-6C12.3,13.9,15.7,13.9,19.2,13.9z" />
            <path d="M3.7,2.3c0,3.2,0,6.4,0,9.7c-1.2,0-2.3,0-3.5,0C-0.8,9.3,1.2,3.7,3.7,2.3z" />
            <path d="M0.9,13.9c1,0,1.9,0,2.9,0c0,1.3,0,2.5,0,3.8C2.5,16.7,1.5,15.5,0.9,13.9z" />
        </svg>
    );
};
