import React from 'react';

type Props = {
    className?: string;
};

export const TFTIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M14.7,9.9c-0.8-0.5-0.6-0.2-0.2,0.2c1.5,1.4,2.4,2.7,2.8,3.9c0.2,0.8,0,1.2-0.3,0.6c-0.5-1-1.2-1.8-1.7-2.1
                c-0.4-0.3-0.4-0.2-0.2,0.1c0.8,1.1,1.3,2.2,0.8,3.1c-0.8,1.4-4.1,1.1-7.3-0.8c-4.1-2.4-6.6-5.6-5.7-7.3c0.4-0.7,1.5-1,2.8-0.9
                c0.3,0,0.3-0.1,0-0.1c-1.5-0.4-3.4,0-4,1.1c-1.2,2.1,1.6,5.9,6.2,8.5c1,0.6,2.2,1.2,3.7,1.7c0.9,0.3,0.8,0.7-0.8,0.4
                C9.1,17.9,7,17,5.1,15.9c-0.6-0.3-0.6-0.2-0.2,0.2c0.4,0.4,1.1,1,2,1.5c4.6,2.7,10.3,3.4,11.9,0.6C20.2,15.6,17.6,11.7,14.7,9.9z"
            />
            <path d="M0,0l2.5,4.3h5.1v8.4c1.3,1,3.4,2.1,4.9,2.2V4.3h5.1L20,0H0z" />
        </svg>
    );
};
