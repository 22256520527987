import React from 'react';

type Props = {
    className?: string;
};

export const SPINLogo: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`${className ? className : ''}`} width="192" height="50" viewBox="0 0 192 50">
            <path
                d="M43,11.8L22.9,0.2c-0.5-0.3-1.4-0.3-1.9,0L0.9,11.8c-0.5,0.3-0.9,1-0.9,1.6v23.2c0,0.6,0.4,1.3,0.9,1.6
                l20.1,11.6c0.5,0.3,1.4,0.3,1.9,0L43,38.2c0.5-0.3,0.9-1,0.9-1.6V13.4C44,12.8,43.6,12.1,43,11.8z M6.7,16.9c0-0.4,0.3-0.9,0.7-1.1
                l14-8.1c0.4-0.2,1-0.2,1.3,0l6.3,3.7c0.4,0.2,0.4,0.5,0,0.8L7.3,24.6c-0.4,0.2-0.7,0-0.7-0.4V16.9z M37.3,33.1
                c0,0.4-0.3,0.9-0.7,1.1l-14,8.1c-0.4,0.2-1,0.2-1.3,0L15,38.6c-0.4-0.2-0.4-0.5,0-0.8l14-8.1c0.4-0.2,0.7-0.7,0.7-1.1v-2.9
                c0-0.4-0.3-0.6-0.7-0.4L11.2,35.7c-0.4,0.2-1,0.2-1.3,0l-2.5-1.5c-0.4-0.2-0.7-0.7-0.7-1.1v-2.9c0-0.4,0.3-0.9,0.7-1.1l25.5-14.7
                c0.4-0.2,1-0.2,1.3,0l2.5,1.5c0.4,0.2,0.7,0.7,0.7,1.1V33.1L37.3,33.1z M63,22.7c-2.2,0-4.2-0.7-5.8-2.2l1.7-2
                c1.4,1.1,2.7,1.7,4.1,1.7c0.6,0,1.1-0.1,1.5-0.4c0.4-0.3,0.6-0.6,0.6-1v0c0-0.4-0.2-0.8-0.6-1c-0.4-0.2-1.1-0.5-2.3-0.8
                c-0.8-0.2-1.4-0.4-1.9-0.6c-0.5-0.2-1-0.4-1.4-0.7c-0.4-0.3-0.8-0.7-1-1.2c-0.2-0.5-0.3-1-0.3-1.7v0c0-1.2,0.4-2.2,1.3-2.9
                c0.9-0.8,2-1.1,3.4-1.1c1.9,0,3.6,0.6,5.1,1.7L66,12.5c-1.2-0.9-2.4-1.4-3.6-1.4c-0.6,0-1,0.1-1.4,0.4c-0.3,0.2-0.5,0.5-0.5,0.9v0
                c0,0.5,0.2,0.8,0.6,1.1c0.4,0.2,1.2,0.5,2.4,0.8c1.5,0.4,2.6,0.9,3.3,1.5c0.7,0.6,1.1,1.5,1.1,2.6v0c0,1.3-0.5,2.3-1.4,3.1
                C65.7,22.3,64.5,22.7,63,22.7z M74.7,22.5v-11h-4.2V8.8h11.3v2.7h-4.2v11H74.7z M82.3,22.5l5.9-13.8h2.7l5.9,13.8h-3l-1.3-3.1h-5.9
                l-1.3,3.1H82.3z M87.6,16.8h3.9l-1.9-4.6L87.6,16.8z M101.8,22.5v-5.4l-5.3-8.3h3.4l3.4,5.6l3.4-5.6h3.3l-5.3,8.2v5.4H101.8z
                M58.1,41.2V27.5h5.6c1.6,0,2.9,0.4,3.8,1.3c0.9,0.9,1.4,2,1.4,3.4v0c0,1.5-0.5,2.7-1.6,3.5c-1,0.8-2.4,1.2-4,1.2H61v4.2L58.1,41.2
                L58.1,41.2z M61,34.4h2.5c0.8,0,1.4-0.2,1.8-0.6c0.4-0.4,0.7-0.9,0.7-1.5v0c0-0.7-0.2-1.2-0.7-1.6c-0.5-0.4-1.1-0.6-1.9-0.6H61V34.4
                z M72.3,41.2V27.5h2.9v11h6.9v2.6H72.3L72.3,41.2z M90.8,41.4c-1.9,0-3.3-0.5-4.4-1.5c-1-1-1.6-2.5-1.6-4.5v-7.8h2.9v7.7
                c0,1.1,0.3,2,0.8,2.6c0.5,0.6,1.3,0.9,2.3,0.9c1,0,1.7-0.3,2.3-0.9c0.5-0.6,0.8-1.4,0.8-2.5v-7.9h2.9v7.7c0,2-0.5,3.6-1.6,4.6
                C94.2,40.9,92.7,41.4,90.8,41.4z M107.6,41.4c-2.1,0-3.9-0.7-5.2-2c-1.3-1.3-2-3-2-5v0c0-2,0.7-3.6,2.1-5c1.4-1.4,3.1-2.1,5.1-2.1
                c1.2,0,2.2,0.2,3,0.5c0.8,0.3,1.6,0.8,2.4,1.4l-1.8,2.2c-0.6-0.5-1.1-0.8-1.7-1.1c-0.6-0.2-1.2-0.3-2-0.3c-1.1,0-2.1,0.4-2.9,1.3
                c-0.8,0.9-1.2,1.9-1.2,3.1v0c0,1.3,0.4,2.4,1.2,3.2c0.8,0.8,1.8,1.3,3.1,1.3c1.2,0,2.1-0.3,2.9-0.9v-2.1h-3.1v-2.5h5.9v5.9
                C111.7,40.7,109.8,41.4,107.6,41.4z M124,41.4c-2.1,0-3.9-0.7-5.2-2c-1.3-1.3-2-3-2-5v0c0-2,0.7-3.6,2.1-5c1.4-1.4,3.1-2.1,5.1-2.1
                c1.2,0,2.2,0.2,3,0.5c0.8,0.3,1.6,0.8,2.4,1.4l-1.8,2.2c-0.6-0.5-1.1-0.8-1.7-1.1c-0.6-0.2-1.2-0.3-2-0.3c-1.1,0-2.1,0.4-2.9,1.3
                c-0.8,0.9-1.2,1.9-1.2,3.1v0c0,1.3,0.4,2.4,1.2,3.2c0.8,0.8,1.8,1.3,3.1,1.3c1.2,0,2.1-0.3,2.9-0.9v-2.1h-3.1v-2.5h5.9v5.9
                C128.1,40.7,126.2,41.4,124,41.4z M133.9,41.2V27.5h10.3v2.6h-7.4V33h6.6v2.6h-6.6v3h7.5v2.6H133.9L133.9,41.2z M148.1,41.2V27.5
                h5.3c2.1,0,3.9,0.6,5.2,1.9c1.4,1.3,2.1,2.9,2.1,4.9v0c0,1.9-0.7,3.6-2.1,4.9c-1.4,1.3-3.1,2-5.2,2H148.1L148.1,41.2z M153.4,30.1
                H151v8.5h2.4c1.3,0,2.3-0.4,3.1-1.2c0.8-0.8,1.2-1.8,1.2-3v0c0-1.2-0.4-2.3-1.2-3C155.7,30.5,154.7,30.1,153.4,30.1z M171.6,41.2
                V27.5h2.9v13.7H171.6z M179.1,41.2V27.5h2.7l6.6,8.6v-8.6h2.9v13.7h-2.5l-6.8-8.9v8.9H179.1z"
            />
        </svg>
    );
};
