import React from 'react';

interface Props {
    className?: string;
}

export const FacebookIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="22" height="22" viewBox="0 0 22 22">
            <path
                d="M-51.176,117.356a11,11,0,0,0-11,11,11,11,0,0,0,11,11c.027,0,.052,0,.079,0v-7.678H-54.19v-2.961H-51.1v-3.646a3.486,3.486,0,0,1,3.311-3.381h3.328v2.67h-1.462c-.888,0-1.972.72-1.972,1.56v2.8h3.12v2.956h-3.12v7.188a11,11,0,0,0,7.717-10.5A11,11,0,0,0-51.176,117.356Z"
                transform="translate(62.176 -117.356)"
            />
        </svg>
    );
};
