import React from 'react';
import { NumberSlider } from '../../../../input/NumberSlider';
import { RadioButton } from '../../../../input/RadioButton';
import { StatesAndCodes } from '../../../../../client/static';
import { ControlledMultiSelectComponent } from '../../../../forms/ControlledMultiSelectComponent';
import { Control, UseFormRegister, UseFormSetValue, useFormState, UseFormWatch } from 'react-hook-form';
import { GamesSelectComponent } from '../../../../forms/GameSelectComponent';
import { gql } from '@urql/core';
import { useAuthenticatedQuery } from '../../../../../client/hooks';
import { FindPlayersFormDocument, FindPlayersFormQuery } from '../../../../../generated/spin-graphql';
import { FindPlayersFormData } from './FindPlayersPopup';
import { PlayerAcademicInterests } from '../../../../players/profile/PlayerAcademicInterests';

type Props = {
    register: UseFormRegister<FindPlayersFormData>;
    watch: UseFormWatch<FindPlayersFormData>;
    control: Control<FindPlayersFormData, object>;
    setValue: UseFormSetValue<FindPlayersFormData>;
};

export enum PlayerLocationEnum {
    MY_STATE = 'my_state',
    OTHER_STATES = 'other_states',
    ANY_STATE = 'any_state',
    INTERNATIONAL = 'international',
    ANY_LOCATION = 'any_location',
}

gql`
    query FindPlayersForm {
        espin_study_field {
            ...PlayerProfileEditingFormStudyFields
        }

        espin_study_subject {
            ...PlayerProfileEditingFormStudySubjects
        }
    }
`;

export const FindPlayersForm: React.FC<Props> = ({ register, watch, control, setValue }) => {
    const { errors } = useFormState({ control });

    const { data, isReady } = useAuthenticatedQuery<FindPlayersFormQuery>({
        query: FindPlayersFormDocument,
    });

    return (
        <>
            <div className="location-container">
                <h2 className="form-heading">{'Where do you want to look for players?'}</h2>
                <RadioButton
                    label={'In my state.'}
                    name="location_preference"
                    id="location_preference_my_state"
                    value={PlayerLocationEnum.MY_STATE}
                    register={register}
                    checked={watch('location_preference') === PlayerLocationEnum.MY_STATE}
                />
                <RadioButton
                    label={'I am open to states outside of my home state.'}
                    name="location_preference"
                    id="location_preference_other_states"
                    value={PlayerLocationEnum.OTHER_STATES}
                    register={register}
                    checked={watch('location_preference') === PlayerLocationEnum.OTHER_STATES}
                />
                <RadioButton
                    label={'I am open to any location in the US.'}
                    name="location_preference"
                    id="location_preference_any_state"
                    value={PlayerLocationEnum.ANY_STATE}
                    register={register}
                    checked={watch('location_preference') === PlayerLocationEnum.ANY_STATE}
                />
                <RadioButton
                    label={'I am looking for colleges outside of the US.'}
                    name="location_preference"
                    id="location_preference_international"
                    value={PlayerLocationEnum.INTERNATIONAL}
                    register={register}
                    checked={watch('location_preference') === PlayerLocationEnum.INTERNATIONAL}
                />
                <RadioButton
                    label={'I am open to any location.'}
                    name="location_preference"
                    id="location_preference_any_location"
                    value={PlayerLocationEnum.ANY_LOCATION}
                    register={register}
                    checked={watch('location_preference') === PlayerLocationEnum.ANY_LOCATION}
                />
                {watch('location_preference') === PlayerLocationEnum.OTHER_STATES && (
                    <ControlledMultiSelectComponent
                        error={(errors.states as any)?.message}
                        required={true}
                        placeholder={'States'}
                        name="states"
                        control={control}
                        options={StatesAndCodes}
                    />
                )}
            </div>

            <div className="have-container">
                <h2 className="form-heading">I want the players to have</h2>
                <PlayerAcademicInterests
                    register={register}
                    control={control}
                    watch={watch}
                    setValue={setValue}
                    studyFieldOptions={isReady ? data.espin_study_field : []}
                    studySubjectOptions={isReady ? data.espin_study_subject : []}
                    isRequired={false}
                />
                <GamesSelectComponent
                    error={(errors.games as any)?.message}
                    placeholder="Games"
                    control={control}
                    defaultValue={null}
                    name={'games'}
                />
                <NumberSlider
                    label={'Minimum GPA'}
                    max={4}
                    min={0}
                    step={0.1}
                    name={'gpa'}
                    htmlFor={'gpa'}
                    required={true}
                    control={control}
                    value={watch('gpa')}
                />
            </div>
        </>
    );
};
