import React from 'react';
import Head from 'next/head';
import favicon from '../../../../public/favicon.ico';
import faviconsvg from '../../../../public/favicon.svg';
import icon16 from '../../../../public/icon-16x16.png';
import icon32 from '../../../../public/icon-32x32.png';
import appletouch from '../../../../public/apple-touch-icon.png';
import embed from '../../../../public/embed.jpg';

export type CustomSEO = {
    description?: string;
    title?: string;
    author?: string;
    url?: string;
    image?: string;
};

export const HeadMeta: React.FC<{ seo?: CustomSEO }> = ({ seo }) => {
    const baseUrl = process.env.NEXT_PUBLIC_BASE_URL ?? 'https://www.staypluggedin.com';
    let description = seo?.description ?? `The Esports Recruiting Platform for College Esports Scholarships`;
    // limit description meta to 160 characters
    description = description.length > 156 ? `${description.substr(0, 156)}...` : description;
    const title = seo?.title ? `${seo.title} | Stay Plugged In` : `Stay Plugged In - Find Esports Scholarships`;
    // default SEO image from base url too
    const image = seo?.image ?? `${baseUrl}${embed.src}`;
    return (
        <Head>
            <title>{title}</title>
            <meta name="viewport" content="initial-scale=1.0, width=device-width" />
            <meta name="description" content={description} />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={seo?.url ?? baseUrl} />
            <meta property="og:image" content={image} />
            <meta property="og:site_name" content="Stay Plugged In" />
            <meta name="twitter:image" content={image} />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:creator" content={seo?.author ?? 'Stay Plugged In'} />
            <meta name="twitter:title" content={title} />
            <meta name="twitter:description" content={description} />
            <meta name="keywords" content={`stay plugged in`} />

            <link rel="apple-touch-icon" href={appletouch.src} />
            <link rel="shortcut icon" href={icon16.src} />
            <link rel="icon" type="image/png" sizes="32x32" href={icon32.src} />
            <link rel="icon" type="image/png" sizes="16x16" href={icon16.src} />
            <link rel="mask-icon" href="/icon-96x96.png" color="#29acdb" />
            <link rel="icon" type="image/svg+xml" href={faviconsvg.src} />
            <link rel="alternate icon" href={favicon.src} />
            <link rel="mask-icon" href={faviconsvg.src} color="#29acdb" />
        </Head>
    );
};
