import React, { useCallback } from 'react';
import { useModals } from '../../../SpinModalProvider';
import { BaseRouteModal } from '../../BaseRouteModal';
import { useAppState } from '../../../../../client/providers/AppProvider';
import {
    Espin_Team_Type_Enum,
    PlayerAcceptTeamInviteByIdDocument,
    PlayerAcceptTeamInviteByIdMutation,
    PlayerAcceptTeamInviteByIdMutationVariables,
    PlayerDeleteTeamInviteByIdDocument,
    PlayerDeleteTeamInviteByIdMutation,
    PlayerDeleteTeamInviteByIdMutationVariables,
    PlayerMyTeamsInviteFragment,
    UpdatePlayerHsOnTeamInviteDocument,
    UpdatePlayerHsOnTeamInviteMutation,
    UpdatePlayerHsOnTeamInviteMutationVariables,
} from '../../../../../generated/spin-graphql';
import { GameIcon } from '../../../../icons/GameIcon';
import { useAuthenticatedMutation } from '../../../../../client/hooks';
import { toast } from 'react-toastify';
import { gql } from 'urql';

gql`
    mutation PlayerDeleteTeamInviteById($inviteId: uuid!) {
        delete_espin_team_invite_by_pk(id: $inviteId) {
            id
        }
    }

    mutation PlayerAcceptTeamInviteById($player_profile_id: uuid!, $inviteId: uuid!, $teamId: uuid!) {
        insert_espin_team_members_one(object: { player_profile_id: $player_profile_id, team_id: $teamId }) {
            id
        }
        update_espin_team_invite_by_pk(pk_columns: { id: $inviteId }, _set: { status: accepted }) {
            id
        }
    }

    mutation UpdatePlayerHSOnTeamInvite($player_profile_id: uuid!, $hsId: uuid!) {
        update_espin_player_profile(
            where: { id: { _eq: $player_profile_id } }
            _set: { hs_name: "", linked_high_school: $hsId }
        ) {
            returning {
                id
            }
        }
    }
`;

function PlayerInviteResponseContent({ invite, currentPlayerHighSchool }: PlayerInviteResponsePopupProps) {
    const { user } = useAppState();
    const { closeActiveModal } = useModals();

    const [_, deleteInvite] = useAuthenticatedMutation<
        PlayerDeleteTeamInviteByIdMutation,
        PlayerDeleteTeamInviteByIdMutationVariables
    >(PlayerDeleteTeamInviteByIdDocument);

    const [__, acceptInvite] = useAuthenticatedMutation<
        PlayerAcceptTeamInviteByIdMutation,
        PlayerAcceptTeamInviteByIdMutationVariables
    >(PlayerAcceptTeamInviteByIdDocument);

    const [___, updateHS] = useAuthenticatedMutation<
        UpdatePlayerHsOnTeamInviteMutation,
        UpdatePlayerHsOnTeamInviteMutationVariables
    >(UpdatePlayerHsOnTeamInviteDocument);
    console.log('dialog', { currentPlayerHighSchool });
    const onAcceptInvite = useCallback(
        async (
            inviteId: string,
            teamId: string,
            playerProfileId: string,
            // teamName: string,
            // hsName: string,
            hsId?: string,
        ) => {
            // we run this first (we can't toss it into the real thing, it buggers it...)
            if (invite.team.team_type === Espin_Team_Type_Enum.School) {
                if (currentPlayerHighSchool.isCustomHighSchool) {
                    // this is a HS invite + I have a custom HS set
                    const hsResult = await updateHS(
                        {
                            hsId,
                            player_profile_id: playerProfileId,
                        },
                        // no additional type names, don't retrigger things pls.
                    );
                    if (hsResult.error) {
                        console.log({ err: hsResult.error });
                        toast.error(`There was an error updating your high school data`);
                    }
                }
            }

            const result = await acceptInvite(
                {
                    inviteId,
                    player_profile_id: playerProfileId,
                    teamId,
                },
                {
                    additionalTypenames: [
                        'espin_team_invite',
                        'espin_team_members',
                        'espin_team',
                        'espin_player_profile',
                    ],
                },
            );
            if (result.error) {
                toast.error(`There was an error accepting the team invite`);
            } else {
                toast.success('Team invite accepted');
            }

            closeActiveModal();
        },
        [acceptInvite, closeActiveModal, currentPlayerHighSchool.isCustomHighSchool, invite.team.team_type, updateHS],
    );

    const onDeclineInvite = useCallback(
        async (inviteId: string, teamName: string) => {
            const result = await deleteInvite({ inviteId }, { additionalTypenames: ['espin_team_invite'] });
            closeActiveModal();
            if (result.error) {
                toast.error(`There was an error declining the team invite`);
            } else {
                toast.success('Team invite declined');
            }
        },
        [closeActiveModal, deleteInvite],
    );

    return (
        <>
            <div className="invite-response-content">
                <div className="team-info">
                    {invite.team.high_school && (
                        <div className="high-school">
                            <a href={`/high-schools/${invite.team.high_school.slug}`}>
                                {' '}
                                {invite.team.high_school.name}
                            </a>
                        </div>
                    )}
                    <h2 className="team-name">{invite.team.display_name}</h2>
                    <div className="game">
                        <GameIcon
                            key={`game-${invite.team.game.game_definition}`}
                            game={invite.team.game.game_definition}
                            gameName={invite.team.game.name}
                        />
                        {invite.team.game.name}
                    </div>
                </div>
                <div className="button-container">
                    <button
                        onClick={() => onDeclineInvite(invite.id, invite.team.display_name)}
                        className="button delete red-button medium-button"
                    >
                        Decline Invite
                    </button>
                    <button
                        className="button medium-button blue-button"
                        onClick={() =>
                            onAcceptInvite(
                                invite.id,
                                invite.team.id,
                                user.player_profile.id,
                                // invite.team.display_name,
                                invite.team.high_school?.id,
                            )
                        }
                    >
                        Accept Invite
                    </button>
                </div>
            </div>
        </>
    );
}

type PlayerInviteResponsePopupProps = {
    invite: PlayerMyTeamsInviteFragment;
    currentPlayerHighSchool: { isCustomHighSchool: boolean; val: string };
};

export function PlayerInviteResponsePopup({ invite, currentPlayerHighSchool }: PlayerInviteResponsePopupProps) {
    const { closeActiveModal } = useModals();
    console.log({ currentPlayerHighSchool });
    return (
        <BaseRouteModal
            className="create-team-popup"
            content={<PlayerInviteResponseContent currentPlayerHighSchool={currentPlayerHighSchool} invite={invite} />}
            description=""
            title="Invitation Response"
            onCancel={() => closeActiveModal()}
        />
    );
}
