import React from 'react';

interface Props {
    className?: string;
}

export const InstagramIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="24" height="24" viewBox="0 0 24 24">
            <path
                d="M12,2.2c3.2,0,3.6,0,4.8,0.1c1.2,0.1,1.8,0.2,2.2,0.4c0.6,0.2,1,0.5,1.4,0.9c0.4,0.4,0.7,0.8,0.9,1.4
            c0.2,0.4,0.4,1.1,0.4,2.2c0.1,1.3,0.1,1.6,0.1,4.8s0,3.6-0.1,4.8c-0.1,1.2-0.2,1.8-0.4,2.2c-0.2,0.6-0.5,1-0.9,1.4
            c-0.4,0.4-0.8,0.7-1.4,0.9c-0.4,0.2-1.1,0.4-2.2,0.4c-1.3,0.1-1.6,0.1-4.8,0.1s-3.6,0-4.8-0.1c-1.2-0.1-1.8-0.2-2.2-0.4
            c-0.6-0.2-1-0.5-1.4-0.9c-0.4-0.4-0.7-0.8-0.9-1.4c-0.2-0.4-0.4-1.1-0.4-2.2c-0.1-1.3-0.1-1.6-0.1-4.8s0-3.6,0.1-4.8
            C2.3,6,2.5,5.4,2.6,4.9c0.2-0.6,0.5-1,0.9-1.4C4,3.1,4.4,2.9,4.9,2.7C5.3,2.5,6,2.3,7.2,2.2C8.4,2.2,8.8,2.2,12,2.2 M12,0
            C8.7,0,8.3,0,7.1,0.1C5.8,0.1,4.9,0.3,4.1,0.6C3.4,0.9,2.7,1.4,2,2C1.3,2.7,0.9,3.4,0.6,4.2C0.3,4.9,0.1,5.8,0.1,7.1
            C0,8.3,0,8.8,0,12c0,3.3,0,3.7,0.1,4.9c0.1,1.3,0.3,2.1,0.6,2.9C0.9,20.7,1.3,21.3,2,22c0.7,0.7,1.3,1.1,2.1,1.4
            c0.8,0.3,1.6,0.5,2.9,0.6C8.3,24,8.7,24,12,24s3.7,0,4.9-0.1c1.3-0.1,2.1-0.3,2.9-0.6c0.8-0.3,1.5-0.7,2.1-1.4
            c0.7-0.7,1.1-1.3,1.4-2.1c0.3-0.8,0.5-1.6,0.6-2.9C24,15.7,24,15.3,24,12s0-3.7-0.1-4.9c-0.1-1.3-0.3-2.1-0.6-2.9
            C23.1,3.4,22.7,2.7,22,2c-0.7-0.7-1.3-1.1-2.1-1.4c-0.8-0.3-1.6-0.5-2.9-0.6C15.7,0,15.3,0,12,0L12,0z"
            />
            <path
                d="M12,5.8c-3.4,0-6.2,2.8-6.2,6.2c0,3.4,2.8,6.2,6.2,6.2s6.2-2.8,6.2-6.2C18.2,8.6,15.4,5.8,12,5.8z M12,16
            c-2.2,0-4-1.8-4-4c0-2.2,1.8-4,4-4s4,1.8,4,4C16,14.2,14.2,16,12,16z"
            />
            <circle cx="18.4" cy="5.6" r="1.4" />
        </svg>
    );
};
