import React from 'react';

type Props = {
    className?: string;
};

export const FIFA22Icon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M10,0C4.5,0,0,4.5,0,10s4.5,10,10,10s10-4.5,10-10S15.5,0,10,0z M11,3.3l1.4-0.9c1.8,0.6,3.4,1.8,4.4,3.3L16.3,7L15,7.5
                l-4-2.8V3.3z M7.6,2.3l1.4,1v1.4L5,7.5L3.7,7L3.3,5.7C4.3,4.1,5.8,2.9,7.6,2.3z M5.1,15.1l-1.1,0.1C2.7,13.8,2,12,2,10
                c0-0.1,0-0.2,0-0.4l1-0.7l1.4,0.5l1.5,4.3L5.1,15.1z M12.5,17.6C11.7,17.9,10.9,18,10,18s-1.7-0.1-2.5-0.4l-0.7-1.5L7.4,15h5.1
                l0.6,1.1L12.5,17.6z M12.3,13H7.7L6.4,9L10,6.4L13.6,9L12.3,13z M16.1,15.2l-1.1-0.1l-0.8-1.4l1.5-4.3L17,8.9l1,0.7
                c0,0.1,0,0.2,0,0.3C18,12,17.3,13.8,16.1,15.2z"
            />
        </svg>
    );
};
