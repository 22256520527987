import React from 'react';
import { NewsletterSignup } from './NewsletterSignup';

type Props = {};

export const FooterNewsletter: React.FC<Props> = ({}) => {
    return (
        <div className="footer-column newsletter">
            <p className="footer-column-title">Keep up to Date</p>
            <p className="footer-column-description">
                {`Don't miss your chance to earn cash prizes. Stay up to date with upcoming 
            tournaments & events.`}
            </p>
            <NewsletterSignup />
        </div>
    );
};
