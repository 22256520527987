import React from 'react';

type Props = {
    className?: string;
};

export const FortniteIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M4.4,0v20l4.5-1.1v-6.7h4.5V7.8H8.9V4.4h5L15.6,0H4.4z" />
        </svg>
    );
};
