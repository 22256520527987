import React from 'react';

interface Props {
    className?: string;
}

export const DiscordIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="24" height="17" viewBox="0 0 24 17">
            <path
                d="M103.618,191.972a10.239,10.239,0,0,0-5.761-2.11l-.2.219a12.756,12.756,0,0,1,5.322,2.608,19.275,19.275,0,0,0-8.412-2.01h0a19.275,19.275,0,0,0-8.412,2.01,12.756,12.756,0,0,1,5.322-2.608l-.2-.219a10.24,10.24,0,0,0-5.761,2.11,27.094,27.094,0,0,0-2.95,11.844,8.235,8.235,0,0,0,6.279,3.046l1.316-1.672a5.174,5.174,0,0,1-3.488-2.349,16.294,16.294,0,0,0,7.894,2.309,16.294,16.294,0,0,0,7.894-2.309,5.174,5.174,0,0,1-3.488,2.349l1.316,1.672a8.235,8.235,0,0,0,6.279-3.046A27.093,27.093,0,0,0,103.618,191.972Zm-12.9,9.913a2.255,2.255,0,1,1,2.093-2.249A2.175,2.175,0,0,1,90.721,201.885Zm7.694,0a2.255,2.255,0,1,1,2.093-2.249A2.175,2.175,0,0,1,98.415,201.885Z"
                transform="translate(-82.568 -189.862)"
            />
        </svg>
    );
};
