import React, { useState } from 'react';
import { gql } from '@urql/core';
import * as Yup from 'yup';
import { NewsletterRequestDocument } from '../../generated/spin-graphql';
import { NewsletterInputField } from './NewsletterInputField';
import { toast } from 'react-toastify';
import { useFormWithSchema } from '../../client/hooks/use-form-schema';
import { useMutation } from 'urql';

gql`
    mutation NewsletterRequest($input: espin_newsletter_request_insert_input!) {
        insert_espin_newsletter_request_one(object: $input) {
            id
        }
    }
`;

const SignupSchema = Yup.object({
    email: Yup.string().email('Invalid email address').required('Required'),
    first_name: Yup.string().required('Required'),
    last_name: Yup.string().required('Required'),
});
type SignupSchemaValues = Yup.InferType<typeof SignupSchema>;

export const NewsletterSignup: React.FC<{}> = () => {
    const [insertNewsletterRequestResult, insertNewsletterRequest] = useMutation(NewsletterRequestDocument);

    const {
        register,
        handleSubmit,
        watch,
        formState: { errors, isSubmitting },
    } = useFormWithSchema(SignupSchema);
    const onSubmit = async (data: SignupSchemaValues) => {
        insertNewsletterRequest({ input: data }).then((result) => {
            if (result.error) {
                // if it hits here, then it's likely a duplicate (emails should be unique)
                toast.error('This email is already on the mailing list');
            } else {
                setHasSubmitted(true);
            }
        });
    };

    const [hasSubmitted, setHasSubmitted] = useState(false);

    if (!hasSubmitted) {
        return (
            <form className="newsletter-form" onSubmit={handleSubmit(onSubmit)}>
                <NewsletterInputField
                    error={errors.first_name?.message}
                    required={true}
                    className="first-name"
                    htmlFor={'first_name'}
                    label="First Name"
                    placeholder="First Name"
                    register={register('first_name', { required: true })}
                />
                <NewsletterInputField
                    error={errors.last_name?.message}
                    required={true}
                    className="last-name"
                    htmlFor={'last_name'}
                    label="Last Name"
                    placeholder="Last Name"
                    register={register('last_name', { required: true })}
                />
                <NewsletterInputField
                    error={errors.email?.message}
                    required={true}
                    className="email"
                    htmlFor={'email'}
                    label="Email"
                    placeholder="Email"
                    register={register('email', { required: true })}
                />
                <button className="button medium-button blue-button" type="submit" disabled={isSubmitting}>
                    Stay Informed
                </button>
            </form>
        );
    }

    return <div className="copy">Thank you for signing up!</div>;
};
