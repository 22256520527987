import React, { useCallback } from 'react';
import { BaseRouteModal } from '../BaseRouteModal';
import {
    AddOrEditGamePopupContentFragment,
    AddPlayerGameFromPopupDocument,
    AddPlayerGameFromPopupMutation,
    AddPlayerGameFromPopupMutationVariables,
    AddPlayerGameAndUpdatePrimaryFromPopupMutation,
    AddPlayerGameAndUpdatePrimaryFromPopupMutationVariables,
    AddPlayerGameAndUpdatePrimaryFromPopupDocument,
} from '../../../../generated/spin-graphql';
import { InputField } from '../../../input/InputField';
import { useModals } from '../../SpinModalProvider';
import { gql } from '@urql/core';
import { useAuthenticatedMutation } from '../../../../client/hooks';
import { GamesSelectComponent } from '../../../forms/GameSelectComponent';
import { toast } from 'react-toastify';
import { useAppState } from '../../../../client/providers/AppProvider';
import * as Yup from 'yup';
import { useFormWithSchema } from '../../../../client/hooks/use-form-schema';
import { ToggleSwitch } from '../../toggles/ToggleSwitch';
import { validators } from '../../../../shared/shared.utils';

type Props = { games?: Array<AddOrEditGamePopupContentFragment> };

gql`
    fragment AddOrEditGamePopupContent on espin_player_game {
        id
        game {
            name
            game_definition
        }
        in_game_username
        rank
        role
        primary
        tracker
    }
`;

gql`
    mutation AddPlayerGameFromPopup($input: espin_player_game_insert_input!) {
        insert_espin_player_game_one(object: $input) {
            id
        }
    }

    mutation AddPlayerGameAndUpdatePrimaryFromPopup($input: espin_player_game_insert_input!, $id: uuid!) {
        update_espin_player_game_by_pk(_set: { primary: false }, pk_columns: { id: $id }) {
            id
        }
        insert_espin_player_game_one(object: $input) {
            id
        }
    }
`;

const FormDataSchema = Yup.object({
    primary: Yup.boolean().default(true),
    role: Yup.string().required('Required'),
    in_game_username: Yup.string().required('Required'),
    tracker: Yup.string()
        .matches(validators.httpsUrl, {
            message: 'Please enter the full Tracker.gg address, starting with https',
        })
        .required('Required'),
    rank: Yup.string().required('Required'),
    game: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required('Please select a game'),
    }),
});
type FormData = Yup.InferType<typeof FormDataSchema>;

const AddGamePopupContent: React.FC<Props> = ({ games }) => {
    const hasPrimaryGame = !!games?.length;
    const [_, addGame] = useAuthenticatedMutation<
        AddPlayerGameFromPopupMutation,
        AddPlayerGameFromPopupMutationVariables
    >(AddPlayerGameFromPopupDocument);
    const [__, addGameAndUpdatePrimary] = useAuthenticatedMutation<
        AddPlayerGameAndUpdatePrimaryFromPopupMutation,
        AddPlayerGameAndUpdatePrimaryFromPopupMutationVariables
    >(AddPlayerGameAndUpdatePrimaryFromPopupDocument);
    const { user } = useAppState();
    const {
        register,
        handleSubmit,
        control,
        watch,

        formState: { isSubmitting, isDirty, errors },
    } = useFormWithSchema(FormDataSchema);
    const { closeActiveModal } = useModals();
    const onSubmit = useCallback(
        async (values: FormData) => {
            const { role, tracker, rank, game, primary, in_game_username } = values;
            const result =
                hasPrimaryGame && primary
                    ? await addGameAndUpdatePrimary(
                          {
                              input: {
                                  game_id: game.value,
                                  in_game_username,
                                  rank,
                                  role,
                                  tracker,
                                  profile_id: user.player_profile.id,
                                  primary,
                              },
                              id: games.filter((g) => g.primary)[0].id, // there should only ever be 1 primary game
                          },
                          { additionalTypeNames: ['espin_player_profile', 'espin_player_game'] },
                      )
                    : await addGame(
                          {
                              input: {
                                  game_id: game.value,
                                  in_game_username,
                                  rank,
                                  role,
                                  tracker,
                                  profile_id: user.player_profile.id,
                                  primary,
                              },
                          },
                          { additionalTypenames: ['espin_player_profile', 'espin_player_game'] },
                      );

            if (result.error) {
                toast.error(`There was an error adding your game. Check details and try again`);
                console.error(result.error);
                return;
            }

            toast.success('Game added!');
            closeActiveModal();
        },
        [addGame, addGameAndUpdatePrimary, user, closeActiveModal, games, hasPrimaryGame],
    );

    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(onSubmit)(e);
            }}
        >
            <div className="add-or-edit-game-content">
                <GamesSelectComponent
                    error={errors.game?.value?.message}
                    control={control}
                    isMulti={false}
                    required={true}
                    placeholder={'Select a game'}
                    name="game"
                    excludedGames={games}
                />
                <InputField
                    error={errors.in_game_username?.message}
                    htmlFor={'in_game_username'}
                    label="In-Game Username"
                    placeholder="In-Game Username"
                    required={true}
                    register={register}
                />
                <InputField
                    error={errors.rank?.message}
                    htmlFor={'rank'}
                    label="Rank"
                    placeholder="Rank"
                    required={true}
                    register={register}
                />
                <InputField
                    error={errors.role?.message}
                    htmlFor={'role'}
                    label="Role"
                    placeholder="Role"
                    required={true}
                    register={register}
                />
                <InputField
                    error={errors.tracker?.message}
                    htmlFor={'tracker'}
                    label="Tracker.gg Link"
                    placeholder="Tracker.gg Link"
                    required={true}
                    register={register}
                />
                {!!games?.length ? (
                    <ToggleSwitch
                        className="primary-game-toggle"
                        label="Is this your primary game?"
                        value={!hasPrimaryGame}
                        required={false}
                        register={register}
                        error={errors.primary?.message}
                        name="primary"
                    />
                ) : (
                    <div className="primary-game-toggle">
                        <div className="description">This will be your primary game</div>
                    </div>
                )}
                <div className="button-container">
                    <button disabled={isSubmitting} className="button medium-button blue-button">
                        Add Game
                    </button>
                </div>
            </div>
        </form>
    );
};

export const AddGamePopup: React.FC<Props> = ({ games }) => {
    const { closeActiveModal } = useModals();

    return (
        <BaseRouteModal
            content={<AddGamePopupContent games={games} />}
            description=""
            title={'Add Game'}
            onCancel={closeActiveModal}
        />
    );
};
