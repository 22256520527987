import { useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { getClientEnv } from '../../client/clientEnv';
import { recordPageView } from './analyticsWrapper';
import Script from 'next/script';
import { useAppState } from '../../client/providers/AppProvider';

export function MixPanelAnalytics() {
    const router = useRouter();
    const { user, isLoggingIn } = useAppState();
    //to avoid multiple downloads
    const [render, setRender] = useState(false);

    useEffect(() => {
        const handleRouteChange = (url: string) => {
            if (!url.startsWith('/auth-callback')) {
                recordPageView(url);
            }
        };
        router.events.on('routeChangeComplete', handleRouteChange);
        return () => {
            router.events.off('routeChangeComplete', handleRouteChange);
        };
    }, [router.events, user]);

    useEffect(() => {
        // note, user MAY be undefined
        if (!isLoggingIn && render === false && getClientEnv().NEXT_PUBLIC_MIXPANEL_TOKEN !== '') {
            setRender(true);
        }
    }, [isLoggingIn, render]);

    return (
        <>
            {render && (
                <Script
                    id={'mixpanel-script'}
                    strategy="afterInteractive"
                    // nextjs minification breaks the original snippet from mixed panel
                    //fixed by replacing match(/^\/\//) with match(/^\\/\\//)
                    dangerouslySetInnerHTML={{
                        __html: `

                    
                    if(window.mixpanel === undefined){
                        // ok now we download
                        (function(e,a){if(!a.__SV){var b=window;try{var c,l,i,j=b.location,g=j.hash;c=function(a,b){return(l=a.match(RegExp(b+"=([^&]*)")))?l[1]:null};g&&c(g,"state")&&(i=JSON.parse(decodeURIComponent(c(g,"state"))),"mpeditor"===i.action&&(b.sessionStorage.setItem("_mpcehash",g),history.replaceState(i.desiredHash||"",e.title,j.pathname+j.search)))}catch(m){}var k,h;window.mixpanel=a;a._i=[];a.init=function(b,c,f){function e(b,a){var c=a.split(".");2==c.length&&(b=b[c[0]],a=c[1]);b[a]=function(){b.push([a].concat(Array.prototype.slice.call(arguments,
                            0)))}}var d=a;"undefined"!==typeof f?d=a[f]=[]:f="mixpanel";d.people=d.people||[];d.toString=function(b){var a="mixpanel";"mixpanel"!==f&&(a+="."+f);b||(a+=" (stub)");return a};d.people.toString=function(){return d.toString(1)+".people (stub)"};k="disable time_event track track_pageview track_links track_forms register register_once alias unregister identify name_tag set_config reset opt_in_tracking opt_out_tracking has_opted_in_tracking has_opted_out_tracking clear_opt_in_out_tracking people.set people.set_once people.unset people.increment people.append people.union people.track_charge people.clear_charges people.delete_user".split(" ");
                            for(h=0;h<k.length;h++)e(d,k[h]);a._i.push([b,c,f])};a.__SV=1.2;b=e.createElement("script");b.type="text/javascript";b.async=!0;b.src="undefined"!==typeof MIXPANEL_CUSTOM_LIB_URL?MIXPANEL_CUSTOM_LIB_URL:"file:"===e.location.protocol&&"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js".match(/^\\/\\//)?"https://cdn.mxpnl.com/libs/mixpanel-2-latest.min.js":"//cdn.mxpnl.com/libs/mixpanel-2-latest.min.js";c=e.getElementsByTagName("script")[0];c.parentNode.insertBefore(b,c)}})(document,window.mixpanel||[]);
                    
                            // and init
                            mixpanel.init('${getClientEnv().NEXT_PUBLIC_MIXPANEL_TOKEN}', { debug: ${
                            process.env.NODE_ENV === 'development'
                        } });
                        }
                        // track landing page pageview
                        window.mixpanel.track('PageView', {
                            path_path: window.location.pathname
                        });
                    if ("${user}" !== "undefined" && window.mixpanel !== undefined && "${
                            getClientEnv().NEXT_PUBLIC_MIXPANEL_TOKEN
                        }" !="" ) {
                        
                        window.mixpanel.identify("${user?.id}");
                        window.mixpanel.people.set({
                            role: "${user?.role}",
                            $email: "${user?.email}",
                        });
                        
                        
                }
                 `,
                    }}
                    onError={(e) => {
                        console.error('Script failed to load', e);
                    }}
                ></Script>
            )}
        </>
    );
}
