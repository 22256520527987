import React from 'react';
import { UseFormRegister } from 'react-hook-form';

export interface InputFieldProps {
    className?: string;
    label: string | JSX.Element;
    name: string;
    value: any;
    checked?: boolean;
    register?: UseFormRegister<any>;
    id: string;
}

export const RadioButton: React.FC<InputFieldProps> = ({ className, name, label, value, checked, register, id }) => {
    return (
        <div className={`radio-button-field-container${className ? ` ${className}` : ''}`}>
            <label htmlFor={id} className="radio-button-label">
                <input
                    className="radio-button"
                    type={'radio'}
                    name={name}
                    value={value}
                    id={id}
                    checked={checked}
                    {...(!!register && register(name))}
                />
                {label}
            </label>
        </div>
    );
};
