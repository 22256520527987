import React from 'react';
import { Control, Controller } from 'react-hook-form';
import Select from 'react-select';

interface Props {
    value?: any;
    placeholder: string;
    className?: string;
    required: boolean;
    name?: string;
    control: Control<any>;
    isMulti: boolean;
    options: any[];
    onChange?: (any) => void;
    error?: string;
    warning?: string;
    disabled?: boolean;
}

export const SelectField: React.FC<Props> = ({
    name,
    placeholder,
    className,
    required,
    isMulti,
    control,
    options,
    error,
    warning,
    onChange,
    value,
    disabled,
}) => {
    return (
        <div
            className={`select-field-container${className ? ` ${className}` : ''}${error ? ` error` : ''}${
                disabled ? ` disabled` : ''
            }`}
        >
            <Controller
                name={name}
                rules={{ required }}
                control={control}
                render={({ field }) => (
                    <Select
                        className={`select-container${error ? ` error` : ''}${required ? ` has-required` : ''}`}
                        classNamePrefix={`select`}
                        placeholder={placeholder}
                        {...field}
                        {...(isMulti && { isMulti })}
                        options={options}
                        isClearable={false}
                        isDisabled={disabled}
                        value={value || field.value}
                        onChange={(val) => {
                            if (onChange) {
                                field.onChange(onChange(val));
                            } else {
                                field.onChange(val);
                            }
                        }}
                    />
                )}
            />
            {required && <span className="required">*</span>}
            {error && <p className="error-label">{error}</p>}
            {warning && <p className="warning-label">{warning}</p>}
        </div>
    );
};
