import { FeatureListItem } from '../components/journey/FeatureListItem';
import { Espin_External_Provider_Enum, Espin_Subscription_Status_Enum } from '../generated/spin-graphql';

export const COLLEGES_PER_PAGE = 50;
export const HIGH_SCHOOLS_PER_PAGE = 50;
export const CONVERT_TO_MILES = 1609;

export const CollegesDefaultSearch = {
    unsubbedSearch: {
        _and: [
            { subscribed: { _eq: false } },
            {
                _or: [
                    { subscription: { status: { _neq: Espin_Subscription_Status_Enum.Active } } },
                    {
                        _not: {
                            subscription: {},
                        },
                    },
                ],
            },
            { verified: { _eq: true } },
            { slug: { _is_null: false } },
        ],
    },
    subbedSearch: {
        _and: [
            {
                _or: [
                    { subscribed: { _eq: true } },
                    { subscription: { status: { _eq: Espin_Subscription_Status_Enum.Active } } },
                ],
            },
            { verified: { _eq: true } },
            { slug: { _is_null: false } },
        ],
    },
    subbedOffset: 0,
    unsubbedOffset: 0,
    subbedPageSize: COLLEGES_PER_PAGE,
    unsubbedPageSize: COLLEGES_PER_PAGE,
};

export const PLAYER_PAGE_SIZE = 50;

export const ExternalProviderRedirects = {
    [Espin_External_Provider_Enum.Playvs]: 'https://www.playvs.com',
};

export const CollegeSubscriptionFeatureList: FeatureListItem[] = [
    {
        label: 'College Database List',
        description:
            'List your program as a recruiting esports college or university to be noticed by players and parents interested.',
        free: { name: 'Silver', included: true },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Custom College Profile',
        description:
            'Create your own custom college profile to highlight your inside & outside the game opportunities and program information.',
        free: { name: 'Silver', included: true },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Intro Recruiting Meeting',
        description:
            'Talk directly with a recruiting specialist to define your needs and create a plan of action to reach more recruits.',
        free: { name: 'Silver', included: true },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Continuous Individual Meetings',
        description:
            'You and your program can talk directly with a recruiting specialist throughout your entire recruiting process. Our experts can help answer questions, help find the right fit players for your program and keep you up to date on any opportunities that may fit you.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Attend Recruiting Events',
        description:
            'Watch recruiting events! Events include weekly tournaments, recruiting showcases and LAN events throughout the country. SPIN Recruiting events such as Carry 2 College and the Showcase Series are specifically structured to increase recruiting opportunities.',
        free: { name: 'Silver', included: true },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Annual Recruiting Event on Your Campus',
        description:
            'Host an event live on your campus, engaging hundreds of students and their parents on your campus.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: false },
        tier2: { name: 'Platinum', included: false },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Player Search Engine',
        description:
            'Able to search via game title, name, gamertag, GPA & location across all SPIN players. Easily use automated tools to find players quicker and start the recruiting conversation.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'View Player Profile',
        description:
            'Ability to see the player card with recruiting centric information to streamline the recruiting process.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: "View Player Subscribers' Contact Info",
        description:
            'Ability to see the player card with recruiting centric information to streamline the recruiting process, with their contact information.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'View Player Contact Information',
        description: 'Regardless of player subscription status, you will be able to see their contact information.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: false },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Player Connection',
        description:
            'Ability to be connected directly to the player via email or discord for any player on the platform upon request.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Enhanced Player Connection',
        description:
            'For players that fit what you are looking for, a Recruiting Specialist will reach out and connect the college to said players.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: false },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Virtual College Fair',
        description:
            'Ability to participate in virtual college fairs as they are hosted with a dedicated voice channel.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'SPIN College Fair',
        description:
            'Enhanced Fair services including first access to limited seat availability, and other singular event related items.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: false },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'High School Profile View',
        description: 'Colleges can view high school profiles, but without contact information.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'High School Profile Information',
        description:
            'Colleges can view high school profiles with contact information & team rosters, regardless of high school or player status.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: false },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Subscriber Only Discord',
        description:
            'Access to a subscriber only discord category where you can interact directly with Recruiting Specialists and other colleges to stay up to date on all opportunities. There will also be educational material, tips & tricks and panel discussions hosted here.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Unique Content & News',
        description:
            'Continuous content that has all recruiting updates on new opportunities & new scholarship opportunities. Also included is educational material to help streamline the recruiting process.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
];

export const CollegeSubscriptionFeatureListForDashboard: FeatureListItem[] = [
    {
        label: 'Dashboard Features',
        description: '',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: false },
        tier2: { name: 'Platinum', included: false },
        tier3: { name: 'Diamond', included: false },
    },
    {
        label: 'Automatic Reply',
        description:
            'For each player that messages your account, a default reply will be sent back to ensure no player is left out.',
        free: { name: 'Silver', included: true },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Automatic Reply - Custom',
        description:
            'For each player that messages your account, a custom reply will be sent back to help you connect with students quicker.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Event Scouting Reports',
        description:
            'Access to a scouting report for each SPIN event with all necessary recruiting information for each participating player.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Profile View Notifications',
        description: 'Ability to see all notifications and WHO they came from.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Following Feature',
        description:
            'Follow any recruit that fits your needs and stay up to date as they progress closer to graduation.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Messaging Capabilities',
        description: 'Ability to send and receive messages to players.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'LAN Event Booth Space',
        description: 'Ability to reserve booth space at SPIN LAN events.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: true },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
    {
        label: 'Group Messaging',
        description:
            'Ability to send group messages to players, in a limited amount. Easily send templated messages to students from a specific geographic area, by game played.',
        free: { name: 'Silver', included: false },
        tier1: { name: 'Gold', included: false },
        tier2: { name: 'Platinum', included: true },
        tier3: { name: 'Diamond', included: true },
    },
];

export const teamCaptainChangeMessage =
    'Please note that if you change the team captain, you will lose access to edit the team or register it for events, and the new team captain will have that access instead.';
