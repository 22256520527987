import React from 'react';

interface Props {
    className?: string;
}

export const HamburgerIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M19,6H1C0.4,6,0,5.6,0,5v0c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v0C20,5.6,19.6,6,19,6z" />
            <path d="M19,11H1c-0.6,0-1-0.4-1-1v0c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v0C20,10.6,19.6,11,19,11z" />
            <path d="M19,16H1c-0.6,0-1-0.4-1-1v0c0-0.6,0.4-1,1-1h18c0.6,0,1,0.4,1,1v0C20,15.6,19.6,16,19,16z" />
        </svg>
    );
};
