import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { DashboardLayout } from './layout/DashboardLayout';
import { Layout } from './layout/Layout';

export function GenericInLayoutPageLoading() {
    return <Skeleton containerClassName="full-page-skeleton" />;
}

export function GenericFullPageLoading() {
    return (
        <Layout>
            <GenericInLayoutPageLoading />
        </Layout>
    );
}

export function DashboardFullPageLoading() {
    return (
        <DashboardLayout pageTitle={''}>
            {' '}
            <GenericInLayoutPageLoading />
        </DashboardLayout>
    );
}
