import React from 'react';

type Props = {
    className?: string;
};

export const SPINIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`${className ? className : ''}`} width="62" height="70" viewBox="0 0 62 70">
            <path
                d="M60.2,16.5L32.3,0.3c-0.7-0.4-1.9-0.4-2.6,0L1.8,16.5c-0.7,0.4-1.3,1.4-1.3,2.3v32.4c0,0.8,0.6,1.8,1.3,2.3l27.9,16.2
                c0.7,0.4,1.9,0.4,2.6,0l27.9-16.2c0.7-0.4,1.3-1.4,1.3-2.3V18.8C61.5,18,60.9,16.9,60.2,16.5z M9.7,23.7c0-0.6,0.4-1.3,0.9-1.6
                l19.5-11.3c0.5-0.3,1.3-0.3,1.8,0l8.8,5.1c0.5,0.3,0.5,0.8,0,1.1L10.6,34.5c-0.5,0.3-0.9,0.1-0.9-0.5V23.7z M52.3,46.3
                c0,0.6-0.4,1.3-0.9,1.6L31.9,59.2c-0.5,0.3-1.3,0.3-1.8,0l-8.8-5.1c-0.5-0.3-0.5-0.8,0-1.1l19.5-11.3c0.5-0.3,0.9-1,0.9-1.6v-4
                c0-0.6-0.4-0.8-0.9-0.5L16,49.9c-0.5,0.3-1.3,0.3-1.8,0l-3.5-2c-0.5-0.3-0.9-1-0.9-1.6v-4.1c0-0.6,0.4-1.3,0.9-1.6L46,20.1
                c0.5-0.3,1.3-0.3,1.8,0l3.5,2c0.5,0.3,0.9,1,0.9,1.6V46.3L52.3,46.3z"
            />
        </svg>
    );
};
