import React from 'react';

interface Props {
    className?: string;
}

export const CheckmarkIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M6.2,14.7L2,10.5c-0.5-0.5-1.2-0.5-1.7,0s-0.5,1.2,0,1.7l5,5c0.5,0.5,1.2,0.5,1.7,0L19.7,4.5c0.5-0.5,0.5-1.2,0-1.7
                c-0.5-0.5-1.2-0.5-1.7,0L6.2,14.7z"
            />
        </svg>
    );
};
