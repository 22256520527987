import Link from 'next/link';
import React, { useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { useAppState } from '../../../client/providers/AppProvider';
import { PictureWithFallback } from '../../sections/PictureWithFallback';
import { LoadingIcon } from '../../svg/LoadingIcon';
import { RightArrow } from '../../svg/RightArrow';

type Props = {};

export const AccountDropdown: React.FC<Props> = ({}) => {
    const { a0user, user, isLoggedIn, isLoggingIn, logout, isAuthenticated } = useAppState();

    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);

    let profilePicture = user?.player_profile?.profile_image ?? null;
    let username = user?.player_profile?.spin_username ?? user?.email.split('@').shift() ?? <Skeleton />;

    const isCollegeUser = !!user?.college_profile?.college;
    const isHSUser = !!user?.high_school_profile?.high_school;
    if (isCollegeUser) {
        username = user.college_profile.college.program_name;
        profilePicture = user?.college_profile?.college?.logo;
    } else if (isHSUser) {
        username = user.high_school_profile.high_school.name;
        profilePicture = user?.high_school_profile?.high_school?.profile_pic_file;
    } else if (!!user?.parent_profile?.child_profiles) {
        // using the default SPIN logo if it's a parent user
        // change this if there is a feature for parent users having their own profile image
        profilePicture = null;
    }

    return (
        <div
            className={`account-dropdown${dropdownOpen ? ' active' : ''}`}
            onClick={() => setDropdownOpen(!dropdownOpen)}
        >
            {isLoggingIn && <LoadingIcon className="loading-icon rotating" />}
            {!isLoggingIn && user && (
                <>
                    <div className="profile-picture">
                        <PictureWithFallback
                            icon={profilePicture}
                            width={40}
                            height={40}
                            alt={`${username}} profile picture`}
                        />
                    </div>
                    <p className={`username${isCollegeUser ? ' college-name' : ''}`}>{username}</p>
                    <RightArrow className="arrow" />
                    {dropdownOpen && (
                        <div className="links">
                            <Link href="/dashboard">
                                <a className={`link`}>Dashboard</a>
                            </Link>
                            <Link href="/dashboard/account-settings/account">
                                <a className={`link`}>Account Settings</a>
                            </Link>
                            <div
                                className={`link`}
                                onClick={() =>
                                    logout({
                                        returnTo: `${window.origin}`,
                                    })
                                }
                            >
                                Sign Out
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
