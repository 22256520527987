import Link from 'next/link';
import React from 'react';

type Props = {};

export const ComingSoon: React.FC<Props> = ({}) => {
    return (
        <div className="coming-soon-container">
            <div className="new-coming-soon-container">
                <div className="new">New</div>
                <h2 className="coming-soon">Coming Soon</h2>
            </div>
            <p className="description">{"Take a look at what's coming next!"}</p>
            <Link href={'/pricing'}>
                <a className="button medium-button blue-button">Learn More</a>
            </Link>
        </div>
    );
};
