import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { FindPlayersResultsFragment } from '../../../../../generated/spin-graphql';
import { PlayerPreviewCard } from './PlayerPreviewCard';
import { gql } from '@urql/core';

type Props = {
    results: FindPlayersResultsFragment[];
    hasSearched: boolean;
    isSearching: boolean;
};

gql`
    fragment FindPlayersResults on espin_player_profile {
        id
        ...PlayerListItem
        ...PlayerCard
    }
`;

export const FindPlayersResults: React.FC<Props> = ({ results, hasSearched, isSearching }) => {
    if (isSearching) {
        return (
            <div className="find-players-results">
                <div className="results-container">
                    <Skeleton className="player-preview-card-skeleton" />
                    <Skeleton className="player-preview-card-skeleton" />
                    <Skeleton className="player-preview-card-skeleton" />
                    <Skeleton className="player-preview-card-skeleton" />
                </div>
                <div className="cta-container">
                    <Skeleton className="cta-card-skeleton" />
                </div>
            </div>
        );
    }

    if (hasSearched && results.length === 0) {
        return (
            <div className="find-players-results">
                <h2 className="no-results-found">No results found.</h2>
            </div>
        );
    }

    return (
        <div className="find-players-results">
            <div className="results-container">
                {results.map((player, index) => (
                    <PlayerPreviewCard key={`player-${index}`} player={player} />
                ))}
            </div>
        </div>
    );
};
