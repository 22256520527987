import React from 'react';
import { SPINIcon } from '../svg/SPINIcon';

type Props = {};

export const DefaultPicture: React.FC<Props> = ({}) => {
    return (
        <div className="default-picture">
            <SPINIcon className="spin-logo" />
        </div>
    );
};
