import React from 'react';
import { useModals } from '../SpinModalProvider';
import { BaseRouteModal } from './BaseRouteModal';

type Props = {
    params: GenericConfirmationPopupParams;
};

export type GenericConfirmationPopupParams = {
    popupTitle: string;
    askText: string | JSX.Element;
    confirmCTAText: string;
    cancelCTAText: string;
    onConfirm: () => void;
    onCancel: () => void;
    confirmCTAButtonColour: string;
    cancelCTAButtonColour: string;
};

const GenericConfirmationPopupContent: React.FC<Props> = ({ params }) => {
    return (
        <div className="generic-confirmation-popup">
            <div>{params.askText}</div>
            <div className="button-container">
                {!!params.confirmCTAText && (
                    <button
                        className={`button medium-button ${params.confirmCTAButtonColour}-button`}
                        onClick={(e) => {
                            e.preventDefault();
                            params.onConfirm();
                        }}
                    >
                        {params.confirmCTAText}
                    </button>
                )}
                {!!params.cancelCTAText && (
                    <button
                        className={`button medium-button ${params.cancelCTAButtonColour}-button`}
                        onClick={(e) => {
                            e.preventDefault();
                            params.onCancel();
                        }}
                    >
                        {params.cancelCTAText}
                    </button>
                )}
            </div>
        </div>
    );
};

export const GenericConfirmationModal: React.FC<Props> = ({ params }) => {
    const { closeActiveModal } = useModals();

    return (
        <BaseRouteModal
            content={<GenericConfirmationPopupContent params={params} />}
            description=""
            title={params.popupTitle}
            onCancel={closeActiveModal}
        />
    );
};
