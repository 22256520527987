import React from 'react';

type Props = {
    className?: string;
};

export const PokemonUniteIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M0.1,11.4c0,0,0,0.2,0,0.3c0.8,4.8,5,8.4,9.8,8.4c5.5,0,10-4.4,10.1-10c0-5.5-4.4-10-9.9-10C4.5,0,0,4.4,0,10
                C0,10.4,0,10.9,0.1,11.4z M1.4,10.9c0.7-0.3,1.5-0.6,2.3-0.8c0.4,3.2,3.1,5.6,6.3,5.6s5.9-2.4,6.3-5.6c0.8,0.3,1.5,0.6,2.2,1
                c-0.6,4.3-4.3,7.5-8.6,7.5C5.6,18.6,1.9,15.3,1.4,10.9z M9.9,10.9c1,0,1.9-0.6,2.2-1.6c0.4,0,0.7,0.1,1.1,0.1
                c-0.1,1.8-1.5,3.1-3.3,3.1c-1.7,0-3.2-1.4-3.3-3.1c0.3,0,0.7-0.1,1-0.1C8,10.3,8.9,10.9,9.9,10.9L9.9,10.9z M8.6,8.5
                c0-0.5,0.3-1,0.8-1.2c0.5-0.2,1.1-0.1,1.4,0.3s0.5,1,0.3,1.4C11,9.5,10.5,9.8,9.9,9.8C9.2,9.8,8.6,9.3,8.6,8.5L8.6,8.5z M18.5,9.9
                c-0.7-0.4-1.5-0.7-2.3-1V4.2C17.7,5.8,18.5,7.8,18.5,9.9L18.5,9.9z M13.2,2.1v6.1c-0.3,0-0.6-0.1-1-0.1C12,6.9,11,6.1,9.9,6.1
                S7.8,6.9,7.6,8C7.3,8.1,7,8.1,6.7,8.1V2.1C8.8,1.2,11.1,1.2,13.2,2.1L13.2,2.1z M3.6,4.2v4.6C2.8,9,2.1,9.3,1.3,9.6
                C1.4,7.6,2.2,5.7,3.6,4.2z"
            />
        </svg>
    );
};
