import React from 'react';

type Props = {
    className?: string;
};

export const SmiteIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <polygon
                points="6.8,5.5 6.8,7.5 6.8,8.1 6.8,10.1 9.2,10.1 10.8,10.1 10.8,10.7 6.8,10.7 6.8,12.7 10.8,12.7 13.2,12.7 13.2,10.7 
                13.2,10.1 13.2,8.1 10.8,8.1 9.2,8.1 9.2,7.5 13.2,7.5 13.2,5.5 9.2,5.5 	"
            />
            <path
                d="M17.8,7.4L20,3.3h-3h-0.3H10H3.3H3H0l2.2,4.1H0.1l2.5,3.8H1.3l4.4,5.5l-0.5-1.2H10h4.8l-0.5,1.2l4.4-5.5h-1.3l2.5-3.8H17.8
                z M16.3,12l-1,2.3H10H4.7l-1-2.3h1.5L3.4,8.5h1.9L3.8,4.4H10h6.2l-1.6,4.1h1.9L14.7,12H16.3z"
            />
        </svg>
    );
};
