import React, { useCallback, useEffect, useState } from 'react';
import { BaseRouteModal } from '../../BaseRouteModal';
import { useModals } from '../../../SpinModalProvider';
import { Control, UseFormRegister, UseFormSetValue, UseFormWatch } from 'react-hook-form';
import { FindPlayersForm, PlayerLocationEnum } from './FindPlayersForm';
import { FindPlayersResults } from './FindPlayersResults';
import { CTACard } from '../find-colleges/CTACard';
import { gql } from '@urql/core';
import * as Yup from 'yup';
import {
    Espin_Game_Definition_Enum,
    Espin_Player_Profile_Bool_Exp,
    FindPlayersResultsFragment,
    FindPlayersSearchDocument,
    FindPlayersSearchQuery,
    FindPlayersSearchQueryVariables,
} from '../../../../../generated/spin-graphql';
import { useAppState } from '../../../../../client/providers/AppProvider';
import { useFormWithSchema } from '../../../../../client/hooks/use-form-schema';
import { toast } from 'react-toastify';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuthenticatedClient } from '../../../../../client/hooks/use-authenticated-client';
import { useRouter } from 'next/router';

type Props = {
    isSearching: boolean;
    hasSearched: boolean;
    results: FindPlayersResultsFragment[];
    register: UseFormRegister<FindPlayersFormData>;
    watch: UseFormWatch<FindPlayersFormData>;
    control: Control<FindPlayersFormData, object>;
    setValue: UseFormSetValue<FindPlayersFormData>;
};

gql`
    query FindPlayersSearch($search: espin_player_profile_bool_exp!) {
        espin_player_profile(
            where: $search
            order_by: [{ spin_username: asc }, { user: { subscription_status: { is_subscribed: desc } } }]
            limit: 4
        ) {
            ...FindPlayersResults
        }
    }
`;

const FindPlayersPopupContent: React.FC<Props> = ({
    results,
    isSearching,
    hasSearched,
    register,
    watch,
    control,
    setValue,
}) => {
    return (
        <>
            {(!hasSearched || isSearching) && (
                <FindPlayersForm register={register} watch={watch} control={control} setValue={setValue} />
            )}
            {isSearching ||
                (hasSearched && (
                    <FindPlayersResults results={results} isSearching={isSearching} hasSearched={hasSearched} />
                ))}
        </>
    );
};

const FindPlayersFormDataSchema = Yup.object({
    location_preference: Yup.mixed()
        .oneOf(Object.values(PlayerLocationEnum), 'Please select one.')
        .required('Please select a location preference'),
    states: Yup.mixed().test('state_check', 'Please enter a state.', (val, ctx) => {
        if (!!val && ctx.parent['location_preference'] === PlayerLocationEnum.OTHER_STATES) {
            const schema = Yup.mixed();
            return schema.isValidSync(val);
        }
        return true;
    }),
    preferred_study_fields: Yup.array(Yup.string()).nullable(),
    games: Yup.array(Yup.object({ label: Yup.string(), value: Yup.string() })),
    gpa: Yup.string().required('Please select a GPA.'),
});
export type FindPlayersFormData = Yup.InferType<typeof FindPlayersFormDataSchema>;

export const FindPlayersPopup: React.FC<Props> = ({}) => {
    const { closeActiveModal } = useModals();
    const router = useRouter();
    const [results, setResults] = useState<FindPlayersResultsFragment[]>([]);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [hasSearched, setHasSearched] = useState<boolean>(false);
    const { user } = useAppState();
    const { getAccessTokenSilently } = useAuth0();
    const { getAuthClient } = useAuthenticatedClient();

    useEffect(() => {
        const onRouteChangeStart = (url) => {
            closeActiveModal();
        };

        router.events.on('routeChangeStart', onRouteChangeStart);

        return () => {
            router.events.off('routeChangeStart', onRouteChangeStart);
        };
    }, [router.events, closeActiveModal]);

    const buildSearchCriteria = useCallback(
        (values: FindPlayersFormData) => {
            const locationPreference = values.location_preference;

            let locationSearch: Espin_Player_Profile_Bool_Exp | null = null;
            switch (locationPreference) {
                case PlayerLocationEnum.MY_STATE: {
                    locationSearch = {
                        address: { state: { _eq: user.college_profile.college.address.state } },
                    };
                    break;
                }
                case PlayerLocationEnum.OTHER_STATES: {
                    if (!!values.states && values.states.length) {
                        locationSearch = {
                            address: { state: { _in: values.states.map((s) => s.value) } },
                        };
                    }
                    break;
                }
                case PlayerLocationEnum.ANY_STATE: {
                    locationSearch = {
                        address: { country: { _eq: 'United States' } },
                    };
                    break;
                }
                case PlayerLocationEnum.ANY_LOCATION: {
                    break;
                }
                case PlayerLocationEnum.INTERNATIONAL: {
                    locationSearch = {
                        address: { country: { _neq: 'United States' } },
                    };
                    break;
                }
                default:
                    break;
            }

            let studyFieldsSearch: Espin_Player_Profile_Bool_Exp | null = null;
            if (values.preferred_study_fields) {
                studyFieldsSearch = {
                    preferred_study_fields: {
                        _has_keys_any: values.preferred_study_fields,
                    },
                };
            }

            let gamesSearch: Espin_Player_Profile_Bool_Exp | null = null;
            if (values.games) {
                const games = values.games.map((g) => g['value'] as Espin_Game_Definition_Enum);
                gamesSearch = {
                    player_games: {
                        game: {
                            game_definition: {
                                _in: games,
                            },
                        },
                    },
                };
            }

            let gpaSearch: Espin_Player_Profile_Bool_Exp | null = null;
            if (values.gpa) {
                gpaSearch = {
                    gpa: { _gte: values.gpa },
                };
            }

            const base: Espin_Player_Profile_Bool_Exp = {
                _and: [
                    studyFieldsSearch && studyFieldsSearch,
                    gamesSearch && gamesSearch,
                    gpaSearch && gpaSearch,
                    locationSearch && locationSearch,
                ].filter((s) => !!s),
            };

            return base;
        },
        [user],
    );

    const showResults = useCallback(
        async (values: FindPlayersFormData) => {
            const searchCriteria = buildSearchCriteria(values);
            const accessToken = await getAccessTokenSilently();
            const { options, gqlClient } = getAuthClient(accessToken);
            setIsSearching(true);
            const r = await gqlClient
                .query<FindPlayersSearchQuery, FindPlayersSearchQueryVariables>(
                    FindPlayersSearchDocument,
                    {
                        search: searchCriteria,
                    },
                    {
                        ...options,
                        requestPolicy: 'network-only',
                    },
                )
                .toPromise();

            if (!!r.error) {
                toast.error('Error in search');
                setResults([]);
                return;
            }

            setResults(r.data.espin_player_profile);
            setIsSearching(false);
            setHasSearched(true);
        },
        [getAccessTokenSilently, setResults, setHasSearched, buildSearchCriteria, getAuthClient],
    );

    const { register, handleSubmit, control, watch, formState, setValue } = useFormWithSchema(
        FindPlayersFormDataSchema,
        {
            defaultValues: {
                gpa: '0.0',
                location_preference: PlayerLocationEnum.MY_STATE,
            },
        },
    );

    const searchTitle = 'Find Players';
    const resultsTitle = 'Player Results';
    const searchSubtitle =
        'Fill out the fields to get a curated list of players that best match what you are looking for.';
    const resultsSubtitle = 'These results best fit your search criteria. Click on them to go to their profile!';

    return (
        <BaseRouteModal
            content={
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(showResults)(e);
                    }}
                    className="find-players-form"
                >
                    <FindPlayersPopupContent
                        hasSearched={hasSearched}
                        isSearching={isSearching}
                        results={results}
                        register={register}
                        watch={watch}
                        control={control}
                        setValue={setValue}
                    />
                </form>
            }
            subtitle={hasSearched ? resultsSubtitle : searchSubtitle}
            title={hasSearched ? resultsTitle : searchTitle}
            className={'find-players-popup'}
            onCancel={closeActiveModal}
            buttonArray={
                hasSearched
                    ? [
                          <div key="search" className="cta-container">
                              <CTACard
                                  className="player-search-engine"
                                  title="Player Search Engine"
                                  description="There are plenty of other players on our platform, search for more players and find additional recruits!"
                                  buttonLabel="Search"
                                  buttonLink="/players"
                              />
                          </div>,
                      ]
                    : [
                          <button
                              key="search"
                              disabled={formState.isSubmitting}
                              onClick={handleSubmit(showResults)}
                              className="button medium-button blue-button"
                          >
                              Search
                          </button>,
                      ]
            }
        />
    );
};
