import React from 'react';

type Props = {
    className?: string;
};

export const RightArrow: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="5" height="8" viewBox="0 0 5 8">
            <path
                d="M0.4,0.2c-0.3,0.3-0.3,0.7,0,1L3,3.8c0.1,0.1,0.1,0.3,0,0.4L0.4,6.8c-0.3,0.3-0.3,0.7,0,1c0.3,0.3,0.7,0.3,1,0
                l3.1-3.1c0.4-0.4,0.4-1,0-1.4L1.4,0.2C1.1-0.1,0.7-0.1,0.4,0.2z"
            />
        </svg>
    );
};
