export function CokeAllStarsLogo({ className }: { className?: string }) {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 432 432"
            width={432}
            height={432}
            overflow="visible"
            className={className}
        >
            <g>
                <path
                    fill="#141336"
                    d="M359.9,253.5c26-69.2,26.8-131.8,26.8-131.8s-52.6-47.4-128.2-61.5V48.7c0-4.1-2.4-8.2-5.9-10.2l-30.7-17.7
		c-1.7-1-3.8-1.5-5.9-1.5c-2.1,0-4.2,0.5-5.9,1.5l-30.7,17.7c-3.5,2-5.9,6.2-5.9,10.2v11.5C97.9,74.3,45.3,121.7,45.3,121.7
		s0.8,62.7,26.9,132.1c-2.5,20.2-5.1,40.4-7.6,60.6l57.5,28.5c23.4,28.9,54,54.2,93.9,71c39.9-16.8,70.5-42.3,94-71.2l57.5-28.4
		C365,294.1,362.4,273.8,359.9,253.5z"
                />
                <g>
                    <g>
                        <path
                            fill="#E41E2A"
                            d="M347.8,238.9l-131.8,26l-131.8-26c0,0-0.9-2.6-1.3-3.8c-16-47.3-20.2-89.4-21.3-107.2
				c18.9-14.8,78.9-56.7,153.6-56.7h1.6c74.6,0,134.7,41.9,153.6,56.7c-1.1,18-5.4,60.5-21.5,107.9
				C348.5,236.7,347.8,238.9,347.8,238.9z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M216.8,74.9c32.3,0,65.5,8,98.7,23.7c24,11.4,42,24.1,50.9,30.9c-1.3,18.8-5.8,59.6-21.2,104.8
				c0,0.1-0.1,0.4-0.1,0.4L216,252.1L86.9,234.9c0,0-0.2-0.7-0.4-1.1c-15.3-45.1-19.7-85.6-20.9-104.2c8.9-6.8,26.9-19.6,50.9-30.9
				c33.2-15.7,66.4-23.7,98.7-23.7H216.8 M216.8,67.2h-1.6c-78.5,0-141.1,45.6-157.5,58.8c0.8,15.4,4.7,60.1,21.6,110.2
				c0.8,2.2,1.5,4.4,2.3,6.6c2.3,4.6,89.6,9.3,134.5,9.3c44.8,0,131.5-4.9,134.5-9.3c0.7-2,1.4-4,2.1-6
				c17.1-50.2,20.9-95.4,21.8-110.9C357.9,112.8,295.3,67.2,216.8,67.2L216.8,67.2z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#E41E2A"
                            d="M216,395.3c-33.1-15.1-61.6-38-84.8-67.9c-2.9-3.7-5.7-7.6-8.5-11.5c31-1.1,93.3-16.4,93.3-16.4
				s62.3,15.2,93.3,16.4c-2.8,4.1-5.7,8-8.7,11.8C277.4,357.5,248.9,380.3,216,395.3z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M302,319.5c0,0-2.9,4-4.4,5.9c-22.4,28.8-49.9,50.9-81.5,65.7c-31.8-14.9-59.3-37.1-81.8-66
				c-1.4-1.8-4.2-5.6-4.2-5.6l86-23.5L302,319.5z M216,310.4c-33.5,0-66.9,0.7-100.3,2c4,6.1,8.1,11.9,12.5,17.5
				c24,30.9,53.6,54.3,87.9,69.7c34.2-15.3,63.7-38.7,87.6-69.4c4.4-5.7,8.7-11.6,12.7-17.8C282.9,311,249.5,310.4,216,310.4
				L216,310.4z"
                        />
                    </g>
                    <g>
                        <path
                            fill="#141336"
                            d="M252.6,37.4l-30.7-17.7c-1.7-1-3.8-1.5-5.9-1.5c-2.1,0-4.2,0.5-5.9,1.5l-30.7,17.7
				c-3.5,2-5.9,6.2-5.9,10.2V83c0,4.1,2.4,8.2,5.9,10.2l30.7,17.7c1.7,1,3.8,1.5,5.9,1.5c2.1,0,4.2-0.5,5.9-1.5l30.7-17.7
				c3.5-2,5.9-6.2,5.9-10.2V47.6C258.5,43.5,256.1,39.4,252.6,37.4z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M247.7,45.4l-30.2-17.5c-0.8-0.4-2-0.4-2.8,0l-30.2,17.5c-0.8,0.4-1.4,1.5-1.4,2.4v34.9
				c0,0.9,0.6,2,1.4,2.4l30.2,17.5c0.8,0.4,2,0.4,2.8,0l30.2-17.5c0.8-0.4,1.4-1.5,1.4-2.4V47.8C249.1,46.9,248.4,45.8,247.7,45.4z
				 M192.9,53.1c0-0.6,0.4-1.4,1-1.7L215,39.2c0.5-0.3,1.4-0.3,2,0l9.5,5.5c0.5,0.3,0.5,0.8,0,1.1l-32.6,18.9c-0.5,0.3-1,0.1-1-0.6
				V53.1z M239.1,77.5c0,0.6-0.4,1.4-1,1.7L217,91.3c-0.5,0.3-1.4,0.3-2,0l-9.6-5.5c-0.5-0.3-0.5-0.8,0-1.1l21.1-12.2
				c0.5-0.3,1-1.1,1-1.7v-4.4c0-0.6-0.4-0.9-1-0.6l-26.8,15.5c-0.5,0.3-1.4,0.3-2,0l-3.8-2.2c-0.5-0.3-1-1.1-1-1.7v-4.4
				c0-0.6,0.4-1.4,1-1.7l38.4-22.2c0.5-0.3,1.4-0.3,2,0l3.8,2.2c0.5,0.3,1,1.1,1,1.7V77.5z"
                        />
                    </g>
                    <image width="786" height="57" transform="matrix(0.24 0 0 0.24 121.68 120.48)"></image>
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M121.7,130.4l1.4-1.6c1.2,1.1,2.5,1.7,4.1,1.7c1.4,0,2.3-0.6,2.3-1.6v0c0-0.9-0.5-1.4-2.9-2
				c-2.8-0.7-4.3-1.5-4.3-3.9v0c0-2.2,1.9-3.8,4.4-3.8c1.9,0,3.4,0.6,4.7,1.6l-1.2,1.7c-1.2-0.9-2.3-1.3-3.5-1.3
				c-1.3,0-2.1,0.7-2.1,1.5v0c0,1,0.6,1.4,3.1,2c2.7,0.7,4.2,1.7,4.2,3.8v0c0,2.4-1.9,3.9-4.6,3.9
				C125.1,132.5,123.3,131.8,121.7,130.4z"
                        />
                        <path fill="#FFFFFF" d="M138.7,121.4h-4.1v-2.1h10.5v2.1H141v10.9h-2.3V121.4z" />
                        <path
                            fill="#FFFFFF"
                            d="M151.7,119.2h2.1l5.7,13.1h-2.4l-1.3-3.1h-6.1l-1.3,3.1H146L151.7,119.2z M155,127.1l-2.2-5.2l-2.2,5.2
				H155z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M164.8,127.2l-5.2-7.9h2.7l3.6,5.8l3.7-5.8h2.6l-5.2,7.8v5.2h-2.3V127.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M182.4,119.3h5.1c3,0,4.9,1.7,4.9,4.3v0c0,2.9-2.3,4.4-5.2,4.4h-2.6v4.2h-2.3V119.3z M187.3,126
				c1.7,0,2.8-1,2.8-2.3v0c0-1.5-1.1-2.3-2.8-2.3h-2.7v4.7H187.3z"
                        />
                        <path fill="#FFFFFF" d="M196.1,119.3h2.3v10.9h6.8v2.1h-9.1V119.3z" />
                        <path
                            fill="#FFFFFF"
                            d="M208.3,126.8v-7.5h2.3v7.4c0,2.4,1.2,3.7,3.3,3.7c2,0,3.3-1.2,3.3-3.6v-7.5h2.3v7.4
				c0,3.9-2.2,5.8-5.6,5.8C210.4,132.5,208.3,130.5,208.3,126.8z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M223.2,125.8L223.2,125.8c0-3.7,2.8-6.8,6.7-6.8c2.3,0,3.7,0.6,5,1.8l-1.4,1.7c-1-0.9-2-1.4-3.6-1.4
				c-2.4,0-4.2,2.1-4.2,4.6v0c0,2.7,1.8,4.6,4.4,4.6c1.2,0,2.3-0.4,3.1-1V127h-3.3v-2h5.5v5.5c-1.3,1.1-3.1,2-5.4,2
				C225.9,132.5,223.2,129.6,223.2,125.8z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M239.1,125.8L239.1,125.8c0-3.7,2.8-6.8,6.7-6.8c2.3,0,3.7,0.6,5,1.8l-1.4,1.7c-1-0.9-2-1.4-3.6-1.4
				c-2.4,0-4.2,2.1-4.2,4.6v0c0,2.7,1.8,4.6,4.4,4.6c1.2,0,2.3-0.4,3.1-1V127h-3.3v-2h5.5v5.5c-1.3,1.1-3.1,2-5.4,2
				C241.7,132.5,239.1,129.6,239.1,125.8z"
                        />
                        <path fill="#FFFFFF" d="M255.5,119.3h9.6v2h-7.3v3.4h6.5v2h-6.5v3.5h7.4v2h-9.7V119.3z" />
                        <path
                            fill="#FFFFFF"
                            d="M269.2,119.3h4.8c4.1,0,6.9,2.8,6.9,6.5v0c0,3.7-2.8,6.5-6.9,6.5h-4.8V119.3z M274,130.2
				c2.7,0,4.5-1.8,4.5-4.4v0c0-2.5-1.8-4.4-4.5-4.4h-2.6v8.8H274z"
                        />
                        <path fill="#FFFFFF" d="M291.9,119.3h2.3v13h-2.3V119.3z" />
                        <path fill="#FFFFFF" d="M299,119.3h2.1l7,9v-9h2.2v13h-1.9l-7.2-9.2v9.2H299V119.3z" />
                    </g>
                    <g>
                        <polygon fill="#141336" points="64.7,313.2 123.1,342.2 107.6,310.1 			" />
                        <polygon fill="#141336" points="367.5,313.2 308.7,342.2 324.2,310.1 			" />
                        <g>
                            <polygon fill="#FFFFFF" points="99.1,312.5 87.3,312.9 112.9,328.5 				" />
                            <polygon fill="#FFFFFF" points="332.9,312.5 344.7,312.9 319.1,328.5 				" />
                        </g>
                    </g>
                    <image width="510" height="212" transform="matrix(0.24 0 0 0.24 154.8 327.84)"></image>
                    <g>
                        <polygon
                            fill="#FFFFFF"
                            points="216,361.2 218,367.2 224.3,367.2 219.2,371 221.1,377 216,373.3 210.9,377 212.8,371 
				207.7,367.2 214,367.2 			"
                        />
                        <g>
                            <polygon
                                fill="#FFFFFF"
                                points="163.3,326.4 165.3,332.4 171.6,332.4 166.5,336.1 168.4,342.1 163.3,338.4 158.2,342.1 
					160.1,336.1 155,332.4 161.3,332.4 				"
                            />
                            <polygon
                                fill="#FFFFFF"
                                points="268.7,326.4 270.7,332.4 277,332.4 271.9,336.1 273.8,342.1 268.7,338.4 263.6,342.1 
					265.5,336.1 260.4,332.4 266.7,332.4 				"
                            />
                        </g>
                        <path
                            fill="#FFFFFF"
                            d="M188,344.2l5-4.3c2-1.7,2.7-2.6,2.7-3.9c0-1.4-1-2.3-2.4-2.3s-2.3,0.7-3.4,2.2l-1.8-1.4
				c1.4-2,2.8-3.1,5.4-3.1c2.9,0,4.8,1.8,4.8,4.4v0c0,2.3-1.2,3.5-3.8,5.7l-3,2.5h7v2.3H188V344.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M202.4,339L202.4,339c0-4.2,2.5-7.6,6.4-7.6c3.8,0,6.3,3.3,6.3,7.5v0c0,4.2-2.5,7.6-6.4,7.6
				S202.4,343.2,202.4,339z M212.5,339L212.5,339c0-2.9-1.5-5.3-3.7-5.3c-2.3,0-3.7,2.3-3.7,5.2v0c0,2.9,1.5,5.2,3.7,5.2
				C211.1,344.2,212.5,341.9,212.5,339z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M218.6,344.2l5-4.3c2-1.7,2.7-2.6,2.7-3.9c0-1.4-1-2.3-2.4-2.3s-2.3,0.7-3.4,2.2l-1.8-1.4
				c1.4-2,2.8-3.1,5.4-3.1c2.9,0,4.8,1.8,4.8,4.4v0c0,2.3-1.2,3.5-3.8,5.7l-3,2.5h7v2.3h-10.6V344.2z"
                        />
                        <path
                            fill="#FFFFFF"
                            d="M232.7,343.9l1.8-1.7c1,1.2,2.2,1.9,3.8,1.9c1.4,0,2.5-0.9,2.5-2.2v0c0-1.4-1.3-2.3-3.4-2.3h-1.2
				l-0.4-1.6l3.9-4.2h-6.3v-2.2h9.7v1.9l-4.1,4.2c2.2,0.3,4.3,1.4,4.3,4.1v0c0,2.7-2.1,4.6-5.1,4.6
				C235.7,346.5,233.9,345.4,232.7,343.9z"
                        />
                    </g>
                    <image width="1051" height="332" transform="matrix(0.24 0 0 0.24 91.68 150)"></image>
                    <g>
                        <path
                            fill="#FFFFFF"
                            d="M200.7,226.2c-6-4.3-13.3-6.2-20-5.9c-6.7,0.2-9.8,5.2-9.8,5.2c-3-5.3-9-8.6-18.3-8.6
				c-15,0.1-29.9,6.9-41.7,6.9c-11.3-0.1-19.5-7.2-19.2-19.8c0.6-22.1,19.4-42,33.4-50.9c8.1-5.1,14.8-6.6,19.2-6.1
				c3.2,0.4,7,4.1,4.6,9.6c-3.6,8-8.6,7.3-8.4,3.5c0.1-2.5,1.8-4,3-4.9c0.8-0.5,2.2-0.7,2.9-0.7c0.7-0.6,1.3-5-4.5-3.6
				c-5.8,1.4-12.7,7-19.4,14.3c-6.6,7.3-17,21-19.6,35.5c-1.2,6.7-0.5,19,15.4,18.9c13.5,0,33.5-8.8,51.8-8.6c7.1,0.1,13.3,2,18.1,5
				C193,219,198.7,223.3,200.7,226.2 M205.2,186.1l2.8,0c0,0-14,20-21.8,19c-4.4-0.5-3-5.9-3-5.9s-6.2,7.2-11.8,5.7
				c-5.1-1.3-1.8-9.8-1.8-9.8c-0.6,0.2-11.7,12.7-19.5,9.6c-8.1-3.2-3.9-13.6-2.3-16.7c1.3-2.6,2.8-5.2,2.8-5.2s-1.3,0.4-2,0.5
				c-0.8,0.2-2,0.4-2,0.4s-3.9,6.2-6.3,9.4c-2.4,3.1-12.4,13.8-18.3,11.6c-5.9-2.2-4.4-10.4-1-16.9c5.4-10.6,18.2-23.6,25.9-21.6
				c7.7,2,1.3,14.6,1.3,14.6s1.5,0.2,5.2-2.3c5.4-3.6,12.4-13.3,20-12.1c3.6,0.5,6.9,4.2,2.3,11.2c-1.5,2.2-3.9,4.2-5.8,2.2
				c-1.2-1.2-0.1-3.5,1.1-5c1.7-2.2,4.1-1.9,4.1-1.9s1.3-2.9-1.9-2.8c-2.6,0-9.2,6-12.9,12.1c-3.4,5.6-8.6,15.1-3.5,17.2
				c4.7,1.9,13.8-8.7,18-14.3c4.2-5.6,13.8-18.2,21.7-18.7c2.7-0.2,4.4,2.3,4.4,2.3l0.9-1.5l8,0c0,0-15.1,25-16.3,26.9
				c-0.9,1.5-2.2,4.8,1.2,3C198.1,195.1,205.2,186.1,205.2,186.1 M143.5,183.2c-0.1,0-2.1-0.6-4.1-1.9c-1.9-1.3-2.8-3.1-2.9-3.3
				c-0.1-0.1-0.1,0-0.2,0.1c-0.1,0.1-0.5,0.7-3.4,4.8c-2.9,4.2-7.1,12.7-3.2,14.1c2.5,1,6.8-3.5,9.1-6.4c2.1-2.7,4.7-6.9,4.8-7.1
				C143.7,183.3,143.6,183.3,143.5,183.2 M146.7,169.9c-2.1-1.6-7.4,2.1-7.5,5.8c-0.1,3.9,4.8,5,5.4,5c0,0,0.5,0.2,0.8-0.3
				c0.1-0.3,0.8-1.5,1.5-3.4C147.7,175.1,148.7,171.4,146.7,169.9 M187.2,191c3.2-4.4,12.2-19.8,12.2-19.8s-0.1-1.1-1.2-1.1
				c-1.2-0.1-3.3,0.3-7.4,5.4c-4.1,5.1-7.3,9.1-9.6,13.3c-2.5,4.6-3.7,7.8-2.2,8.7c0.4,0.2,1,0.3,1.5,0.2c0.9-0.2,2.2-1.2,3.9-3.1
				C185.2,193.6,186.1,192.4,187.2,191 M223,167.3l-7.6,0l-4.3,6.9l7.5,0L223,167.3z M334.6,155.4c-5.6,3.6-13.4,6.8-20.8,6
				c-1.9,1.9-3.9,4.2-6,6.6c12.9,2.6,24.2-5.3,30.3-12.1c3.8-4.2,5.6-8,5.6-8S340.2,151.9,334.6,155.4 M312.2,213.9
				c-1.1,1.7-2.3,5,1.2,3c4.3-2.5,10.5-11.7,10.5-11.7l2.8,0c0,0-3.7,5.8-8.5,11c-4.4,4.8-9.7,9.2-13.5,8.7c-4.4-0.5-3-6.3-3-6.3
				s-6.3,6.6-10.6,6.1c-5.8-0.7-4-7.9-4-7.9s-6.7,8.1-11.2,7.8c-7.1-0.4-5.3-9.1-3.3-15.1c1.1-3.2,2.1-5.8,2.1-5.8s-0.7,0.2-2.2,0.4
				c-0.8,0.1-2.2,0.2-2.2,0.2s-3,5.2-5.3,8.3c-2.4,3.1-12.4,13.8-18.3,11.6c-5.4-2-3.6-10.4-0.4-17c4.7-9.6,17.7-23.5,25.2-21.6
				c7.8,1.9,0.1,16.1,0.1,16.1s0,0.1,0.1,0.1c0.2,0.1,0.6,0.1,1.5-0.1c1.5-0.3,2.9-0.8,2.9-0.8s7.9-16.3,16.6-27
				c8.7-10.7,26-29,32.8-24.5c1.6,1.1,0.9,4.9-1.4,9.5c-1,0.3-2,0.6-3.1,0.8c1.5-2.7,2.5-5,2.8-6.6c1-5.5-11.9,6.1-21.2,18.6
				c-9.3,12.5-14.9,23.9-14.9,23.9s1.9-1,4.8-3.4c3-2.4,7.2-6.2,11.8-10.9c3.1-3.1,6.7-7.1,10-11.5c1,0,2-0.1,2.9-0.3
				c-3.8,5-7.7,9.6-11.1,13c-3.7,3.7-7.7,7.7-11.2,10.6c-5.5,4.6-9.8,7.2-9.8,7.2s-8.2,16.2-3.8,17.3c2.6,0.6,7.7-7.2,7.7-7.2
				s6.7-10.1,10.7-14.7c5.5-6.2,10.2-9.8,14.9-9.8c2.7,0,4.3,2.9,4.3,2.9l1.3-2l8,0C328.4,186.9,313.4,212,312.2,213.9 M267.6,203.8
				c-0.2-0.1-1.9-0.9-3.6-2.3c-1.8-1.5-2.7-3.2-2.7-3.3c-0.1-0.1-0.2,0-0.2,0.1c-0.1,0.1-0.4,0.4-3.3,4.5
				c-2.9,4.1-7.2,12.7-3.3,14.2c2.5,0.9,6.7-3.4,9-6.4c2.1-2.7,4.1-6.2,4.3-6.4C267.8,203.9,267.7,203.8,267.6,203.8 M271.6,196.8
				c0.7-1.9,1.7-5.7-0.2-7.2c-2.1-1.6-7.4,2.1-7.5,5.8c0,3.9,3.7,5.6,4.6,5.9c0.5,0.1,0.7,0.1,0.9-0.3
				C269.7,200.8,270.9,198.7,271.6,196.8 M317.3,191.7c0.2-0.3,0.2-0.5,0.2-0.5s-0.1-1.1-1.2-1.1c-1.2-0.1-2.9,0.2-7.3,5
				c-4.4,4.8-7.1,9-9.4,13.2c-2.5,4.6-3.6,8-2.1,8.9c0.4,0.2,1,0.3,1.5,0.2c1-0.2,2.1-1.1,3.8-3.1c0.8-0.9,1.7-2.2,2.7-3.6
				C308.5,206.6,315.8,194.2,317.3,191.7 M334.6,155.4c-5.6,3.6-13.4,6.8-20.8,6c-1.9,1.9-3.9,4.2-6,6.6
				c12.9,2.6,24.2-5.3,30.3-12.1c3.8-4.2,5.6-8,5.6-8S340.2,151.9,334.6,155.4 M298.3,164.6c-6.1-3.2-10.5-7-15.1-9.4
				c-4.4-2.3-7.3-3.2-10.9-2.8c-0.6,0.1-1.5,0.4-1.5,0.4s1.8,6.3-5.6,16.8c-7.5,10.7-14.2,10.6-16.1,7.6c-2-3.1,0.6-9.4,5-15.1
				c4.6-5.9,12.5-10.7,12.5-10.7s-2.5-1.6-8.8,1.9c-6.1,3.4-16.6,11.5-26.4,25.3c-9.8,13.8-11.7,21.4-13.2,27.3
				c-1.4,6-0.2,12.4,6.4,12.4c6.7,0,14.4-10,16.6-13.6c4.1-6.5,6.8-16.6,1.3-15.2c-2.7,0.7-4.9,3.7-6.3,6.6c-1.4,3-2,5.8-2,5.8
				s-2.3,0.8-5.4,3.1c-3.1,2.3-5.1,4.5-5.1,4.5s0-4.8,3.9-12.5c3.9-7.7,10.9-11.2,14.8-11.5c3.6-0.3,8.2,2.8,4.2,13.9
				c-4,11.2-15.7,24.7-27.9,24.8c-11.3,0.1-18.6-14.3-2.8-39.3c17.5-27.6,37.9-36.1,44.5-37c6.6-0.9,8.7,2,8.7,2s5.5-2.5,12.6-2
				c8.1,0.6,14.3,6.3,22.5,10.4C302.3,160.5,300.2,162.6,298.3,164.6 M268.4,153.6c0,0-4.7,1.9-10.3,7.9c-5.5,6-8.7,12.8-6.4,14.6
				c0.9,0.7,3,0.8,6.8-2.6c3-2.7,6-6.1,8.1-11.1C269.1,156.4,268.4,153.6,268.4,153.6"
                        />
                        <g>
                            <path
                                fill="#FFFFFF"
                                d="M326,218c-0.8-0.8-1.7-1.1-2.7-1.1c-1.1,0-2,0.4-2.7,1.1c-0.8,0.7-1.1,1.7-1.1,2.7c0,1.1,0.4,2,1.1,2.7
					c0.8,0.8,1.7,1.1,2.7,1.1c1.1,0,2-0.4,2.7-1.1c0.8-0.8,1.1-1.7,1.1-2.7C327.2,219.6,326.8,218.7,326,218z M325.7,223.1
					c-0.6,0.6-1.4,1-2.3,1c-0.9,0-1.7-0.3-2.3-1c-0.6-0.7-1-1.5-1-2.4c0-0.9,0.3-1.7,1-2.3c0.7-0.7,1.4-1,2.3-1c0.9,0,1.7,0.3,2.3,1
					c0.7,0.6,1,1.4,1,2.3C326.6,221.6,326.3,222.4,325.7,223.1z"
                            />
                            <path
                                fill="#FFFFFF"
                                d="M325,222.5c0-0.1,0-0.2,0-0.3v-0.3c0-0.2-0.1-0.4-0.2-0.7c-0.1-0.2-0.4-0.4-0.7-0.4
					c0.3,0,0.5-0.1,0.6-0.2c0.3-0.2,0.4-0.5,0.4-0.8c0-0.5-0.2-0.9-0.7-1.1c-0.2-0.1-0.6-0.1-1.2-0.1h-1.5v4.2h0.7v-1.7h0.6
					c0.4,0,0.7,0,0.8,0.2c0.3,0.1,0.4,0.5,0.4,0.9v0.4l0,0.1c0,0,0,0,0,0v0h0.7l0,0C325,222.7,325,222.7,325,222.5z M323.9,220.5
					c-0.2,0.1-0.4,0.1-0.7,0.1h-0.7v-1.5h0.6c0.4,0,0.7,0.1,0.9,0.2c0.2,0.1,0.3,0.3,0.3,0.6C324.3,220.2,324.2,220.4,323.9,220.5z"
                            />
                        </g>
                    </g>
                    <g>
                        <image width="1263" height="296" transform="matrix(0.24 0 0 0.24 64.56 249.12)"></image>
                        <path
                            fill="#141336"
                            d="M367.5,313.2c-101,0-201.9,0-302.9,0c2.9-23.5,5.9-46.9,8.8-70.4c95.1,0,190.1,0,285.2,0
				C361.6,266.3,364.6,289.8,367.5,313.2z"
                        />
                    </g>
                </g>
            </g>
            <path
                fill="#0D0D23"
                d="M95.1,293c-1.2,0-1.8,0-3.1,0c-0.8,4.8-1.2,7.2-2,12c-3.8,0-5.6,0-9.4,0c3.9-16.1,7.8-32.3,11.6-48.4
	c4.1,0,6.2,0,10.4,0c0.4,16.2,0.8,32.3,1.4,48.4c-3.7,0-5.6,0-9.3,0C94.8,300.2,94.9,297.8,95.1,293z M95.3,286.5
	c0.2-6.2,0.5-12.3,0.7-18.5c-1,6.2-2,12.3-2.9,18.5C94,286.5,94.4,286.5,95.3,286.5z M131.3,297.7c-0.2,2.9-0.3,4.4-0.5,7.3
	c-6,0-11.9,0-17.9,0c1.5-16.1,2.9-32.3,4.4-48.4c3.6,0,5.4,0,8.9,0c-1.1,13.7-2.3,27.5-3.4,41.2
	C126.2,297.7,127.9,297.7,131.3,297.7z M158.5,297.7c-0.1,2.9-0.2,4.4-0.4,7.3c-6,0-11.9,0-17.9,0c1.1-16.1,2.1-32.3,3.2-48.4
	c3.6,0,5.4,0,8.9,0c-0.8,13.7-1.6,27.5-2.4,41.2C153.4,297.7,155.1,297.7,158.5,297.7z M220.7,270.8c0-2.8,0-4.2,0-7
	c-1.2,0-1.9,0-3.1,0c0,5.1,0,7.7,0,12.8c3,0,4.5,0,7.5,0c1.9,1.6,2.9,2.4,4.8,4c0.1,6.8,0.2,13.6,0.3,20.3c-1.9,1.6-2.9,2.4-4.8,4
	c-4.9,0-7.3,0-12.2,0c-1.9-1.6-2.9-2.4-4.8-4c0-4.1,0-6.2,0.1-10.3c3.7,0,5.5,0,9.2,0c0,2.8,0,4.2,0,7c1.3,0,1.9,0,3.2,0
	c0-5.5,0-8.3-0.1-13.8c-3,0-4.5,0-7.6,0c-1.9-1.6-2.9-2.4-4.7-4c0-6.5,0.1-12.9,0.1-19.4c1.9-1.6,2.8-2.4,4.7-4c4.7,0,7,0,11.7,0
	c1.9,1.6,2.8,2.4,4.7,4c0.1,4.1,0.1,6.2,0.1,10.3C226.1,270.8,224.3,270.8,220.7,270.8z M244.1,263.8c-2.2,0-3.4,0-5.6,0
	c-0.1-2.9-0.1-4.4-0.1-7.3c6.7,0,13.4,0,20.1,0c0.1,2.9,0.2,4.4,0.3,7.3c-2.2,0-3.4,0-5.6,0c0.5,13.7,0.9,27.5,1.4,41.2
	c-3.7,0-5.6,0-9.3,0C244.8,291.2,244.4,277.5,244.1,263.8z M277.7,293c-1.2,0-1.8,0-3.1,0c0,4.8,0,7.2,0,12c-3.8,0-5.7,0-9.4,0
	c1.3-16.1,2.6-32.3,3.8-48.4c4.1,0,6.2,0,10.4,0c3,16.2,6.1,32.3,9.2,48.4c-3.7,0-5.6,0-9.3,0C278.5,300.2,278.2,297.8,277.7,293z
	 M276.9,286.5c-0.8-6.2-1.5-12.3-2.3-18.5c0,6.2,0,12.3,0,18.5C275.5,286.5,276,286.5,276.9,286.5z M311.6,305
	c-2.1-6.5-4.2-13.1-6.2-19.6c-0.1,0-0.2,0-0.3,0c0.5,6.5,1,13.1,1.6,19.6c-3.7,0-5.6,0-9.3,0c-1.1-16.1-2.3-32.3-3.4-48.4
	c5.2,0,10.4,0,15.6,0c2,1.6,3,2.4,5,4c0.6,7,1.2,13.9,1.9,20.9c-1,0.9-1.6,1.4-2.6,2.3c2.5,7.1,5,14.2,7.5,21.3
	C317.5,305,315.5,305,311.6,305z M304.6,278.1c0.8,0,1.3,0,2.1,0c-0.5-5.7-0.7-8.6-1.2-14.3c-0.8,0-1.2,0-2.1,0
	C303.9,269.5,304.1,272.3,304.6,278.1z M338,270.8c-0.3-2.8-0.5-4.2-0.8-7c-1.2,0-1.9,0-3.1,0c0.5,5.1,0.8,7.7,1.4,12.8
	c3,0,4.5,0,7.5,0c2.1,1.6,3.1,2.4,5.2,4c0.8,6.8,1.6,13.6,2.4,20.3c-1.7,1.6-2.6,2.4-4.4,4c-4.9,0-7.3,0-12.2,0
	c-2.1-1.6-3.2-2.4-5.2-4c-0.4-4.1-0.6-6.2-1-10.3c3.7,0,5.5,0,9.2,0c0.3,2.8,0.5,4.2,0.8,7c1.3,0,1.9,0,3.2,0
	c-0.6-5.5-0.9-8.3-1.5-13.8c-3,0-4.5,0-7.6,0c-2.1-1.6-3.1-2.4-5.2-4c-0.6-6.5-1.3-12.9-1.9-19.4c1.7-1.6,2.6-2.4,4.3-4
	c4.7,0,7,0,11.7,0c2,1.6,3.1,2.4,5.1,4c0.5,4.1,0.7,6.2,1.2,10.3C343.4,270.8,341.6,270.8,338,270.8z M196.9,283.5
	c-0.2-1.7-0.7-3.8-1.3-5.7c-0.6-1.9-1.2-3.5-1.5-4.2c-0.1-0.3-0.4-0.6-0.7-0.8c-2-1.1-3.6-1.7-4.3-1.4c-0.6,0.2-1,0.7-1.4,1.2
	c-0.6,0.7-1.2,1.4-2.4,1.4c-1,0.1-3.9,0.1-4.9,0c-1.2-0.1-1.7-0.8-2.3-1.4c-0.4-0.5-0.7-0.9-1.3-1.2c-0.7-0.3-2.4,0.2-4.4,1.4
	c-0.3,0.2-0.6,0.5-0.7,0.8c-0.7,1.4-2.9,6.4-3.4,9.9c-0.3,2.3-0.1,4.1,0.7,5.1c0.4,0.4,0.8,0.7,1.4,0.7c0,0,0,0,0,0
	c0.7,0,2.3-1.5,4.3-4.1c0.6-0.8,1.6-1.3,2.6-1.3c2.1,0,3.2,0,5.3-0.1c2.1,0,3.2,0,5.3,0.1c1,0,1.9,0.5,2.5,1.3
	c1.8,2.6,3.3,4.1,4,4.1c0,0,0,0,0,0c0.5,0,1-0.2,1.4-0.7C196.7,287.6,197.1,285.8,196.9,283.5z"
            />
            <path
                fill="#FFFFFF"
                d="M95.1,290.1c-1.2,0-1.8,0-3.1,0c-0.8,4.8-1.2,7.2-2,12c-3.8,0-5.6,0-9.4,0c3.9-16.1,7.8-32.3,11.6-48.4
	c4.1,0,6.2,0,10.4,0c0.4,16.2,0.8,32.3,1.4,48.4c-3.7,0-5.6,0-9.3,0C94.8,297.3,94.9,294.9,95.1,290.1z M95.3,283.7
	c0.2-6.2,0.5-12.3,0.7-18.5c-1,6.2-2,12.3-2.9,18.5C94,283.7,94.4,283.7,95.3,283.7z M131.3,294.9c-0.2,2.9-0.3,4.4-0.5,7.3
	c-6,0-11.9,0-17.9,0c1.5-16.1,2.9-32.3,4.4-48.4c3.6,0,5.4,0,8.9,0c-1.1,13.7-2.3,27.5-3.4,41.2
	C126.2,294.9,127.9,294.9,131.3,294.9z M158.5,294.9c-0.1,2.9-0.2,4.4-0.4,7.3c-6,0-11.9,0-17.9,0c1.1-16.1,2.1-32.3,3.2-48.4
	c3.6,0,5.4,0,8.9,0c-0.8,13.7-1.6,27.5-2.4,41.2C153.4,294.9,155.1,294.9,158.5,294.9z M220.7,268c0-2.8,0-4.2,0-7
	c-1.2,0-1.9,0-3.1,0c0,5.1,0,7.7,0,12.8c3,0,4.5,0,7.5,0c1.9,1.6,2.9,2.4,4.8,4c0.1,6.8,0.2,13.6,0.3,20.3c-1.9,1.6-2.9,2.4-4.8,4
	c-4.9,0-7.3,0-12.2,0c-1.9-1.6-2.9-2.4-4.8-4c0-4.1,0-6.2,0.1-10.3c3.7,0,5.5,0,9.2,0c0,2.8,0,4.2,0,7c1.3,0,1.9,0,3.2,0
	c0-5.5,0-8.3-0.1-13.8c-3,0-4.5,0-7.6,0c-1.9-1.6-2.9-2.4-4.7-4c0-6.5,0.1-12.9,0.1-19.4c1.9-1.6,2.8-2.4,4.7-4c4.7,0,7,0,11.7,0
	c1.9,1.6,2.8,2.4,4.7,4c0.1,4.1,0.1,6.2,0.1,10.3C226.1,268,224.3,268,220.7,268z M244.1,260.9c-2.2,0-3.4,0-5.6,0
	c-0.1-2.9-0.1-4.4-0.1-7.3c6.7,0,13.4,0,20.1,0c0.1,2.9,0.2,4.4,0.3,7.3c-2.2,0-3.4,0-5.6,0c0.5,13.7,0.9,27.5,1.4,41.2
	c-3.7,0-5.6,0-9.3,0C244.8,288.4,244.4,274.7,244.1,260.9z M277.7,290.1c-1.2,0-1.8,0-3.1,0c0,4.8,0,7.2,0,12c-3.8,0-5.7,0-9.4,0
	c1.3-16.1,2.6-32.3,3.8-48.4c4.1,0,6.2,0,10.4,0c3,16.2,6.1,32.3,9.2,48.4c-3.7,0-5.6,0-9.3,0C278.5,297.3,278.2,294.9,277.7,290.1z
	 M276.9,283.7c-0.8-6.2-1.5-12.3-2.3-18.5c0,6.2,0,12.3,0,18.5C275.5,283.7,276,283.7,276.9,283.7z M311.6,302.1
	c-2.1-6.5-4.2-13.1-6.2-19.6c-0.1,0-0.2,0-0.3,0c0.5,6.5,1,13.1,1.6,19.6c-3.7,0-5.6,0-9.3,0c-1.1-16.1-2.3-32.3-3.4-48.4
	c5.2,0,10.4,0,15.6,0c2,1.6,3,2.4,5,4c0.6,7,1.2,13.9,1.9,20.9c-1,0.9-1.6,1.4-2.6,2.3c2.5,7.1,5,14.2,7.5,21.3
	C317.5,302.1,315.5,302.1,311.6,302.1z M304.6,275.2c0.8,0,1.3,0,2.1,0c-0.5-5.7-0.7-8.6-1.2-14.3c-0.8,0-1.2,0-2.1,0
	C303.9,266.7,304.1,269.5,304.6,275.2z M338,268c-0.3-2.8-0.5-4.2-0.8-7c-1.2,0-1.9,0-3.1,0c0.5,5.1,0.8,7.7,1.4,12.8
	c3,0,4.5,0,7.5,0c2.1,1.6,3.1,2.4,5.2,4c0.8,6.8,1.6,13.6,2.4,20.3c-1.7,1.6-2.6,2.4-4.4,4c-4.9,0-7.3,0-12.2,0
	c-2.1-1.6-3.2-2.4-5.2-4c-0.4-4.1-0.6-6.2-1-10.3c3.7,0,5.5,0,9.2,0c0.3,2.8,0.5,4.2,0.8,7c1.3,0,1.9,0,3.2,0
	c-0.6-5.5-0.9-8.3-1.5-13.8c-3,0-4.5,0-7.6,0c-2.1-1.6-3.1-2.4-5.2-4c-0.6-6.5-1.3-12.9-1.9-19.4c1.7-1.6,2.6-2.4,4.3-4
	c4.7,0,7,0,11.7,0c2,1.6,3.1,2.4,5.1,4c0.5,4.1,0.7,6.2,1.2,10.3C343.4,268,341.6,268,338,268z M196.9,280.7
	c-0.2-1.7-0.7-3.8-1.3-5.7c-0.6-1.9-1.2-3.5-1.5-4.2c-0.1-0.3-0.4-0.6-0.7-0.8c-2-1.1-3.6-1.7-4.3-1.4c-0.6,0.2-1,0.7-1.4,1.2
	c-0.6,0.7-1.2,1.4-2.4,1.4c-1,0.1-3.9,0.1-4.9,0c-1.2-0.1-1.7-0.8-2.3-1.4c-0.4-0.5-0.7-0.9-1.3-1.2c-0.7-0.3-2.4,0.2-4.4,1.4
	c-0.3,0.2-0.6,0.5-0.7,0.8c-0.7,1.4-2.9,6.4-3.4,9.9c-0.3,2.3-0.1,4.1,0.7,5.1c0.4,0.4,0.8,0.7,1.4,0.7c0,0,0,0,0,0
	c0.7,0,2.3-1.5,4.3-4.1c0.6-0.8,1.6-1.3,2.6-1.3c2.1,0,3.2,0,5.3-0.1c2.1,0,3.2,0,5.3,0.1c1,0,1.9,0.5,2.5,1.3
	c1.8,2.6,3.3,4.1,4,4.1c0,0,0,0,0,0c0.5,0,1-0.2,1.4-0.7C196.7,284.8,197.1,283,196.9,280.7z"
            />
        </svg>
    );
}
