import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { useState } from 'react';
import { RightArrow } from '../../svg/RightArrow';
import { dropdownLink } from './NavigationBar';

type Props = {
    navItem: string;
    navItemLink: string;
    dropDownLinks: dropdownLink[];
};

export const NavDropdown: React.FC<Props> = ({ navItem, navItemLink, dropDownLinks }) => {
    const [dropdownOpen, setDropdownOpen] = useState<boolean>(false);
    const router = useRouter();

    return (
        <div className={`nav-dropdown${dropdownOpen ? ' active' : ''}`} onClick={() => setDropdownOpen(!dropdownOpen)}>
            <>
                <div className="nav-item">
                    <Link href={`${navItemLink}`}>
                        <a className={`nav-dropdown-link${router.pathname === `${navItemLink}` ? ' active' : ''}`}>
                            {navItem}
                        </a>
                    </Link>
                    <RightArrow className={`arrow${router.pathname === `${navItemLink}` ? ' active' : ''}`} />
                </div>
                {dropdownOpen && (
                    <div className="links">
                        {dropDownLinks?.map((item, idx) => (
                            <Link href={`${item?.path}`} key={idx}>
                                <a className={`link`}>{item?.name}</a>
                            </Link>
                        ))}
                    </div>
                )}
            </>
        </div>
    );
};
