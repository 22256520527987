import React, { useState } from 'react';

type Props = {
    props: GenericToggleSwitchProps<any>;
};

export type GenericToggleSwitchProps<Type> = {
    options: { label: string; value: Type }[];
    currentValue: Type;
    onToggle: (value: Type) => void;
};

export const GenericToggleSwitch: React.FC<Props> = ({ props }) => {
    const [value, setValue] = useState<any>(props.currentValue);

    const changeValue = (value: any) => {
        setValue(value);
        props.onToggle(value);
    };

    return (
        <div className="generic-toggle-switch">
            {props.options.map((option, index) => (
                <div
                    key={`toggle-option-${option.value}-${index}`}
                    onClick={() => changeValue(option.value)}
                    className={`option${value === option.value ? ' active' : ''}`}
                >
                    {option.label}
                </div>
            ))}
        </div>
    );
};
