import React from 'react';

type Props = {
    className?: string;
};

export const CSGOIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M17.5,3c-0.3,0-0.6,0-0.9,0c-0.4,0.1-0.8,0-1.1-0.1c0-0.1,0.1-0.3,0.1-0.3c-0.1,0-0.1,0-0.2,0c-0.1-0.2,0-0.5,0-0.7
                c0,0-0.1,0-0.1,0c0,0.3,0,0.5,0,0.8C15,2.6,15,2.5,14.8,2.5l-3.7,0c-0.2,0-0.3-0.1-0.5-0.2c0-0.1,0-0.2-0.1-0.4
                c-0.1,0.2-0.1,0.3-0.2,0.5c-0.3,0.1-0.6,0.2-0.9,0.2c0-0.2-0.1-0.3-0.1-0.5C9.7,2.1,9.8,2.1,10,1.9c0.2-0.4,0-0.9-0.1-1.4
                C9.5,0.3,9.2,0.1,8.8,0H8.3l0,0C7.9,0.1,7.6,0.3,7.4,0.6C7.1,1.1,7,1.8,7.2,2.3c0,0-0.1,0.1-0.1,0.1C6.8,2.1,6.3,2.3,6,2.5
                C5.5,2.9,5.3,3.4,5.1,4C5,4.5,5.1,5,5.1,5.5C5,5.6,4.8,5.6,4.6,5.6c-0.1,0-0.2,0-0.2,0.1c-0.1,0.4,0,0.8-0.1,1.2
                C4.2,7.1,4.2,7.4,4.2,7.7c0.2,0,0.4,0.1,0.7,0.1C4.7,7.9,4.5,7.9,4.4,8.1C4.3,8.7,4.5,9.3,4.5,9.9c-0.1,0.1-0.2,0.2-0.3,0.3
                c-0.1,0.2,0,0.4,0,0.6c0,0.2,0,0.4,0.1,0.6c0.1,0.2,0,0.3,0,0.5c-0.1,0.4-0.2,0.8-0.2,1.3c0,0.2,0.1,0.5,0,0.7
                c-0.2,0.5-0.6,1-0.8,1.5c-0.1,0.3,0,0.5-0.2,0.8C3,16.4,2.8,16.7,2.8,17c0,0.2,0.2,0.4,0.1,0.6c-0.1,0.4-0.2,0.9-0.3,1.3
                c-0.1,0.3-0.1,0.6,0,0.9C2.7,19.9,2.8,20,3,20h0.5c0.2,0,0.3,0,0.5-0.1c0-0.2,0-0.5-0.1-0.7c-0.1-0.5,0-1.1,0.3-1.6
                c0.1-0.1,0.3-0.2,0.3-0.3c0.1-0.2,0.1-0.4,0.2-0.7c0.1-0.3,0.3-0.6,0.4-0.9c0-0.2,0-0.4,0-0.5c0-0.2,0.1-0.4,0.3-0.5
                c0.2-0.2,0.3-0.5,0.5-0.6C6,13.9,6,13.7,6.1,13.6c0-0.2,0.1-0.4,0.2-0.6c0.1-0.3,0.2-0.6,0.4-0.8c0.1-0.1,0.2-0.3,0.3-0.5
                c0.1,0.2,0.2,0.4,0.3,0.5c0.2,0.1,0.4,0,0.5,0.2C8,12.7,8,12.9,8.3,13.3c0.1,0.3,0.4,0.2,0.7,0.2c0.1,0.2,0.3,0.2,0.2,0.5
                c0,0.3-0.2,0.4-0.2,0.7c0,0.5,0.1,1.1,0.2,1.6c0,0.2,0.2,0.5,0.2,0.7c0,0.3,0,0.8-0.1,1C9.1,18.4,9,18.8,9,19.2
                c0.3,0.2,0.7,0,1.1,0.1c0.4,0.1,0.8,0.2,1.2,0.2c0.2,0,0.5,0,0.7-0.1c0-0.1,0-0.3,0-0.4c-0.2-0.2-0.5-0.2-0.8-0.4
                c-0.2-0.2-0.4-0.4-0.5-0.6c-0.1-0.2,0-0.4,0-0.6c0-0.3,0-0.6,0.1-0.9c0.1-0.2,0.1-0.5,0-0.7c-0.1-0.3,0-0.6,0.1-0.9
                c0-0.4,0.1-0.8,0.1-1.2c0-0.2-0.1-0.4-0.1-0.7c0-0.2-0.2-0.3-0.3-0.5c-0.3-0.9-0.6-1.7-1.3-2.4c-0.3-0.2-0.4-0.7-0.2-1
                c0.1,0,0.3,0.1,0.4,0.1c0.2-0.2,0.3-0.4,0.3-0.7c0-0.3,0-0.5,0.1-0.8c0-0.3,0.1-0.6,0-0.9c0-0.2-0.2-0.3-0.4-0.4
                c0-0.1,0.1-0.3,0.1-0.4c0.5,0.2,1,0.6,1.5,0.7c0.2,0,0.3,0.1,0.5,0c0.2-0.1,0.4-0.3,0.5-0.4c0.3-0.3,0.3-0.7,0.4-1.1
                c0.1,0,0.2-0.1,0.3-0.1c-0.1-0.3-0.2-0.7-0.2-1C12.8,4,13,3.8,13.1,3.7c0.1-0.1,0.3-0.2,0.4-0.2c0.5,0,1,0,1.4,0c0-0.1,0,0.1,0,0
                c0.5,0,1-0.1,1.5,0c0.3,0.1,0.7,0,1,0C17.5,3.4,17.5,3.1,17.5,3z M11.3,4.8c-0.1-0.2-0.2-0.3-0.3-0.5c0,0,0-0.1,0.1-0.1l0,0
                c0.2,0,0.4,0,0.6,0C11.6,4.4,11.5,4.6,11.3,4.8z M11.3,4.1c0.1-0.1,0.2-0.2,0.2-0.4c0.1,0,0.2,0,0.3,0c0,0.1,0,0.2,0,0.3
                C11.7,4.1,11.5,4.1,11.3,4.1z"
            />
        </svg>
    );
};
