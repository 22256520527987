import React from 'react';

type Props = {
    className?: string;
};

export const Dota2Icon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M11.3,0c-0.1,0.2-0.1,0.2-0.1,0.4c0.1,0,0.2,0,0.3,0.1c0.2,0,0.4,0,0.6,0.3c0.1,0.1,0.4,0.1,0.6,0.1c0.3,0,0.5-0.1,0.5-0.4
                c0-0.3,0.3-0.4,0.6-0.4c0.8,0,1.5,0.1,2.3,0.1c0.2,0,0.4,0,0.6,0.1c0.3,0,0.5,0.1,0.6,0.4c0,0,0.1,0,0.1,0c0.3-0.2,0.5-0.3,0.9-0.2
                c0.2,0,0.5,0,0.7,0c0.4-0.1,0.7,0.2,1,0.4c0.1,0.1,0.1,0.4,0.1,0.7c0,0.3,0,0.6,0,0.9c0,1.3,0,2.7,0.1,4c0,0.2-0.1,0.4-0.3,0.4
                c-0.2,0.1-0.2,0.2-0.2,0.4c0,0.1-0.1,0.3-0.1,0.4c-0.2,0.2-0.2,0.4,0,0.6C20,9,19.7,10,19.7,10.9c0,0.1-0.2,0.2-0.3,0.4
                c0.2,0,0.4,0,0.7,0c0,2.7,0,5.3,0,8c-0.7-0.3-1.4-0.5-2.2-0.3c0.1,0.1,0.2,0.2,0.3,0.3c-0.4,0-0.6,0.1-0.9,0.1
                c-0.4,0-0.9,0-1.3-0.1c-0.5-0.1-1,0-1.5,0c-0.8,0.1-1.6,0-2.4,0.1c-0.4,0-0.8,0.2-1.2,0.3c-0.2,0.1-0.3,0-0.6,0
                c-0.4-0.2-0.8-0.2-1.2-0.2c-0.1,0-0.3,0.2-0.5,0.4c-0.2-0.3-0.4-0.5-0.7-0.8c-0.4,0.1-0.9,0-1.4-0.1c-0.1,0-0.2,0-0.3,0
                c-0.7,0-1.5,0-2.2,0c-0.9,0-1.7,0-2.6,0c-0.3,0-0.7-0.1-1-0.1c-0.2,0-0.2-0.1-0.2-0.3c0-1,0-2,0-3c0-0.1,0-0.3,0.1-0.4
                c0.5-0.5,0.4-1.2,0.5-1.8c0-0.3,0-0.6-0.3-0.8c-0.1-0.1-0.2-0.4-0.2-0.5c0-0.6,0-1.2,0-1.8c0.6-0.3,0.6-0.7,0.3-1.3
                C0.4,8.9,0.3,8.6,0.4,8.3c0.1-0.6,0-1.2-0.2-1.8C0,5.8-0.1,5.1,0.1,4.4c0.2-1,0.3-2,0.1-3.1c0-0.1,0-0.3,0-0.4
                c0-0.5,0.1-0.5,0.6-0.5c1.3,0,2.6,0,3.9,0c0.2,0,0.4,0.1,0.6,0.2c0.1,0,0.1,0.1,0.2,0.1c0.8,0,1.7,0.2,2.4-0.3C8.1,0.1,8.4,0,8.7,0
                C9.6,0,10.4,0,11.3,0z M2.2,3.8c0.1,0.1,0.1,0.2,0.2,0.3c1.3,1.3,2.6,2.7,3.9,4C7.2,9,8,9.9,8.9,10.8c0.9,0.9,1.7,1.8,2.6,2.7
                c0.8,0.8,1.5,1.6,2.3,2.4c0.1,0.1,0.3,0.3,0.5,0.3c0.6,0.1,1.3,0.1,1.9,0.2c0.2,0,0.4-0.1,0.5-0.3c0.6-1,0.9-2.2,1.1-3.3
                c0-0.1,0-0.2-0.1-0.3c-0.3-0.2-0.5-0.4-0.8-0.6c-0.6-0.4-1.2-0.8-1.8-1.2c-1.2-0.8-2.5-1.6-3.7-2.4C10.2,7.6,9.1,6.9,8,6.1
                C7.6,5.9,7.2,5.7,6.8,5.4C6.4,5.1,5.9,5,5.7,4.5c0-0.1-0.1-0.1-0.2-0.1C5.1,4.1,4.6,3.8,4.2,3.5C3.9,3.3,3.7,3.3,3.3,3.4
                C3,3.5,2.6,3.7,2.2,3.8z M3.8,11.9c-0.4,1-0.8,1.9-1.2,2.9c-0.1,0.2-0.1,0.5,0.1,0.7c0.4,0.3,0.8,0.6,1.2,0.9
                c0.1,0.1,0.3,0.2,0.5,0.2C5.3,16.7,6.1,16.2,7,16c0.3-0.1,0.5-0.2,0.8-0.3C6.4,14.4,5.1,13.2,3.8,11.9z M11.9,4.3
                c0.4,0.3,0.7,0.5,1,0.8c0.9,0.6,1.7,1.3,2.6,1.9c0.2,0.2,0.4,0.1,0.5-0.2C16,6.2,16.1,5.6,16.3,5c0.1-0.5,0.1-0.9-0.5-1.1
                c-0.1,0-0.1-0.1-0.1-0.1c-0.4-0.3-0.8-0.5-1.3-0.2c-0.1,0-0.2,0-0.2,0.1C13.4,3.9,12.7,4,11.9,4.3z"
            />
        </svg>
    );
};
