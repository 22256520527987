import Link from 'next/link';
import React, { useMemo } from 'react';
import { useRouter } from 'next/router';
import { MyEventsIcon } from '../../svg/dashboard/MyEventsIcon';
import { MySubscriptionsIcon } from '../../svg/dashboard/MySubscriptionsIcon';
import { MyProfileIcon } from '../../svg/dashboard/MyProfileIcon';
import { MyGamesIcon } from '../../svg/dashboard/MyGamesIcon';
import { MyPlayersProfileIcon } from '../../svg/dashboard/MyPlayersProfileIcon';
import { useAppState } from '../../../client/providers/AppProvider';
import { CommonDashboardNavigationLinks } from './CommonDashboardNavigationLinks';

type Props = {
    messages: number;
};

export const ParentDashboardNavigationLinks: React.FC<Props> = ({ messages }) => {
    const router = useRouter();

    const { user } = useAppState();

    const myPlayersProfilesLink = useMemo(() => {
        if (user) {
            if (user?.parent_profile?.child_profiles.length === 1) {
                return `/dashboard/my-players-profiles/${user.parent_profile.child_profiles[0].slug}`;
            }
        }
        return '/dashboard/my-players-profiles';
    }, [user]);
    const myPlayersProfilesLabel = useMemo(() => {
        if (user) {
            if (user?.parent_profile?.child_profiles.length === 1) {
                return "My Player's Profile";
            }
        }
        return "My Player's Profiles";
    }, [user]);

    return (
        <nav className="links">
            <CommonDashboardNavigationLinks messages={messages} />
            <div className="separator" />
            <Link href="/dashboard/my-players-events">
                <a
                    title="My Events"
                    className={`link${router.pathname == '/dashboard/my-players-events' ? ' active' : ''}`}
                >
                    <MyEventsIcon />
                    <span>{"My Player's Events"}</span>
                </a>
            </Link>
            <Link href="/dashboard/my-players-games">
                <a
                    title="My Games"
                    className={`link${router.pathname == '/dashboard/my-players-games' ? ' active' : ''}`}
                >
                    <MyGamesIcon />
                    <span>{"My Player's Games"}</span>
                </a>
            </Link>
            <Link href={myPlayersProfilesLink}>
                <a title="My Profile" className={`link${router.pathname == myPlayersProfilesLink ? ' active' : ''}`}>
                    <MyPlayersProfileIcon />
                    <span>{myPlayersProfilesLabel}</span>
                </a>
            </Link>
            <Link href="/dashboard/my-profile">
                <a title="My Profile" className={`link${router.pathname == '/dashboard/my-profile' ? ' active' : ''}`}>
                    <MyProfileIcon />
                    <span>My Profile</span>
                </a>
            </Link>
            <Link href="/dashboard/subscriptions">
                <a
                    title="Subscriptions"
                    className={`link${router.pathname == '/dashboard/subscriptions' ? ' active' : ''}`}
                >
                    <MySubscriptionsIcon />
                    <span>Subscriptions</span>
                </a>
            </Link>
        </nav>
    );
};
