import React from 'react';

type Props = {
    className?: string;
};

export const NHL22Icon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M0,15v3h2v-4H1C0.5,14,0,14.5,0,15z" />
            <path d="M7,14H3v4l4.7,0c0.4,0,0.7-0.2,0.9-0.5l0.9-1.9l-1.6-3.5L7,14z" />
            <path d="M19.7,14.3C19.5,14.1,19.3,14,19,14h-1v4h2v-3C20,14.7,19.9,14.5,19.7,14.3z" />
            <path
                d="M11.6,10.8l4-8.8h-3.3l-1.8,4l-0.5,1.1L10,7.2L7.7,2H4.3l4,8.8l1.5,3.3l0.1,0.2l1.4,3.1c0.2,0.3,0.5,0.5,0.9,0.5l4.7,0v-4
                h-4L11.6,10.8z"
            />
        </svg>
    );
};
