import React from 'react';
import { Layout } from '../common/layout/Layout';
import { SPINIcon } from '../svg/SPINIcon';

export function CustomClientErrorScreen() {
    return (
        <Layout>
            <div className="container error-container">
                <SPINIcon className="brand" />
                <h1 className="error-title">An error has occurred</h1>
                <h2>A notification has been sent to the site administrators</h2>
            </div>
        </Layout>
    );
}
