import React, { useRef, useState } from 'react';
import { BaseRouteModal } from './BaseRouteModal';

import { useModals } from '../SpinModalProvider';
import { gql } from '@urql/core';
import {
    ReferralOptionPopupContentQuery,
    ReferralOptionPopupContentDocument,
    Espin_User,
    Espin_Paid_Product_Enum,
} from 'generated/spin-graphql';
import { useQuery } from 'urql';
import { ClientOnly } from '../../../utils/ClientOnly';

type Props = {
    user: Espin_User;
    freeTrial: boolean;
    plan: Espin_Paid_Product_Enum;
};

gql`
    query ReferralOptionPopupContent {
        espin_referral_option(order_by: { sort_order: asc }) {
            ...ReferralOption
        }
    }

    fragment ReferralOption on espin_referral_option {
        id
        name
        sort_order
        description
    }
`;

const ReferralOptionTypeForm: React.FC<Props> = ({ user, freeTrial, plan }) => {
    const [result] = useQuery<ReferralOptionPopupContentQuery>({ query: ReferralOptionPopupContentDocument });
    const [referralOption, setReferralOption] = useState<string>('');
    const form = useRef();
    return (
        <ClientOnly>
            <form
                ref={form}
                action="/api/payments/checkout-sessions"
                method="POST"
                className="subscription-referral-popup-form"
            >
                <input type="hidden" name="product" value={plan} />
                <input type="hidden" name="email" value={user?.email} />
                <input type="hidden" name="userid" value={user?.id} />
                <input type="hidden" name="role" value={user?.role} />
                <input type="hidden" name="referralOption" value={referralOption} />
                {freeTrial && <input type="hidden" name="freeTrial" value={1} />}
                <div className="referral-options">
                    {!!result.data &&
                        result.data.espin_referral_option.map((refOpt, index) => (
                            <div
                                key={`referral-option-${index}`}
                                className={`referral-option${referralOption === refOpt.id ? ' active' : ''}`}
                                onClick={() => setReferralOption(refOpt.id)}
                            >
                                <h2 className="title">{refOpt.name}</h2>
                                <p className="description">{refOpt.description}</p>
                            </div>
                        ))}
                </div>
                <div className="button-container">
                    <button className={`button medium-button blue-button`}>{'Confirm'}</button>
                </div>
            </form>
        </ClientOnly>
    );
};

export const ReferralOptionSelectModal: React.FC<Props> = ({ user, freeTrial, plan }) => {
    const { closeActiveModal } = useModals();
    return (
        <BaseRouteModal
            className="subscription-referral-popup"
            content={<ReferralOptionTypeForm user={user} freeTrial={freeTrial} plan={plan} />}
            title="How did you hear about the Platinum Plan?"
            onCancel={() => closeActiveModal()}
        />
    );
};
