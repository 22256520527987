import React from 'react';

type Props = {
    className?: string;
};

export const WarzoneIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M7.1,17.3c-0.1-1.4-0.2-2.7-0.3-4c-0.1-1.5-0.2-3-0.3-4.5c0-0.1-0.1-0.2-0.1-0.3c0,0.1-0.1,0.2-0.1,0.3
                c-0.2,2.8-0.4,5.6-0.6,8.5c0,0,0,0.1,0,0.1c-1.3,0-2.6,0-4,0c-0.1-0.7-0.2-1.5-0.3-2.2C1,12.3,0.6,9.5,0.2,6.8
                C0.1,5.6-0.1,3.4,0,2.7c0.9,0,1.9,0,2.9,0C2.9,3.2,3,3.8,3,4.3c0.2,2.3,0.3,4.6,0.5,6.8c0,0.1,0.1,0.1,0.1,0.2
                c0-0.1,0.1-0.1,0.1-0.2C3.8,9.9,3.9,8.7,4,7.5C4.1,6,4.2,4.4,4.3,2.9c0-0.1,0-0.1,0-0.2c1.3,0,2.6,0,4,0c0.1,0.8,0.1,1.7,0.2,2.5
                c0.1,2,0.3,4,0.4,6C9,11.2,9,11.3,9,11.4c0-0.1,0.1-0.1,0.1-0.2c0.1-0.9,0.1-1.8,0.2-2.7c0.1-1.9,0.3-3.8,0.4-5.7
                c3.4,0,6.8,0,10.2,0c0,0.6,0,1.1,0,1.7C20,5,19.9,5.6,19.6,6.2c-1.2,2.6-2.5,5.1-3.7,7.7c0,0.1-0.1,0.2-0.1,0.3c1.4,0,2.8,0,4.2,0
                c-0.1,1-0.3,2-0.4,3c-2.5,0-5,0-7.5,0c0-0.7,0-1.3,0-2c0-0.4,0.1-0.8,0.3-1.2c1.3-2.7,2.6-5.4,3.8-8.1c0-0.1,0.1-0.2,0.1-0.3
                c-1,0-1.9,0-2.8,0c-0.3,0-0.4-0.1-0.4-0.4c0-0.3-0.1-0.5-0.1-0.8c0-0.1-0.1-0.1-0.1-0.2c0,0.1-0.1,0.1-0.1,0.2
                c-0.2,1.7-0.4,3.3-0.7,5c-0.3,2.3-0.6,4.7-0.9,7c0,0.2-0.1,0.4-0.1,0.6C9.7,17.3,8.4,17.3,7.1,17.3z"
            />
        </svg>
    );
};
