import { gql } from '@urql/core';

gql`
    fragment PlainTextLocationDisplay on espin_address {
        country
        city
        state
    }
`;

export function PlainTextLocationDisplay({ address }) {
    if (address?.country !== 'United States') {
        return (
            <>
                {address.city}, {address.country}
            </>
        );
    }
    return (
        <>
            {address.city}, {address.state}
        </>
    );
}
