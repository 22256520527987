import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { FindCollegesResultsFragment } from '../../../../../generated/spin-graphql';
import { CollegePreviewCard } from './CollegePreviewCard';
import { CTACard } from './CTACard';
import { gql } from '@urql/core';

type Props = {
    results: FindCollegesResultsFragment[];
    hasSearched: boolean;
    isSearching: boolean;
};

gql`
    fragment FindCollegesResults on espin_college {
        ...CollegeCard
        id
        name
        program_name
        subscribed
        address {
            city
            state
            country
        }
    }
`;

export const FindCollegesResults: React.FC<Props> = ({ results, hasSearched, isSearching }) => {
    if (isSearching) {
        return (
            <div className="find-colleges-results">
                <div className="results-container">
                    <Skeleton className="college-preview-card-skeleton" />
                    <Skeleton className="college-preview-card-skeleton" />
                    <Skeleton className="college-preview-card-skeleton" />
                    <Skeleton className="college-preview-card-skeleton" />
                </div>
                <div className="cta-container">
                    <Skeleton className="cta-card-skeleton" />
                    <Skeleton className="cta-card-skeleton" />
                </div>
            </div>
        );
    }

    if (hasSearched && results.length === 0) {
        return (
            <div className="find-colleges-results">
                <h2 className="no-results-found">No results found.</h2>
            </div>
        );
    }

    return (
        <div className="find-colleges-results">
            <div className="results-container">
                {results.map((college, index) => (
                    <CollegePreviewCard key={`college-${index}`} college={college} />
                ))}
            </div>
            <div className="cta-container">
                <CTACard
                    className="college-search-engine"
                    title="College Search Engine"
                    description="There are plenty of other colleges on our platform, search for more colleges and find additional opportunities!"
                    buttonLabel="Search"
                    buttonLink="/colleges"
                />
                <CTACard
                    className="membership"
                    title="Want to make recruiting easier?"
                    description="Don't know where to start? Become a Platinum Member today and discuss your opportunities with a Recruiting Specialist."
                    buttonLabel="Upgrade"
                    buttonLink="/dashboard/subscriptions"
                />
            </div>
        </div>
    );
};
