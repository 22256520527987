import React, { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { CloseIcon } from '../../svg/CloseIcon';

type DialogButtons = Array<JSX.Element>;

type Props = {
    className?: string;
    title: string;
    description?: string;
    content: JSX.Element;
    onCloseHook?: () => boolean;
    onCancel: () => void;
    buttonArray?: DialogButtons;
    subtitle?: string;
};

export function BaseRouteModal({
    className,
    onCancel,
    content,
    onCloseHook = () => true,
    title,
    description = '',
    buttonArray = [],
    subtitle,
}: Props) {
    const initialFocusRef = useRef();
    return (
        <Dialog
            open={true}
            initialFocus={initialFocusRef}
            onClose={() => {
                if (onCloseHook()) {
                    onCancel();
                }
            }}
        >
            <div className="modal">
                <Dialog.Overlay />
                <div className={`popup${className ? ` ${className}` : ''}`} ref={initialFocusRef}>
                    <div className="popup-header">
                        <div className="popup-title-container">
                            <Dialog.Title className={'popup-title'}>{title}</Dialog.Title>
                            {subtitle && <p className="popup-subtitle">{subtitle}</p>}
                        </div>
                        <button className="close-button" onClick={onCancel}>
                            <CloseIcon />
                        </button>
                    </div>
                    {description && (
                        <Dialog.Description className={'popup-description'}>{description}</Dialog.Description>
                    )}
                    <div className="popup-content">{content}</div>
                    {buttonArray.length > 0 && (
                        <div className="popup-button-container">
                            {buttonArray.map((el, i) => {
                                return <React.Fragment key={`dialog-button-${i}`}>{el}</React.Fragment>;
                            })}
                        </div>
                    )}
                </div>
            </div>
        </Dialog>
    );
}
