import React from 'react';

interface Props {
    className?: string;
}

export const MyGamesIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M10.7,12.2c-0.4-0.4-1-0.4-1.4,0l-2,2C7.1,14.4,7,14.6,7,14.9V18c0,1.1,0.9,2,2,2h2c1.1,0,2-0.9,2-2v-3.1
                c0-0.3-0.1-0.5-0.3-0.7L10.7,12.2z M11,17c0,0.6-0.4,1-1,1s-1-0.4-1-1v-1.3c0-0.3,0.1-0.5,0.3-0.7l0.7-0.7l0.7,0.7
                c0.2,0.2,0.3,0.4,0.3,0.7V17z"
            />
            <path
                d="M9.3,7.8c0.4,0.4,1,0.4,1.4,0l2-2C12.9,5.6,13,5.4,13,5.1V2c0-1.1-0.9-2-2-2H9C7.9,0,7,0.9,7,2v3.1c0,0.3,0.1,0.5,0.3,0.7
                L9.3,7.8z M9,3c0-0.6,0.4-1,1-1s1,0.4,1,1v1.3c0,0.3-0.1,0.5-0.3,0.7L10,5.7L9.3,5C9.1,4.8,9,4.5,9,4.3V3z"
            />
            <path
                d="M18,7h-3.1c-0.3,0-0.5,0.1-0.7,0.3l-2,2c-0.4,0.4-0.4,1,0,1.4l2,2c0.2,0.2,0.4,0.3,0.7,0.3H18c1.1,0,2-0.9,2-2V9
                C20,7.9,19.1,7,18,7z M17,11h-1.3c-0.3,0-0.5-0.1-0.7-0.3L14.3,10L15,9.3C15.2,9.1,15.5,9,15.7,9H17c0.6,0,1,0.4,1,1S17.6,11,17,11z
                "
            />
            <path
                d="M7.8,9.3l-2-2C5.6,7.1,5.4,7,5.1,7H2C0.9,7,0,7.9,0,9v2c0,1.1,0.9,2,2,2h3.1c0.3,0,0.5-0.1,0.7-0.3l2-2
                C8.2,10.3,8.2,9.7,7.8,9.3z M5,10.7C4.8,10.9,4.5,11,4.3,11H3c-0.6,0-1-0.4-1-1s0.4-1,1-1h1.3C4.5,9,4.8,9.1,5,9.3L5.7,10L5,10.7z"
            />
        </svg>
    );
};
