import React from 'react';

interface Props {
    className?: string;
}

export const TwitchIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="21" viewBox="0 0 20 21">
            <path
                d="M114.694,130.128v-2.511h-5c0-.105-.012-.187-.012-.268q0-7.087,0-14.174a1.357,1.357,0,0,1,.066-.409c.368-1.14.746-2.278,1.112-3.419a.237.237,0,0,1,.27-.2q9.171.007,18.341,0h.205v.264q0,6.108,0,12.215a.526.526,0,0,1-.167.406q-2.661,2.7-5.311,5.407a.5.5,0,0,1-.4.169c-1.076-.007-2.153,0-3.228-.008a.62.62,0,0,0-.5.206c-.71.738-1.43,1.467-2.153,2.193a.441.441,0,0,1-.267.138c-.962.01-1.924.006-2.885,0A.514.514,0,0,1,114.694,130.128Zm-1.9-19.09v13.409h4.39v2.541c.068-.066.106-.1.142-.138.74-.752,1.482-1.5,2.218-2.258a.454.454,0,0,1,.359-.151q2.3.008,4.6,0a.52.52,0,0,0,.333-.141c.956-.96,1.9-1.931,2.856-2.894a.47.47,0,0,0,.146-.366q-.005-4.884,0-9.767v-.233Z"
                transform="translate(-109.686 -109.145)"
            />
            <path d="M139.152,128.335V133.9H137.3v-5.561Z" transform="translate(-129.163 -122.539)" />
            <path d="M156.17,133.9h-1.82v-5.569h1.82Z" transform="translate(-141.189 -122.538)" />
        </svg>
    );
};
