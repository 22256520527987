import Link from 'next/link';
import React from 'react';
import { SPINLogo } from '../../svg/SPINLogo';
import { CloseIcon } from '../../svg/CloseIcon';
import { useRouter } from 'next/router';

type Props = {
    mobileNavOpen: boolean;
    onClick: () => void;
    onClose: () => void;
    userControls: JSX.Element;
};

export const MobileNavigation: React.FC<Props> = ({ userControls, mobileNavOpen, onClick, onClose }) => {
    const router = useRouter();

    return (
        <>
            <nav className={`mobile-navigation${mobileNavOpen ? ' open' : ''}`}>
                <div className="mobile-nav-header">
                    <Link href="/">
                        <a className="brand">
                            <SPINLogo className="spin-logo" />
                        </a>
                    </Link>
                    <button className="close-button" onClick={onClick}>
                        <CloseIcon />
                    </button>
                </div>
                <Link href="/colleges">
                    <a
                        onClick={onClose}
                        className={`navigation-item${router.pathname == '/colleges' ? ' active' : ''}`}
                    >
                        Colleges
                    </a>
                </Link>
                <div className="mobile-nav-sub-list">
                    <Link href="/colleges">
                        <a
                            onClick={onClose}
                            className={`navigation-item-sub${router.pathname == '/colleges' ? ' active' : ''}`}
                        >
                            Search Engine
                        </a>
                    </Link>
                    <Link href="/scholarships">
                        <a
                            onClick={onClose}
                            className={`navigation-item-sub${router.pathname == '/scholarships' ? ' active' : ''}`}
                        >
                            Scholarships
                        </a>
                    </Link>
                </div>
                <Link href="/players">
                    <a onClick={onClose} className={`navigation-item${router.pathname == '/players' ? ' active' : ''}`}>
                        Players
                    </a>
                </Link>
                <div className="mobile-nav-sub-list">
                    <Link href="/players">
                        <a
                            onClick={onClose}
                            className={`navigation-item-sub${router.pathname == '/players' ? ' active' : ''}`}
                        >
                            Search Engine
                        </a>
                    </Link>
                    <Link href="/leaderboards">
                        <a
                            onClick={onClose}
                            className={`navigation-item-sub${router.pathname == '/leaderboards' ? ' active' : ''}`}
                        >
                            Rankings
                        </a>
                    </Link>
                </div>
                <Link href="/high-schools">
                    <a
                        onClick={onClose}
                        className={`navigation-item${router.pathname == '/high-schools' ? ' active' : ''}`}
                    >
                        High Schools
                    </a>
                </Link>
                <Link href="/events">
                    <a onClick={onClose} className={`navigation-item${router.pathname == '/events' ? ' active' : ''}`}>
                        Events
                    </a>
                </Link>
                <Link href="/news">
                    <a onClick={onClose} className={`navigation-item${router.pathname == '/news' ? ' active' : ''}`}>
                        News
                    </a>
                </Link>
                <Link href="/pricing">
                    <a
                        onClick={onClose}
                        className={`navigation-item${router.pathname === '/pricing' ? ' active' : ''}`}
                    >
                        Pricing
                    </a>
                </Link>
                <Link href="/partners">
                    <a className={`navigation-item${router.pathname === '/partners' ? ' active' : ''}`}>Partners</a>
                </Link>

                {userControls}
            </nav>
            <div className={`navigation-modal${mobileNavOpen ? ' active' : ''}`} onClick={onClose} />
        </>
    );
};
