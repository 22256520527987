import React from 'react';

type Props = {
    className?: string;
};

export const OverwatchIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M10,0C7.5,0,5.2,0.9,3.5,2.4l2.2,2.2C6.9,3.6,8.4,3.1,10,3.1c1.6,0,3.1,0.6,4.3,1.5l2.2-2.2
                C14.8,0.9,12.5,0,10,0z M2.4,3.5C0.9,5.2,0,7.5,0,10c0,5.5,4.5,10,10,10c5.5,0,10-4.5,10-10c0-2.5-0.9-4.8-2.4-6.5l-2.2,2.2
                c0.9,1.2,1.5,2.7,1.5,4.3c0,1.1-0.3,2.2-0.7,3.1l-3.9-3.9c0,0-1.6-2.3-1.5-2.3v4.6l3.7,3.7c-1.2,1-2.8,1.7-4.5,1.7
                c-1.7,0-3.3-0.6-4.5-1.7l3.7-3.7V6.9c0,0-1.5,2.3-1.5,2.3l-3.9,3.9c-0.5-0.9-0.7-2-0.7-3.1c0-1.6,0.6-3.1,1.5-4.3L2.4,3.5z"
            />
        </svg>
    );
};
