import React from 'react';
import { ReactChildrenProps } from '../../../types/react.types';
import { CollegeSearchHistoryProvider } from './CollegeSearchHistoryProvider';
import { EventSearchHistoryProvider } from './EventSearchHistoryProvider';
import { PlayerSearchHistoryProvider } from './PlayerSearchHistoryProvider';
import { HighSchoolSearchHistoryProvider } from './HighSchoolSearchHistoryProvider';

interface Props {
    components: Array<React.JSXElementConstructor<React.PropsWithChildren<any>>>;
    children: React.ReactNode;
}

function ComposeSearchHistoryProviders(props: Props) {
    const { components = [], children } = props;

    return (
        <>
            {components.reduceRight((acc, Comp) => {
                return <Comp>{acc}</Comp>;
            }, children)}
        </>
    );
}

export function SearchHistoryProvider({ children }: ReactChildrenProps): JSX.Element {
    return (
        // add new search history providers here
        <ComposeSearchHistoryProviders
            components={[
                HighSchoolSearchHistoryProvider,
                CollegeSearchHistoryProvider,
                EventSearchHistoryProvider,
                PlayerSearchHistoryProvider,
            ]}
        >
            {children}
        </ComposeSearchHistoryProviders>
    );
}
