import React, { useRef } from 'react';
import { Dialog } from '@headlessui/react';
import { CloseIcon } from '../../../svg/CloseIcon';
import { CokeAllStarsLogo } from '../../../svg/CokeAllstarsLogo';

type DialogButtons = Array<JSX.Element>;

type Props = {
    className?: string;
    title: string;
    content: JSX.Element;
    onCloseHook?: () => boolean;
    onCancel: () => void;
    subtitle?: string;
};

export function CustomPromoPopup({ className, onCancel, content, onCloseHook = () => true }: Props) {
    const initialFocusRef = useRef();
    return (
        <Dialog
            open={true}
            initialFocus={initialFocusRef}
            onClose={() => {
                if (onCloseHook()) {
                    onCancel();
                }
            }}
        >
            <div className="modal">
                <Dialog.Overlay />
                <div className={`popup${className ? ` ${className}` : ''}`} ref={initialFocusRef}>
                    <div className="logo">
                        <CokeAllStarsLogo className="coke-allstars" />
                        <button className="logo-close-button" onClick={onCancel}>
                            <CloseIcon />
                        </button>
                    </div>

                    <div className="promo-popup-content">
                        <div className="popup-header">
                            <div className="popup-title-container">
                                <Dialog.Title
                                    className={'popup-title promo'}
                                >{`Show Off Your Skills to the Biggest Names in Collegiate Esports.`}</Dialog.Title>
                            </div>
                            <button className="close-button promo" onClick={onCancel}>
                                <CloseIcon />
                            </button>
                        </div>

                        <div className="popup-content">{content}</div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
}
