import React, { useCallback } from 'react';
import { useModals } from '../../../SpinModalProvider';
import { BaseRouteModal } from '../../BaseRouteModal';
import * as Yup from 'yup';
import { useAppState } from '../../../../../client/providers/AppProvider';
import { useFormWithSchema } from '../../../../../client/hooks/use-form-schema';
import { toast } from 'react-toastify';
import { gql } from '@urql/core';
import { Control, useFormState } from 'react-hook-form';

import { useAuthenticatedMutation } from '../../../../../client/hooks';
import { ControlledMultiSelectTextInput } from '../../../../forms/MultiSelectTextInput';
import {
    InvitePlayersToHighSchoolTeamDocument,
    InvitePlayersToHighSchoolTeamMutation,
    InvitePlayersToHighSchoolTeamMutationVariables,
} from '../../../../../generated/spin-graphql';
import { ActionSuccessError } from '../../../../../types/shared.types';

const FormDataSchema = Yup.object({
    players_to_invite: Yup.array(Yup.string().required()).min(1, 'At least 1 email or SPIN username required'),
});
type FormData = Yup.InferType<typeof FormDataSchema>;

gql`
    mutation InvitePlayersToHighSchoolTeam($input: InvitePlayersToTeamActionInput!) {
        invitePlayersToTeamAction(input: $input) {
            notFound
            alreadyInvited
            status
        }
    }
`;

type Props = {
    control: Control<any>;
};

const PlayerInviteOptions: React.FC<Props> = ({ control }) => {
    const { errors } = useFormState({ control });

    return (
        <div className="invite-players-input-container">
            <div className="title-container">
                <div className="required">*</div>
                <p className="tag-select-label">Invite Players</p>
            </div>
            <div className="title-container">
                <p className="tag-select-label">
                    Please invite players using either an email address or their exact SPIN Username.
                </p>
            </div>
            <ControlledMultiSelectTextInput
                control={control}
                error={(errors.players_to_invite as any)?.message}
                name={'players_to_invite'}
                label={``}
                placeholder="Select players to invite"
                required={true}
            />
        </div>
    );
};

function AddPlayersToTeamForm({ teamName, teamId }: ModalProps) {
    const [_, invitePlayers] = useAuthenticatedMutation<
        InvitePlayersToHighSchoolTeamMutation,
        InvitePlayersToHighSchoolTeamMutationVariables
    >(InvitePlayersToHighSchoolTeamDocument);

    const { user } = useAppState();
    const {
        register,
        handleSubmit,
        control,
        watch,
        setError,
        formState: { isSubmitting, isDirty, errors },
    } = useFormWithSchema(FormDataSchema);
    const { closeActiveModal } = useModals();
    const onSubmit = useCallback(
        async (values: FormData) => {
            const { players_to_invite } = values;
            const result = await invitePlayers(
                {
                    input: {
                        teamId,
                        members: players_to_invite,
                    },
                },
                { additionalTypenames: ['espin_team_invite', 'espin_team', 'espin_team_members'] },
            );

            if (result.error) {
                toast.error(`There was an error adding your team. Check details and try again`);
                console.error(result.error);
                return;
            }
            // check ze result to notify which team members failed to add
            const { status, notFound, alreadyInvited } = result.data?.invitePlayersToTeamAction;
            if (status === ActionSuccessError.SUCCESS) {
                // note - we can also have already invited here.
                if (alreadyInvited && alreadyInvited.length > 0) {
                    toast.success(
                        'Players invited! Players already on team or with existing invites were not invited.',
                    );
                } else {
                    toast.success('Players invited!');
                }

                closeActiveModal();
            } else {
                if (notFound && notFound.length > 0) {
                    setError('players_to_invite', {
                        type: 'custom',
                        message: `User(s) not found! Please be sure to only invite players with active accounts. If they don't have an account, have them make one first, then you can invite them to your team.`,
                    });
                } else if (alreadyInvited && alreadyInvited.length > 0) {
                    setError('players_to_invite', {
                        type: 'custom',
                        message: `All invited players are already on the team or have invites pending`,
                    });
                } else {
                    // parse out other things here
                    toast.error(`There was an error inviting your players`);
                }

                return;
            }
        },
        [invitePlayers, teamId, closeActiveModal, setError],
    );

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmit)(e);
                }}
                className="create-team-content"
            >
                <PlayerInviteOptions control={control} />
                <div className="button-container">
                    <button disabled={isSubmitting} className="button medium-button blue-button">
                        Invite
                    </button>
                </div>
            </form>
        </>
    );
}
type ModalProps = {
    teamId: string;
    teamName: string;
};
export function HighSchoolAddPlayersToTeamPopup({ teamName, teamId }: ModalProps) {
    const { closeActiveModal } = useModals();

    return (
        <BaseRouteModal
            className="add-players-to-team-popup"
            content={<AddPlayersToTeamForm teamId={teamId} teamName={teamName} />}
            description=""
            title={`Add Players to ${teamName}`}
            onCancel={() => closeActiveModal()}
        />
    );
}
