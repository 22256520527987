import React from 'react';

type Props = {
    className?: string;
};

export const ClashRoyaleIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M1.8,8.8c0-1.8,0-3.6,0-5.4c0-0.4,0.1-0.5,0.5-0.5c1.3,0,2.5,0,3.8,0c0.1,0,0.3-0.1,0.4-0.1C7.5,1.9,8.6,1,9.6,0.2
                c0.3-0.3,0.4-0.3,0.7,0c1.1,0.9,2.1,1.7,3.2,2.6c0.1,0.1,0.2,0.1,0.3,0.1c1.3,0,2.6,0,3.8,0c0.4,0,0.5,0.1,0.5,0.5
                c0,3.6,0,7.3,0,10.9c0,0.2-0.1,0.4-0.3,0.5c-2.6,1.7-5.1,3.4-7.6,5.1c-0.2,0.1-0.4,0.1-0.6,0c-2.3-1.5-4.5-3-6.8-4.5
                C2.6,15.2,2.3,15,2,14.8c-0.2-0.1-0.3-0.3-0.3-0.5C1.8,12.5,1.8,10.7,1.8,8.8z M16.6,4.3c-0.1,0-0.1,0-0.2,0c-1,0-2.1,0-3.1,0
                c-0.2,0-0.3,0-0.4-0.2C12,3.4,11,2.7,10.1,2C10,1.9,10,1.9,9.9,2C8.9,2.7,8,3.5,7.1,4.2C7,4.3,6.9,4.3,6.7,4.3c-1,0-2.1,0-3.1,0
                c-0.2,0-0.2,0-0.2,0.2c0,3,0,6,0,9.1c0,0.1,0,0.2,0.2,0.3c2.1,1.4,4.2,2.8,6.4,4.2c0.1,0.1,0.2,0.1,0.3,0c2.1-1.4,4.2-2.8,6.3-4.2
                c0.1-0.1,0.2-0.2,0.2-0.3c0-3,0-6,0-8.9C16.6,4.5,16.6,4.4,16.6,4.3z"
            />
            <path
                d="M15,7.3c-0.1,0.5-0.1,0.9-0.2,1.4c-0.2,1.6-0.5,3.2-0.7,4.8c0,0.2-0.1,0.2-0.3,0.2c-2.6,0-5.2,0-7.8,0
                c-0.1,0-0.2,0-0.3-0.2c-0.3-2-0.6-4-0.9-6.1c0,0,0-0.1,0-0.1c0.4,0,0.8,0,1.3,0c0,0,0.1,0.1,0.1,0.2c0.1,0.5,0.3,1,0.4,1.4
                C6.8,9,6.8,9.1,7,9.1c0.7,0,0.7,0,0.9-0.7c0.2-0.6,0.4-1.2,0.6-1.8c0.1-0.2,0.1-0.3,0.3-0.2c0.7,0,1.5,0,2.2,0
                c0.2,0,0.3,0.1,0.3,0.2c0.2,0.7,0.5,1.5,0.7,2.2c0.1,0.2,0.1,0.3,0.3,0.3c0.7,0,0.7,0,0.8-0.6c0.1-0.3,0.2-0.7,0.3-1
                c0-0.1,0.1-0.2,0.1-0.2C14.1,7.3,14.6,7.3,15,7.3z"
            />
        </svg>
    );
};
