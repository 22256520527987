import React from 'react';

interface Props {
    className?: string;
}

export const LoadingIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M17.2,2.9C15.4,1.1,12.8,0,10,0C4.4,0-0.1,4.5-0.1,10S4.4,20,10,20c4.7,0,8.7-3.2,9.8-7.5h-2.6c-1,2.9-3.9,5-7.2,5
	            c-4.2,0-7.6-3.4-7.6-7.5S5.8,2.5,10,2.5c2.1,0,4,0.9,5.4,2.2L17.2,2.9z"
            />
        </svg>
    );
};
