import React from 'react';

interface Props {
    className?: string;
}

export const FollowersIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? ` ${className}` : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M15.3,12H4.7c-2.2,0-4,1.8-4,4v1.3c0,1.5,1.2,2.7,2.7,2.7h13.3c1.5,0,2.7-1.2,2.7-2.7V16
                C19.3,13.8,17.5,12,15.3,12z M17.1,17.3c0,0.2-0.2,0.4-0.4,0.4H3.3c-0.2,0-0.4-0.2-0.4-0.4V16c0-1,0.8-1.8,1.8-1.8h10.7
                c1,0,1.8,0.8,1.8,1.8V17.3z"
            />
            <path
                d="M10,0C7.1,0,4.7,2.4,4.7,5.3c0,2.9,2.4,5.3,5.3,5.3c2.9,0,5.3-2.4,5.3-5.3C15.3,2.4,12.9,0,10,0z M10,8.4
                C8.3,8.4,6.9,7,6.9,5.3c0-1.7,1.4-3.1,3.1-3.1c1.7,0,3.1,1.4,3.1,3.1C13.1,7,11.7,8.4,10,8.4z"
            />
        </svg>
    );
};
