import { useNeedsOnboardingInfo } from '../../../client/hooks';
import { Footer } from '../../footer/Footer';
import { FixedProfileIncomplete } from '../../sections/FixedProfileIncomplete';
import { NavigationBar } from '../navigation/NavigationBar';
import { CustomSEO, HeadMeta } from './HeadMeta';

type Props = {
    seoOverride?: CustomSEO;
};

export const Layout: React.FC<Props> = ({ seoOverride, children }) => {
    const { needs } = useNeedsOnboardingInfo();
    return (
        <>
            <HeadMeta seo={seoOverride} />
            <NavigationBar isTransparentAtTop={false} />
            {children}
            <Footer />
            <FixedProfileIncomplete show={needs} />
        </>
    );
};
