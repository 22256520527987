import Link from 'next/link';
import React from 'react';
import { SPINLogo } from '../../svg/SPINLogo';
import { CloseIcon } from '../../svg/CloseIcon';
import { DashboardNavigationLinks } from './DashboardNavigationLinks';
import { ParentDashboardNavigationLinks } from './ParentDashboardNavigationLinks';
import { Espin_Role_Enum } from '../../../generated/spin-graphql';
import { useAppState } from '../../../client/providers/AppProvider';
import { HighSchoolDashboardNavigationLinks } from './HighSchoolDashboardNavigationLinks';

type Props = {
    open: boolean;
    onClick: () => void;
    onClose: () => void;
    messages: number;
};

export const MobileDashboardNavigation: React.FC<Props> = ({ open, onClick, onClose, messages }) => {
    const { user } = useAppState();

    let links = <DashboardNavigationLinks messages={messages} />;

    if (user?.role === Espin_Role_Enum.EspinParentUser) {
        links = <ParentDashboardNavigationLinks messages={messages} />;
    }

    if (user?.role === Espin_Role_Enum.EspinHighschoolUser) {
        links = <HighSchoolDashboardNavigationLinks messages={messages} />;
    }

    return (
        <>
            <div className={`mobile-dashboard-navigation${open ? ' open' : ''}`}>
                <div className="brand-container">
                    <Link href="/">
                        <a className="brand">
                            <SPINLogo className="spin-logo" />
                        </a>
                    </Link>
                    <button className="minimize-button" onClick={onClick}>
                        <CloseIcon />
                    </button>
                </div>
                {links}
            </div>
            <div className={`navigation-modal${open ? ' active' : ''}`} onClick={onClose} />
        </>
    );
};
