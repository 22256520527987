import React, { useCallback, useState } from 'react';
import { BaseRouteModal } from '../../BaseRouteModal';
import { useModals } from '../../../SpinModalProvider';
import { CollegeLocationEnum, FindCollegesForm } from './FindCollegesForm';
import { FindCollegesResults } from './FindCollegesResults';
import { Control, UseFormRegister, UseFormWatch } from 'react-hook-form';
import { useFormWithSchema } from '../../../../../client/hooks/use-form-schema';
import * as Yup from 'yup';
import { gql } from '@urql/core';
import {
    Espin_College_Bool_Exp,
    Espin_Subscription_Status_Enum,
    FindCollegesResultsFragment,
    FindCollegesSearchDocument,
    FindCollegesSearchQuery,
    FindCollegesSearchQueryVariables,
} from '../../../../../generated/spin-graphql';
import { useAppState } from '../../../../../client/providers/AppProvider';
import { useClient } from 'urql';
import { toast } from 'react-toastify';

type Props = {
    hasSearched: boolean;
    isSearching: boolean;
    results: FindCollegesResultsFragment[];
    register: UseFormRegister<FindCollegesFormData>;
    watch: UseFormWatch<FindCollegesFormData>;
    control: Control<FindCollegesFormData, object>;
};

gql`
    query FindCollegesSearch($subbedSearch: espin_college_bool_exp!, $unsubbedSearch: espin_college_bool_exp!) {
        unsubbedColleges: espin_college(where: $unsubbedSearch, order_by: { name: asc }, limit: 4) {
            ...FindCollegesResults
        }
        subbedColleges: espin_college(where: $subbedSearch, order_by: { name: asc }, limit: 4) {
            ...FindCollegesResults
        }
    }
`;

const FindCollegesPopupContent: React.FC<Props> = ({ results, register, watch, control, hasSearched, isSearching }) => {
    return (
        <>
            {(!hasSearched || isSearching) && <FindCollegesForm register={register} watch={watch} control={control} />}
            {isSearching ||
                (hasSearched && (
                    <FindCollegesResults results={results} isSearching={isSearching} hasSearched={hasSearched} />
                ))}
        </>
    );
};

const FindCollegesFormDataSchema = Yup.object({
    location_preference: Yup.mixed()
        .oneOf(Object.values(CollegeLocationEnum), 'Please select one.')
        .required('Please select a location preference'),
    states: Yup.mixed().test('state_check', 'Please enter a state.', (val, ctx) => {
        if (!!val && ctx.parent['location_preference'] === CollegeLocationEnum.OTHER_STATES) {
            const schema = Yup.string();
            return schema.isValidSync(val);
        }
        return true;
    }),
    scholarships: Yup.boolean(),
    primary_game: Yup.boolean(),
    gpa: Yup.string().required('Please select a GPA.'),
});
export type FindCollegesFormData = Yup.InferType<typeof FindCollegesFormDataSchema>;

export const FindCollegesPopup: React.FC<Props> = ({}) => {
    const { closeActiveModal } = useModals();
    const [results, setResults] = useState<FindCollegesResultsFragment[]>([]);
    const [isSearching, setIsSearching] = useState<boolean>(false);
    const [hasSearched, setHasSearched] = useState<boolean>(false);
    const { user } = useAppState();
    const gqlClient = useClient();

    const buildSearchCriteria = useCallback(
        (values: FindCollegesFormData, isSubbedSearch: boolean) => {
            const locationPreference = values.location_preference;

            let locationSearch: Espin_College_Bool_Exp | null = null;
            switch (locationPreference) {
                case CollegeLocationEnum.MY_STATE: {
                    locationSearch = {
                        address: { state: { _eq: user.player_profile.address.state } },
                    };
                    break;
                }
                case CollegeLocationEnum.OTHER_STATES: {
                    if (!!values.states && values.states.length) {
                        locationSearch = {
                            address: { state: { _in: values.states } },
                        };
                    }
                    break;
                }
                case CollegeLocationEnum.ANY_STATE: {
                    locationSearch = {
                        address: { country: { _eq: 'United States' } },
                    };
                    break;
                }
                case CollegeLocationEnum.ANY_LOCATION: {
                    break;
                }
                case CollegeLocationEnum.INTERNATIONAL: {
                    locationSearch = {
                        address: { country: { _neq: 'United States' } },
                    };
                    break;
                }
                default:
                    break;
            }

            let scholarshipsSearch: Espin_College_Bool_Exp | null = null;
            if (values.scholarships) {
                scholarshipsSearch = {
                    scholarships: { _eq: values.scholarships },
                };
            }

            let gamesSearch: Espin_College_Bool_Exp | null = null;
            if (values.primary_game) {
                gamesSearch = {
                    college_games: {
                        game: {
                            game_definition: {
                                _eq: user.player_profile.player_games.find((g) => g.primary === true)?.game
                                    .game_definition,
                            },
                        },
                    },
                };
            }

            let gpaSearch: Espin_College_Bool_Exp | null = null;
            if (values.gpa) {
                gpaSearch = {
                    min_gpa: { _gte: values.gpa },
                };
            }

            let subbedSearch: Espin_College_Bool_Exp = {
                _or: [
                    { subscribed: { _eq: true } },
                    { subscription: { status: { _eq: Espin_Subscription_Status_Enum.Active } } },
                ],
            };
            let unsubbedSearch: Espin_College_Bool_Exp = {
                _and: [
                    { subscribed: { _eq: false } },
                    {
                        _or: [
                            { subscription: { status: { _neq: Espin_Subscription_Status_Enum.Active } } },
                            {
                                _not: {
                                    subscription: {},
                                },
                            },
                        ],
                    },
                ],
            };

            const base: Espin_College_Bool_Exp = {
                _and: [
                    subbedSearch && isSubbedSearch && subbedSearch,
                    unsubbedSearch && !isSubbedSearch && unsubbedSearch,
                    scholarshipsSearch && scholarshipsSearch,
                    gamesSearch && gamesSearch,
                    gpaSearch && gpaSearch,
                    locationSearch && locationSearch,
                ].filter((s) => !!s),
            };

            return base;
        },
        [user],
    );

    const showResults = useCallback(
        async (values: FindCollegesFormData) => {
            const unsubbedCriteria = buildSearchCriteria(values, false);
            const subbedCriteria = buildSearchCriteria(values, true);
            setIsSearching(true);

            const r = await gqlClient
                .query<FindCollegesSearchQuery, FindCollegesSearchQueryVariables>(
                    FindCollegesSearchDocument,
                    {
                        subbedSearch: subbedCriteria,
                        unsubbedSearch: unsubbedCriteria,
                    },
                    {
                        requestPolicy: 'network-only',
                    },
                )
                .toPromise();

            if (!!r.error) {
                toast.error('Error in search');
                setResults([]);
                return;
            }

            setIsSearching(false);
            setHasSearched(true);
            setResults([...r.data.subbedColleges, ...r.data.unsubbedColleges].slice(0, 4));
        },
        [gqlClient, setHasSearched, setResults, buildSearchCriteria],
    );

    const { register, handleSubmit, control, watch, formState } = useFormWithSchema(FindCollegesFormDataSchema, {
        defaultValues: {
            gpa: '0.0',
            location_preference: CollegeLocationEnum.MY_STATE,
        },
    });

    const searchTitle = 'Find Colleges';
    const resultsTitle = 'College Results';
    const searchSubtitle =
        'Fill out the fields to get a curated list of colleges that best match what you are looking for.';
    const resultsSubtitle = 'These results best fit your search criteria. Click on them to go to their profile!';

    return (
        <BaseRouteModal
            content={
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(showResults)(e);
                    }}
                    className="find-colleges-form"
                >
                    <FindCollegesPopupContent
                        isSearching={isSearching}
                        hasSearched={hasSearched}
                        results={results}
                        register={register}
                        watch={watch}
                        control={control}
                    />
                </form>
            }
            subtitle={hasSearched ? resultsSubtitle : searchSubtitle}
            title={hasSearched ? resultsTitle : searchTitle}
            className={'find-colleges-popup'}
            onCancel={closeActiveModal}
            buttonArray={
                hasSearched
                    ? []
                    : [
                          <button
                              key="search"
                              onClick={handleSubmit((e) => showResults(e))}
                              disabled={formState.isSubmitting}
                              className="button medium-button blue-button"
                          >
                              Search
                          </button>,
                      ]
            }
        />
    );
};
