import React from 'react';

type Props = {
    className?: string;
};

export const OmegaStrikersIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 90.72 93.32">
            <g>
                <ellipse
                    className="cls-1"
                    cx="45.36"
                    cy="46.66"
                    rx="52.15"
                    ry="38.92"
                    transform="translate(-19.67 48.97) rotate(-47.85)"
                    fill="#fff"
                    // stroke="#db1274"
                    // strokeWidth={2}
                    strokeLinejoin="round"
                    strokeLinecap="round"
                />
                <path
                    className="cls-2"
                    d="M44.79,63.81h-11.05s-8.13-2.37-9.47-7.58c-1.22-4.74,0-9.95,0-9.95,0,0,9.16-18.47,20.53-18.47s9.79,0,9.79,0l17.68,13.58-5.05,1.58,10.74,9.47-14.21,1.58s-6.16-.47,0,5.68l-8.21,2.21"
                    fill="#141235"
                />
                <polygon className="cls-2" points="34.61 42.22 48.9 39.25 41.76 53.34 34.61 42.22" fill="#fff" />
            </g>
        </svg>
    );
};
