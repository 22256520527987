// import '../scripts/wdyr';
// To use why did you render, uncomment this and rename the file oldbabel.config.js to babel.config.js
// then add it into the components you need to using // <Component>.whyDidYouRender = true;

import '../styles/main.scss';
import { AppProps } from 'next/app';
import { withUrqlClient } from 'next-urql';
import React from 'react';
// hacky poly fill as auth0-react, nextjs and urql don't play nicely. sad
import fetch from 'node-fetch';
import { abortableFetch } from 'abortcontroller-polyfill/dist/cjs-ponyfill';

global.fetch = abortableFetch(fetch).fetch;
import { getClientEnv } from 'client/clientEnv';
import { Slide, ToastContainer } from 'react-toastify';
import { Auth0Provider } from '@auth0/auth0-react';

import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';
import { AppStateProvider } from '../client/providers/AppProvider';
import Router from 'next/router';
import { SpinModalProvider } from '../components/common/SpinModalProvider';
import { GoogleAnalytics } from '../components/analytics/GoogleAnalytics';
import { MixPanelAnalytics } from '../components/analytics/MixPanelAnalytics';

import { cacheExchange, dedupExchange, fetchExchange } from 'urql';
import { SearchHistoryProvider } from '../client/providers/search/SearchHistoryProvider';

import { BugSnagErrorBoundary } from '../components/logging/BugSnagLogger';

const onRedirectCallback = (appState: { returnTo?: string }) => {
    // Use Next.js's Router.replace method to replace the url
    // // how to remove the flash of bits?

    if (appState?.returnTo) {
        Router.replace(appState.returnTo);
    }
};

function ESpinSite({ Component, pageProps }: AppProps) {
    return (
        <Auth0Provider
            domain={getClientEnv().NEXT_PUBLIC_AUTH0_DOMAIN}
            clientId={getClientEnv().NEXT_PUBLIC_AUTH0_ESPIN_CLIENT_ID}
            audience={getClientEnv().NEXT_PUBLIC_AUTH0_AUDIENCE}
            redirectUri={typeof window !== 'undefined' && `${window.location.origin}/auth-callback`}
            onRedirectCallback={onRedirectCallback}
            leeway={120}
            useRefreshTokens={true}
            cacheLocation={'localstorage'}
        >
            <AppStateProvider>
                <BugSnagErrorBoundary>
                    <GoogleAnalytics />
                    <MixPanelAnalytics />

                    <SpinModalProvider>
                        <SearchHistoryProvider>
                            <Component {...pageProps} />
                        </SearchHistoryProvider>
                    </SpinModalProvider>
                </BugSnagErrorBoundary>
            </AppStateProvider>

            <ToastContainer
                autoClose={1500}
                hideProgressBar
                closeOnClick
                draggable
                position="bottom-right"
                closeButton={false}
                transition={Slide}
                style={{ zIndex: '50000' }}
            />
        </Auth0Provider>
    );
}

export default withUrqlClient(
    (ssrExchange) => ({
        url: getClientEnv().NEXT_PUBLIC_GQL_URL,
        exchanges: [dedupExchange, cacheExchange, ssrExchange, fetchExchange],
    }),
    { ssr: false },
)(ESpinSite);
