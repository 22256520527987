import React from 'react';

type Props = {
    className?: string;
};

export const RogueCompanyIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M0,1C0.1,1,0.1,1,0,1c1.6,0.8,3.2,1.7,4.8,2.5c0.8,0.4,1.7,0.9,2.5,1.3c0.2,0.1,0.2,0.1,0.1,0.3C7.2,5.6,7,6.2,6.8,6.7
                C6.7,6.9,6.8,7.2,6.9,7.4C7.1,7.7,7.4,8,7.6,8.2c0.1,0.1,0.1,0.2,0,0.2C6.7,9.1,5.9,9.7,5,10.4c-0.7,0.5-1.4,1.1-2.1,1.6
                C2.9,12,2.8,12,2.7,12c-0.4-0.4-0.9-0.9-1.3-1.3c-0.2-0.2-0.3-0.4-0.3-0.7C1,9.2,0.9,8.5,0.8,7.7C0.8,7,0.7,6.3,0.6,5.6
                C0.5,4.9,0.4,4.2,0.3,3.4C0.2,2.9,0.2,2.3,0.1,1.7C0.1,1.5,0,1.4,0,1.2C0,1.1,0,1.1,0,1z"
            />
            <path
                d="M20,1.1c-0.1,0.7-0.2,1.4-0.3,2.1c-0.1,0.6-0.2,1.3-0.2,1.9c-0.1,0.6-0.2,1.3-0.3,1.9C19.1,7.7,19.1,8.4,19,9
                c-0.1,0.4-0.1,0.8-0.2,1.2c0,0.1-0.1,0.2-0.2,0.3c-0.5,0.5-0.9,1-1.4,1.4c-0.1,0.1-0.1,0-0.1,0c-1-0.8-2-1.5-3-2.3
                c-0.6-0.4-1.1-0.8-1.7-1.3c-0.1-0.1-0.1-0.1,0-0.2C12.6,8,12.8,7.7,13,7.5c0.2-0.3,0.2-0.5,0.1-0.9c-0.2-0.5-0.4-1-0.6-1.5
                c-0.1-0.2,0-0.2,0.1-0.3c0.6-0.3,1.2-0.6,1.8-1C16.2,3,18,2,19.8,1.1C19.8,1.1,19.9,1.1,20,1.1C20,1,20,1,20,1.1z"
            />
            <path
                d="M13.2,15.9c0,0-0.1,0.1-0.1,0.1c-0.7,0.7-1.4,1.4-2.1,2.1c-0.2,0.2-0.5,0.5-0.7,0.7C10,19,9.9,19,9.8,18.8
                c-0.7-0.6-1.3-1.3-1.9-1.9c-0.3-0.3-0.5-0.5-0.8-0.8c-0.2-0.2-0.2-0.2,0-0.4c0.6-1,1.3-1.9,1.9-2.9c0.3-0.4,0.6-0.9,0.9-1.3
                c0.2-0.3,0.3-0.4,0.5,0C11.2,12.9,12.2,14.4,13.2,15.9C13.1,15.9,13.1,15.9,13.2,15.9z"
            />
            <path
                d="M6.1,15.3c-0.1-0.1-0.2-0.2-0.3-0.3c-0.7-0.7-1.4-1.4-2.1-2.1c-0.1-0.1-0.2-0.2,0-0.3c1.5-1.1,3-2.2,4.4-3.3
                c0.1-0.1,0.2-0.1,0.2,0c0.3,0.4,0.5,0.7,0.8,1.1c0.1,0.1,0.1,0.2,0,0.3c-0.3,0.5-0.6,1-0.9,1.4c-0.4,0.5-0.7,1.1-1.1,1.6
                C6.9,14.2,6.6,14.7,6.1,15.3C6.2,15.2,6.2,15.2,6.1,15.3z"
            />
            <path
                d="M13.8,15.3c-0.3-0.4-0.5-0.8-0.8-1.2c-0.7-1.1-1.4-2.2-2.2-3.3c-0.1-0.2-0.1-0.3,0-0.4c0.3-0.3,0.5-0.7,0.8-1.1
                c0.1-0.1,0.1-0.1,0.3,0c1,0.7,2,1.5,3,2.2c0.5,0.4,1,0.7,1.5,1.1c0.1,0.1,0.1,0.1,0,0.2C15.6,13.6,14.7,14.4,13.8,15.3
                C13.9,15.2,13.9,15.2,13.8,15.3z"
            />
            <path
                d="M10,4.2c0.1,0,0.1,0.1,0.1,0.1c0.3,0.6,0.6,1.2,0.9,1.8c0.1,0.3,0.3,0.5,0.4,0.8c0,0.1,0,0.1,0,0.2
                c-0.4,0.5-0.8,1.1-1.2,1.6C10,8.8,10,8.8,9.8,8.6C9.4,8.1,9,7.6,8.6,7c-0.1-0.1,0-0.1,0-0.2C8.9,6.2,9.2,5.6,9.5,5
                c0.1-0.2,0.2-0.4,0.3-0.6C9.9,4.3,9.9,4.2,10,4.2z"
            />
        </svg>
    );
};
