import React, { Dispatch, SetStateAction, useCallback } from 'react';
import { BaseRouteModal } from '../BaseRouteModal';
import { useModals } from '../../SpinModalProvider';
import { GamesSelectComponent } from '../../../forms/GameSelectComponent';
import { Control, FormState, UseFormWatch } from 'react-hook-form';
import { StatesAndCodes } from '../../../../client/static';
import * as Yup from 'yup';
import { useFormWithSchema } from '../../../../client/hooks/use-form-schema';
import { SelectField } from '../../../input/SelectField';
import { gql } from 'urql';
import { useAuthenticatedMutation, useAuthenticatedQuery } from '../../../../client/hooks';
import {
    FindPlayersFormDocument,
    FindPlayersFormQuery,
    SendMassPlayerMessageActionDocument,
    SendMassPlayerMessageActionMutation,
    SendMassPlayerMessageActionMutationVariables,
    SendMassPlayerMessageInput,
} from '../../../../generated/spin-graphql';
import { useAppState } from '../../../../client/providers/AppProvider';
import { toast } from 'react-toastify';
import { CheckboxWithControl } from '../../../input/Checkbox';

type ContentProps = {
    control: Control<SendMassCollegeMessageFormValues, object>;
    watch: UseFormWatch<SendMassCollegeMessageFormValues>;
    formState: FormState<SendMassCollegeMessageFormValues>;
};

gql`
    mutation SendMassPlayerMessageAction($input: SendMassPlayerMessageInput!) {
        sendMassPlayerMessage(input: $input) {
            messages_sent
        }
    }
`;

export const MessageTemplates = [
    {
        label:
            'Hello {SPIN Username}, the information you provided on your player profile fits what I am looking for in future recruits here at {College Name}. I would like to set up a time to discuss what opportunities we have here with you. Please let me know if you are interested in connecting.',
        value: 'all',
    },
    {
        label:
            'Hello {SPIN Username}, we are looking for {Game Name} players to grow our roster. We are interested in setting up a time to discuss what opportunities we have here at {College Name}. Please let me know if you are interested in connecting.',
        value: 'primary_game',
    },
    {
        label:
            'Hello {SPIN Username}, we are looking for players in {Player State}! We are located in {College State} and are interested in connecting to discuss what opportunities we have here. Please let me know if you are interested in connecting.',
        value: 'states',
    },
];

export const SendMassCollegeMessageFormDataSchema = Yup.object({
    message_template: Yup.object({
        label: Yup.string(),
        value: Yup.string(),
    }).required('Please enter a message template.'),
    primary_game: Yup.object({
        label: Yup.mixed(),
        value: Yup.mixed(),
    }).test('primary_game_required', 'Please select a game.', (val, ctx) => {
        if (!!val && ctx.parent['message_template'] === 'primary_game') {
            const schema = Yup.string();
            return schema.isValidSync(val);
        }
        return true;
    }),
    preferred_study_fields: Yup.array(Yup.mixed())
        .typeError('Please enter up to 3 preferred study fields.')
        .test({
            message: 'Please enter up to 3 preferred study fields.',
            test: (arr) => !!arr && arr.length <= 3 && arr.length > 0,
        }),
    states: Yup.array(Yup.mixed())
        .typeError('Please enter up to 5 states.')
        .test('states_required', 'Please enter up to 5 states.', (val, ctx) => {
            if (!!val && ctx.parent['message_template'] === 'states') {
                const schema = Yup.string();
                return schema.isValidSync(val);
            }
            return true;
        }),
    followed_players: Yup.boolean(),
});

export type SendMassCollegeMessageFormValues = Yup.InferType<typeof SendMassCollegeMessageFormDataSchema>;

const ComposeGroupMessagePopupContent: React.FC<ContentProps> = ({ control, watch, formState }) => {
    const { data, isReady } = useAuthenticatedQuery<FindPlayersFormQuery>({
        query: FindPlayersFormDocument,
    });

    return (
        <>
            <p className="description">Send a message to each player you follow that matches your criteria.</p>
            <SelectField
                error={formState.errors.message_template?.message}
                placeholder="Message"
                name="message_template"
                control={control}
                options={MessageTemplates}
                isMulti={false}
                required={true}
            />
            {watch('message_template')?.value === 'states' && (
                <SelectField
                    error={formState.errors.states?.message}
                    required={true}
                    placeholder={'States (Up to 5)'}
                    name="states"
                    control={control}
                    options={StatesAndCodes.map((s) => {
                        return { label: s.name, value: s.name };
                    })}
                    isMulti={true}
                />
            )}
            {watch('message_template')?.value === 'primary_game' && (
                <GamesSelectComponent
                    error={(formState.errors as any).primary_game?.value?.message}
                    control={control}
                    isMulti={false}
                    required={true}
                    placeholder={'Select a game'}
                    name="primary_game"
                />
            )}
            <SelectField
                error={formState.errors.preferred_study_fields?.message}
                required={true}
                placeholder={'Field of Study (Up to 3)'}
                name="preferred_study_fields"
                control={control}
                options={
                    isReady
                        ? data.espin_study_field.map((s) => {
                              return { label: s.name, value: s.name };
                          })
                        : []
                }
                isMulti={true}
            />
            <CheckboxWithControl label={'Only send to Players I follow'} name="followed_players" control={control} />
            {watch('message_template')?.value && (
                <div className="message-preview">
                    <p className="label">Message Preview</p>
                    <p className="message">
                        {MessageTemplates.find((m) => m.value === watch('message_template')?.value)?.label}
                    </p>
                </div>
            )}
        </>
    );
};

type Props = {
    setNextAvailable: Dispatch<SetStateAction<undefined | Date>>;
};
export const ComposeGroupMessagePopup: React.FC<Props> = ({ setNextAvailable }) => {
    const { closeActiveModal } = useModals();
    const { handleSubmit, watch, control, formState } = useFormWithSchema(SendMassCollegeMessageFormDataSchema);
    const { user } = useAppState();

    const [_, sendMassPlayerMessageAction] = useAuthenticatedMutation<
        SendMassPlayerMessageActionMutation,
        SendMassPlayerMessageActionMutationVariables
    >(SendMassPlayerMessageActionDocument);

    const onSubmit = useCallback(
        async (values: SendMassCollegeMessageFormValues) => {
            const input: SendMassPlayerMessageInput = {
                user_id: user?.id,
                message_template: values.message_template.label,
                states: values.states,
                primary_game: values.primary_game.value,
                preferred_study_fields: values.preferred_study_fields,
                followed_players: values.followed_players,
            };
            const result = await sendMassPlayerMessageAction({ input });
            if (result.error) {
                toast.error(`Invalid Group Messaging Fields`);
                console.error(result.error);
                return;
            }
            const messagesSent = result.data.sendMassPlayerMessage.messages_sent;
            if (messagesSent === 0) {
                toast.error(
                    `0 messages were sent, please widen your search criteria to reach more players and try again.`,
                );
            } else {
                toast.success(
                    `Group message sent to ${messagesSent} player${messagesSent >= 2 ? `s` : ``} successfully!`,
                );
                let nextAvailableDate = new Date();
                nextAvailableDate.setDate(nextAvailableDate.getDate() + 7);
                setNextAvailable(nextAvailableDate);
                closeActiveModal();
            }
        },
        [sendMassPlayerMessageAction, user?.id, closeActiveModal, setNextAvailable],
    );

    return (
        <BaseRouteModal
            content={
                <form
                    className="compose-group-message-content"
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleSubmit(onSubmit)(e);
                    }}
                >
                    <ComposeGroupMessagePopupContent control={control} watch={watch} formState={formState} />
                </form>
            }
            description=""
            className="compose-group-message-popup"
            title={'Compose Group Message'}
            onCancel={closeActiveModal}
            buttonArray={[
                <button
                    key="compose-group-message"
                    disabled={formState.isSubmitting}
                    onClick={handleSubmit((e) => onSubmit(e))}
                    className="compose-button button medium-button blue-button"
                >
                    Send Message
                </button>,
            ]}
        />
    );
};
