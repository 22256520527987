import React, { useCallback } from 'react';
import { BaseRouteModal } from '../BaseRouteModal';
import {
    AddOrEditGamePopupContentFragment,
    UpdatePlayerGameDocument,
    UpdatePlayerGameMutation,
    UpdatePlayerGameMutationVariables,
} from '../../../../generated/spin-graphql';
import { InputField } from '../../../input/InputField';

import { useModals } from '../../SpinModalProvider';
import { gql } from '@urql/core';
import { useAuthenticatedMutation } from '../../../../client/hooks';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useFormWithSchema } from '../../../../client/hooks/use-form-schema';
import { ToggleSwitch } from '../../toggles/ToggleSwitch';
import { validators } from '../../../../shared/shared.utils';
type Props = {
    game: AddOrEditGamePopupContentFragment;
    primaryGameId: string;
};

gql`
    fragment AddOrEditGamePopupContent on espin_player_game {
        id
        game {
            name
            game_definition
        }
        in_game_username
        rank
        role
        primary
        tracker
    }
`;

gql`
    mutation UpdatePlayerGame($input: espin_player_game_set_input!, $id: uuid!, $primaryGameId: uuid!) {
        updatePrimary: update_espin_player_game_by_pk(_set: { primary: false }, pk_columns: { id: $primaryGameId }) {
            id
        }
        update_espin_player_game_by_pk(_set: $input, pk_columns: { id: $id }) {
            id
        }
    }
`;
const FormDataSchema = Yup.object({
    primary: Yup.boolean().required('Required'),
    role: Yup.string().required('Required'),
    in_game_username: Yup.string().required('Required'),
    tracker: Yup.string()
        .matches(validators.httpsUrl, {
            message: 'Please enter the full Tracker.gg address, starting with https',
        })
        .required('Required'),
    rank: Yup.string().required('Required'),
});
type FormData = Yup.InferType<typeof FormDataSchema>;
const EditGamePopupContent: React.FC<Props> = ({ game, primaryGameId }) => {
    const [_, updatePlayerGame] = useAuthenticatedMutation<UpdatePlayerGameMutation, UpdatePlayerGameMutationVariables>(
        UpdatePlayerGameDocument,
    );
    const { closeActiveModal } = useModals();
    const onSubmit = useCallback(
        async (values: FormData) => {
            const { primary, rank, role, tracker, in_game_username } = values;
            const result = await updatePlayerGame(
                {
                    input: {
                        rank,
                        role,
                        in_game_username,
                        tracker,
                        primary,
                    },
                    id: game.id,
                    primaryGameId: primary ? primaryGameId : '00000000-0000-0000-0000-000000000000',
                },
                { additionalTypenames: ['espin_player_profile', 'espin_player_game'] },
            );

            // // // reset the form, but hold the values
            if (result.error) {
                toast.error('There was an error in updating your game');
                console.error(result.error);
                return;
            }
            toast.success('Game updated');
            closeActiveModal();
        },
        [game, updatePlayerGame, closeActiveModal, primaryGameId],
    );

    const {
        register,
        handleSubmit,

        formState: { isSubmitting, isDirty, errors },
    } = useFormWithSchema(FormDataSchema, {
        defaultValues: { ...game },
    });
    return (
        <form
            onSubmit={(e) => {
                e.preventDefault();
                handleSubmit(onSubmit)(e);
            }}
        >
            <div className="add-or-edit-game-content">
                <InputField
                    error={errors.in_game_username?.message}
                    htmlFor={'in_game_username'}
                    label="In-Game Username"
                    placeholder="In-Game Username"
                    required={true}
                    register={register}
                />
                <InputField
                    error={errors.rank?.message}
                    htmlFor={'rank'}
                    label="Rank"
                    placeholder="Rank"
                    required={true}
                    register={register}
                />
                <InputField
                    error={errors.role?.message}
                    htmlFor={'role'}
                    label="Role"
                    placeholder="Role"
                    required={true}
                    register={register}
                />
                <InputField
                    error={errors.tracker?.message}
                    htmlFor={'tracker'}
                    label="Tracker.gg Link"
                    placeholder="Tracker.gg Link"
                    required={false}
                    register={register}
                />
                {!game.primary ? (
                    <ToggleSwitch
                        className="primary-game-toggle"
                        label="Is this your primary game?"
                        value={game.primary}
                        register={register}
                        error={errors.primary?.message}
                        name="primary"
                    />
                ) : (
                    <div className="primary-game-toggle">
                        <div className="description">This is your primary game</div>
                    </div>
                )}
                <div className="button-container">
                    <button disabled={isSubmitting} className="button medium-button blue-button">
                        Save Changes
                    </button>
                </div>
            </div>
        </form>
    );
};

export const EditGamePopup: React.FC<Props> = ({ game, primaryGameId }) => {
    const { closeActiveModal } = useModals();

    return (
        <BaseRouteModal
            content={<EditGamePopupContent game={game} primaryGameId={primaryGameId} />}
            description=""
            title={`Edit - ${game.game.name}`}
            onCancel={closeActiveModal}
        />
    );
};
