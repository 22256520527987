import React, { useCallback } from 'react';
import { useModals } from '../../../SpinModalProvider';
import { BaseRouteModal } from '../../BaseRouteModal';
import { GamesSelectComponent } from '../../../../forms/GameSelectComponent';
import * as Yup from 'yup';
import { useAppState } from '../../../../../client/providers/AppProvider';
import { useFormWithSchema } from '../../../../../client/hooks/use-form-schema';
import { toast } from 'react-toastify';
import { InputField } from '../../../../input/InputField';
import { gql } from '@urql/core';
import { useAuthenticatedMutation } from '../../../../../client/hooks';
import invariant from 'tiny-invariant';
import {
    CreateHighSchoolTeamActionDocument,
    CreateHighSchoolTeamActionMutation,
    CreateHighSchoolTeamActionMutationVariables,
} from '../../../../../generated/spin-graphql';
import { ActionSuccessError } from '../../../../../types/shared.types';
import { PlayerInviteOptions } from './InvitePlayerOptions';

export const CreateTeamFormDataSchema = Yup.object({
    team_name: Yup.string().required('Required').min(3, 'Must have a team name with at least 3 characters'),
    game: Yup.object().shape({
        label: Yup.string(),
        value: Yup.string().required('Please select a game'),
    }),
    players_to_invite: Yup.array(Yup.string().required()),
});
export type CreateTeamFormData = Yup.InferType<typeof CreateTeamFormDataSchema>;

// TODO - restrict to non-deleted players!
gql`
    query PlayerInviteOptions {
        espin_player_profile(order_by: [{ spin_username: asc }]) {
            id
            spin_username
            user_id
        }
    }

    mutation CreateHighSchoolTeamAction($input: CreateHighSchoolTeamInput!) {
        createHighSchoolTeam(input: $input) {
            id
            notFound
            status
            comments
        }
    }
`;

function CreateTeamForm() {
    const [_, createTeam] = useAuthenticatedMutation<
        CreateHighSchoolTeamActionMutation,
        CreateHighSchoolTeamActionMutationVariables
    >(CreateHighSchoolTeamActionDocument);

    const { user } = useAppState();
    const {
        register,
        handleSubmit,
        control,
        watch,
        setError,
        formState: { isSubmitting, isDirty, errors },
    } = useFormWithSchema(CreateTeamFormDataSchema);
    const { closeActiveModal } = useModals();
    const onSubmit = useCallback(
        async (values: CreateTeamFormData) => {
            invariant(user.high_school_profile?.high_school?.id, 'No high school here');

            const { team_name, game, players_to_invite } = values;
            const result = await createTeam(
                {
                    input: {
                        name: team_name,
                        primary_game: game.value,
                        high_school_id: user.high_school_profile.high_school.id,
                        members: players_to_invite,
                    },
                },
                { additionalTypenames: ['espin_team', 'espin_team_invite'] },
            );

            if (result.error) {
                toast.error(`There was an error adding your team. Check details and try again`);
                console.error(result.error);
                return;
            }
            // check ze result to notify which team members failed to add
            const { status, notFound, id, comments } = result.data?.createHighSchoolTeam;
            if (status === ActionSuccessError.SUCCESS) {
                toast.success('Team added!');
                closeActiveModal();
            } else {
                if (notFound && notFound.length > 0) {
                    setError('players_to_invite', {
                        type: 'custom',
                        message: `User(s) not found! Please be sure to only invite players with active accounts. If they don't have an account, have them make one first, then you can invite them to your team.`,
                    });
                } else if (id === 'Duplicate Team Name') {
                    toast.error(`That team name already exists`);
                } else if (comments) {
                    toast.error(comments);
                } else {
                    // parse out other things here
                    toast.error(`There was an error editing your team`);
                }

                return;
            }
        },
        [user.high_school_profile?.high_school?.id, createTeam, closeActiveModal, setError],
    );

    return (
        <>
            <form
                onSubmit={(e) => {
                    e.preventDefault();
                    handleSubmit(onSubmit)(e);
                }}
                className="create-team-content"
            >
                <GamesSelectComponent
                    error={errors.game?.value?.message}
                    control={control}
                    isMulti={false}
                    required={true}
                    placeholder={'Select a game'}
                    name="game"
                />
                <InputField
                    error={errors.team_name?.message}
                    htmlFor={'team_name'}
                    name={'team_name'}
                    label="Team Name"
                    placeholder="Team Name"
                    required={true}
                    register={register}
                />
                <PlayerInviteOptions control={control} required={true} />
                <div className="button-container">
                    <button disabled={isSubmitting} className="button medium-button blue-button">
                        Add Team
                    </button>
                </div>
            </form>
        </>
    );
}

export function HighSchoolCreateTeamPopup() {
    const { closeActiveModal } = useModals();
    return (
        <BaseRouteModal
            className="create-team-popup"
            content={<CreateTeamForm />}
            description=""
            title="Create Team"
            onCancel={() => closeActiveModal()}
        />
    );
}
