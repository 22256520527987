// simple react select component for selecting games
import { gql } from '@urql/core';
import React, { useMemo } from 'react';
import { useAuthenticatedQuery } from '../../client/hooks';
import {
    AddOrEditGamePopupContentFragment,
    GamesSelectComponentDocument,
    GamesSelectComponentQuery,
    GamesSelectComponentQueryVariables,
} from '../../generated/spin-graphql';
import { SelectField } from '../input/SelectField';

gql`
    query GamesSelectComponent {
        espin_game {
            game_definition
            name
            image_url
            team_max_size
            website
        }
    }
`;

export type GameSelectProps = {
    control: any;
    name: string;
    isMulti?: boolean;
    defaultValue?: { label: string; value: string };
    required?: boolean;
    placeholder?: string;
    error?: string;
    disabled?: boolean;
    excludedGames?: Array<AddOrEditGamePopupContentFragment>;
};

export function GamesSelectComponent({
    control,
    name,
    defaultValue,
    required = false,
    isMulti = true,
    placeholder = 'Game',
    error,
    disabled,
    excludedGames = [],
}: GameSelectProps) {
    const { isReady, data } = useAuthenticatedQuery<GamesSelectComponentQuery, GamesSelectComponentQueryVariables>({
        query: GamesSelectComponentDocument,
    });
    const { espin_game: availableGames = [] } = data ?? {};
    const availableGamesAfterExclusions = availableGames.filter(
        (game) => !!!excludedGames.some((g) => game.game_definition === g.game.game_definition),
    );
    const options = useMemo(
        () => availableGamesAfterExclusions.map((g) => ({ label: g.name, value: g.game_definition })),
        [availableGamesAfterExclusions],
    );
    if (!isReady) {
        return <></>;
    }

    return (
        <SelectField
            className="offered-games"
            name={name}
            required={required}
            control={control}
            placeholder={placeholder}
            isMulti={isMulti}
            options={options}
            error={error}
            disabled={disabled}
        />
    );
}
