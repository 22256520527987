import React from 'react';

type Props = {
    className?: string;
};

export const RainbowSixSiegeIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path d="M10.4,12.5l0,0.2c0,0,0.2,0,0.3,0.2L10.5,13c0,0-0.2-0.4-0.8,0.1v1h1v-1.3l-0.1-0.2H10.4z" />
            <path
                d="M11.5,6.6c0,0-2.1,0-2.2,0.6l0-3.4c0,0,0.7-0.8,1.5,0l0,2h4.1V3.3c0,0,0-3.4-4.9-3.2c0,0-4.6-0.4-5,3.2v13.6
                c0,0-0.2,3.2,4.8,3.1c0,0,4.5,0.5,5.1-3V8.3C14.9,8.3,14.6,6.6,11.5,6.6z M12.9,12.1c-1.3,0-2.1,0.4-2.1,0.4v1.7
                c-0.8-0.1-1.2,0.4-1.2,0.4v3.2H9.5v0.1h-1v-0.1H8.2v-0.3h0.1v-6.2l0,0l0-0.1H8.2v-0.3h0.1v-0.2h0.2v-0.1h1.1
                c0.3,0.8,1.6,0.1,1.6,0.1c1.2-0.8,1.7-0.1,1.7-0.1V12.1z"
            />
        </svg>
    );
};
