import React from 'react';

type Props = {
    className?: string;
};

export const TekkenIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <polygon points="15.9,12.3 12.1,12.3 15,7.3 18.6,7.3 20,4.7 0,4.7 4.3,7.3 11.2,7.3 5.7,15.3 7.9,15.3 15.9,12.1 " />
        </svg>
    );
};
