import React, { createContext, useContext } from 'react';
import { Updater, useImmer } from 'use-immer';
import {
    FieldOfStudyToggleType,
    PlayerSearchFilterState,
} from '../../../components/players/search/PlayerSearchFilterComponent';
import { PlayerCardFragment } from '../../../generated/spin-graphql';
import { ReactChildrenProps } from '../../../types/react.types';

type PlayerSearchHistoryData = {
    playerFilters: PlayerSearchFilterState;
    playerSearch: string;
    previousSearchData: Array<PlayerCardFragment>;
    previousSearchCount: number;
    previousSearchOffset: number;
};

export type PlayerSearchHistory = PlayerSearchHistoryData & {
    setPlayerSearchHistory: Updater<PlayerSearchHistoryData>;
};

const PlayerSearchHistoryContext = createContext<PlayerSearchHistory>({
    playerFilters: {
        useGames: false,
        gameFilters: [],
        useMaxDistance: false,
        useGPA: false,
        fieldOfStudy: { label: 'Select field of study', value: '' },
        useFilterByFieldOfStudy: false,
        minGPA: 2,
        useGraduatingClass: false,
        advancedFieldOfStudy: [],
        graduatingClass: [],
        fieldOfStudyType: FieldOfStudyToggleType.SIMPLE,
        useState: false,
        state: '',
        usePlayersIDoNotFollow: false,
        usePlayersIFollow: false,
        useSorting: false,
        sorting: null,
        classOptions: [],
        maxDistance: 50,
        useInternationalSearch: false,
    },
    playerSearch: '',
    previousSearchData: [],
    previousSearchCount: 0,
    previousSearchOffset: 0,
    setPlayerSearchHistory: null,
});

export function PlayerSearchHistoryProvider({ children }: ReactChildrenProps): JSX.Element {
    const [searchHistory, setPlayerSearchHistory] = useImmer<PlayerSearchHistoryData>({
        playerFilters: {
            useGames: false,
            gameFilters: [],
            fieldOfStudy: { label: 'Select field of study', value: '' },
            useMaxDistance: false,
            useGPA: false,
            minGPA: 2,
            fieldOfStudyType: FieldOfStudyToggleType.SIMPLE,
            advancedFieldOfStudy: [],
            useFilterByFieldOfStudy: false,
            usePlayersIDoNotFollow: false,
            usePlayersIFollow: false,
            useGraduatingClass: false,
            graduatingClass: [],
            useState: false,
            state: '',
            useSorting: false,
            sorting: null,
            classOptions: [],
            maxDistance: 50,
            useInternationalSearch: false,
        },
        playerSearch: '',
        previousSearchCount: 0,
        previousSearchData: [],
        previousSearchOffset: 0,
    });

    return (
        <PlayerSearchHistoryContext.Provider
            value={{
                setPlayerSearchHistory,
                ...searchHistory,
            }}
        >
            {children}
        </PlayerSearchHistoryContext.Provider>
    );
}

export const usePlayerSearchHistoryProvider = (): PlayerSearchHistory =>
    useContext(PlayerSearchHistoryContext) as PlayerSearchHistory;
