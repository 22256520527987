import React from 'react';
import { RegisterOptions, UseFormRegister } from 'react-hook-form';

export interface InputFieldProps {
    label: string;
    placeholder: string;
    className?: string;
    htmlFor: string;
    register?: UseFormRegister<any>;
    required: boolean;
    value?: string;
    name?: string;
    handleChange?: (value: string) => void;
    additionalRegisterProps?: RegisterOptions;
    error?: string;
    onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
}

export const InputField: React.FC<InputFieldProps> = ({
    value,
    name,
    handleChange,
    label,
    placeholder,
    className,
    htmlFor,
    register,
    required,
    additionalRegisterProps,
    error,
    onKeyPress,
}) => {
    return (
        <div className={`input-field-container${className ? ` ${className}` : ''}${error ? ` error` : ''}`}>
            <label htmlFor={htmlFor}>{label}</label>
            <input
                className={`input-field${required ? ` has-required` : ''}${error ? ` error` : ''} `}
                placeholder={placeholder}
                name={name}
                value={value}
                onKeyPress={onKeyPress}
                // register CAN be undefined if it's a controlled field like custom documents
                {...(!!register &&
                    register(name ?? htmlFor, {
                        required,
                        ...(!!additionalRegisterProps && { ...additionalRegisterProps }),
                    }))}
                required={required}
                // custom handle change to override register
                {...(!!handleChange && { onChange: (e) => handleChange && handleChange(e.currentTarget.value) })}
            />
            {required && <span className="required">*</span>}
            {error && <p className="error-label">{error}</p>}
        </div>
    );
};
