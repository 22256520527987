import Link from 'next/link';
import React, { useMemo, useState } from 'react';
import { SPINLogo } from '../../svg/SPINLogo';
import { SPINIcon } from '../../svg/SPINIcon';
import { RightArrow } from '../../svg/RightArrow';
import { DashboardNavigationLinks } from './DashboardNavigationLinks';
import { ParentDashboardNavigationLinks } from './ParentDashboardNavigationLinks';
import { useAppState } from '../../../client/providers/AppProvider';
import { Espin_Role_Enum } from '../../../generated/spin-graphql';
import { HighSchoolDashboardNavigationLinks } from './HighSchoolDashboardNavigationLinks';

type Props = {
    messages: number;
};

export const DashboardNavigation: React.FC<Props> = ({ messages }) => {
    const [minimized, setMinimized] = useState<boolean>(false);
    const { user } = useAppState();

    let links = <DashboardNavigationLinks messages={messages} />;

    if (user?.role === Espin_Role_Enum.EspinParentUser) {
        links = <ParentDashboardNavigationLinks messages={messages} />;
    }

    if (user?.role === Espin_Role_Enum.EspinHighschoolUser) {
        links = <HighSchoolDashboardNavigationLinks messages={messages} />;
    }

    return (
        <div className={`dashboard-navigation${minimized ? ' minimized' : ''}`}>
            <div className="brand-container">
                <Link href="/">
                    <a className="brand">
                        <SPINLogo className="spin-logo" />
                        <SPINIcon className="spin-logo spin-icon" />
                    </a>
                </Link>
                <button className="minimize-button" onClick={() => setMinimized(!minimized)}>
                    <RightArrow />
                </button>
            </div>
            {links}
        </div>
    );
};
