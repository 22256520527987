import React, { createContext, useContext } from 'react';
import { Updater, useImmer } from 'use-immer';
import { CollegeSearchFilterState } from '../../../components/colleges/search/CollegeSearchFilterComponent';
import { CollegeCardFragment } from '../../../generated/spin-graphql';
import { ReactChildrenProps } from '../../../types/react.types';

export type CollegeSearchHistoryData = {
    collegeFilters: CollegeSearchFilterState;
    collegeSearch: string;
    previousSearchData: Array<CollegeCardFragment>;
    previousSearchCount: number;
    previousSearchOffset: number;
    hasSearched: boolean;
};

export type CollegeSearchHistory = CollegeSearchHistoryData & {
    setCollegeSearchHistory: Updater<CollegeSearchHistoryData>;
};

const CollegeSearchHistoryContext = createContext<CollegeSearchHistory>({
    collegeFilters: {
        gameFilters: [],
        useMaxDistance: false,
        scholarships: false,
        maxDistance: 50,
        useInternationalSearch: false,
    },
    collegeSearch: '',
    previousSearchData: [],
    previousSearchCount: 0,
    previousSearchOffset: 0,
    hasSearched: false,
    setCollegeSearchHistory: null,
});

export function CollegeSearchHistoryProvider({ children }: ReactChildrenProps): JSX.Element {
    const [historyData, setCollegeSearchHistory] = useImmer<CollegeSearchHistoryData>({
        collegeFilters: {
            gameFilters: [],
            useMaxDistance: false,
            scholarships: false,
            maxDistance: 50,
            useInternationalSearch: false,
        },
        collegeSearch: '',
        previousSearchData: [],
        previousSearchCount: 0,
        previousSearchOffset: 0,
        hasSearched: false,
    });

    return (
        <CollegeSearchHistoryContext.Provider
            value={{
                setCollegeSearchHistory,
                ...historyData,
            }}
        >
            {children}
        </CollegeSearchHistoryContext.Provider>
    );
}

export const useCollegeSearchHistoryProvider = (): CollegeSearchHistory =>
    useContext(CollegeSearchHistoryContext) as CollegeSearchHistory;
