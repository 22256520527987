import React from 'react';
import { SPINIcon } from '../svg/SPINIcon';
import { SPINLogo } from '../svg/SPINLogo';
import { FooterDescription } from './FooterDescription';
import { FooterNewsletter } from './FooterNewsletter';
import { FooterSocials } from './FooterSocials';

type Props = {};

export const Footer: React.FC<Props> = ({}) => {
    return (
        <footer className="footer">
            <div className="container">
                <FooterDescription />
                <FooterSocials />
                <FooterNewsletter />
            </div>
            <SPINIcon className="footer-background-icon" />
        </footer>
    );
};
