import { getClientEnv } from '../../client/clientEnv';
import { MixpanelEvents } from '../../shared/shared.types';

export const recordPageView = (url: string) => {
    // if (getClientEnv().NEXT_PUBLIC_MIXPANEL_TOKEN !== '') {
    //     const mixpanel = window['mixpanel'];
    //     if (mixpanel) {
    //         mixpanel.track('PageView', {
    //             path_path: url,
    //         });
    //     }

    //     // ((window as unknown) as any)?.mixpanel.track('PageView', {
    //     //     path_path: url,
    //     // });
    // }
    mixpanelRecordEvent({ event: MixpanelEvents.PAGEVIEW, properties: { path_path: url } });
};
export const mixpanelRecordEvent = ({
    event,
    properties,
}: {
    event: string;
    properties: { [key: string]: string | boolean | number | object };
}) => {
    if (getClientEnv().NEXT_PUBLIC_MIXPANEL_TOKEN !== '') {
        const mixpanel = window['mixpanel'];
        if (mixpanel) {
            mixpanel.track(event, properties);
        }
    }
};

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const recordEvent = ({ action, category, label, value }) => {
    ((window as unknown) as any)?.gtag('event', action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};
