import { gql } from '@urql/core';
import React, { useCallback, useEffect, useMemo } from 'react';
import { Control, UseFormRegister, UseFormSetValue, useFormState, UseFormWatch } from 'react-hook-form';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { useImmerReducer } from 'use-immer';
import {
    PlayerAcademicInterestsStudyFieldsFragment,
    PlayerAcademicInterestsStudySubjectsFragment,
} from '../../../generated/spin-graphql';
import { CheckmarkIcon } from '../../svg/CheckmarkIcon';
import { CloseIcon } from '../../svg/CloseIcon';

gql`
    fragment PlayerAcademicInterestsStudyFields on espin_study_field {
        name
    }

    fragment PlayerAcademicInterestsStudySubjects on espin_study_subject {
        name
        study_field {
            name
        }
    }
`;

type Props<T = any> = {
    register: UseFormRegister<T>;
    control: Control<T, object>;
    watch: UseFormWatch<T>;
    studyFieldOptions: PlayerAcademicInterestsStudyFieldsFragment[];
    studySubjectOptions: PlayerAcademicInterestsStudySubjectsFragment[];
    setValue: UseFormSetValue<T>;
    isRequired?: boolean;
    name?: string; // uses preferred_study_fields as name if left empty
    disabled?: boolean;
    outlined?: boolean;
    hasLabel?: boolean;
    isCreatable?: boolean;
};

export type PlayerStudyInterestsReducerState = {
    currentField: string;
    currentSubject: string;
    academicInterests: string[];
    disableSubject: boolean;
};

export type PlayerStudyInterestsReducerAction =
    | { type: 'setCurrentField'; currentField: string }
    | { type: 'setCurrentSubject'; currentSubject: string }
    | { type: 'setAcademicInterests'; academicInterests: string[] }
    | { type: 'addToAcademicInterests'; newItem: string }
    | { type: 'removeFromAcademicInterests'; removeItem: string }
    | { type: 'setDisableSubject'; disableSubject: boolean };

const playerStudyInterestsReducer = (
    state: PlayerStudyInterestsReducerState,
    action: PlayerStudyInterestsReducerAction,
): PlayerStudyInterestsReducerState => {
    switch (action.type) {
        case 'setCurrentField':
            return { ...state, currentField: action.currentField };
        case 'setCurrentSubject':
            return { ...state, currentSubject: action.currentSubject };
        case 'setAcademicInterests':
            return { ...state, academicInterests: action.academicInterests };
        case 'addToAcademicInterests':
            return { ...state, academicInterests: [...state.academicInterests, action.newItem] };
        case 'removeFromAcademicInterests':
            return {
                ...state,
                academicInterests: state.academicInterests.filter((item) => item !== action.removeItem),
            };
        case 'setDisableSubject':
            return { ...state, disableSubject: action.disableSubject };
    }
};

export const PlayerAcademicInterests: React.FC<Props> = ({
    register,
    control,
    watch,
    studyFieldOptions,
    studySubjectOptions,
    setValue,
    isRequired = true,
    disabled,
    outlined,
    name,
    hasLabel = true,
    isCreatable = true,
}) => {
    const [playerStudyInterestsState, playerStudyInterestsDispatch] = useImmerReducer<
        PlayerStudyInterestsReducerState,
        PlayerStudyInterestsReducerAction
    >(playerStudyInterestsReducer, {
        currentField: '',
        currentSubject: '',
        academicInterests: [],
        disableSubject: false,
    });

    const fieldOptions = useMemo(() => {
        return !!studyFieldOptions
            ? studyFieldOptions.map((o) => {
                  return { label: o.name, value: o.name };
              })
            : [];
    }, [studyFieldOptions]);

    const subjectOptions = useMemo(() => {
        return !!studySubjectOptions
            ? studySubjectOptions
                  .filter((o) => o.study_field.name === playerStudyInterestsState.currentField)
                  .filter(
                      (o) => !playerStudyInterestsState.academicInterests.includes(`${o.study_field.name} | ${o.name}`),
                  )
                  .map((o) => {
                      return { label: o.name, value: o.name };
                  })
            : [];
    }, [studySubjectOptions, playerStudyInterestsState.currentField, playerStudyInterestsState.academicInterests]);

    const { errors } = useFormState({ control });

    const addToInterests = useCallback(
        (e) => {
            e.preventDefault();
            // only add to interests if we have at least a study field
            // subject is allowed to be blank
            if (!!playerStudyInterestsState.currentField) {
                // only do the pipe stuff if it's not a custom creatable field
                const newItem =
                    playerStudyInterestsState.disableSubject || !!!playerStudyInterestsState.currentSubject
                        ? playerStudyInterestsState.currentField
                        : `${playerStudyInterestsState.currentField} | ${playerStudyInterestsState.currentSubject}`;
                setValue(
                    !!name ? name : 'preferred_study_fields',
                    [...playerStudyInterestsState.academicInterests, newItem],
                    {
                        shouldDirty: true,
                    },
                );
                playerStudyInterestsDispatch({
                    type: 'addToAcademicInterests',
                    newItem,
                });
                playerStudyInterestsDispatch({ type: 'setCurrentSubject', currentSubject: '' });
            }
        },
        [
            name,
            setValue,
            playerStudyInterestsDispatch,
            playerStudyInterestsState.academicInterests,
            playerStudyInterestsState.currentField,
            playerStudyInterestsState.currentSubject,
            playerStudyInterestsState.disableSubject,
        ],
    );

    const removeFromInterests = useCallback(
        (e, item) => {
            e.preventDefault();
            setValue(
                !!name ? name : 'preferred_study_fields',
                playerStudyInterestsState.academicInterests.filter((i) => i !== item),
                { shouldDirty: true },
            );
            playerStudyInterestsDispatch({ type: 'removeFromAcademicInterests', removeItem: item });
        },
        [name, playerStudyInterestsState.academicInterests, playerStudyInterestsDispatch, setValue],
    );

    const updateStudyField = useCallback(
        (e) => {
            // if this is a creatable field, disable the subject select
            playerStudyInterestsDispatch({
                type: 'setDisableSubject',
                disableSubject: e.__isNew__,
            });
            playerStudyInterestsDispatch({ type: 'setCurrentSubject', currentSubject: '' });
            playerStudyInterestsDispatch({ type: 'setCurrentField', currentField: e.value });
        },
        [playerStudyInterestsDispatch],
    );

    useEffect(() => {
        playerStudyInterestsDispatch({
            type: 'setAcademicInterests',
            academicInterests: watch(!!name ? name : 'preferred_study_fields') || [],
        });
    }, [name, playerStudyInterestsDispatch, watch]);

    return (
        <div className={`academic-interests${disabled ? ' disabled' : ''}${outlined ? ' outlined' : ''}`}>
            {hasLabel && (
                <div className="top-container">
                    <div className="title-container">
                        {isRequired && <div className="required">*</div>}
                        <p className="tag-select-label">Academic Interests</p>
                    </div>
                    <p className="warning-label">
                        {'Custom input will not be searchable by colleges. Only pre-defined options will be.'}
                    </p>
                </div>
            )}
            <input type="hidden" {...register(!!name ? name : 'preferred_study_fields')} />
            <div className="field-subject-selection-container">
                <div className={`select-field-container${errors.preferred_study_fields ? ` error` : ''}`}>
                    {isCreatable && (
                        <CreatableSelect
                            className={`select-container${errors.preferred_study_fields ? ` error` : ''}${
                                isRequired ? ` has-required` : ''
                            }`}
                            classNamePrefix={`select`}
                            name={`field`}
                            placeholder={`Field`}
                            value={
                                !!playerStudyInterestsState.currentField
                                    ? {
                                          label: playerStudyInterestsState.currentField,
                                          value: playerStudyInterestsState.currentField,
                                      }
                                    : null
                            }
                            options={fieldOptions}
                            onChange={(e) => updateStudyField(e)}
                        />
                    )}
                    {!isCreatable && (
                        <Select
                            isDisabled={disabled}
                            className={`select-container${errors.preferred_study_fields ? ` error` : ''} has-required`}
                            classNamePrefix={`select`}
                            name={`field`}
                            placeholder={`Field`}
                            value={
                                !!playerStudyInterestsState.currentField
                                    ? {
                                          label: playerStudyInterestsState.currentField,
                                          value: playerStudyInterestsState.currentField,
                                      }
                                    : null
                            }
                            options={fieldOptions}
                            onChange={(e) => updateStudyField(e)}
                        />
                    )}
                    {isRequired && <span className="required">*</span>}
                    {errors.preferred_study_fields && (
                        <p className="error-label">{(errors.preferred_study_fields as any)?.message}</p>
                    )}
                </div>
                <div
                    className={`select-field-container${errors.preferred_study_fields ? ` error` : ''}${
                        playerStudyInterestsState.disableSubject ? ` disabled` : ''
                    }`}
                >
                    {isCreatable && (
                        <CreatableSelect
                            className={`select-container${errors.preferred_study_fields ? ` error` : ''}${
                                isRequired ? ` has-required` : ''
                            }`}
                            classNamePrefix={`select`}
                            name={`subject`}
                            placeholder={`Subject`}
                            isDisabled={playerStudyInterestsState.disableSubject}
                            value={
                                !!playerStudyInterestsState.currentSubject
                                    ? {
                                          label: playerStudyInterestsState.currentSubject,
                                          value: playerStudyInterestsState.currentSubject,
                                      }
                                    : null
                            }
                            options={subjectOptions}
                            onChange={(e) =>
                                playerStudyInterestsDispatch({ type: 'setCurrentSubject', currentSubject: e.value })
                            }
                        />
                    )}
                    {!isCreatable && (
                        <Select
                            className={`select-container${errors.preferred_study_fields ? ` error` : ''} has-required`}
                            classNamePrefix={`select`}
                            name={`subject`}
                            placeholder={`Subject`}
                            isDisabled={disabled || playerStudyInterestsState.disableSubject}
                            value={
                                !!playerStudyInterestsState.currentSubject
                                    ? {
                                          label: playerStudyInterestsState.currentSubject,
                                          value: playerStudyInterestsState.currentSubject,
                                      }
                                    : null
                            }
                            options={subjectOptions}
                            onChange={(e) =>
                                playerStudyInterestsDispatch({ type: 'setCurrentSubject', currentSubject: e.value })
                            }
                        />
                    )}
                </div>
                <button onClick={(e) => addToInterests(e)} className="checkmark-button">
                    <CheckmarkIcon />
                </button>
            </div>
            <div className="interests">
                {!!playerStudyInterestsState.academicInterests &&
                    playerStudyInterestsState.academicInterests.map((interest, index) => (
                        <div key={`interest_${index}`} className="tag">
                            <label className="label">{interest}</label>
                            <button onClick={(e) => removeFromInterests(e, interest)} className="close-button button">
                                <CloseIcon />
                            </button>
                        </div>
                    ))}
            </div>
        </div>
    );
};
