import React from 'react';

type Props = {
    className?: string;
};

export const CallOfDutyIcon: React.FC<Props> = ({ className }) => {
    return (
        <svg className={`icon${className ? className : ''}`} width="20" height="20" viewBox="0 0 20 20">
            <path
                d="M4.5,2.5c0.1,0.1,0.1,0.2,0.1,0.3c2.4,4,4.8,8.1,7.2,12.1c0.1,0.2,0.1,0.3,0,0.5c-0.2,0.2-0.3,0.5-0.5,0.8
            c-0.1-0.2-0.2-0.4-0.3-0.6C8.5,11.4,6,7.1,3.5,2.8C3.3,2.6,3.2,2.5,3,2.5c-0.4,0-0.8,0-1.2,0c0.1,0.1,0.1,0.2,0.1,0.3
            c2.8,4.8,5.7,9.7,8.5,14.5c0.1,0.2,0.1,0.3,0,0.5c-0.2,0.2-0.3,0.5-0.4,0.8C6.7,12.8,3.4,7.2,0,1.5c0.1,0,0.2,0,0.3,0
            c2.1,0,4.1,0,6.2,0c0.2,0,0.3,0.1,0.4,0.2c1,1.7,2,3.4,3,5.1C9.9,6.9,9.9,7,10,7.1C10.1,7,10.1,7,10.2,6.9c1-1.7,2-3.4,3-5.2
            c0.1-0.2,0.2-0.2,0.4-0.2c0.3,0,0.6,0,0.9,0c-0.1,0.1-0.1,0.2-0.2,0.3C13.2,3.9,12,5.9,10.7,8c-0.1,0.2-0.1,0.3,0,0.4
            c0.2,0.3,0.4,0.7,0.6,1c0.1-0.1,0.1-0.2,0.1-0.2c1.5-2.5,2.9-5,4.4-7.4c0.1-0.2,0.3-0.3,0.5-0.3c0.3,0,0.6,0,0.9,0
            c-0.1,0.1-0.1,0.2-0.1,0.2c-1.7,2.9-3.4,5.7-5,8.6c-0.1,0.1-0.1,0.3,0,0.4c0.2,0.3,0.4,0.6,0.6,1c0.1-0.1,0.1-0.2,0.2-0.3
            c1.9-3.2,3.8-6.5,5.7-9.7c0.1-0.2,0.2-0.3,0.4-0.3c0.3,0,0.6,0,1,0c-2.4,4.1-4.8,8.2-7.3,12.4c-0.1-0.1-0.1-0.2-0.1-0.2
            C10.4,10,8.3,6.4,6.2,2.8C6.1,2.6,5.9,2.5,5.7,2.5c-0.3,0-0.6,0-0.9,0C4.7,2.5,4.6,2.5,4.5,2.5z"
            />
        </svg>
    );
};
